import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {setTheme} from 'ngx-bootstrap/utils';
import {MessagingService} from "./service/messaging.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'push-notification';

  constructor(private translate: TranslateService,
              private messagingService: MessagingService) {
    setTheme('bs4');
  }


  onActivate(): void {
    window.scroll(0, 0);
  }

  ngOnInit() {
  }
}
