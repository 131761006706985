<div style="background-color: black" class="h-100">
  <div class="row" style="padding: 20px">

    <div class="col">
      <p style="color: white;font-weight: bold">{{'client.location'|translate}}</p>

      <p style="color: white">{{mission.missionStreetAddress}} {{mission.missionCityAddress}}</p>


      <p style="color: white;font-weight: bold">{{'client.date'|translate}}</p>

      <p style="color: white">{{mission.missionStartDate | date: 'dd-MM-yyyy'}}</p>

      <p style="color: white;font-weight: bold">{{'client.time'|translate}}</p>

      <div class="col" style="color: white">
        {{mission.missionStartDate | date:'HH:mm'}}-{{mission.missionEndDate | date:'HH:mm'}}
      </div>
    </div>

    <div class="col" style="color: white">

      <h1>{{'client.assigned_agents'|translate}} ({{agents ? agents.length : 0}})</h1>
      <div class="row fa-align-center" *ngFor="let agent of agents">
        <div class="col-1">
          <mat-icon class="icon-display mt-lg-5" svgIcon="placeholder_user" *ngIf="!agent.path"></mat-icon>
          <img *ngIf="agent.path" [src]="agent.path" class="icon-display mt-lg-5" />
        </div>

        <div style="margin-left: 40px" class="col-lg-5 align-content-end mt-4">
          <p>{{agent.agentName}}</p>
          <p>{{agent.agentPhoneNumber}}</p>
          <ng-container *ngIf="agent.extensionStatus">
          <p *ngIf="agent.extensionStatus==='EXTENDED'">{{'client.extension_duration'|translate}} {{agent.extensionDuration}} hr</p>
          </ng-container>
        </div>

        <div class="col-3  mt-4" *ngIf="missionStartedForAgent(agent)">
          <button mat-button color="primary" class="button-white"
                  style="text-align: center;width: auto;text-align:left;font-size: 14px;border-radius: 10px"
                  (click)="missionInProgressMission(agent)">
            {{'client.view_mission_progress'|translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="col" style="color: white">

      <div style="text-align: left;margin-top: 30px" *ngIf="!missionStarted">
<!--        <button mat-button color="primary" class="button-white"-->
<!--                style="text-align: center;width: auto;text-align:-->
<!--                      left;font-size: 14px;border-radius: 10px" (click)="extendMission()">-->
<!--          Postpone mission-->
<!--        </button>-->
      </div>


      <button *ngIf="!missionStarted" mat-button class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 16px;background-color: black;color: white;margin-top: 20px" (click)="cancelMission()">
        {{'client.cancel_mission'|translate}}
      </button>
      <button *ngIf="!missionStarted" mat-button class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 16px;background-color: black;color: white;margin-top: 20px" (click)="downloadInvoice()">
        {{'client.view_invoice_receipt'|translate}}
      </button>
    </div>
  </div>


</div>
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>
