import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {BusinessownerdashboardhomeComponent} from "./businessownerdashboardhome/businessownerdashboardhome.component";
 import {BusinessownerdashboardComponent} from "./businessownerdashboard/businessownerdashboard.component";
import {ManageagentsComponent} from "./manageagents/manageagents.component";
import {GeneralsettingsComponent} from "./generalsettings/generalsettings.component";
import {ManageemployeeagencyComponent} from "./manageemployeeagency/manageemployeeagency.component";
import {ManagepartnersComponent} from "./managepartners/managepartners.component";
import {ManageclientsComponent} from "./manageclients/manageclients.component";
import {ReportingComponent} from "./reporting/reporting.component";
import {CanActivateGuard} from "../service/can-activate.guard";
import { ManageemployeeComponent } from "./manageemployee/manageemployee.component";
import {FaqAdminComponent} from "./faq-admin/faq-admin.component";
import {TariffManagementComponent} from "./tariff-management/tariff-management.component";

const routes: Routes = [
  {
    path: 'businessownermanager', component: BusinessownerdashboardComponent,canActivate: [CanActivateGuard],
    children: [
      {path: '', component: BusinessownerdashboardhomeComponent},
      {path: 'manageagents',component: ManageagentsComponent},
      {path:'generalsettings',component: GeneralsettingsComponent},
      {path:'manageemployeeagency',component: ManageemployeeagencyComponent},
      {path:'managepartners',component: ManagepartnersComponent},
      {path:'manageclients',component: ManageclientsComponent},
      {path:'manageemployee',component: ManageemployeeComponent},
      {path:'managefaq',component: FaqAdminComponent},
      {path:'managetariff',component: TariffManagementComponent},
      {path:'reporting',component: ReportingComponent}]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessownermanagerRoutingModule {

}
