import {
  CREATE_MISSION_PROGRESS_REQUEST,
  DELETE_MISSION_PROGRESS_REQUEST,
  GET_MISSION_PROGRESS_REQUEST,
  MissionProgressActions
} from "./mission-progress.actions";
import {MissionProgress} from "../../client/model/mission-progress";


export const initialState: MissionProgress[]= [];


export function MissionProgressReducer(state = initialState, action: MissionProgressActions): any {
  switch (action.type) {
    case CREATE_MISSION_PROGRESS_REQUEST:

      const object: MissionProgress[] = action.payload;
      return {
        missionProgress: object
      };

    case GET_MISSION_PROGRESS_REQUEST:

      return {
        ...state
      };
    case DELETE_MISSION_PROGRESS_REQUEST:

      return {
        missionProgress: null
      };
    default:
      return state;
  }
}

export const getMissionProgressRequest = (state: any) => {
  return state.missionProgress;
};
