
<ng-container>


    <form [formGroup]="rForm" class="myFormStyle"  (ngSubmit)="confirmSave()">

     
        <div  >
            <div class="row">
                <div class="col-md-6">

                   
    
                   
                    <div class="form-group">
                        <label for="email">{{'label.email'|translate}}</label>
                        <input type="email" class="myInput" id="email" formControlName="email"  placeholder="">
                    </div>

                    <div class="form-group">
                        <label for="firstName">{{'label.firstName'|translate}}</label>
                        <input type="text" class="myInput" formControlName="firstName" id="firstName"   placeholder="">

                        <span [class.hidden]="rForm['controls'].firstName.valid || !submitted" class="help-block">
                            {{'label.fieldRequired'|translate}}
                            </span>
                    </div>

                    <div class="form-group">
                        <label for="lastName">{{'label.lastName'|translate}}</label>
                        <input type="text" class="myInput" formControlName="lastName" id="lastName"   placeholder="">

                        <span [class.hidden]="rForm['controls'].lastName.valid || !submitted" class="help-block">
                            {{'label.fieldRequired'|translate}}
                            </span>
                    </div>

                    

                </div>

                <div class="col-md-6">
                   
                  
        
                    <div class="form-group">
                        <label >{{'label.group'|translate}}</label>
                        <select class="myInput" formControlName="groupId" >
                            <ng-container *ngIf="userGroups">
                                <option *ngFor="let userGroup of userGroups" value="{{userGroup.groupId}}">{{userGroup.name}}</option>
                                <!-- <a mat-list-item href="#" *ngFor="let userGroup of userGroups" routerLink="{{menu.url}}">    <mat-icon svgIcon="{{menu.icon}}" style="margin-right: 5px"></mat-icon>{{menu.name}}</a> -->
                              </ng-container>
                            <!-- <option  value="101">{{'label.employee'|translate}}</option> -->
                            <!-- <option value="100">{{'label.admin'|translate}}</option> -->
                        </select>
                    </div>

                    <div class="form-group">
                        <label >{{'label.language'|translate}}</label>
                        <select class="myInput" formControlName="language" >
                            <option value="EN">{{'language.english'|translate}}</option>
                            <option value="FR">{{'language.french'|translate}}</option>
                        </select>
                    </div>
                </div>
    
            </div>
    
        </div>
       
        
        <footer>
            <button  type="submit" class="btn btn-success">
                {{'btn.submit'|translate}}
            </button>
            <button type="button" class="btn btn-default" (click)="cancel()">
                {{'btn.cancel'|translate}}
            </button>

          </footer>
      </form>

    
</ng-container>