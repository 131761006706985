<header class="header">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="content">

    <section class="header-logo">
      <h1><a style="color: white;font-weight: bold" translate>nbs.title</a></h1>
    </section>
    <div class="header-account" >

       <span style="margin-right: 20px; color: white"  class="btn" *ngIf="!this.isLoggedIn" (click)="onRoute('client/oauth2/login')">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.login</a>
              </span>

      <span style="margin-right: 20px; color: white"  class="btn" *ngIf="!this.isLoggedIn" (click)="onRoute('client-registration')">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.register</a>
              </span>

      <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn" class="btn" (click)="onRoute('client')">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.dashboard</a>
              </span>

      <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn" class="btn" (click)="onRoute('help')">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.help</a>
              </span>

      <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn" class="btn">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.profileSettings</a>
              </span>

      <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn" class="btn" (click)="signOut()">
                  <a  style="margin-right: 20px; color: white" translate>nbs.menu.label.signOut</a>
              </span>

      <span style="margin-right: 20px; color: white" >
                <a mat-icon-button   style="margin-right: 20px; color: white" [matMenuTriggerFor]="menu"><span class="flag-icon flag-icon-round" [ngClass]="currentFlag"></span> </a>
            <mat-menu #menu="matMenu">
                                       <button mat-menu-item (click)="useLanguage('en')">
                                        <span class="flag-icon flag-icon-gb flag-icon-round"></span>
                                         <span>English</span>
                                       </button>
                                       <button mat-menu-item (click)="useLanguage('fr')">
                                        <span class="flag-icon flag-icon-fr flag-icon-round"></span>
                                         <span>French</span>
                                       </button>

</mat-menu>
 </span>

    </div>
  </div>
</header>

