import {Component, Inject, OnInit} from '@angular/core';
import {DocumentService} from "../../../service/document.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AgentRegistration, appConstants, FileProperty, ProfileStatus} from "../../../model";
import {DomSanitizer} from "@angular/platform-browser";
import {UsersService} from "../../../service/users.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-agentviewdetails',
  templateUrl: './agentviewdetails.component.html',
  styleUrls: ['./agentviewdetails.component.css']
})
export class AgentviewdetailsComponent implements OnInit {

  agentApproval:boolean=false;
  viewAgent:boolean=false;


  photoImage: any = 'assets/images/svg/profile.svg';
  cardFrontImage: any = 'assets/images/png/300.png';
  cardBackImage: any = 'assets/images/png/300.png';
  registrationDate: any;
  lastUpdateDate: any;
  nationalRegistration: any;
  businessName: any;
  firstName: any;
  lastName: any;
  emailAddress: any;
  phoneNumber: any;
  gender: any;
  dateOfBirth: any;
  height: any;
  weight: any;
  language: any;
  dressCode: any;
  streetAddress: any;
  city: any;
  zipCode: any;
  distanceRestriction: any;
  bankAccount: any;

  isAgentActive:boolean=false;


  constructor(private documentService: DocumentService,
              private userService: UsersService,
              public dialogRef: MatDialogRef<AgentviewdetailsComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private sanitizer: DomSanitizer,
              private toastr: ToastrService) { 
  }

  ngOnInit(): void {
    if(this.data.type===appConstants.viewAgentAgentApproval){
      this.agentApproval=true;
      this.viewAgent=false;
    }else if(this.data.type===appConstants.viewAgentPlainView){
      this.viewAgent=true;
      this.agentApproval=false;
    }
    this.registrationDate = this.data.agentInfo.createdDate;
    this.lastUpdateDate = this.data.agentInfo.lastUpdateDate;
    this.nationalRegistration = this.data.agentInfo.nationalRegister;
    this.businessName = this.data.agentInfo.businessName;
    this.firstName = this.data.agentInfo.firstName;
    this.lastName = this.data.agentInfo.lastName;
    this.emailAddress = this.data.agentInfo.email;
    this.phoneNumber = this.data.agentInfo.userPhoneNumber;
    this.gender = this.data.agentInfo.gender;
    this.dateOfBirth = this.data.agentInfo.dateOfBirth;
    this.height = this.data.agentInfo.height;
    this.weight = this.data.agentInfo.weight;
    this.language = this.data.agentInfo.language;
    this.dressCode = this.data.agentInfo.dressCode;
    this.streetAddress = this.data.agentInfo.streetAddress;
    this.city = this.data.agentInfo.city;
    this.zipCode = this.data.agentInfo.addressZipCode;
    this.distanceRestriction = this.data.agentInfo.distanceRestriction;
    this.bankAccount = this.data.agentInfo.bankAccountNumber;

    this.isAgentActive=(this.data.agentInfo.userProfileStatus==ProfileStatus.ACTIVE)

    this.getDocumentImage(this.data.agentInfo.agentPhotoPath, 'photo');
    this.getDocumentImage(this.data.agentInfo.agentProfessionalCardFrontPath, 'cardFront');
    this.getDocumentImage(this.data.agentInfo.agentProfessionalCardBackPath, 'cardBack');
  }


  getDocumentImage(fileName: string, docType: string) {
    this.documentService.getImageAsBase64(fileName).subscribe((result: FileProperty) => {

      if(result){
        if (docType === 'photo') {
          this.photoImage = [`data:image/${result.extension.toLowerCase()};base64,${(this.sanitizer.bypassSecurityTrustResourceUrl(result.base64) as any).changingThisBreaksApplicationSecurity}`];
        } else if (docType === 'cardFront') {
          this.cardFrontImage = [`data:image/${result.extension.toLowerCase()};base64,${(this.sanitizer.bypassSecurityTrustResourceUrl(result.base64) as any).changingThisBreaksApplicationSecurity}`];
        } else if (docType === 'cardBack') {
          this.cardBackImage = [`data:image/${result.extension.toLowerCase()};base64,${(this.sanitizer.bypassSecurityTrustResourceUrl(result.base64) as any).changingThisBreaksApplicationSecurity}`];
        }
      }
     
    });
  }

  activateAgent(){
    let data = {
      "userPhoneNumber": this.phoneNumber,
      "activate": true,
      "userType": "AGENT"
    }

    this.changeProfileStatus(data);
  }

  deactivateAgent(){
    let data = {
      "userPhoneNumber": this.phoneNumber,
      "activate": false,
      "userType": "AGENT"
    }

    this.changeProfileStatus(data);
  }

  approveAgent() {
    let data = {
      "userPhoneNumber": this.phoneNumber,
      "activate": true,
      "userType": "AGENT"
    }

    this.changeProfileStatus(data);
    
  }

  changeProfileStatus(data:any){
    this.userService.approveAgent(data).subscribe(value => {
      if(value.result=='OK'){
        this.toastr.success('Agent is now approved', 'Agent Approval!',{
          timeOut: 3000,
          closeButton: true,
          progressAnimation:'decreasing',
          progressBar: true,
          tapToDismiss: true,
          newestOnTop:true,
        });
        this.dialogRef.close(this.data);
      }else{
        this.toastr.error('There was error in approving Agent', 'Agent Approval!',{
          timeOut: 3000,
          closeButton: true,
          progressAnimation:'decreasing',
          progressBar: true,
          tapToDismiss: true,
          newestOnTop:true,
        });
        this.dialogRef.close();
      }

    })
  }

  closeViewAgent(){
    this.dialogRef.close();
  }

  rejectViewAgent(){
    this.dialogRef.close();
  }
}
