import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FavoriteAgent} from "../../model/favorite-agent";

@Component({
  selector: 'app-favorite-agent-booking',
  templateUrl: './favorite-agent-booking.component.html',
  styleUrls: ['./favorite-agent-booking.component.css']
})
export class FavoriteAgentBookingComponent implements OnInit {

  favoriteAgent: FavoriteAgent;

  constructor(public dialogRef: MatDialogRef<FavoriteAgentBookingComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data){
      this.favoriteAgent = this.data.favoriteAgent;
    }
  }

  booking(type:string) {
    this.dialogRef.close({
      action: type,
      favoriteAgent: this.favoriteAgent
    });
  }

  cancel() {
    this.dialogRef.close({
      action: 'CANCEL',
      favoriteAgent: this.favoriteAgent
    });
  }
}
