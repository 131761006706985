import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {UsersService} from "../../../service/users.service";
import {
  AgentRegistration,
  AgentStatistics,
  appConstants,
  CustomResponse,
  ResponseUserInformationList
} from "../../../model";
import {MatTableDataSource} from "@angular/material/table";
import {AgentHistoryListDatasource} from "../../../service/datasource/agent-history-list-datasource";
import {ExecutedMissionDatasource} from "../../../service/datasource/executed-mission-datasource";
import {tap} from "rxjs/operators";
import {MissionService} from "../../../service/mission.service";
import {AgentviewdetailsComponent} from "../agentviewdetails/agentviewdetails.component";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :AgentHistoryListDatasource;


  columnsToDisplay = ['agentInformation','totalMissions','completedMissions','pendingMissions','cancelledMissions','missionInProgress','clientCancelledMissions','missionExtensions','buttons'];
  private paginator: MatPaginator | undefined;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];

  constructor(private httpClient: HttpClient,private dialog: MatDialog,
              private missionService: MissionService,private userService: UsersService,
              private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.dataSource = new AgentHistoryListDatasource(this.missionService);
    this.dataSource?.loadAgentHistory();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  ngAfterViewInit() {
    this.dataSource?.counter$
      .pipe(
        tap((count) => {
          // @ts-ignore
          this.paginator.length = count;
        })
      )
      .subscribe();

    // @ts-ignore
    this.paginator.page
      .pipe(
        tap(() => this.loadAgentHistory())
      )
      .subscribe();
  }

  loadAgentHistory() {
    // @ts-ignore
    this.dataSource?.loadAgentHistory(this.paginator.pageIndex, this.paginator.pageSize);
  }

  openAgentViewDetails(phoneNumber:string){
    this.userService.getUserProfile({phoneNumber:phoneNumber}).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){
        this.openViewAgentInfo(result.data);
      }else{
      this.toastr.error("Error in processing");
      }
    });
  }

  openViewAgentInfo(data:any): void {
    const dataToSend={agentInfo:data,type:appConstants.viewAgentPlainView}
    const dialogRef = this.dialog.open(AgentviewdetailsComponent
      , {
        width: '65%',
        height: '80%',
        data:dataToSend
        // backdropClass: 'bg-color' // This is the "wanted" line
      });
  }

}
