<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

    <div fxLayout="row "style="padding-left: 0%; padding-right: 0%;">
      <div
        fxFlex="100"
        fxFlex.md="33"
        fxFlex.sm="50"
        fxFlex.xs="100"
        fxLayout="column"
        style="padding: 5px;"
      >
        <mat-card matRipple matRippleColor="var(--light-grey)"  >
          <mat-tab-group>
            <mat-tab label="{{'general_settings.permission'|translate}}">
                <app-user-permission></app-user-permission>
            </mat-tab>
            <mat-tab label="Other settings">
                <!-- <app-agentbookedmissions></app-agentbookedmissions> -->
            </mat-tab>
            <!-- <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.executedMissions'|translate}}">
                <app-agentexecutedmissions></app-agentexecutedmissions>
            </mat-tab>
            <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.activeAgents'|translate}}"> <app-activeagents></app-activeagents> </mat-tab>
            <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.disabledAgents'|translate}}"> <app-disabled-agents></app-disabled-agents> </mat-tab>
            <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.agentRegistrations'|translate}}"> <app-agentregistrations></app-agentregistrations> </mat-tab> -->
          </mat-tab-group>
        </mat-card>
      </div>
    </div>
  </div>
  
  