<div>
    <h1 style="color: black;text-align: center">{{'nbs.title'|translate}}</h1>
   
    <div class="p-margin c-align">
  
  
      <div class="p-margin"> 
  
        <form #form="ngForm">
          <intl-tel-input
            [name]="'phoneNumber'"
            [cssClass]="'image_box'"
            [labelCssClass]="'col-sm-2 col-form-label'"
            [required]="true"
            [options]="{
             localizedCountries: { ch: 'zw' }
         }"
            [onlyLocalized]="true"
            [(E164PhoneNumber)]="E164PhoneNumber"
          ></intl-tel-input>
        </form>
      </div>
      
    </div>
  
    <div class="p-margin c-align " style="text-align: center">
      <div>
        <button mat-button color="primary" class="button-center" style="color:white; text-align: center;width: 70%;text-align:
        center;" (click)="forgetPassword()">
          {{ 'label.change_password_title' | translate }}
        </button>
      </div>
  
  
 
    </div>
  </div>
  