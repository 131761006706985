<div>
  <mat-card style=" height: auto" class="c-align">
    <form [formGroup]="form">
    <h1 style="color: black;text-align: center">{{data.title}}</h1>

    <div class="row">

      <div class="col">
        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.hour'|translate}}</mat-label>
          <mat-select formControlName="hour">
            <mat-option *ngFor="let hour of hours" [value]="hour"
                        style="color:  black">{{hour}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.minute'|translate}}</mat-label>
          <mat-select formControlName="minute">
            <mat-option *ngFor="let minute of minutes" [value]="minute"
                        style="color:  black">{{minute}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">

      <div class="col">

      </div>
        <div class="col" (click)="okDialog()">
          <button  mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="okDialog()"
                   [ngClass]="form.invalid?'button-grey-center':'button-center'"
                   [disabled]="form.invalid">
            {{'client.ok'|translate}}
          </button>

        </div>


    </div>
    </form>
  </mat-card>

</div>
