<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="100"
         fxFlex.md="100"
         fxFlex.sm="100"
         fxFlex.xs="100"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label"> {{'nbs.businessownermanager.manageagents.missionInfo.clientPhoneNumber'|translate}} </span><span class="contentText"> +{{missionData?.clientPhoneNumber}}</span>
    </div>

  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.name'|translate}} </span><span class="contentText">{{missionData?.missionName}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.status'|translate}} </span><span class="contentText">{{missionData?.missionStatus}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.description'|translate}} </span><span class="contentText">{{missionData?.missionDescription}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.type'|translate}} </span><span class="contentText">{{missionData?.missionType}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.startDate'|translate}} </span><span class="contentText">{{missionData?.missionStartDate | date: 'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.endDate'|translate}}  </span><span class="contentText">{{missionData?.missionEndDate | date: 'dd/MM/yyyy HH:mm'}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.dateCreated'|translate}} </span><span class="contentText">{{missionData?.missionDateCreated | date: 'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.duration'|translate}} </span><span class="contentText">{{missionData?.missionDurationHours}} hrs</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}} </span><span class="contentText">{{missionData?.missionStreetAddress}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.city'|translate}} </span><span class="contentText">{{missionData?.missionCityAddress}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label"> {{'nbs.businessownermanager.manageagents.missionInfo.zipCode'|translate}} </span><span class="contentText">{{missionData?.missionZipCodeAddress}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.totalNumberOfAgents'|translate}} </span><span class="contentText">{{missionData?.numberOfAgents}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.numberOfFemaleAgents'|translate}} </span><span class="contentText">{{missionData?.numberOfMaleAgents}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.numberOfMaleAgents'|translate}} </span><span class="contentText">{{missionData?.numberOfFemaleAgents}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.missionDressCode'|translate}} </span><span class="contentText">{{missionData?.missionDressCode}}</span>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <span class="label">{{'nbs.businessownermanager.manageagents.missionInfo.missionLanguage'|translate}} </span><span class="contentText">{{missionData?.language}}</span>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <button mat-button (click)="closeViewMission()"  class="button-center" style="background-color: var(--danger); text-align: center;width: 70%;text-align:
        center;" >
      {{'nbs.businessownermanager.manageagents.missionInfo.closeButton'|translate}}
    </button>
  </div>
</div>
