import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { PasswordResetInitiateRequest } from 'src/assets/model/password-reset-initiate-request';
import { PasswordResetRequest } from 'src/assets/model/password-reset-request';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  rForm: FormGroup; 
  submitted:boolean=false;

  phoneNumber="";


  passwordResetIntiateRequest: PasswordResetInitiateRequest = new PasswordResetInitiateRequest();

  passwordResetRequest: PasswordResetRequest = new PasswordResetRequest();

  constructor(
    private fb: FormBuilder,
    private  authService: AuthenticationService,
     @Inject(MAT_DIALOG_DATA) public data: any,
     public dialogRef: MatDialogRef<ChangePasswordComponent>,
     private snackBar: MatSnackBar
  ) {
    this.initForm();
   }
 
  initForm(){

    this.rForm = this.fb.group({
      otpCode: ['',Validators.required],
      newPassword: ['',Validators.required],
      newPasswordConfirmation: ['',Validators.required]
    });
  }

  // private String requestIdentifier;
  // private String otpCode;
  // private String newPassword;
  // private String newPasswordConfirmation;

  ngOnInit(): void {

    this.phoneNumber=this.data.phoneNumber

    this.sendInitialResquest();
  }

  submit(){
    this.submitted=true;

    if(this.rForm.valid){
      if(this.rForm.get("newPassword")?.value===this.rForm.get("newPasswordConfirmation")?.value){
        Object.assign(this.passwordResetRequest,this.rForm.value);

        this.authService.resetPassord(this.passwordResetRequest)
        .subscribe((data: any) => {

          if(data.success===true){
            this.openSnackBar('Password reset successfully', 'Close');
            this.dialogRef.close();
            
          }else{
            this.openSnackBar('Password reset failed. Please retry', 'Close');
          }
          
          
        }, error => {
          this.openSnackBar('Password reset failed. Please retry', 'Close');
        });

      }
    }

    
  }


  sendInitialResquest(){
   
    this.passwordResetIntiateRequest.username= this.phoneNumber;

    this.authService.initiateResetPassord(this.passwordResetIntiateRequest)
    .subscribe((data: any) => {

      if(data.success===true){
        this.openSnackBar('OTP sent successfully', 'Close');

        this.passwordResetRequest.requestIdentifier=data.requestIdentifier;
      }else{
        this.openSnackBar('Reset password initialization failed', 'Close');
      }
    
     
    }, error => {
      this.openSnackBar('Reset password initialization failed', 'Close');
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
