import {FavoriteAgent} from "./favorite-agent";

export class MissionRequest {

  clientMobile?: string;
  missionName?: string;
  missionDescription?: string;
  missionPrice: number;
  agents?: string[];
  missionStartDate: string;
  mainMissionId: string;
  missionEndDate: string;
  missionStreetAddress?: string;
  missionCityAddress?: string;
  missionZipcodeAddress?: string;
  numberOfAgents: number;
  latitude?: number;
  longitude?: number;
  missionType?: string;
  missionDressCode?: string;
  language?: string;
  numberOfMaleAgents?: number;
  numberOfFemaleAgents?: number;
  gender?: string;
  missionDuration: number;
  listFavoriteAgents?: FavoriteAgent[];
  isMissionExtension: boolean;
  deleted: boolean;
  missionId: any;
  splitMission:any;

}


export class TariffRequest {

  clientMobile?: string;
  clientPhoneNumber:string;
  missionName?: string;
  missionDescription?: string;
  missionPrice: number;
  agents?: string[];
  missionStartDate: String;
  missionEndDate: String;
  maxAgentTime:string;
  missionStreetAddress?: string;
  missionCityAddress?: string;
  missionZipcodeAddress?: string;
  numberOfAgents?: number;
  latitude?: number;
  longitude?: number;
  missionType?: string;
  missionDressCode?: string;
  language?: string;
  numberOfMaleAgents?: number;
  numberOfFemaleAgents?: number;
  gender?: string;
  missionDuration: number;
  listFavoriteAgents?: FavoriteAgent[];
  tempDate: Date;
  tempEndTime: string;
  tempStartTime: string;
  clientPaymentRate:number;

}

export class TariffRequestMessage {
  clientPhoneNumber: string;
  numberOfAgents: number;
  missionStartDate: string;
  missionEndDate: string;
  extension: boolean;
  amount: boolean;
  clientPaymentRate:number;
}

export class MissionUpdateRequest {
  missionId: any;
  gatewayReference?: string;
  deleted: boolean;

}

