import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse} from "../../model";
import {StorageService} from "../../service/storage.service";
import {UserProfile} from "../model/user-profile";
import {MissionRequest} from "../model/mission-request";


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) {

  }


  getAccount(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.GET_ACCOUNT_URL}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }
  buyLicense(accountLicenseBody: any): Observable<CustomResponse> {
    const url = `/api/account/transaction/buy`;
    return this.http.post<CustomResponse>(url, accountLicenseBody);
  }

  buyLicenseWithPaymentGateway(accountLicenseBody: any): Observable<CustomResponse> {
    const url = `/api/account/subscription/gateway/payment/buy`;
    return this.http.post<CustomResponse>(url, accountLicenseBody);
  }
}
