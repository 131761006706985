import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public static readonly GROUPID = 'GROUPID';
  public static readonly MENU = 'MENU';
  public static readonly USER_TOKEN = 'USER_TOKEN';
  public static readonly USER_PHONE_NUMBER = 'USER_PHONE_NUMBER';
  public static readonly USERNAME="USERNAME";
  public static readonly USER_TYPE= 'USER_TYPE';
  public static readonly OTP_VALIDATION_DATA = 'OTP_VALIDATION_DATA';
  public static readonly CLIENT_AGENT_PROFILE = 'CLIENT_AGENT_PROFILE';
  public static readonly USER_CATEGORY = 'USER_CATEGORY';
  public static readonly BOOKING_PAYMENT = 'BOOKING_PAYMENT';
  public static readonly USER_REGISTRATION = 'USER_REGISTRATION';
  public static readonly USER_LANGUAGE="USER_LANGUAGE";
  public static readonly MISSION_EXTENSION="MISSION_EXTENSION";
  public static readonly PAYMENT_STATUS="PAYMENT_STATUS";
  public static readonly MISSION_EXTENSION_WAITING="MISSION_EXTENSION_WAITING";
  public static readonly SELECTED_FAV_AGENT="SELECTED_FAV_AGENT";

  constructor() { }


  saveObject(key: string, object: any): void {
    this.deleteItem(key);
    localStorage.setItem(key, JSON.stringify(object));
  }

  saveString(key: string, data: string): void {
    this.deleteItem(key);
    localStorage.setItem(key, data);
  }

  getItem(key: string): string {
    return <string>localStorage.getItem(key);
  }

  deleteItem(key: string): void {
    localStorage.removeItem(key);
  }


  clear(): void {
    localStorage.clear();
  }
}
