import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {AgentHistory, Mission} from "../../model";
import {BehaviorSubject, Observable, of} from "rxjs";
import {MissionService} from "../mission.service";
import {catchError, finalize} from "rxjs/operators";

export class AgentHistoryListDatasource implements DataSource<AgentHistory>{

  private agentHistorySubject = new BehaviorSubject<AgentHistory[]>([]);
  private agentHistoryData:AgentHistory[]=[];
  private agentHistoryDataUnfiltered:AgentHistory[]=[];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private missionService: MissionService) { }


  connect(collectionViewer: CollectionViewer): Observable<AgentHistory[]> {
    return this.agentHistorySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.agentHistorySubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  loadAgentHistory(pageNumber = 0, pageSize = 5) {
    this.loadingSubject.next(true);
    this.missionService.getAgentHistoryList({ page: pageNumber, size: pageSize })
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result:any) => {
          this.agentHistorySubject.next(result.data.content);
          this.countSubject.next(result.data.totalElements);
          this.agentHistoryDataUnfiltered=result.data.content;
        }
      );
  }

  loadFiltered(filterValue:string){
    const searchValue=filterValue.trim().toLowerCase();
    this.agentHistoryData = this.agentHistoryDataUnfiltered.filter(mission=>{
      return (mission.phoneNumber.toLowerCase().includes(searchValue) || mission.agentName.toLowerCase().includes(searchValue))
    });
    this.agentHistorySubject.next(this.agentHistoryData);

  }
}
