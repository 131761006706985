
<ng-container *ngIf="clientDetails" >

  <!-- {{clientDetails | json}} -->
 {{'label.changeRateFor'|translate}} : {{clientDetails.businessName}}

  <form class="myFormStyle" [formGroup]="form"  >


    <div  >

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.currentRate'|translate}}</label>
            <input type="text" class="myInput" value="{{currentRate}}"  formControlName="currentRate"    readonly  placeholder="{{currentRate}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.newRate'|translate}}</label>
            <input type="text" class="myInput"  value="{{newRate}}" formControlName="newPaymentRate"   placeholder="">

          </div>


        </div>

      </div>


    </div>


    <footer>
      <button  (click)="changeRate(clientDetails.businessPhoneNumber)" class="btn btn-danger">
        {{'btn.changeRate'|translate}}
      </button>

      <button type="button" class="btn btn-default" (click)="cancel()">
        {{'btn.dismiss'|translate}}
      </button>

    </footer>
  </form>


</ng-container>
