import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {CustomResponse, Mission, RevenueSummary} from "../../model";
import {MissionService} from "../../service/mission.service";

@Component({
  selector: 'app-manageagents',
  templateUrl: './manageagents.component.html',
  styleUrls: ['./manageagents.component.css']
})
export class ManageagentsComponent implements OnInit {


  revenueSummary:RevenueSummary|undefined;

  constructor(private missionService: MissionService ) { }

  ngOnInit(): void {
    this.loadRevenueSummary()
  }

  loadRevenueSummary(){
    this.missionService.getRevenueSummary().subscribe((result:CustomResponse)=>{
      this.revenueSummary=result.data;
    });
  }

}
