import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {LoginComponent} from "../../../common/login/login.component";
import {AgentviewdetailsComponent} from "../agentviewdetails/agentviewdetails.component";
import {UsersService} from "../../../service/users.service";
import {AgentRegistration, appConstants, ResponseUserInformationList} from "../../../model";

@Component({
  selector: 'app-agentregistrations',
  templateUrl: './agentregistrations.component.html',
  styleUrls: ['./agentregistrations.component.css']
})
export class AgentregistrationsComponent implements OnInit, AfterViewInit {

searchValue="";
  dataSource :any=[];
  resultsLength = 0;


  columnsToDisplay = ['agentName','phoneNumber', 'dob','action'];
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(private httpClient: HttpClient,private dialog: MatDialog,private usersService: UsersService) {
 /*   this.httpClient.get<AgentRegistration[]>("assets/data/test-agents.json").subscribe(data =>{
      console.log(data);
      this.dataSource = new MatTableDataSource<AgentRegistration>(data);
    });*/

    this.fetchAgentsPendingApproval();

  }

  fetchAgentsPendingApproval(){
    this.usersService.getAgentsPendingApproval().subscribe((users:any)=>{
      //console.log(users);
      let resp = users as ResponseUserInformationList;
      this.resultsLength=resp.data.length;
      this.dataSource = new MatTableDataSource<AgentRegistration>(resp.data);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }


  openSignInDialog(data:any): void {
    const dataToSend={agentInfo:data,type:appConstants.viewAgentAgentApproval}
    const dialogRef = this.dialog.open(AgentviewdetailsComponent
      , {
        width: '65%',
        height: '80%',
        data:dataToSend
        // backdropClass: 'bg-color' // This is the "wanted" line
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
        const index = this.dataSource.data.indexOf(result.id);
        console.log(index);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        this.dataSource.paginator = this.paginator;
      }
    });
  }



}
