<div class="row">

  <div class="col-sm-4">
    <mat-card  style="text-align: left; height: 120px">
      <p style="color: darkgrey;font-size: 12px;padding: 0px;font-weight: bold;">{{'client.account_balance'|translate}}</p>
      <p style="color: black;font-size: 16px;padding: 0px;font-weight: bold;">{{accountBalance | currency:'EUR'}}</p>
    </mat-card>
  </div>
  <div class="col-sm-8">
    <mat-card  style="text-align: left; height: 120px">
      <p style="color: darkgrey;font-size: 12px;padding: 0px;font-weight: bold;">{{'client.favourite_agents'|translate}}</p>
      <div class="col">

        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.agents'|translate}}</mat-label>
          <mat-select id="agent">
            <mat-option *ngFor="let val of agents" [value]="val"
                        style="color:  black" (click)="selectedAgent(val)">{{val.agentName}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
  </div>

</div>
