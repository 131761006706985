<app-client-header *ngIf="_type!=='sub_user'"></app-client-header>
<div class="my-3">
  <mat-card style=" height: auto;width: 100%">
    <div style="margin-left: 20%; margin-right: 20%">
      <form [formGroup]="form">
        <div class="row">

          <div class="col">

            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'label.firstName'|translate}}</mat-label>
              <input matInput placeholder="{{'label.firstName'|translate}}" formControlName="firstName" required>

            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'label.lastName'|translate}}</mat-label>
              <input matInput placeholder="{{'label.lastName'|translate}}" formControlName="lastName" required>

            </mat-form-field>
          </div>
        </div>
        <div class="col">

          <div>
            <mat-radio-group [formControl]="floatLabelControl">
              <mat-radio-button *ngIf="_type!=='sub_user'" value="auto" class="w-50" (click)="this.isBusiness('auto')">
                <div class="border border-secondary" style="border: #6d6d6d; margin: 10px;padding: 10px">
                  {{'client.is_it_for_business_usage'|translate}}
                </div>
              </mat-radio-button>
              <mat-radio-button value="always" (click)="this.isBusiness('always')">
                <div class="border border-secondary" *ngIf="_type!=='sub_user'" style="border: #6d6d6d; margin: 10px;padding: 10px">
                  {{'client.is_it_for_personal_usage'|translate}}
                </div>
                <div class="border border-secondary" *ngIf="_type==='sub_user'" style="border: #6d6d6d; margin: 10px;padding: 10px">
                  {{'client.for_business_usage'|translate}}
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <div>
          <div class="col" *ngIf="floatLabelControl.value === 'auto'">

            <mat-form-field appearance="outline" class="w-100">
              <mat-label> {{'client.business_email'|translate}}</mat-label>
              <input matInput placeholder="{{'client.business_email'|translate}" formControlName="businessEmail" required>

            </mat-form-field>
          </div>

          <div class="col" *ngIf="floatLabelControl.value === 'auto'">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> {{'client.business_name'|translate}}</mat-label>
              <input matInput placeholder="{{'client.business_name'|translate}}" formControlName="businessName" required>

            </mat-form-field>
          </div>

          <div class="col" *ngIf="floatLabelControl.value === 'auto'">

            <div>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'client.vat_number'|translate}}</mat-label>
                <input matInput placeholder="{{'client.vat_number'|translate}}" formControlName="vatNumber" required>

              </mat-form-field>
            </div>
          </div>


          <div class="col" *ngIf="floatLabelControl.value === 'auto'">

            <mat-form-field appearance="outline" class="w-100" (click)="invoiceAddressDialog()">
              <mat-label>{{'client.invoicing_address'|translate}}</mat-label>
              <input matInput placeholder="{{'client.invoicing_address'|translate}}" formControlName="invoicingAddress" required>

            </mat-form-field>
          </div>
          <div class="col">
            <div formArrayName="mainMissionAddress">
              <div *ngFor="let address of addresses.controls; index as i">
                <mat-form-field appearance="outline" class="w-100" (click)="mainMissionAddressDialog(i)">
                  <mat-label>{{'client.main_mission_address'|translate}}</mat-label>
                  <input matInput placeholder="{{'client.main_mission_address'|translate}}" [formControlName]="i" autocomplete="off"  required>
                  <mat-icon *ngIf="i >0"  (click)="deleteAddress(i)" matTooltip="Remove" matSuffix>delete</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
                    <div class="col">
                      <a mat-button [routerLink]="" (click)="addMissionAddress()">+ Add Address</a>
                    </div>
        </div>

        <div class="row">
          <div class="col" style="margin-left: 5px">
            {{'client.phone_number'|translate}}
          </div>

          <div class="col" style="margin-left: 5px" *ngIf="floatLabelControl.value === 'auto'">
            {{'client.business_phone_number'|translate}}
          </div>
        </div>

        <div class="row">
          <div class="col">

            <div>

              <div class="p-margin">

                <form>

                  <intl-tel-input
                    [name]="'phoneNumber'"
                    [cssClass]="'image_box'"
                    [labelCssClass]="'col-sm-2 col-form-label'"
                    [required]="true"
                    [options]="{
           preferredCountries: ['ch'],
           localizedCountries: { ch: 'be' }
       }"
                    [onlyLocalized]="true"
                    [(E164PhoneNumber)]="loginPhoneNumber"
                  >

                  </intl-tel-input>


                </form>
              </div>

            </div>
          </div>

          <div class="col" *ngIf="floatLabelControl.value === 'auto'">

            <div>

              <div class="p-margin">

                <form>

                  <intl-tel-input
                    [name]="'phoneNumber'"
                    [cssClass]="'image_box'"
                    [labelCssClass]="'col-sm-2 col-form-label'"
                    [required]="true"
                    [options]="{
           preferredCountries: ['ch'],
           localizedCountries: { ch: 'be' }
       }"
                    [onlyLocalized]="true"
                    [(E164PhoneNumber)]="businessPhoneNumber"
                  >

                  </intl-tel-input>


                </form>
              </div>

            </div>
          </div>


        </div>

        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'nbs.login.label.password'| translate}}</mat-label>
              <input matInput placeholder="{{'nbs.login.label.password'| translate}}" formControlName="password" type="password" required>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'label.confirm_password'|translate}}</mat-label>
              <input matInput placeholder="{{'label.confirm_password'|translate}}" formControlName="confirmPassword" type="password" required>

            </mat-form-field>
          </div>
        </div>

        <div class="col">

          <mat-form-field appearance="fill" class="input-rounded-decoration w-100 mt-2">
            <mat-label>{{'label.language'|translate}}</mat-label>
            <mat-select id="language"
                        name="language"
                        formControlName="language"

                        >
              <mat-option *ngFor="let val of languages" [value]="val"
                          style="color:  black">{{val}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>

<!--        <div class="col">-->

<!--          <mat-form-field appearance="outline">-->

<!--            <mat-label>{{'client.date'|translate}}</mat-label>-->
<!--            <input matInput [matDatepicker]="picker" formControlName="date">-->
<!--            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--            <mat-datepicker #picker></mat-datepicker>-->
<!--          </mat-form-field>-->


<!--        </div>-->

        <div style="text-align: right;margin-top: 30px">
          <button mat-button color="primary" class="button-center"
                  style="text-align: center;width: 30%;text-align:
                      center;font-size: 14px;border-radius: 10px" (click)="registration()"
                  [ngClass]="form.invalid?'button-grey-center':'button-center'"
                  [disabled]="form.invalid">
            {{'client.continue_btn' | translate}}
          </button>
        </div>

      </form>

    </div>
  </mat-card>
</div>
