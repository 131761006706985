import {Component, Inject, OnInit} from '@angular/core';
import {CustomResponse, User} from "../../../model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsersService} from "../../../service/users.service";
import {ToastrService} from "ngx-toastr";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {patternValidator} from "../../../client/validators/customValidators";
import {Address} from "../../../client/model/address";
import {RateRequest} from "../../../client/model/rate-request";
import {StorageService} from "../../../service/storage.service";
import {UserProfile} from "../../../client/model/user-profile";
import {CREATE_AGENT} from "../../../store/agent-store/agent.actions";
import {ClientUserService} from "../../../client/service/client-user.service";
import {Account} from "../../../client/model/account";

@Component({
  selector: 'app-change-client-rate',
  templateUrl: './change-client-rate.component.html',
  styleUrls: ['./change-client-rate.component.css']
})
export class ChangeClientRateComponent implements OnInit {


  clientDetails=new User();
newRate:number=0;
currentRate:number;
  form: FormGroup;
  constructor(

    public dialogRef: MatDialogRef<ChangeClientRateComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private storageService: StorageService,
    private clientUserService: ClientUserService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {

    Object.assign(this.clientDetails,this.data);
    this.getCurrentRate(this.clientDetails.businessPhoneNumber)
    console.log(this.clientDetails);
    this.CreateForm();
  }
getCurrentRate(phoneNumber:string){
  //activateClient
  this.clientUserService.getAccount(phoneNumber).subscribe(result => {
    if (result.result === "OK") {
      const account = <Account>result.data;
    this.currentRate=account.paymentRate;

    }
  })
}
  cancel(){
    this.dialogRef.close();
  }

  changeRate(phonenumber:string){
    console.log('phoneNumber'+phonenumber);
    let data = {} as RateRequest;
    data.phoneNumber = phonenumber;
    data.userId=this.storageService.getItem(StorageService.USER_PHONE_NUMBER);
    // @ts-ignore
    data.rate = this.form.get('newPaymentRate').value;

    this.userService.changeRate(data).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){

        this.toastr.success("Update successfully","RATE");
        this.dialogRef.close();
      }else{
        this.toastr.error("Error in processing");
      }
    });


  }
  CreateForm(): void {


      this.form = new FormGroup({
        currentRate: new FormControl(''),
        businessPhoneNumber: new FormControl(''),
        lastName: new FormControl(''),
        firstName: new FormControl(''),
        businessName: new FormControl(''),
        newPaymentRate: new FormControl('', Validators.required),

      });


  }


}
