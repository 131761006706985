import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountLicenseRequest} from "../../model/account-license-request";
import {PaymentService} from "../../service/payment.service";
import {StorageService} from "../../../service/storage.service";
import {AccountService} from "../../service/account.service";
import {ClientUserService} from "../../service/client-user.service";
import {MissionService} from "../../service/mission.service";
import {Store} from "@ngrx/store";
import {getMissionRequest} from "../../../store";
import {MissionRequest} from "../../model/mission-request";
import {MissionExtensionRequest} from "../../model/mission-extension-request";
import {AgentExtensionWaitingDialogComponent} from "../../dialogs/agent-extension-waiting-dialog/agent-extension-waiting-dialog.component";
import {DELETE_MISSION_REQUEST} from "../../../store/mission-request/mission-request.actions";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-gateway-payment',
  templateUrl: './gateway-payment.component.html',
  styleUrls: ['./gateway-payment.component.css']
})
export class GatewayPaymentComponent implements OnInit {

  initiatePaymentResponse: any;

  accountLicenseBody = new AccountLicenseRequest();
  payment: any;
  missionRequest: MissionRequest;

  tariffRequestMessage: MissionExtensionRequest;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private storageService: StorageService,
    private accountsService: AccountService,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private subscriberService: ClientUserService,
    private missionService: MissionService,
    private store: Store,
    private dialog: MatDialog,
  ) {


    this.spinner.show();
    if( this.storageService.getItem(StorageService.BOOKING_PAYMENT)){

      this.payment=JSON.parse(this.storageService.getItem(StorageService.BOOKING_PAYMENT));
      this.finalisePayment(this.payment.data.gatewayReference)
      console.log("222222222222222222");
    }else {
      console.log("111111111111111111");
      this.route.queryParams.subscribe(params => {

        this.initiatePaymentResponse = JSON.parse(params['initiatePaymentResponse']);
        this.storageService.saveObject(StorageService.BOOKING_PAYMENT, this.initiatePaymentResponse);
        window.location.href = this.initiatePaymentResponse.data.redirectUrl;

        console.log(this.initiatePaymentResponse)

        this.finalisePayment(this.initiatePaymentResponse.data.gatewayReference);

      });
    }

  }

  ngOnInit(): void {
    const missionExtension = this.storageService.getItem(StorageService.MISSION_EXTENSION);

    if(missionExtension){
      this.tariffRequestMessage = JSON.parse(missionExtension) as MissionExtensionRequest;
    }
    this.store.select(getMissionRequest).subscribe((value) => {
      if (value) {
        this.missionRequest = value;
      }
    })
  }


  finalisePayment(gatewayReference: any) {
    this.paymentService.callPaymentWebHook(gatewayReference).subscribe(data => {
      console.log(data);
      console.log("333333333333333333");
      this.getPaymentAndProcess(gatewayReference);
    }, error => {
      console.log(error);
      this.goToHomePage();
    });


  }

  getPaymentAndProcess(gatewayReference: any) {
    // Log.i("getPaymentAndProcess","calling getPaymentAndProcess");
    this.paymentService.getPaymentByGatewayReference(gatewayReference).subscribe(data => {
      console.log(data)
      console.log("44444444444444444");

      if (data) {
        if (data.result === 'OK') {
          var paymentReturned = data.data;
          if (data.data.transactionStatus === "SUCCESS") {

            this.sendSubscriptionDataAfterPaymentForProcessing(data);
          } else if (paymentReturned.transactionStatus === ("FAILED")) {
            if (data.gatewayStatus === ("canceled")) {
              //moveBack=true;
              //closeGateWayActivity();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();

            }
            if (paymentReturned.gatewayStatus === ("failed")) {
              //moveBack=false;
              //processFailedToGetPayment();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            }
            if (paymentReturned.gatewayStatus === ("expired")) {
              //moveBack=true;
              //closeGateWayActivity();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            } else {
              //processFailedToGetPayment();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            }
          } else if (paymentReturned.transactionStatus === ("PENDING")) {
            //processFailedToGetPayment();
            this.openSnackBar('Payment failed', 'Close');
            this.goToHomePage();
          } else {
            //processFailedToGetPayment();
            this.openSnackBar('Payment failed', 'Close');
            this.goToHomePage();
          }
        } else {
          //processFailedToGetPayment();
          this.openSnackBar('Payment failed', 'Close');
          this.goToHomePage();
        }
      } else {
        //processFailedToGetPayment();
        this.openSnackBar('Payment failed', 'Close');
        this.goToHomePage();
      }


    }, error => {
      console.log(error)
      this.openSnackBar('Payment failed', 'Close');
      this.goToHomePage();
    });

  }

  goToHomePage() {
    this.spinner.hide();
    this.router.navigate(['client']);
  }


  sendSubscriptionDataAfterPaymentForProcessing(paymentResult: any) {


    if (this.tariffRequestMessage) {


      this.missionService.clientExtendMission(this.tariffRequestMessage).subscribe(response => {
        if (response.result == 'OK') {
          if (this.tariffRequestMessage.agentPhoneNumber) {

            this.storageService.saveObject(StorageService.MISSION_EXTENSION_WAITING, this.tariffRequestMessage);

            const dialogRef = this.dialog.open(AgentExtensionWaitingDialogComponent
              , {
                width: '550px',
                backdropClass: 'bg-color',
                data: {
                  missionExtensionRequest: this.tariffRequestMessage,
                },
                disableClose: false
              });

            dialogRef.afterClosed().subscribe((result: any) => {
              if (result === 'OK') {
                this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION_WAITING);
                this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
                this.openSnackBar('Payment was successful', 'Close');
                this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
                this.goToHomePage();
              }
            });


          } else {
            this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
            this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
            this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
            this.openSnackBar('Payment was successful', 'Close');
            this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
            this.goToHomePage();
          }

        } else {
          this.storageService.saveString(StorageService.PAYMENT_STATUS, 'false');
          this.openSnackBar('Payment failed', 'Close');
          this.goToHomePage();
        }
      })

    }else {
      this.missionService.createMission(this.missionRequest).subscribe((response) => {

        if (response.result == 'OK') {
          this.openSnackBar('Payment was successful', 'Close');

          this.goToHomePage();
        } else {
          this.openSnackBar('Payment failed', 'Close');
          this.goToHomePage();
        }

      }, error => {
        this.openSnackBar('Payment failed', 'Close');
        this.goToHomePage();
      });

    }

  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


}
