<div>
    <h1 style="color: var(--primary);text-align: center">{{'nbs.title'|translate}}</h1>
    <p class="c-align" style="color: var(--primary); margin: 10px">{{'nbs.login.label.terms'|translate}}</p>


    <div class="p-margin c-align">

      <div class="p-margin">

        <form #form="ngForm">
          <intl-tel-input
            [name]="'phoneNumber'"
            [cssClass]="'image_box'"
            [labelCssClass]="'col-sm-2 col-form-label'"
            [required]="true"
            [options]="{
           preferredCountries: ['ch'],
           localizedCountries: { ch: 'be' }
       }"
            [onlyLocalized]="true"
            [(E164PhoneNumber)]="E164PhoneNumber"
          ></intl-tel-input>
        </form>
      </div>
      <form novalidate [formGroup]="ngForm">
        <input matInput type="password" style="padding: 10px;" class="p-margin image_box"
               [(ngModel)]="password"
               name="password"
               formControlName="password"
               placeholder="Password">

        <div *ngIf="ngForm.get('password').invalid && (ngForm.get('password').dirty || ngForm.get('password').touched)">
          <div *ngIf="ngForm.get('password').errors.required">
            <p style="color: var(--danger)">{{'nbs.login.label.passwordrequired'|translate}}</p>
          </div>

          <div *ngIf="ngForm.get('password').errors.minlength">
            <p style="color: var(--danger)"> {{'nbs.login.label.passwordtooshort'|translate}}</p>
          </div>

        </div>
      </form>


    </div>

    <div class="p-margin c-align " style="text-align: center">
      <div *ngIf="!processingLogin">
        <button mat-button  class="button-center" style="background-color: var(--primary); text-align: center;width: 45%;text-align:
        center;" (click)="login()">
          {{'nbs.login.label.login'|translate}}
        </button>
      </div>
      <div class="c-align " *ngIf="processingLogin" >
        <mat-progress-spinner
          class=" c-align my_progress"
          color="primary"
          mode="indeterminate"
          >
        </mat-progress-spinner>
      </div>
      <p class="c-align" style="color: var(--primary); margin: 20px;font-size: 14px">

        <a class="anchor_white" style="cursor: pointer;" (click)="forgetPassword()">{{'nbs.login.label.forgotpassword'|translate}}</a>
      </p>
    </div>
  </div>
