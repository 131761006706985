import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

  hours: string[] = [
    '00',
    '01'
    ,'02'
    ,'03'
    ,'04'
    ,'05'
    ,'06'
    ,'07'
    ,'08'
    ,'09'
    ,'10'
    ,'11'
    ,'12'
    ,'13'
    ,'14'
    ,'15'
    ,'16'
    ,'17'
    ,'18'
    ,'19'
    ,'20'
    ,'21'
    ,'22'
    ,'23'
  ];
  minutes: string[] = [
    '00',
    '01'
    ,'02'
    ,'03'
    ,'04'
    ,'05'
    ,'06'
    ,'07'
    ,'08'
    ,'09'
    ,'10'
    ,'11'
    ,'12'
    ,'13'
    ,'14'
    ,'15'
    ,'16'
    ,'17'
    ,'18'
    ,'19'
    ,'20'
    ,'21'
    ,'22'
    ,'23'
    ,'24'
    ,'25'
    ,'26'
    ,'27'
    ,'28'
    ,'29'
    ,'30'
    ,'31'
    ,'32'
    ,'33'
    ,'34'
    ,'35'
    ,'36'
    ,'37'
    ,'38'
    ,'39'
    ,'40'
    ,'41'
    ,'42'
    ,'43'
    ,'44'
    ,'45'
    ,'46'
    ,'47'
    ,'48'
    ,'49'
    ,'50'
    ,'51'
    ,'52'
    ,'53'
    ,'54'
    ,'55'
    ,'56'
    ,'57'
    ,'58'
    ,'59'
  ]
  form: FormGroup;
  constructor(public dialogRef: MatDialogRef<TimePickerComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      hour: new FormControl('', Validators.required),
      minute: new FormControl('', Validators.required),

    });

  }
  okDialog() {


    // @ts-ignore
    let hour = this.form.get('hour').value;
    // @ts-ignore
    let minute = this.form.get('minute').value;

    this.dialogRef.close({
      confirmation: 'DONE',
      time: `${hour}:${minute}`
    });
  }

  cancelDialog() {
    this.dialogRef.close();
  }
}
