<div>
    <h1 style="color: black;text-align: center">{{ 'label.change_password_title' | translate }}</h1>
    
  
  
    <div class="p-margin c-align">
  
  
      <form novalidate [formGroup]="rForm">

        <!-- OTP CODE -->

        <input matInput type="password" style="padding: 10px;width: 70%" class="p-margin image_box"
               name="otpCode"
               formControlName="otpCode"
               placeholder="Type OTP received">
  
        <div *ngIf="submitted && !rForm.get('otpCode').valid ">
          <div *ngIf="rForm.get('otpCode').errors.required">
            <p style="color: rgb(182, 97, 97)">{{ 'label.field_required' | translate }}</p>
          </div>

        </div>

        <!-- PASSWORD -->
        <input matInput type="password" style="padding: 10px;width: 70%" class="p-margin image_box"
               name="newPassword"
               formControlName="newPassword"
               placeholder="New Password">
  
        <div *ngIf="submitted && !rForm.get('newPassword').valid ">
          <div *ngIf="rForm.get('newPassword').errors.required">
            <p style="color: rgb(182, 97, 97)">{{ 'label.password_required' | translate }}</p>
          </div>

        </div>

        <!-- RETYPE PASSWORD -->
        <input matInput type="password" style="padding: 10px;width: 70%" class="p-margin image_box"
             
               name="newPasswordConfirmation"
               formControlName="newPasswordConfirmation"
               placeholder="Retype Password">
  
        <div *ngIf="submitted && !rForm.get('newPasswordConfirmation').valid ">
            <p style="color: rgb(182, 97, 97); font-weight: bold;">{{ 'label.password_required' | translate }}</p>
        </div>

        <div *ngIf="rForm.get('newPassword').value!==rForm.get('newPasswordConfirmation').value">
          <p style="color: rgb(182, 97, 97); font-weight: bold;"> {{ 'label.password_not_match' | translate }}</p>
        </div>

      </form>
  
  
    </div>
  
    <div class="p-margin c-align " style="text-align: center">
      <div>
        <button mat-button color="primary" class="button-center" style="color:white; text-align: center;width: 70%;text-align:
        center;" (click)="submit()">
          {{ 'btn.save_btn' | translate }}
        </button>
      </div>
  

    </div>
  </div>