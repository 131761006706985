<div>
  <mat-card style=" height: auto">

    <div class="row c-align mb-lg-5">
      <div class="col">

        <h1>{{pushNotification.title}}</h1>
        <p style="color: gray">{{pushNotification.msg}}</p>
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionName}}</p>
      </div>

      <div class="col">
        <p>{{mission.missionStartDate | date: 'dd-MM-yyyy'}}</p>
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionDescription}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.address'|translate}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionStreetAddress}}, {{mission.missionZipCodeAddress}} {{mission.missionCityAddress}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>Date :</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'dd-MM-yyyy'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'dd-MM-yyyy'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>Time :</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'HH:mm'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'HH:mm'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{'nbs.businessownermanager.manageagents.missionInfo.type'|translate}} : {{mission.missionType}}</p>
      </div>
      <div class="col">
      </div>

    </div>
    <div class="row">

      <div class="col">
        <p>{{'client.status'|translate}} : {{mission.missionStatus}}</p>
      </div>
      <div class="col">
      </div>

    </div>
    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">


      <div class="col" (click)="doneDialog()">
        <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="doneDialog()">
          {{'client.ok'|translate}}
        </button>


      </div>

    </div>
  </mat-card>
</div>
