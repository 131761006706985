import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {catchError, finalize, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import {UsersService} from "../../service/users.service";
import {
  AgentRegistration,
  AgentStatistics,
  appConstants,
  AuthServerUser,
  CustomResponse,
  ResponseUserInformationList
} from "../../model";

import {ToastrService} from "ngx-toastr";
import { EmployeeDatasource } from 'src/app/service/datasource/employee-datasource';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ViewEmployeeDetailsComponent } from './view-employee-details/view-employee-details.component';

@Component({
  selector: 'app-manageemployee',
  templateUrl: './manageemployee.component.html',
  styleUrls: ['./manageemployee.component.css']
})
export class ManageemployeeComponent implements OnInit {


  user: AuthServerUser;

  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :EmployeeDatasource;


  columnsToDisplay = ['fullName','username','email','status','buttons'];
   paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];

  constructor(private httpClient: HttpClient,private dialog: MatDialog,
              private authService: AuthenticationService,
              private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.dataSource = new EmployeeDatasource(this.authService);
    this.dataSource?.loadData();

    //this. loadData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  ngAfterViewInit() {
    this.dataSource?.counter$
    .pipe(
      tap((count) => {
        // @ts-ignore
        this.paginator.length = count;
      })
    )
    .subscribe();

  // @ts-ignore
  this.paginator.page
    .pipe(
      tap(() => this.loadData())
    )
    .subscribe();
  }

  loadData() {

    this.dataSource?.loadData(this.paginator.pageIndex, this.paginator.pageSize);

  }

  openViewDetails(element:any){
    this.openViewInfo(element);
  }

  openViewInfo(data:any): void {
    
    const dialogRef = this.dialog.open(ViewEmployeeDetailsComponent
      , {
        width: '50%',
        data:data

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadData()
      });
  }

  add(){
    const dialogRef = this.dialog.open(AddEmployeeComponent
      , {
        

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadData()
      });
  }
}
