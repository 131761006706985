<div class="bg-grad-dashboard" style="height: auto;padding: 20px">

    <div class="content">
      <ngx-spinner></ngx-spinner>
      <p class="header" style="padding: 20px;">{{'client.make_payment'|translate}}</p>

      <mat-card style="margin-top: 16px">
        <div class="pt-lg-4">


          <mat-horizontal-stepper labelPosition="bottom" #stepper>


            <!-- TAB CHECKOUT -->
            <mat-step>
              <ng-template matStepLabel>{{'client.checkout'|translate}}</ng-template>

              <div class="center" *ngIf="missionRequest">
                  <div class="p-name">{{'client.total'|translate}} {{missionRequest.missionPrice  | currency:'EUR'}}</div>
                  <div class="p-price">Agent Required: {{missionRequest.numberOfAgents}} </div>
                  <div class="p-description">{{'client.hours'|translate}}: {{missionRequest.missionDuration}}</div>
              </div>

              <div class="center" *ngIf="tariffRequestMessage">
                <div class="p-name">Total {{tariffRequestMessage.priceForExtension  | currency:'EUR'}}</div>
              </div>
              <div class="text-center">
                <!-- <button (click)="goForward(stepper)" class="button p-next" >Next</button> -->
                <button mat-button color="primary" class="button-center button p-next"
                        style="text-align: center;text-align:
                    center;font-size: 14px;border-radius: 10px" (click)="goForward(stepper)">
                        {{'client.next_btn'|translate}}
                </button>
              </div>



            </mat-step>


            <!-- TAB PAYMENT-->
            <mat-step>

              <ng-template matStepLabel>{{'client.checkout'|translate}}</ng-template>

              <div class="col">

                <div  class="md-6">
                    <div class="" (click)="togglePaymentMode(1)"
                    [ngClass]="(togglerPaymentMode==1)?'div-opaque':'div-no-opaque'">
                        <div >
                            <img class="center" src="../../../../assets/icons_prepaid_pay.png" alt="No Image Available" style="width: 150px;height: 150px"/>
                          </div>
                        <div class="">
                            <h3 class="" style="text-align: center;">{{'client.prepaid_account'|translate}}</h3>

                        </div>

                    </div>
                </div>

                <div  class="md-6">
                    <div class="" (click)="togglePaymentMode(2)"
                    [ngClass]="(togglerPaymentMode==2)?'div-opaque':'div-no-opaque'">
                        <div >
                            <img class="center" src="../../../../assets/icons_pay_online.png" alt="No Image Available" style="width: 150px;height: 150px"/>
                          </div>
                        <div class="">
                            <h3 class="" style="text-align: center;">{{'client.online_payments'|translate}}</h3>

                        </div>

                    </div>
                </div>




              </div>

              <!-- IF BALANCE SUFFICIENT -->
              <ng-container *ngIf="(togglerPaymentMode==1)">
                <div class="center">
                  <div class="p-account-balance-title">{{'client.account_balance'|translate}}</div>
                  <div class="p-account-balance"> {{accountBalance  | currency:'EUR' }}</div>
                  <hr/>
                  <div class="p-account-remaining-balance">
                      <!-- <div style="width: 45%; margin-left: 0px; margin-right: 0px; text-align: left;">Remaining balance</div> -->
                      <!-- <div style="width: 45%; margin-left: 0px; margin-right: 0px; text-align: right; ">{{(accountBalance-plan.price )| currency:'EUR'}}</div> -->

                      <!-- <div class="bloc1" style="width: 45%; margin-left: 0px; margin-right: 0px; text-align: left;"> </div> -->
                      <!-- <div class="bloc2" style="width: 45%; margin-left: 0px; margin-right: 0px; text-align: right; "> </div> -->

                      <div class="Row">
                        <div class="Column" style="text-align: left;"><span>{{'client.remaining_balance'|translate}}</span></div>
                        <div class="Column" style="text-align: right;">

                        <span  *ngIf="tariffRequestMessage">{{(accountBalance-tariffRequestMessage.priceForExtension )| currency:'EUR'}}</span>
                          <span *ngIf="missionRequest" >{{(accountBalance-missionRequest.missionPrice )| currency:'EUR'}}</span>
                        </div>
                    </div>
                  </div>
                  <hr />
                  <div class="p-account-total-paid">
                      <!-- <div class="bloc1" style="width: 45%;  text-align: left;"><span >Total paid</span>  </div> -->
                      <!-- <div class="bloc2" style="width: 45%;  text-align: right; "><span >{{plan.price  | currency:'EUR'}}</span></div> -->

                      <div class="Row">
                        <div class="Column" style="text-align: left;"><span >{{'client.total_paid'|translate}}</span></div>
                        <div class="Column" style="text-align: right;">
                          <!-- <span >{{missionRequest.missionPrice  | currency:'EUR'}}</span> -->
                          <span  *ngIf="tariffRequestMessage">{{(tariffRequestMessage.priceForExtension )| currency:'EUR'}}</span>
                          <span *ngIf="missionRequest" >{{(missionRequest.missionPrice  )| currency:'EUR'}}</span>
                        </div>
                    </div>

                  </div>
                  <hr />
              </div>
              </ng-container>

              <!-- IF BALANCE NONSUFFICIENT -->
              <ng-container *ngIf="(togglerPaymentMode==2)">
                <div class="col">
                  <div  class="md-3" (click)="sendPaymentToGateway(paymentMode.MOLLIE)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../assets/payment_icon/icons_amex.svg" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Amex</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3" (click)="sendPaymentToGateway(paymentMode.BELFIUS)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_belfius.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Belfius Direct</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3" (click)="sendPaymentToGateway(paymentMode.KBC)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_cbc.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>CBC Online</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.ING_HOME_PAY)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_ing_homepay.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>ING Homepay</strong></h3>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col">
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.KBC)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_kbc.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>KBC Online</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.MOLLIE)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_maestro.svg" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Maestro</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.BANCONTACT)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_bancontact.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Bancontact</strong></h3>
                      </div>
                    </div>

                  </div>
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.MOLLIE)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_mastercard.png" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Mastercard</strong></h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col">
                  <div  class="md-3"  (click)="sendPaymentToGateway(paymentMode.MOLLIE)">
                    <div class="content-inline-img">
                      <img class="center" src="../../../../assets/payment_icon/icons_visa.svg" alt="No Image Available" style="width: 30%;"/>
                      <div class="text">
                        <h3><strong>Visa</strong></h3>
                      </div>
                    </div>

                  </div>

                </div>
              </ng-container>



              <div class="text-center">
                <button mat-button color="primary" class="button-center button p-next"
                            style="text-align: center;text-align:
                        center;font-size: 14px;border-radius: 10px; margin-left: 5px; margin-right: 5px;" (click)="goBack(stepper)">

                    </button>
                <!-- <button (click)="goBack(stepper)" class="button p-next" >Previous</button> -->

                <!-- <button color="primary" type="button" class="button p-next"  disabled="disabled" >Pay</button> -->


                <button *ngIf="(togglerPaymentMode==1)"
                (click)="sendPayment()"
                mat-button color="primary" class="button-center button p-next"
                            style="text-align: center;text-align:
                        center;font-size: 14px;border-radius: 10px ;margin-left: 5px; margin-right: 5px;"
                            [ngClass]="(!sufficientBalance)?'button-grey-center':'button-center'"
                            [disabled]="!sufficientBalance" >
                  {{'client.checkout'|translate}}
                    </button>
              </div>

            </mat-step>



          </mat-horizontal-stepper>


        </div>
      </mat-card>

    </div>


  </div>
