import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeLandingComponent} from "./common/home-landing/home-landing.component";
import {LandingAComponent} from "./common/landing-a/landing-a.component";
import {FaqClientComponent} from "./common/faq-client/faq-client.component";

const routes: Routes = [
  { path: '',component: HomeLandingComponent },
  {path:'home',component:HomeLandingComponent},
  {path:'admin-landing',component: LandingAComponent},
  {
    path: 'businessowner',
    loadChildren: () => import('./businessownermanager/businessownermanager.module').then(m => m.BusinessownermanagerModule)
  },
  {
    path: 'app-client',
    loadChildren: () => import('./client/client.module')
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
