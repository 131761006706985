<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <!-- loop over the cardList and show the cards -->
    <div
      fxFlex="25"
      fxFlex.md="33"
      fxFlex.sm="50"
      fxFlex.xs="100"
      fxLayout="column"
      style="padding: 5px;"
    >
      <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard " >
        <mat-card-subtitle class="myCard"><p>{{'nbs.businessownermanager.manageagents.revenuesummary.totalMissions'|translate}}</p></mat-card-subtitle>
        <p style="color: var(--primary);" class="cardStat" >{{revenueSummary?.totalMissions}}
        </p>
      </mat-card>
    </div>
    <div
      fxFlex="25"
      fxFlex.md="33"
      fxFlex.sm="50"
      fxFlex.xs="100"
      fxLayout="column"
      style="padding: 5px;"
    >
      <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard" >
        <mat-card-subtitle class="myCard"><p>{{'nbs.businessownermanager.manageagents.revenuesummary.potentialRevenue'|translate}}</p></mat-card-subtitle>
        <p style="color: var(--primary);" class="cardStat" >
          €{{revenueSummary?.potentialRevenue}}
        </p>
      </mat-card>
    </div>
    <div
      fxFlex="25"
      fxFlex.md="33"
      fxFlex.sm="50"
      fxFlex.xs="100"
      fxLayout="column"
      style="padding: 5px;"
    >
      <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard"  >
        <mat-card-subtitle class="myCard"><p>{{'nbs.businessownermanager.manageagents.revenuesummary.revenueMade'|translate}}</p></mat-card-subtitle>
        <p style="color: var(--primary);" class="cardStat" >
          €{{revenueSummary?.revenueMade}}
        </p>
      </mat-card>
    </div>
    <div
      fxFlex="25"
      fxFlex.md="33"
      fxFlex.sm="50"
      fxFlex.xs="100"
      fxLayout="column"
      style="padding: 5px;"
    >
      <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard"  >
        <mat-card-subtitle class="myCard"><p>{{'nbs.businessownermanager.manageagents.revenuesummary.revenueLost'|translate}}</p></mat-card-subtitle>
        <p style="color: var(--primary);" class="cardStat" >
          €{{revenueSummary?.revenueLost}}</p>
      </mat-card>
    </div>
  </div>
  <div fxLayout="row "style="padding-left: 0%; padding-right: 0%;">
    <div
      fxFlex="100"
      fxFlex.md="33"
      fxFlex.sm="50"
      fxFlex.xs="100"
      fxLayout="column"
      style="padding: 5px;"
    >
      <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard" style="text-align: center" >
        <mat-tab-group>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.agentHistory'|translate}}">
          <app-agents></app-agents>
          </mat-tab>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.bookedMissions'|translate}}">
          <app-agentbookedmissions></app-agentbookedmissions>
          </mat-tab>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.executedMissions'|translate}}">
          <app-agentexecutedmissions></app-agentexecutedmissions>
          </mat-tab>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.activeAgents'|translate}}"> <app-activeagents></app-activeagents> </mat-tab>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.disabledAgents'|translate}}"> <app-disabled-agents></app-disabled-agents> </mat-tab>
          <mat-tab label="{{'nbs.businessownermanager.manageagents.tabs.agentRegistrations'|translate}}"> <app-agentregistrations></app-agentregistrations> </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>

