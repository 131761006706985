import {Component, Inject, OnInit} from '@angular/core';
import {AccountService} from '../../service/account.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Account} from '../../model/account';
import {Otp} from '../../model/otp';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {ClientUser} from "../../model/client-user";
import {StorageService} from "../../../service/storage.service";
import {AuthenticationService} from "../../../service/authentication.service";
import {CustomResponse} from "../../../model";
import {NotificationClientService} from "../../../service/notification-client.service";
import {UserProfile} from "../../model/user-profile";
import {ClientUserService} from "../../service/client-user.service";

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  userExist: string;
  phoneNumber: string;
  otpRequestData: string;
  otpCode: string;
  registrationUser: ClientUser;
  isTeamMember = false;
  currentAccount: Account;

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationClientService,
    private storageService: StorageService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<OtpComponent>,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private store: Store,
    private router: Router,
    private clientUserService: ClientUserService,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    private snackBar: MatSnackBar) {

    this.phoneNumber = this.storageService.getItem(StorageService.USER_PHONE_NUMBER);
    this.otpRequestData = this.storageService.getItem(StorageService.OTP_VALIDATION_DATA);
    const subscriber = this.storageService.getItem(StorageService.USER_REGISTRATION);
    this.registrationUser = JSON.parse(subscriber) as ClientUser;
  }


  ngOnInit(): void {


  }


  onOtpChange(event: any): void {

    this.otpCode = event;
  }

  resendOtp(): void {
    this.spinner.show();
    const otp = new Otp();
    otp.serviceName = 'test';
    otp.phoneNumber = this.phoneNumber;

    this.notificationService.requestOtp(otp).subscribe(value => {
      this.spinner.hide();
      if (value.result === 'OK') {
        this.storageService.deleteItem(StorageService.OTP_VALIDATION_DATA);
        this.storageService.saveString(StorageService.OTP_VALIDATION_DATA, value.data);
        this.otpRequestData = value.data;
        this.openSnackBar('Code was send successfully', 'Close');
      } else {
        this.openSnackBar('Failed to resend OTP, please try again later', 'Close');
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Failed to resend OTP, please try again later', 'Close');
    });
  }

  submitCode(): void {

    const otp = new Otp();
    otp.code = this.otpCode;
    otp.requestIdentifier = this.otpRequestData;
    this.spinner.show();
    this.notificationService.validateOtp(otp).subscribe(value => {
      this.spinner.hide();
      if (value.result === 'OK') {
        this.dialogRef.close();
        // user registration implementation

        this.registerUser();

      } else {
        this.openSnackBar('Invalid OTP supplied, please try again later', 'Close');
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Invalid OTP supplied, please try again later', 'Close');
    });


  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async registerUser() {

    this.registrationUser.groupId = 200;
    if (this.data === true) {
      await this.completeRegistrationProcess();
    } else {
      this.authenticationService.userRegistration(this.registrationUser).subscribe(async value => {

        if (value.success === true) {
          await this.completeRegistrationProcess();
        } else {
          this.openSnackBar('Failed to create an account, please try again later', 'Close');
        }
      }, error => {
        this.openSnackBar('Failed to create an account, please try again later', 'Close');
      });

    }
  }


  async completeRegistrationProcess(): Promise<void> {
    this.registrationUser.username = this.phoneNumber;
    const res = await this.getUserProfile();
    if (res && res.result === 'OK') {
      const profile = <UserProfile> res.data;
      if (profile) {

        this.dialogRef.close();
        this.userExist = 'YES';

        // todo emit login state
        // this.appState.logedInUser.emit(true);

        this.router.navigate(['client']);

      } else {
        // register user profile

        this.clientUserService.createUser(this.registrationUser).subscribe(async value=>{

          if (value.result === 'OK') {
            const createdUser = await this.getUserProfile();
            if (createdUser && createdUser.result === 'OK') {
              const profile = <UserProfile> createdUser.data;
              if (profile) {

                this.dialogRef.close();
                this.userExist = 'YES';

                // todo emit login state
                // this.appState.logedInUser.emit(true);

                this.router.navigate(['client']);

              } else {
                this.openSnackBar('Failed to create an account, please try again later', 'Close');
              }
            }else{
              this.openSnackBar('Failed to create an account, please try again later', 'Close');
            }
          }else{
            this.openSnackBar('Failed to create an account, please try again later', 'Close');

          }


        },error => {
          this.openSnackBar('Failed to create an account, please try again later', 'Close');

        })




        return;

      }
    } else {

      // register user profile

      this.clientUserService.createUser(this.registrationUser).subscribe(async value=>{

        if (value.result === 'OK') {
          const createdUser = await this.getUserProfile();
          if (createdUser && createdUser.result === 'OK') {
            const profile = <UserProfile> createdUser.data;
            if (profile) {

              this.dialogRef.close();
              this.userExist = 'YES';

              // todo emit login state
              // this.appState.logedInUser.emit(true);

              this.router.navigate(['client']);

            } else {
              this.openSnackBar('Failed to create an account, please try again later', 'Close');
            }
          }else{
            this.openSnackBar('Failed to create an account, please try again later', 'Close');
          }
        }else{
          this.openSnackBar('Failed to create an account, please try again later', 'Close');

        }


      },error => {
        this.openSnackBar('Failed to create an account, please try again later', 'Close');

      })


      return;
    }


  }



  // @ts-ignore
  private getUserProfile(): Promise<CustomResponse> {

   return  new Promise((resolve, reject) => {

      this.clientUserService.getProfile(this.phoneNumber).subscribe((result: CustomResponse)=>{

        if(result.result === "OK"){
          const profile = <UserProfile> result.data;
          this.storageService.saveObject(StorageService.CLIENT_AGENT_PROFILE,profile)
          this.storageService.saveString(StorageService.USER_CATEGORY,profile.userType)
          if(profile.userType === 'CLIENT'){
            resolve(result);
          }else{
            this.openSnackBar('AGENT is allowed to mobile application', 'Close');
            // @ts-ignore
            resolve(null);
          }

        }else{
          // @ts-ignore
          resolve(null);
        }

      },error => {
        reject(null);
      })
    });


  }



}
