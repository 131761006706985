<div>
  <mat-card style=" height: auto">

    <div class="row" *ngIf="pending">

      <div class="col">
        {{'client.complete_payment'|translate}}
      </div>

    </div>
    <div class="row">

      <div class="col">
         <span>
         <mat-icon svgIcon="icons_job">
         </mat-icon>
           {{mission.missionName}}
       </span>
      </div>

      <div class="col">
        <p>{{mission.missionStartDate | date: 'dd-MM-yyyy'}}</p>
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionDescription}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.address'|translate}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionStreetAddress}}, {{mission.missionZipCodeAddress}} {{mission.missionCityAddress}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.date'|translate}} :</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'dd-MM-yyyy'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'dd-MM-yyyy'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.time'|translate}} :</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'HH:mm'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'HH:mm'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{'nbs.businessownermanager.manageagents.missionInfo.type'|translate}} : {{mission.missionType}}</p>
      </div>
      <div class="col">
      </div>

    </div>
    <div class="row">

      <div class="col">
        <p>{{'client.status'|translate}} : {{mission.missionStatus}}</p>
      </div>
      <div class="col">
      </div>

    </div>

    <div class="mt-lg-5" style="" *ngIf="agents && agents.length >0">
      <h1>Agents</h1>
      <div class="col" style="color: black">

        <div class="row" *ngFor="let agent of agents">
          <div class="col" style="margin-left: 30px">
            <mat-icon class="icon-display mt-lg-5" svgIcon="placeholder_user" *ngIf="!agent.path"></mat-icon>
            <img *ngIf="agent.path" [src]="agent.path" class="icon-display mt-lg-5" />
          </div>

          <div class="col-1 mt-4">
            <p>{{agent.agentName}}</p>
            <p>{{agent.agentPhoneNumber}}</p>
          </div>
          <div class="col">
          </div>

        </div>

      </div>


    </div>


    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">


      <div class="col" (click)="extendMission()" *ngIf="missionExtensionWindowValid">


        <div>
          <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="extendMission()">
            {{'client.extend_mission'|translate}}
          </button>
        </div>


      </div>
      <div class="col" (click)="cancelDialog()">


        <div *ngIf="!pending">
          <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="cancelDialog()">
            {{'client.cancel_mission'|translate}}
          </button>
        </div>

        <div *ngIf="pending">
          <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="updateMissionWithSufficiency(mission.id,false)">
            {{'client.processing_payment'|translate}}
          </button>
        </div>


      </div>

    </div>
  </mat-card>
</div>
