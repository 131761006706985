import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Mission} from "../../../model";
import {MissionAgent} from "../../model/mission-agent";
import {MissionService} from "../../service/mission.service";
import {MissionExtensionRequest} from "../../model/mission-extension-request";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-agent-extension-waiting-dialog',
  templateUrl: './agent-extension-waiting-dialog.component.html',
  styleUrls: ['./agent-extension-waiting-dialog.component.css']
})
export class AgentExtensionWaitingDialogComponent implements OnInit {

  missionAgent: MissionAgent;
  missionExtensionRequest: MissionExtensionRequest;
  message: string = 'please wait for an agent approval....'
  agentAcceptedMission: boolean = false;
  mySubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<AgentExtensionWaitingDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private missionService: MissionService) {
  }

  ngOnInit(): void {

    if (this.data) {
      this.missionExtensionRequest = this.data.missionExtensionRequest;

      this.mySubscription = interval(10000).subscribe((x => {
        this.getAgentMission();
      }));
    }
  }


  getAgentMission(): void {

    this.missionService.agentGetMission(this.missionExtensionRequest.missionId, this.missionExtensionRequest.agentPhoneNumber)
      .subscribe(response => {
        console.log(response);
        if (response.result === 'OK') {


          this.missionAgent = <MissionAgent>response.data;


          if (this.missionAgent && this.missionAgent.extensionStatus && this.missionAgent.extensionStatus === 'EXTENDED') {

            this.message = 'Agent accepted the mission extension';
            this.mySubscription.unsubscribe();
            this.agentAcceptedMission = true;
          }else if (this.missionAgent && this.missionAgent.extensionStatus && this.missionAgent.extensionStatus === 'REJECTED') {

            this.message = 'Agent rejected the mission extension. You can create a new one';
            this.mySubscription.unsubscribe();
            this.agentAcceptedMission = true;

          }
        }

      });
  }


  okDialog() {
    this.dialogRef.close('OK');

  }
}
