
 <!-- {{dataDetail | json}} -->

<ng-container *ngIf="dataDetail" >

   
  
    <form class="myFormStyle"  >
  
     
        <div  >
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label >{{'label.username'|translate}}</label>
                        <input type="text" class="myInput" value="{{dataDetail.username}}"   readonly  placeholder="">
  
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.email'|translate}} </label>
                        <input type="text" class="myInput" value="{{dataDetail.email}}" readonly  placeholder="">
                    </div>
                    
        
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label >{{'label.firstName'|translate}}</label>
                        <input type="text" class="myInput"  value="{{dataDetail.firstName}}"  readonly  placeholder="">
  
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.lastName'|translate}} </label>
                        <input type="text" class="myInput" value="{{dataDetail.lastName}}" readonly  placeholder="">
                    </div>
                    
        
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label >{{'label.language'|translate}}</label>
                        <input type="text" class="myInput"  value="{{dataDetail.language}}"  readonly  placeholder="">
  
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                  
        
                </div>
            </div>
    
        </div>
       
        
        <footer>
          
            <button (click)="editUser(dataDetail)" class="btn btn-info">
                {{'btn.edit'|translate}}
            </button>

            <button *ngIf="dataDetail.enabled===true" (click)="deactivateUser(dataDetail)" class="btn btn-danger">
                {{'btn.deactivate'|translate}}
            </button>
  
            <button *ngIf="dataDetail.enabled===false" (click)="activateUser(dataDetail)" class="btn btn-success">
              {{'btn.activate'|translate}}
            </button>
  
           
              
            <button type="button" class="btn btn-default" (click)="cancel()">
                {{'btn.dismiss'|translate}}
            </button>
  
          </footer>
      </form>
  
    
  </ng-container>
  
    