import {Component, OnInit} from '@angular/core';
import {DressCode} from "../model/dress-code";
import {ClientUserService} from "../service/client-user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {match} from "minimatch";
import {MissionRequest, TariffRequest} from "../model/mission-request";
import {UserProfile} from "../model/user-profile";
import {TariffsService} from "../service/tarrifs.service";
import {UtilService} from "../service/util.service";
import * as moment from 'moment';
import {DatePipe} from "@angular/common";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MessageDialog} from "../model/message-dialog";
import {ConfirmationDialogComponent} from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {BookAgentSummaryDialogComponent} from "../dialogs/book-agent-summary-dialog/book-agent-summary-dialog.component";
import {Store} from "@ngrx/store";
import {CREATE_MISSION_REQUEST, DELETE_MISSION_REQUEST} from "../../store/mission-request/mission-request.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../service/storage.service";
import {TimePickerComponent} from "../dialogs/time-picker/time-picker.component";
import {MessageDialogComponent} from "../dialogs/message-dialog/message-dialog.component";
import {FavoriteAgent} from "../model/favorite-agent";
import {MissionService} from "../service/mission.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Account} from "../model/account";
import {AccountService} from "../service/account.service";
import {MissionSetting} from "../../model";
import {TranslateService} from "@ngx-translate/core";
import {UserAddress} from "../model/UserAddress";
import {Address} from "../model/address";


declare const google: any;

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-book-agent-planned',
  templateUrl: './book-agent-planned.component.html',
  styleUrls: ['./book-agent-planned.component.css'],
  providers: [

  ]
})
export class BookAgentPlannedComponent implements OnInit {

  genders: string[] = ['Any', 'Male', 'Female', 'Mixed'];
  languages: string[] = ["English", "French", "Dutch", "Spanish"];
  numberOfAgents: string[] = ["1", "2", "3", "4", "5", "6", "7"];
  dressCodes: DressCode[] = [];
  userAddresses:UserAddress[]=[];
  userStreets: string[] = [];
  addressData:Address=new Address();
  mainMissionStreet: string;
  mainMissionCity: string;
  mainMissionZipCode: string;

  newAddress: boolean = false;
  isReadOnly: boolean = true;
  form: FormGroup;
  loading = false;
  userProfile: UserProfile | null;
  femaleRequired: string[] = ["0", "1", "2", "3", "4", "5", "6", "7"];
  maleRequired: string[] = ["0", "1", "2", "3", "4", "5", "6", "7"];
  lat = -17.85856;
  lng = 31.021465600000003;
  pointList: { lat: number; lng: number }[] = [];
  drawingManager: any;
  selectedShape: any;
  selectedArea = 0;
  geocoder: any;
  disableFemale: boolean = true;
  disableMale: boolean = true;
  numberOfAgentsVal: string;
  favoriteAgent: FavoriteAgent;
  isAgent: boolean = false;
  clientPaymentRate:number=100;
 minimumDuration:number = 0;
  settings:MissionSetting[]=[];
  constructor(private clientUserService: ClientUserService,
              private tariffsService: TariffsService,
              private utilService: UtilService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private store: Store,
              private router: Router,
              private storageService: StorageService,
              private route: ActivatedRoute,
              private missionService: MissionService,
              private snackBar: MatSnackBar,
              private accountService: AccountService,
              private translate: TranslateService
              ) {
    this.route.params.subscribe(params => {
      const agent = params['agent'];
      if (agent) {

        const agentString = this.storageService.getItem(StorageService.SELECTED_FAV_AGENT);

        if(agentString){
          this.favoriteAgent = <FavoriteAgent>JSON.parse(agentString);

          this.isAgent = true;
        }
      }

    });
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      missionDescription: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      // date: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required),
      numberOfAgents: new FormControl('', Validators.required),
      sex: new FormControl('', Validators.required),
      dressCode: new FormControl('', Validators.required),
      maleRequired: new FormControl('', Validators.required),
      femaleRequired: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      street: new FormControl('')
    });
    this.getDressCodes();
    this.userProfile = this.clientUserService.getLoggedInProfile();
    this.setCurrentPosition();

    this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
    this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
    const phoneNumber = this.clientUserService.getLoggedInProfile()?.businessPhoneNumber;
    this.getMissionAddresses(phoneNumber!);
  this.getAccount(phoneNumber!);
this.getMinumumDurationSetting();
this.getMissionSetting();

    this.form.controls.address.disable();
    this.form.controls.zipCode.disable();
    this.form.controls.city.disable();
    this.form.controls.street.enable();
  }
  getMissionSetting(){
    this.missionService.getMissionSettings('MISSION',true).subscribe((response) => {
      if (response) {

        this.settings =response;

      }
    });
  }
  getMinumumDurationSetting(){
    this.missionService.getMissionSetting('MISSION_MINIMUM_DURATION_IN_MINUTES',true).subscribe((response) => {
      if (response) {
        const setting = <MissionSetting>response;
        this.minimumDuration = Number(setting.value);

      }
    });
  }
  getMissionAddresses(phoneNumber:string){
    this.missionService.getMissionAddresses(phoneNumber!).subscribe((response) => {
      if (response.result === 'OK') {
        this.userAddresses=<UserAddress[]>response.data;
        this.userStreets=this.userAddresses.map(function(add){
          return add.mainMissionStreet;
        });

      }
    });
}
  selectAddress(index:number, event: any) {
    if (event.isUserInput) {

      if (this.userAddresses.length > index) {
        this.mainMissionStreet = this.userAddresses[index].mainMissionStreet;
        this.mainMissionCity = this.userAddresses[index].mainMissionCity
        this.mainMissionZipCode = this.userAddresses[index].mainMissionZipCode
      }
    }
  }
  getAccount(phoneNumber:string){
    this.accountService.getAccount(phoneNumber).subscribe((response) => {
      if (response.result === 'OK') {
        const account = <Account>response.data;

        this.clientPaymentRate = account.paymentRate;

      }
    });
  }
  checkChange( $event:any){
    this.newAddress= $event.checked;
    if(this.newAddress) {
      this.form.controls.address.enable();
      this.form.controls.zipCode.enable();
      this.form.controls.city.enable();
      this.form.controls.street.disable();
      this.form.controls.street.setValue(null);
      this.mainMissionStreet = '';
      this.mainMissionCity = '';
      this.mainMissionZipCode = '';
      this.isReadOnly=false;
    }
    else{
      this.form.controls.address.disable();
      this.form.controls.zipCode.disable();
      this.form.controls.city.disable();
      this.form.controls.street.enable();
    }
  }

  getDressCodes(): void {

    this.clientUserService.getDressCode().subscribe((response) => {
      if (response.data) {
        this.dressCodes = <Array<DressCode>>response.data;
      }
    });

    if (this.favoriteAgent) {
      this.form.get('numberOfAgents')?.patchValue('1');
      this.form.get('sex')?.patchValue('Any');
      this.form.get('maleRequired')?.patchValue('0');
      this.form.get('femaleRequired')?.patchValue('0');
    }

  }
  getTranslation(str:string) {
    const currentLang = this.translate.currentLang; // get current language
    const returnValue = this.translate.translations[currentLang][str]; // get converted string from current language
    if (returnValue === undefined) {
      return this.translate.translations.en_merch[str]; // if value is getting undefined then return default language string, en_merch is default english language file here
    } else {
      return returnValue;
    }
  }
  toDateTime(date:Date) {
    var str = '';
    var year, month, day, hour, min;
    year = date.getUTCFullYear();
    month = date.getUTCMonth() + 1;
    month = month < 10 ? '0' + month : month;
    day = date.getUTCDate();
    day = day < 10 ? '0' + day : day;
    hour = date.getUTCHours();
    hour = hour < 10 ? '0' + hour : hour;
    min = date.getUTCMinutes();
    min = min < 10 ? '0' + min : min;

    str += year + '-' + month + '-' + day;
    str += ' ' + hour + ':' + min;
    return str;
  }

  onSubmit(): void {

    // @ts-ignore
    if (this.form.valid && match) {


      const request = new MissionRequest();
      const tariffRequest = new TariffRequest();
      request.clientMobile = this.userProfile?.userPhoneNumber;
      tariffRequest.clientMobile = this.userProfile?.userPhoneNumber;
      tariffRequest.clientPhoneNumber = this.userProfile!.businessPhoneNumber;
      tariffRequest.clientPaymentRate=this.clientPaymentRate;
      request.missionName = this.userProfile?.businessName;
      tariffRequest.missionName = this.userProfile?.businessName;
      // @ts-ignore
      request.missionDescription = this.form.get('missionDescription').value;
      tariffRequest.missionDescription = request.missionDescription!
      request.missionPrice = 0;
      tariffRequest.missionPrice = 0;
      // @ts-ignore
      request.agents = [];
      tariffRequest.agents = [];

      // @ts-ignore
      request.missionStreetAddress = this.form.get('address').value;
      tariffRequest.missionStreetAddress= request.missionStreetAddress;
      // @ts-ignore
      request.missionCityAddress = this.form.get('city').value;
      tariffRequest.missionCityAddress= request.missionCityAddress;
      // @ts-ignore
      request.missionZipcodeAddress = this.form.get('zipCode').value;
      tariffRequest.missionZipcodeAddress= request.missionZipcodeAddress;
      // @ts-ignore
      request.numberOfAgents = this.form.get('numberOfAgents').value;
      tariffRequest.numberOfAgents= request.numberOfAgents;

      if(this.isAgent){
        request.gender = "";
      }else{
        // @ts-ignore
        request.gender = this.form.get('sex').value;
      }

      tariffRequest.numberOfFemaleAgents= request.numberOfFemaleAgents;
      request.missionType = "PREPLANNED";
      tariffRequest.missionType= request.missionType;
      // @ts-ignore
      request.missionDressCode = this.form.get('dressCode').value.name;
      tariffRequest.missionDressCode= request.missionDressCode;
      // @ts-ignore
      request.language = this.form.get('language').value.join(',');
      tariffRequest.language= request.language;
      // @ts-ignore
      request.numberOfMaleAgents = +this.form.get('maleRequired').value;
      tariffRequest.numberOfMaleAgents= request.numberOfMaleAgents;

      // @ts-ignore
      request.numberOfFemaleAgents = +this.form.get('femaleRequired').value;
      tariffRequest.numberOfFemaleAgents= request.numberOfFemaleAgents;

      if(this.isAgent){
        request.listFavoriteAgents = [this.favoriteAgent];
        tariffRequest.listFavoriteAgents = [this.favoriteAgent];
      } else {
        request.listFavoriteAgents = [];
        tariffRequest.listFavoriteAgents = [];

      }

      const totalAgents = +request.numberOfAgents;
      const maleAgents = +request.numberOfMaleAgents;
      const femaleAgents = +request.numberOfFemaleAgents;
      const sex = request.gender;

      if (sex === 'Any') {

        request.numberOfMaleAgents = totalAgents;
        request.numberOfFemaleAgents = totalAgents;

      } else if (sex === 'Female') {
        request.numberOfMaleAgents = 0;
        request.numberOfFemaleAgents = totalAgents;
      } else if (sex === 'Male') {
        request.numberOfFemaleAgents = 0;
        request.numberOfMaleAgents = totalAgents;
      } else if (sex === 'Mixed') {

        const ttl = maleAgents + femaleAgents
        if (ttl !== totalAgents) {

          const messagesDialog = new MessageDialog()
          messagesDialog.title = 'Validation Error';
          messagesDialog.description = `Female and male agents are not adding up to total number of agents`
          messagesDialog.error = true;
          messagesDialog.confirmation = false;
          const dialogRef = this.dialog.open(MessageDialogComponent
            , {
              width: '550px',
              backdropClass: 'bg-color',
              data: messagesDialog,
              disableClose: true
            });

          dialogRef.afterClosed().subscribe((result: any) => {
            return;
          });
          console.log('Female and male agents are not adding up to total number of agents')
          return;
        }

      }
      // @ts-ignore
      const startTime = this.form.get('startTime').value;
      const startDate = new Date(Date.parse(startTime))
      startDate.setSeconds(0,0);
      // @ts-ignore
      const endTime = this.form.get('endTime').value;
      const endDate = new Date(Date.parse(endTime));
      const fullAddress = `${request.missionStreetAddress + ", " + request.missionZipcodeAddress + " " + request.missionCityAddress}`;
      console.log('startDate',startDate);
      console.log('endDate',endDate);
      const diff = moment.duration(moment(endDate).diff(moment(startDate)));
      const minutes = diff.asMinutes();
      const first_day =this.datePipe.transform(startDate, "dd-MM-yyyy HH:mm:SS");
      const last_day =this.datePipe.transform(endDate, "dd-MM-yyyy HH:mm:SS");

      ////
      let newStartDate = new Date(startDate);
      newStartDate.setSeconds(0)
      let newEndDate = new Date(endDate);

      /////
      request.missionStartDate = this.utilService.toUTCDate(newStartDate);
      request.missionEndDate =this.utilService.toUTCDate(newEndDate);
      request.missionDuration = Math.round(((minutes/60) + Number.EPSILON) * 100) / 100;
      console.log('missionDuration ',request.missionDuration );
if(request.missionDuration<Math.round(((this.minimumDuration/60) + Number.EPSILON) * 100) / 100){

  const messagesDialog = new MessageDialog()
  messagesDialog.title = 'Validation Error';
   messagesDialog.description = this.translate.instant('client.minimum_duration')+ this.minimumDuration + this.translate.instant('client.minutes');
   messagesDialog.error = true;
  messagesDialog.confirmation = false;
  const dialogRef = this.dialog.open(MessageDialogComponent
    , {
      width: '550px',
      backdropClass: 'bg-color',
      data: messagesDialog,
      disableClose: true
    });

  dialogRef.afterClosed().subscribe((result: any) => {
    return;
  });
  return;
}

      this.geocoder = new google.maps.Geocoder();
      // @ts-ignore
      this.geocoder.geocode({'address': fullAddress}, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          request.latitude = results[0].geometry.location.lat();
          tariffRequest.latitude = results[0].geometry.location.lat();
          request.longitude = results[0].geometry.location.lng();
          tariffRequest.longitude = results[0].geometry.location.lng();
          console.log(JSON.stringify(request));
          tariffRequest.missionStartDate = first_day!;
          tariffRequest.missionEndDate = last_day!;
         var settings=this.settings.find(t=>t.keyUnique==='AGENT_MISSION_MAXIMUM_DURATION_IN_MINUTES');
          tariffRequest.maxAgentTime=settings!.value;
          this.tariffsService.calculateTariff(tariffRequest).subscribe(response => {

            const messagesDialog = new MessageDialog();
            if (response.result === 'OK') {


              const amount = this.utilService.toDecimal(response.data.priceForMission);
request.mainMissionId=response.data.mainMissionId;
              request.missionPrice = +amount;
request.splitMission=response.data.splitMission;
              const dialogRef = this.dialog.open(BookAgentSummaryDialogComponent
                , {
                  width: '550px',
                  backdropClass: 'bg-color',
                  data: {
                    title: 'Summary',
                    isError: false,
                    isConfirmation: true,
                    request: request,
                  },
                  disableClose: false
                });

              dialogRef.afterClosed().subscribe((result: any) => {

                if(result === 'CONFIRM'){


                  if(request.listFavoriteAgents && request.listFavoriteAgents.length >0){

                    this.missionService.bookFavoriteAgent(request).subscribe((response) => {

                      if (response.result == 'OK') {

                        request.missionId = response.data;
                        this.store.dispatch({type: CREATE_MISSION_REQUEST, payload: request});
                        this.router.navigate(['client/booking/checkout']);

                      } else {
                        this.openSnackBar('Error occurred please try again', 'Close');

                      }

                    }, error => {
                      this.openSnackBar('Error occurred please try again', 'Close');

                    });
                  }else{

                    this.missionService.createMission(request).subscribe((response) => {

                      if (response.result == 'OK') {

                        request.missionId = response.data;
                        this.store.dispatch({type: CREATE_MISSION_REQUEST, payload: request});
                        this.router.navigate(['client/booking/checkout']);

                      } else {
                        this.openSnackBar('Error occurred please try again', 'Close');

                      }

                    }, error => {
                      this.openSnackBar('Error occurred please try again', 'Close');

                    });
                  }

                }
              });

            } else {
              messagesDialog.title = 'Error';
              messagesDialog.description = `${response.data}`
              messagesDialog.error = true;
              messagesDialog.confirmation = false;
              const dialogRef = this.dialog.open(ConfirmationDialogComponent
                , {
                  width: '550px',
                  backdropClass: 'bg-color',
                  data: messagesDialog,
                  disableClose: true
                });

              dialogRef.afterClosed().subscribe((result: any) => {
              });
            }
          });

        } else {
          console.log('Error - ', results, ' & Status - ', status);

        }

      });


    } else {
      console.log('invalid form')
    }
  }


  // @ts-ignore
  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);


    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: { type: any; overlay: { getPaths: () => any; drag: any; getPath: () => any; }; }) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );

  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;


      });
    }
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }

  // @ts-ignore
  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      );
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    );
  }
startTimePicker(): void{
  const dialogRef = this.dialog.open(TimePickerComponent
    , {
      width: '550px',
      backdropClass: 'bg-color',
      data: {
        title: 'Choose start time',

      },
      disableClose: false
    });

  dialogRef.afterClosed().subscribe((result: any) => {

    if(result && result.confirmation === 'DONE'){
      let time = result.time;

      this.form.get('startTime')?.setValue(time);


    }

  });
}


  endTimePicker(): void{
    const dialogRef = this.dialog.open(TimePickerComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          title: 'Choose end time',

        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {

      if(result && result.confirmation === 'DONE'){
        let time = result.time;

        this.form.get('endTime')?.setValue(time);


      }

    });
  }

  selectedGender(val: string) {

    if (val === 'Any') {
      this.disableFemale = true
      this.disableMale = true
      this.form.get('maleRequired')?.setValue(this.numberOfAgentsVal? this.numberOfAgentsVal :'1');
      // @ts-ignore
      this.form.get('femaleRequired')?.setValue(this.numberOfAgentsVal? this.numberOfAgentsVal :'1');
    } else if (val === 'Male') {
      this.form.get('maleRequired')?.setValue(this.numberOfAgentsVal? this.numberOfAgentsVal :'0');
      this.form.get('femaleRequired')?.setValue('0');
      this.disableMale = true
      this.disableFemale = true
    } else if (val === 'Female') {
      this.form.get('femaleRequired')?.setValue(this.numberOfAgentsVal? this.numberOfAgentsVal :'0');
      this.form.get('maleRequired')?.setValue('0');
      this.disableFemale = true
      this.disableMale = true
    } else {
      this.disableFemale = false
      this.disableMale = false
    }

  }

  selectedNumberOfAgents(value: string){
    this.numberOfAgentsVal = value;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
