import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Attribute, Directive} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[advs-compare-pass]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareDirectivePassDirective, multi: true}]
})
export class CompareDirectivePassDirective implements Validator {

  constructor(@Attribute('advs-compare-pass') public comparer: string,
              @Attribute('parent') public parent: string) {
  }

  // @ts-ignore
  validate(c: AbstractControl): { [key: string]: any } {
    const e = c.root.get(this.comparer);

    // value not equal in verify control
    if (e && c.value !== e.value && !this.isParent) {
      return {compare: true};
    }

    // user typing in password and match
    if (e && c.value === e.value && this.isParent) {
      // @ts-ignore
      delete e.errors.compare;
      // @ts-ignore
      if (!Object.keys(e.errors).length) {
        e.setErrors(null);
      }
    }

    // user typing in password and mismatch
    if (e && c.value !== e.value && this.isParent) {
      e.setErrors({compare: true});
    }
  }

  private get isParent() {
    if (!this.parent) {
      return false;
    }
    return this.parent === 'true' ? true : false;
  }
}


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[advs-compare-first-name]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareDirectiveFirsNameDirective, multi: true}]
})
export class CompareDirectiveFirsNameDirective implements Validator {

  constructor(@Attribute('advs-compare-first-name') public comparer: string,
              @Attribute('parent') public parent: string) {
  }

  // @ts-ignore
  validate(c: AbstractControl): { [key: string]: any } {
    const e = c.root.get(this.comparer);

    // value not equal in verify control
    if (e && c.value !== e.value && !this.isParent) {
      e.setErrors(null);
    }

    // user typing in username and match
    if (e && c.value === e.value && this.isParent) {

      return {match: true};
    }

    // user typing in (Firstname or lastname or middlename) and mismatch
    if (e && c.value !== e.value && this.isParent) {
      // e.setErrors({"match": true});
      // delete e.errors['match'];
      // if (!Object.keys(e.errors).length) {
      //   e.setErrors(null);
      // }
      e.setErrors(null);
    }
  }

  private get isParent() {
    if (!this.parent) {
      return false;
    }
    return this.parent === 'true' ? true : false;
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[advs-compare-last-name]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareDirectiveLastNameDirective, multi: true}]
})

export class CompareDirectiveLastNameDirective implements Validator {

  constructor(@Attribute('advs-compare-last-name') public comparer: string,
              @Attribute('parent') public parent: string) {
  }

  // @ts-ignore
  validate(c: AbstractControl): { [key: string]: any } {
    const e = c.root.get(this.comparer);

    // value not equal in verify control
    if (e && c.value !== e.value && !this.isParent) {
      e.setErrors(null);
    }

    // user typing in username and match
    if (e && c.value === e.value && this.isParent) {

      return {match: true};
    }

    // user typing in (Firstname or lastname or middlename) and mismatch
    if (e && c.value !== e.value && this.isParent) {
      // e.setErrors({"match": true});
      // delete e.errors['match'];
      // if (!Object.keys(e.errors).length) {
      //   e.setErrors(null);
      // }
      e.setErrors(null);
    }
  }

  private get isParent() {
    if (!this.parent) {
      return false;
    }
    return this.parent === 'true' ? true : false;
  }
}


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[advs-compare-middle-name]',
  providers: [{provide: NG_VALIDATORS, useExisting: CompareDirectiveMiddleNameDirective, multi: true}]
})

export class CompareDirectiveMiddleNameDirective implements Validator {

  constructor(@Attribute('advs-compare-middle-name') public comparer: string,
              @Attribute('parent') public parent: string) {
  }

  // @ts-ignore
  validate(c: AbstractControl): { [key: string]: any } {
    const e = c.root.get(this.comparer);

    // value not equal in verify control
    if (e && c.value !== e.value && !this.isParent) {
      e.setErrors(null);
    }

    // user typing in username and match
    if (e && c.value === e.value && this.isParent) {

      return {match: true};
    }

    // user typing in (Firstname or lastname or middlename) and mismatch
    if (e && c.value !== e.value && this.isParent) {
      // e.setErrors({"match": true});
      // delete e.errors['match'];
      // if (!Object.keys(e.errors).length) {
      //   e.setErrors(null);
      // }
      e.setErrors(null);
    }
  }

  private get isParent() {
    if (!this.parent) {
      return false;
    }
    return this.parent === 'true' ? true : false;
  }
}

export function patternValidator(regexp: RegExp): ValidatorFn {
  return (control: AbstractControl): { [p: string]: any } | null => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    // @ts-ignore
    return !regexp.test(value) ? {patternInvalid: {regexp}} : null;
  };
}

