import {Action} from '@ngrx/store';
import {MissionRequest} from "../../client/model/mission-request";

export const CREATE_MISSION_REQUEST = 'CREATE_MISSION_REQUEST';
export const GET_MISSION_REQUEST = 'CREATE_MISSION_REQUEST';
export const DELETE_MISSION_REQUEST = 'DELETE_MISSION_REQUEST';




export class CreateMissionRequest implements Action {
  type = CREATE_MISSION_REQUEST;
  constructor(public payload: MissionRequest) { }
}


export class GetMissionRequest implements Action {
  type = GET_MISSION_REQUEST;
  constructor(public payload: MissionRequest) { }
}


export class DeleteMissionRequest implements Action {
  type = DELETE_MISSION_REQUEST;
  constructor(public payload: MissionRequest) { }
}

export type MissionRequestActions
  = CreateMissionRequest |
  GetMissionRequest |
  DeleteMissionRequest;
