<div class="my-3">
  <mat-card style=" height: auto" class="c-align">


    <div class="row mt-lg-5">


      <div class="col">

        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.hour'|translate}}</mat-label>
          <mat-select id="numberOfAgents"
                      name="numberOfAgents"
          >
            <mat-option *ngFor="let agentValue of agents" [value]="agentValue"
                        style="color:  black" (click)="selectedNumberOfAgents(agentValue)">{{agentValue}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row mt-lg-5">


      <div class="col">

        <span style="background-color: #6d6d6d;padding: 10px">{{'client.today_s_rate'|translate}}</span>

        <div class="row mt-lg-5">

          <div class="col" *ngIf="numberSelected">

            <div class="col">
                <span>
                 <mat-icon svgIcon="icons_job">
                 </mat-icon>
                  1
               </span>
            </div>
          </div>
          <div class="col">

            {{numberSelected}}
          </div>
          <div class="col">
            {{paymentRate1 | currency:'EUR'}}
          </div>

        </div>

        <div class="row mt-lg-5">

          <div class="col" *ngIf="numberSelected">

            <div class="col">
                <span>
                 <mat-icon svgIcon="icons_job">
                 </mat-icon>
                  5
               </span>
            </div>
          </div>
          <div class="col">

            {{numberSelected}}
          </div>
          <div class="col">
            {{paymentRate5 | currency:'EUR'}}
          </div>

        </div>

        <div class="row mt-lg-5">

          <div class="col" *ngIf="numberSelected">

            <div class="col">
                <span>
                 <mat-icon svgIcon="icons_job">
                 </mat-icon>
                  10
               </span>
            </div>
          </div>
          <div class="col">

            {{numberSelected}}
          </div>
          <div class="col">
            {{paymentRate10 | currency:'EUR'}}
          </div>

        </div>

      </div>

    </div>
  </mat-card>

</div>
