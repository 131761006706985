import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TriggerService {
  constructor() {}
  private _trigger = new BehaviorSubject<string>("");
  castTrigger = this._trigger.asObservable();

  trigger(value: any) {
    console.log(value)
    this._trigger.next(value);
  }
}
