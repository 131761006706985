export const environment = {
  firebase: {
    apiKey: "AIzaSyAJvrNu9w1YsGX6lWFhztrT_tUGYZQwNDY",
    authDomain: "nbs-brussels.firebaseapp.com",
    projectId: "nbs-brussels",
    storageBucket: "nbs-brussels.appspot.com",
    messagingSenderId: "192122549168",
    appId: "1:192122549168:web:74c3bfb197d5e7300e5c75",
    measurementId: "G-856GZZJ9V8"
  },
  production: true,
  baseUrl: 'https://api.test.nbs.s-mobile.live',
  basicToken: 'Basic bW9hYy1hcHAtY2xpZW50Oll5a016SXoxS2N0TjVtQ1l2bW1q',
  approveAgentUrl:'/api/users/profile/changeProfileActivation',
  getAgentsPendingApprovalUrl: '/api/users/profile/agents/unapproved/get',
  getExecutedMissionsUrl: '/api/mission/get/missions/executed',
  getBookedMissionsUrl: '/api/mission/get/missions/booked',
  getActiveAgents: '/api/users/profile/agents/get',
  getAgentHistoryList:'/api/mission/get/agent/history/all',
  getUserProfileByPhone:'/api/users/profile/get',
  getRevenueSummaryUrl: '/api/mission/revenue/summary/get',
  getUserUrl: '/api/users/profile/get',
  testingClientPhoneNumber: '32479259319,27723034994',
  testingAgentPhoneNumber: '27681410422',
  MISSION_CLIENT_DASHBOARD_STATS:"/api/mission/client/dashboard/stats",
   MISSION_CLIENT_UPCOMING:"/api/mission/client/upcoming",
   MISSION_CLIENT_ONGOING: "/api/mission/client/ongoing",
   MISSION_CLIENT_CONFIRM:"/api/mission/client/confirm",
   MISSION_CLIENT_ARRIVAL_CONFIRMAGENT:"/api/mission/client/arrival/confirmagent",
   MISSION_CLIENT_START:"/api/mission/client/start",
   MISSION_CLIENT_END: "/api/mission/client/end",
   MISSION_CLIENT_EXTEND:"/api/mission/client/extend",
   MISSION_CLIENT_GET :"/api/mission/client/get",
   MISSION_CLIENT_GET_AGENTS:"/api/mission/agents/get",
   MISSION_CLIENT_RATE_AGENT:"/api/mission/client/rate/agent",
   MISSION_CLIENT_GETPAST :"/api/mission/client/getpast",
   MISSION_CLIENT_GET_ACCOUNT_TRANSACTIONS:"/api/mission/get/account/transactions",
   MISSION_GET_INVOICES:"/api/mission/invoice/get",
   MISSION_CREATE:"/api/mission/create",
   MISSION_CANCEL:"/api/mission/cancel",
   MISSION_GET_NEW:"/api/mission/getnew",
   MISSION_GETPAST:"/api/mission/getpast",
   MISSION_BOOK_FAVOURITE_AGENT:"/api/mission/client/favorite/booking",
   MISSIION_AGENT_UPCOMING_URL:"/api/mission/agent/upcoming",
   MISSION_AGENT_DASHBOARD_STATS_URL:"/api/mission/agents/dashboard/stats",
   MISSION_AGENT_UPCOMING_URL:"/api/mission/agent/upcoming",
   MISSION_AGENT_ACCEPT:"/api/mission/agent/accept",
   MISSION_AGENT_FAVOURITE_ACCEPT:"/api/mission/agent/favorite/accept",
   MISSION_AGENT_FAVOURITE_REJECT :"/api/mission/agent/favorite/reject",
   MISSION_AGENT_START:"/api/mission/agent/start",
   MISSION_AGENT_END:"/api/mission/agent/end",
   MISSION_AGENT_CONFIRM:"/api/mission/agent/confirm",
   MISSION_AGENT_EXTEND:"/api/mission/agent/extend",
   MISSION_AGENT_CURRENT:"/api/mission/agent/current",
   MISSION_AGENT_GET:"/api/mission/agent/mission",
   MISSION_AGENT_ARRIVE:"/api/mission/agent/arrive",
   MISSION_AGENT_CANCEL:"/api/mission/agent/cancel",
   MISSION_AGENT_MOVETOSITE:"/api/mission/agent/movetosite",
   MISSION_AGENT_FAV_MISSION :"/api/mission/agent/favorite/mission",
   CREATE_USER_URL:"/api/users/profile/create",
   SET_PROFILE_STATE_URL:"/api/users/profile/setState",
   EDIT_USER_URL:"/api/users/profile/edit",
   GET_USER_URL:"/api/users/profile/get",
   GET_PARTNERS:"/api/api/users/profile/partners",
   GET_DRESSCODE:"/api/users/profile/dresscode",
   GET_QUALIFICATIONS:"/users/profile/qualifications",
   GET_LANGUAGES:"/api/users/profile/languages",
   GET_USERS_WITH_PHONENUMBERS:"/api/users/profile/get/users",
   SAVE_AGENT_LOCATION:"/api/users/agent/location/save",
   GET_AGENT_LOCATION:"/api/users/agent/location",
   GET_FAVOURITE__AGENTS:"/api/users/profile/favorite/agent",
   ADD_AGENT_AS_FAVOURITE:"/api/users/profile/favorite/agent/add",
   BLACKLIST_USER_PROFILE:"/api/users/profile/blacklist",
   AGENT_DOCUMENT_UPDATE:"/api/users/profile/agent/document/update",
   maps_api_key: "AIzaSyAJvrNu9w1YsGX6lWFhztrT_tUGYZQwNDY",
  TARIFF_CALCULATE_URL:"/api/tariff/calculate",
  GET_ACCOUNT_URL: "/api/account/get",
  GET_TRANSACTIONS_URL:"/api/account/transactions/get",
  placesKey: "AIzaSyBwpbA5SzW36Zh8H3M9l6REn9v8ubBemgM",

  // PARTNER
  getBusinessPartner:'/api/users/partners/get',
  getPartners:'/api/users/partners/all',
  savePartner:'/api/users/partners/create',
  activatePartner:'/api/users/partners/activate',

  // AGENCIES
  getAgency:'/api/users/agencies/get',
  getAgencies:'/api/users/agencies/all',
  saveAgency:'/api/users/agencies/create',
  activateAgency:'/api/users/agencies/activate',

  // CLIENT
  getClient:'/api/users/client/get',
  getAllClient:'/api/users/client/all',
  getClientAccount:'/api/users/profile/account',
  activateClient:'/api/users/client/activate',
  getActiveCountries:'/api/users/country/active',
  changeRate:'/api/users/profile/changePaymentRate',
  // USER
  saveUser:'/api/auth-server/v1/user/sign-up',
  activateUser:'/api/auth-server/v1/user/activate',
  deactivateUser:'/api/auth-server/v1/user/deactivate',
  getUserStatus:'/api/auth-server/v1/user/status',
  resetPassword:'/api/auth-server/v1/user/password/reset',
  initiateResetPassord:'/api/auth-server/v1/user/password/initiate',

 // MENU
 getMenu:'/api/users/menu',
 getUserGroup:'/api/users/group',
 getAllUserGroup:'/api/users/group/all',
 getAllMenuByGroupid:'/api/users/menu/permission',
 setMenuPermission:'/api/users/menu/activate',


 // FILE SERVICE
 getImageAsBase64:'/file-api/file/user/document/get',

  // Mission
  getMissionSetting:'/api/mission/get/setting',
  getMissionSettings:'/api/mission/settings',
  getMissionAddresses:'/api/users/profile/get/address',
  createFaqUrl:'/users/faq/create',
  getFaqUrl:'/users/faq/get',

  // Tariffs
  getTariffs:'/tariff/get/all',
  saveTariff: '/tariff/add-update'
}
