import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PaymentService} from "../service/payment.service";
import {StorageService} from "../../service/storage.service";
import {AccountService} from "../service/account.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {ClientUserService} from "../service/client-user.service";
import {MissionService} from "../service/mission.service";
import {Store} from "@ngrx/store";
import {AccountLicenseRequest} from "../model/account-license-request";
import {MissionRequest, MissionUpdateRequest} from "../model/mission-request";
import {getMissionRequest} from "../../store";
import {DELETE_MISSION_REQUEST} from "../../store/mission-request/mission-request.actions";
import {MissionExtensionRequest} from "../model/mission-extension-request";
import {ApproveDialogComponent} from "../dialogs/approve-dialog/approve-dialog.component";
import {AgentExtensionWaitingDialogComponent} from "../dialogs/agent-extension-waiting-dialog/agent-extension-waiting-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PushNotification} from "../model/push-notification";
import {UpdateMissionRequest} from "../model/update-mission-request";
import {MissionProgress} from "../model/mission-progress";
import {CREATE_MISSION_PROGRESS_REQUEST} from "../../store/mission-progress/mission-progress.actions";

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {

  accountLicenseBody = new AccountLicenseRequest();
  payment: any;
  missionRequest: MissionRequest;
  isSuccess = false;
  paymentStatus: string;
  tariffRequestMessage: MissionExtensionRequest;

  constructor(private route: ActivatedRoute,
              private paymentService: PaymentService,
              private storageService: StorageService,
              private accountsService: AccountService,
              private router: Router,
              private snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              private subscriberService: ClientUserService,
              private missionService: MissionService,
              private store: Store,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {

    const missionExtension = this.storageService.getItem(StorageService.MISSION_EXTENSION);

    if (missionExtension) {
      this.tariffRequestMessage = JSON.parse(missionExtension) as MissionExtensionRequest;
    }

    if (!this.storageService.getItem(StorageService.BOOKING_PAYMENT)) {

      this.goToHomePage();
      return;
    }


    this.store.select(getMissionRequest).subscribe((value) => {
      if (value) {
        this.missionRequest = value;
      }
    })

    this.payment = JSON.parse(this.storageService.getItem(StorageService.BOOKING_PAYMENT));
    this.finalisePayment(this.payment.data.gatewayReference)

  }

  finalisePayment(gatewayReference: any) {
    this.spinner.show();
    this.paymentService.callPaymentWebHook(gatewayReference).subscribe(data => {
      console.log(data);
      console.log("333333333333333333");
      this.getPaymentAndProcess(gatewayReference);
    }, error => {
      this.spinner.hide();
      console.log(error);
      this.goToHomePage();
    });


  }

  getPaymentAndProcess(gatewayReference: any) {
    // Log.i("getPaymentAndProcess","calling getPaymentAndProcess");
    this.paymentService.getPaymentByGatewayReference(gatewayReference).subscribe(data => {
      console.log(data)
      console.log("44444444444444444");

      if (data) {
        if (data.result === 'OK') {
          var paymentReturned = data.data;
          this.paymentStatus = 'SUCCESS';
          if (data.data.transactionStatus === "SUCCESS") {

            this.sendSubscriptionDataAfterPaymentForProcessing(data);
          } else if (paymentReturned.transactionStatus === ("FAILED")) {
            this.spinner.hide();
            if (data.gatewayStatus === ("canceled")) {
              //moveBack=true;
              //closeGateWayActivity();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();

            }
            if (paymentReturned.gatewayStatus === ("failed")) {
              //moveBack=false;
              //processFailedToGetPayment();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            }
            if (paymentReturned.gatewayStatus === ("expired")) {
              //moveBack=true;
              //closeGateWayActivity();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            } else {
              //processFailedToGetPayment();
              this.openSnackBar('Payment failed', 'Close');
              this.goToHomePage();
            }
          } else if (paymentReturned.transactionStatus === ("PENDING")) {
            this.spinner.hide();
            //processFailedToGetPayment();
            this.openSnackBar('Payment failed', 'Close');
            this.goToHomePage();
          } else {
            this.spinner.hide();
            //processFailedToGetPayment();
            this.openSnackBar('Payment failed', 'Close');
            this.goToHomePage();
          }
        } else {
          this.spinner.hide();
          this.paymentStatus = 'FAILED';
          //processFailedToGetPayment();
          this.openSnackBar('Payment failed', 'Close');
          this.goToHomePage();
        }
      } else {
        this.spinner.hide();
        //processFailedToGetPayment();
        this.paymentStatus = 'FAILED';
        this.openSnackBar('Payment failed', 'Close');
        this.goToHomePage();
      }


    }, error => {
      this.spinner.hide();
      this.paymentStatus = 'FAILED';
      console.log(error)
      this.openSnackBar('Payment failed', 'Close');
      this.goToHomePage();
    });

  }

  goToHomePage() {
    this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
    this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
    this.storageService.deleteItem(StorageService.BOOKING_PAYMENT);
    this.spinner.hide();
    this.router.navigate(['client']);
  }


  sendSubscriptionDataAfterPaymentForProcessing(paymentResult: any) {

    this.spinner.hide();
    if (this.tariffRequestMessage) {


      this.missionService.clientExtendMission(this.tariffRequestMessage).subscribe(response => {
        if (response.result == 'OK') {
          if (this.tariffRequestMessage.agentPhoneNumber) {

            this.storageService.saveObject(StorageService.MISSION_EXTENSION_WAITING, this.tariffRequestMessage);

            const dialogRef = this.dialog.open(AgentExtensionWaitingDialogComponent
              , {
                width: '550px',
                backdropClass: 'bg-color',
                data: {
                  missionExtensionRequest: this.tariffRequestMessage,
                },
                disableClose: true
              });

            dialogRef.afterClosed().subscribe((result: any) => {
              if (result === 'OK') {
                this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION_WAITING);
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
                this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
                this.openSnackBar('Payment was successful', 'Close');
                this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
                this.goToHomePage();
              }
            });


          } else {
            this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
            this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
            this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
            this.openSnackBar('Payment was successful', 'Close');
            this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
            this.goToHomePage();
          }

        } else {
          this.storageService.saveString(StorageService.PAYMENT_STATUS, 'false');
          this.openSnackBar('Payment failed', 'Close');
          this.goToHomePage();
        }
      })

    } else {

      if(this.missionRequest.listFavoriteAgents && this.missionRequest.listFavoriteAgents.length > 0){

        this.updateFavBookMission(+this.missionRequest.missionId,false);
      }else{
        this.updateMission(+this.missionRequest.missionId, false);      }

    }


  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 8000,
    });
  }

  doneClicked(): void {
  }


  updateMission(missionId: any, deleted: boolean, gatewayReference?: string) {


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    missionUpdateRequest.gatewayReference = gatewayReference;
    this.missionService.updateMission(missionUpdateRequest).subscribe(value => {
      this.spinner.hide();
      if (value.result === 'OK') {

        this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
        this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
        this.openSnackBar('Payment was successful', 'Close');
        this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
        this.goToHomePage();

        this.openSnackBar('Payment was successful', 'Close');

      } else {

        this.openSnackBar('Payment failed', 'Close');

      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('An error occurred', 'Close');
    });
  }

  updateFavBookMission(missionId: any, deleted: boolean, gatewayReference?: string) {


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    missionUpdateRequest.gatewayReference = gatewayReference;
    this.missionService.updateFavBookMission(missionUpdateRequest).subscribe(value => {
      this.spinner.hide();
      if (value.result === 'OK') {

        this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
        this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
        this.openSnackBar('Payment was successful', 'Close');
        this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
        this.goToHomePage();

        this.openSnackBar('Payment was successful', 'Close');

      } else {

        this.openSnackBar('Payment failed', 'Close');

      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('An error occurred', 'Close');
    });
  }
}
