import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {PushNotification} from "../model/push-notification";
import {Mission} from "../../model";
import httpClient from '../http-client';
import axios from "axios";
import instance from "../http-client";
import {Root, Step} from "../model/other-models";
import {MissionAgent} from "../model/mission-agent";
import {AgentLocationMessage} from "../model/agent-location-message";
import {environment} from "../../../environments/environment.prod";
import {MessagingService} from "../../service/messaging.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MissionBottomSheetComponent} from "../components/mission-bottom-sheet/mission-bottom-sheet.component";
import {ClientSingletonService} from "../service/client-singleton.service";
import {MissionDialogComponent} from "../dialogs/mission-dialog/mission-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MissionExtensionDialogComponent} from "../dialogs/mission-extension-dialog/mission-extension-dialog.component";
import {MissionExtensionRequest} from "../model/mission-extension-request";
import {TariffsService} from "../service/tarrifs.service";
import {MessageDialog} from "../model/message-dialog";
import {BookAgentSummaryDialogComponent} from "../dialogs/book-agent-summary-dialog/book-agent-summary-dialog.component";
import {CREATE_MISSION_REQUEST, DELETE_MISSION_REQUEST} from "../../store/mission-request/mission-request.actions";
import {ConfirmationDialogComponent} from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import {UtilService} from "../service/util.service";
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import {TariffRequest, TariffRequestMessage} from "../model/mission-request";
import * as moment from "moment";
import {StorageService} from "../../service/storage.service";
import {interval, Subscription} from "rxjs";
import {Account} from "../model/account";
import {AccountService} from "../service/account.service";

@Component({
  selector: 'app-ongoing-mission',
  templateUrl: './ongoing-mission.component.html',
  styleUrls: ['./ongoing-mission.component.css']
})
export class OngoingMissionComponent implements OnInit,OnDestroy {

  missionId: string;
  mission: Mission;
  lat = -17.7445993;
  lng = 30.9833653;
  zoom = 9;

  pointList: { lat: number; lng: number }[] = [];
  drawingManager: any;
  selectedShape: any;
  selectedArea = 0;
  private geocoder: any;
  array: any = [];
  array2: any = [];
  agents: MissionAgent[] = [];
  agentLocationMessages: AgentLocationMessage[] = [];
  pushNotification: PushNotification;
  public origin: any;
  public destination: any;
  public directions: any[] = [];
  missionExtensionWindowValid = false;
  mySubscription: Subscription;
  clientPaymentRate:number=100;
  constructor(private route: ActivatedRoute,
              private missionService: MissionService,
              private clientUserService: ClientUserService,
              private messagingService: MessagingService,
              private _bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private tariffsService: TariffsService,
              private utilService: UtilService,
              private datePipe: DatePipe,
              private store: Store,
              private router: Router,
              private storageService: StorageService,
              private accountService: AccountService,
  ) {
    this.route.params.subscribe(params => {
      this.missionId = params['missionId'];

    });
  }

  ngOnInit(): void {

    this.mySubscription= interval(15000).subscribe((x =>{
      this.getMissionByMissionId();

    }));
    this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
    this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
    this.getMissionByMissionId();
    this.missionExtensionWindow();
    const phoneNumber = this.clientUserService.getLoggedInProfile()?.businessPhoneNumber;
    this.accountService.getAccount(phoneNumber!).subscribe((response) => {
      if (response.result === 'OK') {
        const account = <Account>response.data;

        this.clientPaymentRate = account.paymentRate;

      }
    });
  }


  getMissionByMissionId(): void {


    this.missionService.clientGetMission(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!, this.missionId)
      .subscribe(response => {
        if (response.result === 'OK') {
          this.mission = <Mission>response.data;

          this.lng = this.mission.lngLocation
          this.lat = this.mission.latLocation
          this.getMissionAgents();

        }

      });


  }

  missionExtensionWindow(): void {


    this.missionService.isValidMissionExtension(this.missionId)
      .subscribe(response => {
        if (response.result === 'OK') {
          this.missionExtensionWindowValid = true;

        }

      });


  }

  // @ts-ignore
  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    // @ts-ignore
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);


    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: { type: any; overlay: { getPaths: () => any; drag: any; getPath: () => any; }; }) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );

  }


  // @ts-ignore
  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      );
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    );
  }

  getPolyLines() {

    for (const agentLocation of this.agentLocationMessages) {

      const origin = {lat: this.mission.latLocation, lng: this.mission.lngLocation};
      const destination = {lat: agentLocation.latitude, lng: agentLocation.longitude};
      this.directions.push({
        origin: origin,
        destination: destination
      })
    }


  }


  getMissionAgents(): void {
    this.agentLocationMessages = [];
    this.missionService.clientGetMissionAgents(this.missionId).subscribe((response) => {

      if (response.result === "OK") {

        this.agents = <MissionAgent[]>response.data;

        if (this.agents && this.agents.length > 0) {

          for (const agent of this.agents) {

            this.clientUserService.getAgentLocation(agent.agentPhoneNumber).subscribe((customerResponse) => {

              if (customerResponse.result === "OK") {

                const agentLocationMessage = <AgentLocationMessage>customerResponse.data;
                this.agentLocationMessages.push(agentLocationMessage);
                this.getPolyLines();
              }

            });

          }

        }

      }


    });
  }

  openBottomSheet() {

    this._bottomSheet.open(MissionBottomSheetComponent, {
      data: {
        mission: this.mission,
        agents: this.agents,
      }
    });

  }

  extendMission() {


    const dialogRef = this.dialog.open(MissionExtensionDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          mission: this.mission,
        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result.action === 'EXTEND') {

        const request = <MissionExtensionRequest>result.missionExtension;



        const tariffRequestMessage = new TariffRequestMessage();
        // @ts-ignore
        tariffRequestMessage.clientPhoneNumber = (request.clientMobileNumber)?request.clientMobileNumber:this.clientUserService.getLoggedInProfile()?.businessPhoneNumber!;

        tariffRequestMessage.numberOfAgents = (1);
        tariffRequestMessage.extension = (true);

        const missionEndDate = this.mission.missionEndDate;
        const eDBefore = new Date(missionEndDate);
        const eD = new Date(missionEndDate);


        // @ts-ignore
        eD.setHours(eD.getHours() + request.extensionDuration);


        // @ts-ignore
        tariffRequestMessage.missionEndDate = this.datePipe.transform(eD, 'dd-MM-yyyy HH:mm:ss');
        // @ts-ignore
        tariffRequestMessage.missionStartDate = this.datePipe.transform(eDBefore, 'dd-MM-yyyy HH:mm:ss');


        console.log(" request "+JSON.stringify(tariffRequestMessage))

        tariffRequestMessage.clientPaymentRate=this.clientPaymentRate;
        this.tariffsService.calculateTariff(tariffRequestMessage).subscribe(response => {

          const messagesDialog = new MessageDialog();
          if (response.result === 'OK') {

            const amount = this.utilService.toDecimal(response.data.priceForMission);

            request.priceForExtension = +amount;
            // @ts-ignore
            tariffRequestMessage.amount = request.priceForExtension;

            const dialogRef = this.dialog.open(BookAgentSummaryDialogComponent
              , {
                width: '550px',
                backdropClass: 'bg-color',
                data: {
                  title: 'Summary',
                  isError: false,
                  isConfirmation: true,
                  request: request,
                  isExtension: true,
                },
                disableClose: false
              });

            dialogRef.afterClosed().subscribe((result: any) => {

              if (result === 'CONFIRM') {
                this.storageService.saveObject(StorageService.MISSION_EXTENSION, request);

                this.store.dispatch({type: CREATE_MISSION_REQUEST, payload: null});

                this.router.navigate(['client/booking/checkout']);

              }
            });

          } else {
            messagesDialog.title = 'Error';
            messagesDialog.description = `${response.data}`
            messagesDialog.error = true;
            messagesDialog.confirmation = false;
            const dialogRef = this.dialog.open(ConfirmationDialogComponent
              , {
                width: '550px',
                backdropClass: 'bg-color',
                data: messagesDialog,
                disableClose: true
              });

            dialogRef.afterClosed().subscribe((result: any) => {
            });
          }
        });


      }
    });

  }

  ngOnDestroy(){
    this.mySubscription.unsubscribe();

  }
}
