import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {UsersService} from "../users.service";
import {catchError, finalize} from "rxjs/operators";
import {TariffService} from "../tariff.service";


export class TariffsDatasource implements DataSource<any>{

  private tariffsSubject = new BehaviorSubject<any[]>([]);
  private tariffsData:any[]=[];
  private tariffsDataUnfiltered:any[]=[];

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private tariffService: TariffService) { }


  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tariffsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tariffsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  loadTariffs(pageNumber=0, pageSize =5) {
    this.loadingSubject.next(true);
    //this.tariffService.getTariffs()
    this.tariffService.getAllTariff({ page: pageNumber, size: pageSize})
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result:any) => {
          this.tariffsSubject.next(result.data.content);
          this.countSubject.next(result.data.totalElements);
          this.tariffsDataUnfiltered=result.data.content;
        }
      );
  }

  loadFiltered(filterValue:string){
    const searchValue=filterValue.trim().toLowerCase();
    this.tariffsData = this.tariffsDataUnfiltered.filter(user=>{

      return (user.phoneNumber.toLowerCase().includes(searchValue)
        || (user.companyName && user.companyName.toLowerCase().includes(searchValue) ) ||
        ( user.emailAddress && user.emailAddress.toLowerCase().includes(searchValue)) )
    });
    this.tariffsSubject.next(this.tariffsData);

  }
}
