import {Component, OnInit} from '@angular/core';
import {AccountService} from "../service/account.service";
import {Account} from "../model/account";
import {ClientUserService} from "../service/client-user.service";
import {UtilService} from "../service/util.service";
import {FavoriteAgent} from "../model/favorite-agent";
import {ConfirmationDialogComponent} from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FavoriteAgentBookingComponent} from "../dialogs/favorite-agent-booking/favorite-agent-booking.component";
import {Router} from "@angular/router";
import {StorageService} from "../../service/storage.service";

@Component({
  selector: 'app-book-agent-header',
  templateUrl: './book-agent-header.component.html',
  styleUrls: ['./book-agent-header.component.css']
})
export class BookAgentHeaderComponent implements OnInit {

  accountBalance: string;
  phoneNumber?: string;
  agents: FavoriteAgent[];
  agent: FavoriteAgent;

  constructor(private accountService: AccountService,
              private clientUserService: ClientUserService,
              private utilService: UtilService,
              private dialog: MatDialog,
              private router: Router,
              private storageService: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.storageService.deleteItem(StorageService.SELECTED_FAV_AGENT);
    this.phoneNumber = this.clientUserService.getLoggedInProfile()?.userPhoneNumber;
    this.accountService.getAccount(this.phoneNumber!).subscribe((response) => {
      if (response.result === 'OK') {
        const account = <Account>response.data;

        this.accountBalance = this.utilService.toDecimal(account.accountBalance);

      } else {

        this.accountBalance = this.utilService.toDecimal(0);

      }
    });
    this.getFavoriteAgents();
  }

  getFavoriteAgents(): void {
    this.clientUserService.getFavoriteAgents(this.phoneNumber!).subscribe(value => {
      if (value.result === 'OK' && value.data) {

        this.agents = <FavoriteAgent[]>value.data;

      }
    });
  }

  selectedAgent(val: FavoriteAgent) {
    this.agent = val;

    const dialogRef = this.dialog.open(FavoriteAgentBookingComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          favoriteAgent: val
        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.action === 'INSTANT') {
        this.storageService.saveObject(StorageService.SELECTED_FAV_AGENT,val);
        this.router.navigate([`client/booking/instant/agent`]);
      } else if (result.action === 'PLANNED') {
        this.storageService.saveObject(StorageService.SELECTED_FAV_AGENT,val);

        this.router.navigate([`client/booking/planned/agent`]);
      }
    });
  }
}
