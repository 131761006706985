
<ng-container *ngIf="dataDetail" >

  <!-- {{dataDetail | json}} -->

  <form class="myFormStyle"  >

   
      <div  >
          <div class="row">
              <div class="col-md-6">
  
                  <div class="form-group">
                      <label >{{'label.agencyName'|translate}}</label>
                      <input type="text" class="myInput" value="{{dataDetail.agencyName}}"   readonly  placeholder="">

                  </div>
                  
      
              </div>
  
              <div class="col-md-6">
                  <div class="form-group">
                      <label >{{'label.emailAddress'|translate}} </label>
                      <input type="text" class="myInput" value="{{dataDetail.emailAddress}}" readonly  placeholder="">
                  </div>
                  
      
              </div>
          </div>
  
          <div class="row">
              <div class="col-md-6">
  
                  <div class="form-group">
                      <label >{{'label.phoneNumber'|translate}}</label>
                      <input type="text" class="myInput"  value="{{dataDetail.phoneNumber}}"  readonly  placeholder="">

                  </div>
                  
      
              </div>
  
              <div class="col-md-6">
                  <div class="form-group">
                      <label >{{'label.vatNumber'|translate}} </label>
                      <input type="text" class="myInput" value="{{dataDetail.vatNumber}}" readonly  placeholder="">
                  </div>
                  
      
              </div>
          </div>
  
      </div>
     
      
      <footer>
        
          <button *ngIf="dataDetail.enabled===true" (click)="activateDeactivate(dataDetail.phoneNumber)" class="btn btn-danger">
              {{'btn.deactivate'|translate}}
          </button>

          <button *ngIf="dataDetail.enabled===false" (click)="activateDeactivate(dataDetail.phoneNumber)" class="btn btn-success">
            {{'btn.activate'|translate}}
          </button>

         
            
          <button type="button" class="btn btn-default" (click)="cancel()">
              {{'btn.dismiss'|translate}}
          </button>

        </footer>
    </form>

  
</ng-container>

  