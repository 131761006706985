import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {CustomResponse} from "../../model";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "../../service/storage.service";
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class FaqService {


  constructor(private http: HttpClient,
              private storageService: StorageService) {

  }
  createFaq(request: any): Observable<CustomResponse> {
    const url = `${environment.createFaqUrl}`;
    return this.http.post<CustomResponse>(url,request);
  }
  getAllFaq( page: number, size: number): Observable<CustomResponse> {
    const url = `${environment.getFaqUrl}?page=${page}&size=${size}`;
    return this.http.get<CustomResponse>(url);
  }
}
