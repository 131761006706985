
export class PushNotification{
  agentPhoneNumber: string;
  clientPhoneNumber: string;
  missionId: string;
  missionNotificationType: string;
  msg: string;
  notificationCategory: string;
  requiredAction: string;
  targetUserGroup: string;
  title: string;
}
