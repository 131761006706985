import {Component, Inject, OnInit} from '@angular/core';
import {CustomResponse, User} from "../../../model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsersService} from "../../../service/users.service";
import {StorageService} from "../../../service/storage.service";
import {ClientUserService} from "../../../client/service/client-user.service";
import {ToastrService} from "ngx-toastr";
import {Account} from "../../../client/model/account";
import {RateRequest} from "../../../client/model/rate-request";
import {Tariff, TariffService} from "../../../service/tariff.service";

@Component({
  selector: 'app-add-tariff',
  templateUrl: './add-tariff.component.html',
  styleUrls: ['./add-tariff.component.css']
})
export class AddTariffComponent implements OnInit {



  tariffDetails=new Tariff();
  newRate:number=0;
  currentRate:number;
  form: FormGroup;
  constructor(

    public dialogRef: MatDialogRef<AddTariffComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tariffService: TariffService,
    private storageService: StorageService,
    private clientUserService: ClientUserService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {

    Object.assign(this.tariffDetails,this.data);


    this.CreateForm();
  }

  cancel(){
    this.dialogRef.close();
  }

  saveTariff(){
console.log("this.tariffDetails", this.tariffDetails);
    this.tariffService.saveTariff(this.tariffDetails).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){
        this.toastr.success("Processed successfully","TARIFF");
        this.dialogRef.close();
      }else{
        this.toastr.error("Error in processing");
      }
    });


  }
  CreateForm(): void {
    this.form = new FormGroup({
      tariffName: new FormControl(''),
      costPerAgent: new FormControl(''),
      minimumNumberOfAgentsForTariff: new FormControl(''),
      maximumNumberOfAgentsForTariff: new FormControl(''),
      dayForTariff: new FormControl(''),
      startTime: new FormControl(''),
      endTime: new FormControl(''),
      specialDay: new FormControl(''),
      specialDayDate: new FormControl(''),
      status: new FormControl(''),
      publicHoliday: new FormControl('')
    });

       }


}
