import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CustomResponse} from "../../model";
import {environment} from "../../../environments/environment.prod";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {



  constructor(private http: HttpClient) {

  }


  saveFirebaseToken(request: any): Observable<CustomResponse> {
    const url = `/notification-api/notification/user/save`;
    return this.http.post<CustomResponse>(url,request);
  }



}
