import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {AuthServerUser, DeactivateUserRequestDto, DeactivateUserResponseDto, RegistrationResponse, Subscriber, Token, UserStatus} from "../model";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {ClientUser} from "../client/model/client-user";
import { Router } from '@angular/router';
import { PasswordResetRequest } from 'src/assets/model/password-reset-request';
import { PasswordResetResponse } from 'src/assets/model/password-reset-response';
import { PasswordResetInitiateRequest } from 'src/assets/model/password-reset-initiate-request';
import { PasswordResetInitiateResponse } from 'src/assets/model/password-reset-initiate-response';
import { appConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient,
    private router: Router,
              private storageService: StorageService) { }

  loginWithUsernameAndPassword(username: string, password: string): Observable<Token> {
    const pwd = encodeURIComponent(password);
    const url = `/api/auth-server/oauth/token?username=${username}&password=${pwd}&grant_type=password`;
    return this.http.post<Token>(url, null, {
        headers: new HttpHeaders()
          .set('Authorization', `${environment.basicToken}`)
      }
    );
  }

  userRegistration(subscriber: ClientUser): Observable<RegistrationResponse> {
    const url = `/api/auth-server/v1/user/sign-up`;
    return this.http.post<RegistrationResponse>(url, subscriber);
  }


  getUserStatus(username: UserStatus): Observable<UserStatus> {
    const authsec = 'Basic bW9hYy1hcHAtY2xpZW50Oll5a016SXoxS2N0TjVtQ1l2bW1q';
    const url = `/api/auth-server/v1/user/status`;
    return this.http.put<UserStatus>(url, username, {
      headers: new HttpHeaders()
        .set('Authorization', authsec)

    });
  }

  getLoggedInUser(): Observable<AuthServerUser> {
    const url = `/api/auth-server/v1/user-details`;
    return this.http.get<AuthServerUser>(url);
  }

  updateBasicInformation( authServerUser: AuthServerUser): Observable<AuthServerUser> {
    const url = `/api/auth-server/v1/update/basic-information`;
    return this.http.put<AuthServerUser>(url, authServerUser);
  }

  logout(){
    this.storageService.clear();

    this.router.navigate(['']);
  }

  getAllUsers(searchRequest:any): Observable<any> {
    const url = `/api/auth-server/v1/all-users`;
    return this.http.get<any>(url,  {params: searchRequest});
  }

  getAllEmployee(searchRequest:any): Observable<any> {
    const url = `/api/auth-server/v1/employee`;
    return this.http.get<any>(url,  {params: searchRequest});
  }

  getUserDetails(username:any): Observable<any> {
    const url = `/api/auth-server/v1/user`;
    return this.http.get<any>(url,  {params: {username:username}});
  }



  saveUser(data:any): Observable<any> {
    const url = environment.saveUser;
    return this.http.post<any>(url,data,{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  activateUser(data:DeactivateUserRequestDto): Observable<DeactivateUserResponseDto> {
    const url = environment.activateUser;
    return this.http.post<any>(url,data);
  }

  deactivateUser(data:DeactivateUserRequestDto): Observable<DeactivateUserResponseDto> {
    const url = environment.deactivateUser;
    return this.http.post<any>(url,data);
  }


  initiateResetPassord(data: PasswordResetInitiateRequest): Observable<PasswordResetInitiateResponse> {

    const authsec = 'Basic bW9hYy1hcHAtY2xpZW50Oll5a016SXoxS2N0TjVtQ1l2bW1q';
    data.serviceName = appConfig.serviceName;

    const url = `/api/auth-server/v1/user/password/initiate`;
    return this.http.post<PasswordResetInitiateResponse>(url, data, {
        headers: new HttpHeaders()
          .set('Authorization', authsec)
      }
    );
  }


  resetPassord(data: PasswordResetRequest): Observable<PasswordResetResponse> {

    const authsec = 'Basic bW9hYy1hcHAtY2xpZW50Oll5a016SXoxS2N0TjVtQ1l2bW1q';
    const url = `/api/auth-server/v1/user/password/reset`;
    return this.http.post<PasswordResetResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Authorization', authsec)

    });
  }

}
