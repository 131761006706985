<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

    <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
      <!-- loop over the cardList and show the cards -->


    </div>
    <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
      <div  fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;">
        <mat-form-field appearance="standard" class="header-search">
          <mat-label>{{'label.search'|translate}}</mat-label>
          <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource"  >


          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="businessName">
            <th mat-header-cell *matHeaderCellDef> {{'label.businessName'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div style="text-align: justify;">
                <p style="font-weight: bold">{{element.businessName}}</p>
                 </div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef> {{'label.firstName'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div >
                <p style="font-weight: bold">{{element.firstName}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> {{'label.lastName'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div >
                <p style="font-weight: bold">{{element.lastName}}</p>
              </div>
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="businessEmailAddress">
            <th mat-header-cell *matHeaderCellDef> {{'label.businessEmailAddress'|translate}} </th>
            <td mat-cell *matCellDef="let element">  {{element.businessEmailAddress}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> {{'label.address'|translate}} </th>
            <td mat-cell *matCellDef="let element">  {{element.streetAddress}} - {{element.city}}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'label.status'|translate}} </th>
            <td mat-cell *matCellDef="let element">
               <a *ngIf="element.userProfileStatus==='ACTIVE'"   class="statusLabel labelSuccess"  >
                {{element.userProfileStatus}}
              </a>
              <a *ngIf="element.userProfileStatus!=='ACTIVE'"   class="statusLabel labelDanger"  >
                {{element.userProfileStatus}}
              </a>

            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openViewDetails(element)">{{'btn.view'|translate}}</button> </td>
          </ng-container>
          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openRateDetails(element)">{{'btn.changeRate'|translate}}</button> </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>


        </table>
        <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes"></mat-paginator>

      </div>
    </div>
  </div>
