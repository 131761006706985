import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { environment } from './../../environments/environment';
import {CustomResponse, Menu} from "../model";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) {

  }

  getAgentsPendingApproval(): Observable<any> {
    const url = environment.getAgentsPendingApprovalUrl;
    return this.http.get<any>(url);
  }

  approveAgent(approvalRequest:any){
  const url=environment.approveAgentUrl;
  return this.http.post<CustomResponse>(url,approvalRequest);
  }

  getActiveAgents(searchRequest:any){
    const  url = environment.getActiveAgents;
    return this.http.get(url,{params:searchRequest})
  }

  getUserProfile(searchRequest:any): Observable<any> {
    const url = environment.getUserProfileByPhone;
    return this.http.get(url, {params: searchRequest})
  }

  /**
   * PARTNERS
   */
   getPartners(searchRequest:any): Observable<any> {
    const url = environment.getPartners;
    return this.http.get<any>(url, {params: searchRequest});
  }

  getBusinessPartner(phonenumber:any): Observable<any> {
    const url = environment.getBusinessPartner;
    return this.http.get<any>(url,{params:{phonenumber:phonenumber}});
  }

  activatePartner(phonenumber:any): Observable<any> {
    const url = environment.activatePartner;
    return this.http.get<any>(url,{params:{phonenumber:phonenumber}});
  }

  savePartner(data:any): Observable<CustomResponse> {
    const url = environment.savePartner;
    return this.http.post<CustomResponse>(url,data);
  }

  /**
   * AGENCIES
   */
   getAgencies(searchRequest:any): Observable<any> {
    const url = environment.getAgencies;
    return this.http.get<any>(url, {params: searchRequest});
  }

  getAgency(phonenumber:any): Observable<any> {
    const url = environment.getAgency;
    return this.http.get<any>(url,{params:{phonenumber:phonenumber}});
  }

  activateAgency(phonenumber:any): Observable<any> {
    const url = environment.activateAgency;
    return this.http.get<any>(url,{params:{phonenumber:phonenumber}});
  }

  saveAgency(data:any): Observable<CustomResponse> {
    const url = environment.saveAgency;
    return this.http.post<CustomResponse>(url,data);
  }

  /**
   * CLIENTS
   */
   getAllClient(searchRequest:any): Observable<any> {
    const url = environment.getAllClient;
    return this.http.get<any>(url, {params: searchRequest});
  }
  getAllActiveCountries(searchRequest:any): Observable<any> {
    const url = environment.getActiveCountries;
    return this.http.get<any>(url, {params: searchRequest});
  }
  getActiveCountries(): Observable<any> {
    const url = environment.getActiveCountries;
    return this.http.get<Menu>(url,{params:{page:1,size:250}});
  }
  getClient(phonenumber:any): Observable<CustomResponse> {
    const url = environment.getClient;
    return this.http.get<CustomResponse>(url,{params:{phonenumber:phonenumber}});
  }

  activateClient(phonenumber:any): Observable<CustomResponse> {
    const url = environment.activateClient;
    return this.http.get<CustomResponse>(url,{params:{phonenumber:phonenumber}});
  }

  changeRate(data:any): Observable<CustomResponse> {
    const url = environment.changeRate;
    return this.http.post<CustomResponse>(url,data);
  }
  /**
   * MENU
   */
   getMenu(groupid:any,language:string): Observable<Menu> {
    const url = environment.getMenu;
    return this.http.get<Menu>(url,{params:{groupid:groupid,language:language}});
  }

  getAllMenuByGroupid(groupid:any,language:string): Observable<Menu> {
    const url = environment.getAllMenuByGroupid;
    return this.http.get<Menu>(url,{params:{groupid:groupid,language:language}});
  }

  getUserGroup(): Observable<any> {
    const url = environment.getUserGroup;
    return this.http.get<any>(url);
  }

  getAllUserGroup(): Observable<any> {
    const url = environment.getAllUserGroup;
    return this.http.get<any>(url);
  }

  setPermission(groupid:any,menuid:any): Observable<Menu> {
    const url = environment.setMenuPermission;
    return this.http.get<Menu>(url,{params:{groupid:groupid,menuid:menuid}});
  }


}
