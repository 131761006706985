import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {catchError, finalize, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import {UsersService} from "../../service/users.service";
import {
  AgentRegistration,
  AgentStatistics,
  appConstants,
  CustomResponse,
  ResponseUserInformationList
} from "../../model";

import {ToastrService} from "ngx-toastr";
import { PartnersDatasource } from 'src/app/service/datasource/partners-datasource';
import { ViewPartnerDetailsComponent } from './view-partner-details/view-partner-details.component';
import { AddPartnerComponent } from './add-partner/add-partner.component';

@Component({
  selector: 'app-managepartners',
  templateUrl: './managepartners.component.html',
  styleUrls: ['./managepartners.component.css']
})
export class ManagepartnersComponent implements OnInit {

  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :PartnersDatasource;

  


  columnsToDisplay = ['companyName','phoneNumber','emailAddress','vatNumber','status','buttons'];
  private paginator: MatPaginator ;


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];

  constructor(private httpClient: HttpClient,private dialog: MatDialog, 
              private userService: UsersService,
              private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.dataSource = new PartnersDatasource(this.userService);
    this.dataSource?.loadPartners();

    //this. loadPartners();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  ngAfterViewInit() {
    this.dataSource?.counter$
      .pipe(
        tap((count) => {
          // @ts-ignore
          this.paginator.length = count;
        })
      )
      .subscribe();

    // @ts-ignore
    this.paginator.page
      .pipe(
        tap(() => this.loadPartners())
      )
      .subscribe();
  }

  loadPartners() {

    // @ts-ignore
    this.dataSource?.loadPartners(this.paginator.pageIndex, this.paginator.pageSize);
   
    // this.userService.loadPartners()
    //   .pipe(
    //     catchError(() => of([]))
    //   )
    //   .subscribe((result:any) => {
         

    //     this.dataSource=result.data;
    //     }
    //   );
  }

  openPartnerViewDetails(element:any){
    this.openViewInfo(element);
  }

  openViewInfo(data:any): void {
    
    const dialogRef = this.dialog.open(ViewPartnerDetailsComponent
      , {
        width: '50%',
        data:data

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadPartners()
      });
  }

  addPartner(){
    const dialogRef = this.dialog.open(AddPartnerComponent
      , {
        width: '50%',
        

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadPartners()
      });
  }

}
