<app-client-header></app-client-header>
<!--<mat-toolbar color="primary">{{title}}</mat-toolbar>-->
<div>
  <div class="row">
<mat-accordion [displayMode]="displayMode" [multi]="multi"
               class="faq-container">
  <mat-expansion-panel *ngFor="let faqItem of faqList">
    <mat-expansion-panel-header>{{faqItem.question}}</mat-expansion-panel-header>
    <p>{{faqItem.answer}}</p>
  </mat-expansion-panel>
</mat-accordion>
  </div>
</div>
<mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizes"
               (page)="change($event)">
</mat-paginator>
<!--<mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes" (page)="change($event)"></mat-paginator>-->
