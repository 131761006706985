<app-client-header></app-client-header>
<div class="h-100">
  <mat-card class="h-100">
    <div>
      <h1 style="color: black;text-align: center">{{'nbs.title'|translate}}</h1>
      <p class="c-align" style="color: black; margin: 10px">{{'nbs.login.label.terms'|translate}}</p>


      <div class="p-margin c-align">

        <p style="color: black;text-align: center">{{'client.phone_number'|translate}}</p>

        <div class="p-margin">

          <form #form="ngForm">
            <intl-tel-input
              [name]="'phoneNumber'"
              [cssClass]="'image_box'"
              [labelCssClass]="'col-sm-2 col-form-label'"
              [required]="true"
              [options]="{
           preferredCountries: ['ch'],
           localizedCountries: { ch: 'be' }
       }"
              [onlyLocalized]="true"
              [(E164PhoneNumber)]="E164PhoneNumber"
            ></intl-tel-input>


          </form>
        </div>
        <p style="color: black;text-align: center">{{'nbs.login.label.password'|translate}}</p>

        <form novalidate [formGroup]="ngForm">
          <input matInput type="password" style="padding: 10px;width: 380px" class="p-margin image_box"
                 name="password"
                 formControlName="password"
                 placeholder="Password">

          <div
            *ngIf="ngForm?.get('password')?.invalid && (ngForm?.get('password')?.dirty || ngForm?.get('password')?.touched)">

            <div *ngIf="ngForm?.get('password')?.errors?.required">
              <p style="color: red">{{'nbs.login.label.passwordrequired'|translate}}</p>
            </div>

          </div>
        </form>


      </div>

      <div class="p-margin c-align " style="text-align: center">
        <div>
          <button mat-button color="primary" class="button-center" style="text-align: center;width: 380px;text-align:
      center;" (click)="login()">
            {{'nbs.login.label.login'|translate}}
          </button>
        </div>

        <p class="c-align" style="color: black; margin: 20px;font-size: 14px">
          <a class="anchor_white" style="color: black;cursor: pointer; text-decoration: none;" (click)="forgetPassword()">{{'nbs.login.label.forgotpassword'|translate}}</a>
        </p>
        <p class="c-align" style="color: black; margin: 20px;font-size: 14px" >

          <a class="anchor_white" style="color: black;cursor: pointer; text-decoration: none;" (click)="createAccount()">{{'client.create_account' | translate}}</a>
        </p>


      </div>
    </div>
  </mat-card>
</div>
