import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PushNotification} from "../model/push-notification";
import {MessagingService} from "../../service/messaging.service";
import {DatePipe} from "@angular/common";
import {UtilService} from "../service/util.service";
import {Mission} from "../../model";
import {ClientSingletonService} from "../service/client-singleton.service";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";

@Component({
  selector: 'app-book-agent',
  templateUrl: './book-agent-dashboard.component.html',
  styleUrls: ['./book-agent-dashboard.component.css']
})
export class BookAgentDashboardComponent implements OnInit {

  pushNotification: PushNotification;
  missions: Mission[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private messagingService: MessagingService,
              private clientSingletonService: ClientSingletonService,
              private utilService: UtilService,
              private clientUserService: ClientUserService,
              private missionService: MissionService,
  ) {
    this.route.params.subscribe(params => {
      const push = params['notificationData'];
      if (push) {
        console.log('Push Notification by a link ', push)
        this.pushNotification = <PushNotification>JSON.parse(push);
        messagingService.currentMessage.next(this.pushNotification);
      }

    });
  }

  ngOnInit(): void {

    this.clientSingletonService.checkUpcomingMissions.subscribe((isCheck)=>{

      if(isCheck){
        this.getUpcomingMissions();
      }

    })
    this.getUpcomingMissions();
  }

  plannedBooking(): void {
    this.router.navigate(['client/booking/planned'])
  }

  instantBooking(): void {
    this.router.navigate(['client/booking/instant'])
  }

  getUpcomingMissions(): void{

    this.missionService.clientGetUpcomingMissions(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!)
      .subscribe((response)=>{

        console.log(response);
        if(response.result === 'OK'){
          const msns = <Mission[]>response.data;

          if(msns && msns.length > 0){
            this.missions    = msns .sort((n1,n2) => {


              if (n1.id - n2.id) {
                return -1;
              }

              return 0;
            });
          }
        }
      })

  }
}
