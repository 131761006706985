import {Component, OnInit} from '@angular/core';
import {Mission} from "../../model";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {ClientSingletonService} from "../service/client-singleton.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-ongoing-missions',
  templateUrl: './ongoing-missions.component.html',
  styleUrls: ['./ongoing-missions.component.css']
})
export class OngoingMissionsComponent implements OnInit {
  missions: Mission[] = [];

  constructor(private missionService: MissionService,
              private clientUserService: ClientUserService,
              private clientSingletonService: ClientSingletonService,
              private dialog: MatDialog,
              private router: Router,
  ) {
  }

  ngOnInit(): void {

    this.clientSingletonService.checkUpcomingMissions.subscribe((isCheck)=>{

      if(isCheck){
        this.getOngoingMissions();
      }

    })
    this.getOngoingMissions();
  }

  getOngoingMissions(): void{

    this.missionService.clientGetOngoingMissions(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!)
      .subscribe((response)=>{

        console.log(response);
        if(response.result === 'OK'){
          const msns = <Mission[]>response.data;

          if(msns && msns.length > 0){
            this.missions    = msns .sort((n1,n2) => {


              if (n1.id - n2.id) {
                return -1;
              }

              return 0;
            });
          }
        }
      })

  }

  toMissionView(mission: Mission): void{


    this.router.navigate([`client/ongoing-mission/${mission.id}`])
  }


}
