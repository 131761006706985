export class  MissionExtensionRequest{

  missionId: string;
  clientPhoneNumber: string;
  agentPhoneNumber: string;
  extensionDuration: number;
  priceForExtension: number;
  missionPrice: number;
  accept: boolean;
  currentMissionEndDate: string;
  clientInitiate: boolean;


}
