import { Component, OnInit } from '@angular/core';
import {LoginComponent} from "../login/login.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-landing-a',
  templateUrl: './landing-a.component.html',
  styleUrls: ['./landing-a.component.css']
})
export class LandingAComponent implements OnInit {

  constructor(private dialog: MatDialog) { }


  ngOnInit() {
  }

  openSignInDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent
      , {
        width: '50%'
        // backdropClass: 'bg-color' // This is the "wanted" line
      });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

