import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse} from "../../model";
import {StorageService} from "../../service/storage.service";
import {UserProfile} from "../model/user-profile";
import {MissionRequest} from "../model/mission-request";


@Injectable({
  providedIn: 'root'
})
export class TariffsService {

  constructor(private http: HttpClient,
              private storageService: StorageService) {

  }


  calculateTariff(request: any): Observable<CustomResponse> {

    const url = `${environment.TARIFF_CALCULATE_URL}`;
    return this.http.post<CustomResponse>(url,request);
  }

}
