export class Model {

}
export const appConstants={
  viewAgentAgentApproval:'agentApproval',
  viewAgentPlainView:'viewAgent'
}

export interface FileProperty {

  fileName: string;
  extension: string;
  base64: string;
  success: boolean;

}

export interface Token {

  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
}

export interface RegistrationResponse{

  success: boolean;
  username: string;

}
export interface Subscriber{
  id: number;
  accountId: number;
  createdDate: Date;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  category: string;
  status: number;
  // auth server
  username: string;
  password: string;
  confirmPassword: string;
  groupId: number;
}
export interface UserStatus {

  username: string;
  registered: boolean;
  lockedAccount: boolean;
  enabled: boolean;

}

export interface AuthServerUser {

  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  accountExpired: boolean;
  groupId: number;
  accountExpirationDate: Date;
  accountLockedDate: Date;
  lastPasswordChangeDate: Date;
  language:string;
  mobileNumber:string

}



export interface CustomResponse{
  result:string;
  data:any;
}

export interface ResponseUserInformationList {
  result:string;
  data:AgentRegistration[];
}

export interface AgentRegistration{
  id: number;
  createdDate: string;
  lastUpdateDate: string;
  firstName: string;
  lastName: string;
  userPhoneNumber: string;
  email: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  qualification: string;
  gender: string;
  dressCode: string;
  nationalRegister: string;
  bankAccountNumber: string;
  job: string;
  distanceRestriction: number;
  agentPhotoFileName: string;
  agentPhotoPath: string;
  agentPhotoStatus: number;
  agentProfessionalCardFrontFileName: string;
  agentProfessionalCardFrontPath: string;
  agentProfessionalCardFrontStatus: number;
  agentProfessionalCardBackFileName: string;
  agentProfessionalCardBackPath: string;
  agentProfessionalCardBackStatus: number;
  agentStatus: number;
  userType: string;
  version: number;
  businessType: string;
  streetAddress: string;
  city: string;
  addressZipCode: string;
  businessEmailAddress: string;
  businessPhoneNumber: string;
  businessName: string;
  vatNumber: string;
  businessStreetAddress: string;
  businessCity: string;
  businessZipCode: string;
  language: string;
  userProfileStatus: string;
  totalRating: number;
  nbOfRating:number;
}

export interface AgentStatistics{
agentInformation: AgentRegistration;
totalMissions:number;
pendingMissions:number;
cancelledMissions:number;
completedMissions:number;
}
export interface MissionInvoice{id:number,
  invoiceId:string,
  fileName:string,
  invoiceType:string
}
export interface Mission{
  id:number;
  mainMissionId:string;
  missionName:string;
  missionDescription:string;
  missionDressCode:string;
  clientPhoneNumber:string;
  missionStatus:string;
  missionStreetAddress:string;
  missionCityAddress:string;
  missionZipCodeAddress:string;
  missionDateCreated:string;
  numberOfAgents:number;//
  numberOfFemaleAgents:number;//
  numberOfMaleAgents:number;//
  maleAgentsAccepted:number;//
  femaleAgentsAccepted:number;//
  missionStartDate:string;
  missionEndDate:string;
  missionDurationHours:string;
  language:string;
  extensionStatus:string;
  extensionDuration:number;
  extensionRequestedDate:string;
  extensionUpdatedDate:string;
  missionType:string;
  lngLocation: number;
  latLocation: number;

}

export  interface AgentHistory{
  phoneNumber: string;
  agentName: string;
  totalMissions: number;
  pendingMission: number;
  completedMissions: number;
  missionInProgress: number;
  cancelledMissions: number;
  clientCancelledMissions: number;
  missionExtensions: number;
}

export interface RevenueSummary{
  id:number;
  totalMissions: number;
  potentialRevenue: number;
  revenueMade: number;
  revenueLost: number;
}

export class MissionSetting{

  id:string;
 keyUnique:string;
  value:string;
  active:boolean;
 category:string;
 description:string;

}
export class Partner{
  companyName: string;
  emailAddress:string;
  enabled:boolean;
  phoneNumber:string;
  vatNumber:string;
  }

  export class Agency{
    agencyName: string;
    emailAddress:string;
    enabled:boolean;
    phoneNumber:string;
    vatNumber:string;
    }

    export class User{
      addressZipCode: string
      agentPhotoFileName: string
      agentPhotoPath: string
      agentPhotoStatus: 0
      agentProfessionalCardBackFileName: string
      agentProfessionalCardBackPath: string
      agentProfessionalCardBackStatus: 0
      agentProfessionalCardFrontFileName: string
      agentProfessionalCardFrontPath: string
      agentProfessionalCardFrontStatus: 0
      agentStatus: 1
      bankAccountNumber: string
      businessCity: string
      businessEmailAddress: string
      businessName: string
      businessPhoneNumber: string
      paymentRate:number
      businessStreetAddress: string
      businessType: string
      businessZipCode: string
      city: string
      createdDate: string
      dateOfBirth: string
      distanceRestriction: 0
      dressCode: string
      email: string
      firstName: string
      gender: string
      height: 0
      id: 4
      job: string
      language: string
      lastName: string
      lastUpdateDate: string
      nationalRegister: string
      nbOfRating: 1
      qualification: string
      streetAddress: string
      totalRating: 0
      userPhoneNumber: string
      userProfileStatus: string
      userType: string
      vatNumber: string
      version: 0
      }

export class DeactivateUserRequestDto{
  username:string
}

export class DeactivateUserResponseDto {

  success:boolean;
  message:string;
  userId:string;

}

export class AuthUser {

  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  accountExpired: boolean;
  groupId: number;
  accountExpirationDate: Date;
  accountLockedDate: Date;
  lastPasswordChangeDate: Date;
  language:string;
  mobileNumber:string

}

export class Menu {

  id: string;
  menuId: number;
  url: string;
  icon: string;
  name: string;
  orderNumber:number;
  enabled: boolean;

}

export const ProfileStatus = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED"

};
