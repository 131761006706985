import { Component, OnInit } from '@angular/core';
import {Mission} from "../../model";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {ClientSingletonService} from "../service/client-singleton.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-past-missions',
  templateUrl: './past-missions.component.html',
  styleUrls: ['./past-missions.component.css']
})
export class PastMissionsComponent implements OnInit {


  missions: Mission[] = [];

  constructor(private missionService: MissionService,
              private clientUserService: ClientUserService,
              private clientSingletonService: ClientSingletonService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {

    this.getPastMissions();
  }

  getPastMissions(): void{

    this.missionService.getClientPastMissions(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!)
      .subscribe((response)=>{

        console.log(response);
        if(response.result === 'OK'){
          const msns = <Mission[]>response.data;

          if(msns && msns.length > 0){

            this.missions    = msns .sort((n1,n2) => {


              if (n1.id - n2.id) {
                return -1;
              }

              return 0;
            });
          }
        }
      })

  }




}
