<div class="c-align">

  <mat-card style="text-align: center; height: 400px" class="d-flex flex-row justify-content-evenly align-items-center">

    <div *ngIf="paymentStatus">
      <ngx-spinner></ngx-spinner>
      <div class="center" *ngIf="missionRequest">
        <div class="p-name">{{'client.total'|translate}} {{missionRequest.missionPrice  | currency:'EUR'}}</div>
        <div class="p-price">{{'nbs.businessownermanager.manageagents.missionInfo.totalNumberOfAgents'|translate}}: {{missionRequest.numberOfAgents}} </div>
        <div class="p-description">{{'client.hours'|translate}}: {{missionRequest.missionDuration}}</div>
      </div>
      <div class="center" *ngIf="tariffRequestMessage">
        <div class="p-name">Total {{tariffRequestMessage.priceForExtension  | currency:'EUR'}}</div>
      </div>
      <div class="text-center mt-lg-5">
        <h1>{{'client.finalizing_payment'|translate}}.....</h1>

      </div>
    </div>

    <div *ngIf="!paymentStatus">

      <h1>{{'client.please_wait_for_moment'|translate}}</h1>

    </div>

  </mat-card>

</div>
