import {EventEmitter, Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ClientSingletonService {


  checkUpcomingMissions: EventEmitter<boolean> = new EventEmitter<boolean>();
  missionStarted: EventEmitter<boolean> = new EventEmitter<boolean>();
  loadingProgressBar = new EventEmitter<boolean>();

  constructor() {

  }




}
