
<ng-container>


    <form [formGroup]="rForm" class="myFormStyle"  (ngSubmit)="confirmSave()">

     
        <div  >
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label for="companyName">{{'label.companyName'|translate}}</label>
                        <input type="text" class="myInput" formControlName="companyName" id="companyName"   placeholder="">

                        <span [class.hidden]="rForm['controls'].companyName.valid || !submitted" class="help-block">
                            {{'label.fieldRequired'|translate}}
                            </span>
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="emailAddress">{{'label.email'|translate}}</label>
                        <input type="email" class="myInput" id="emailAddress" formControlName="emailAddress"  placeholder="">
                    </div>
                    
        
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label for="vatNumber">{{'label.phoneNumber'|translate}}</label> <br>
                        <!-- <input type="email" class="myInput" id="vatNumber" formControlName="vatNumber"  placeholder=""> -->
                            <ngx-intl-tel-input
                                [cssClass]="'myInput myInputPhoneNumberStyle'"
                                [preferredCountries]="[CountryISO.Belgium, CountryISO.UnitedKingdom]"
                                [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="false"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.Belgium"
                                [phoneValidation]="true"
                                formControlName="phoneNumber"
                                width="100%"
                            ></ngx-intl-tel-input>

                            <span [class.hidden]="rForm['controls'].phoneNumber.valid || !submitted" class="help-block">
                                {{'label.fieldRequired'|translate}}
                                </span>
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="vatNumber">{{'label.vatNumber'|translate}}</label>
                        <input type="email" class="myInput" id="vatNumber" formControlName="vatNumber"  placeholder="">
                    </div>
                    
        
                </div>
            </div>
    
        </div>
       
        
        <footer>
            <button  type="submit" class="btn btn-success">
                {{'btn.submit'|translate}}
            </button>
            <button type="button" class="btn btn-default" (click)="cancel()">
                {{'btn.cancel'|translate}}
            </button>

          </footer>
      </form>

    
</ng-container>