import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Mission} from "../../../model";
import {PushNotification} from "../../model/push-notification";

@Component({
  selector: 'app-approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrls: ['./approve-dialog.component.css']
})
export class ApproveDialogComponent implements OnInit {

  mission: Mission;
  pushNotification: PushNotification;
  constructor(public dialogRef: MatDialogRef<ApproveDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.mission = this.data.mission;
      this.pushNotification = this.data.pushNotification;
    }
  }


  cancelDialog() {
    this.dialogRef.close({
      action: 'CANCEL',
      mission:this.mission,
      pushNotification: this.pushNotification
    });

  }

  doneDialog() {
    this.dialogRef.close({
      action: 'APPROVE',
      mission:this.mission,
      pushNotification: this.pushNotification
    });

  }

}
