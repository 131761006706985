import {Action} from '@ngrx/store';
import {MissionProgress} from "../../client/model/mission-progress";

export const CREATE_MISSION_PROGRESS_REQUEST = 'CREATE_MISSION_PROGRESS_REQUEST';
export const GET_MISSION_PROGRESS_REQUEST = 'CREATE_MISSION_PROGRESS_REQUEST';
export const DELETE_MISSION_PROGRESS_REQUEST = 'DELETE_MISSION_PROGRESS_REQUEST';


export class CreateMissionProgressRequest implements Action {
  type = CREATE_MISSION_PROGRESS_REQUEST;

  constructor(public payload: MissionProgress[]) {
  }
}


export class GetMissionProgressRequest implements Action {
  type = GET_MISSION_PROGRESS_REQUEST;

  constructor(public payload: MissionProgress[]) {
  }
}


export class DeleteMissionProgressRequest implements Action {
  type = DELETE_MISSION_PROGRESS_REQUEST;

  constructor(public payload: MissionProgress[]) {
  }
}

export type MissionProgressActions
  = CreateMissionProgressRequest |
  GetMissionProgressRequest |
  DeleteMissionProgressRequest;
