<div class="h-100 mt-lg-3">
  <form [formGroup]="form">
    <mat-card style=" height: auto" *ngIf="mission">

      <div class="c-align mt-lg-5" style="text-align: center">
        <div class="col">
          <p class="p-3 align-content-md-end" style="height: auto;margin: 0px;">{{'client.rate_your_experience_with_the_agent'|translate}}</p>
          <div class="row mt-lg-5">
            <div class="col"></div>
            <div class="col">
              <ngx-star-rating formControlName="rating" [id]="'rating'"></ngx-star-rating>

            </div>

            <div class="col"></div>

          </div>


          <mat-form-field appearance="outline" class="w-75 h-25 mt-lg-5">
            <mat-label>{{'client.reason'|translate}}{{'client.optional'|translate}}</mat-label>
            <input matInput placeholder="Reason(optional)" formControlName="reason">
            <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color"
                      color="green"></mat-icon>
          </mat-form-field>
        </div>

        <div class="col" style="color: white">

          <div style="text-align: center;margin-top: 30px">
            <button mat-button color="primary" class=" button-center"
                    style="text-align: center;width: auto;text-align:
                      left;font-size: 14px;border-radius: 10px" (click)="leaveRating()"
                    [ngClass]="form.invalid?'button-grey-center':'button-center'"
                    [disabled]="form.invalid">
              {{'client.leave_rating'|translate}}
            </button>
          </div>

          <div style="text-align: center;margin-top: 30px">
            <button mat-button color="primary" class=" button-center"
                    style="text-align: center;width: auto;text-align:
                      left;font-size: 14px;border-radius: 10px" (click)="addAgentToFavorites()"
                    [ngClass]="form.invalid?'button-grey-center':'button-center'"
                    [disabled]="form.invalid" *ngIf="!isFavorite">
              {{'client.add_to_favorite'|translate}}
            </button>
          </div>

          <button mat-button class="button-center" style="text-align: center;width: auto;text-align:
      center;font-size: 16px;background-color: white;color: black;margin-top: 20px" (click)="toDashboard()">
            {{'client.continue_btn'|translate}}
          </button>
        </div>
      </div>

    </mat-card>
  </form>
</div>
