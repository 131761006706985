
import {Component, EventEmitter, Input, Output,OnInit} from '@angular/core';
import {FaqItem} from "../../client/model/faq-item";
import {Account} from "../../client/model/account";
import {FaqService} from "../../client/service/faq.service";

@Component({
  selector: 'app-faq-admin',
  templateUrl: './faq-admin.component.html',
  styleUrls: ['./faq-admin.component.css']
})
export class FaqAdminComponent implements OnInit {


  @Input()
  title = 'Admin';

  @Output()
  onFAQItemAdded: EventEmitter<FaqItem> = new EventEmitter<FaqItem>();

  question: string;
  answer: string;
  constructor(private faq:FaqService) { }

  ngOnInit(): void {
  }
  reset() {
    this.question = this.answer = '';
  }

  add(): void {
    const faqItem: FaqItem = {

      question: this.question,
      answer: this.answer
    }
    this.faq.createFaq(faqItem).subscribe(result => {
      if (result.result === "OK") {
        const results = result.data;


      }
    })
    // this.onFAQItemAdded.emit(faqItem);
    this.reset();
  }

}
