import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomResponse, Partner } from 'src/app/model';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-view-partner-details',
  templateUrl: './view-partner-details.component.html',
  styleUrls: ['./view-partner-details.component.css']
})
export class ViewPartnerDetailsComponent implements OnInit {

  partnerDetails=new Partner();

  constructor(
    public dialogRef: MatDialogRef<ViewPartnerDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private toastr: ToastrService
  ) {
    
   }

  ngOnInit(): void {

    Object.assign(this.partnerDetails,this.data);
  }

  cancel(){
    this.dialogRef.close();
  }

  activateDeactivate(phonenumber:string){
    this.userService.activatePartner(phonenumber).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){
       
        this.toastr.success("Update successfully","ACTIVATE");
        this.dialogRef.close();
      }else{
      this.toastr.error("Error in processing");
      }
    });

   
  }


}
