import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {match} from 'minimatch';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Otp} from '../../model/otp';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '@ngrx/store';
import {ClientUser} from "../../model/client-user";
import {StorageService} from "../../../service/storage.service";
import {AuthenticationService} from "../../../service/authentication.service";
import {NotificationClientService} from "../../../service/notification-client.service";
import {UserStatus} from "../../model/user-status";
import {patternValidator} from "../../validators/customValidators";
import {OtpComponent} from "../otp/otp.component";
import {CustomResponse} from "../../../model";
import {UserProfile} from "../../model/user-profile";
import {ClientUserService} from "../../service/client-user.service";
import {ConfirmationDialogComponent} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {AddressDialogComponent} from "../../dialogs/address-dialog/address-dialog.component";
import {Address} from "../../model/address";
import {ClientsDatasource} from "../../../service/datasource/clients-datasource";
import {UsersService} from "../../../service/users.service";
import {CountryDatasource} from "../../../service/datasource/country-datasource";
import {TriggerService} from "../../../service/trigger.service";
import {Account} from "../../model/account";
import {Countries} from "../../model/Countries";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {

  form: FormGroup;
  emailFormControl: FormControl;
  user: ClientUser = new ClientUser();
  floatLabelControl = new FormControl('always');
  businessPhoneNumber: any;
  loginPhoneNumber: any;
  languages: string[] = ["English", "French", "Dutch", "Spanish"];
  AllowedCountries: string[] =['be', 'fr',  'nl', 'de', 'es', 'lu', 'gb' ,'za']

  invoiceStreet: string;
  invoiceCity: string;
  invoiceZipCode: string;


  mainMissionStreet: string;
  mainMissionCity: string;
  mainMissionZipCode: string;
  mainMissionCountryCode: string;
  missionAddress: string[]=[];
  addressData:Address[]=[];

  // @ts-ignore
  dataSource :CountryDatasource;
  countries:Countries[]=[];
  _type = "";
  constructor(
    private storageService: StorageService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private store: Store,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationClientService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private clientUserService: ClientUserService,
    private userService: UsersService,
    private triggerService: TriggerService) {
  }


  ngOnInit(): void {
    // this.AllowedCountries=[];
    this.getActiveCountries();

    this.isBusiness(this.floatLabelControl.value)


    this.storageService.deleteItem(StorageService.OTP_VALIDATION_DATA);
    // this.dataSource = new CountryDatasource(this.userService);
    // this.countries=this.dataSource?.loadActiveCountry();
    // console.log('my countries',this.countries);

    this.triggerService.castTrigger.subscribe(value => {
      this._type = value;
    });
  }
  getActiveCountries(){
    this.userService.getActiveCountries().subscribe((response) => {
      if (response.result === 'OK') {
        this.countries=<Countries[]>response.data.data;
this.AllowedCountries=this.countries.map(a=>a.countryCode);

      }
    });
  }

  registration(): void {


    if (this.form.valid && match) {

      if (this.loginPhoneNumber) {


        if (!this.businessPhoneNumber && this.floatLabelControl.value === 'auto') {

          this.openSnackBar('Invalid business phone number', 'Close');
          return;
        }
        // @ts-ignore
        this.user.confirmPassword = this.form.get('confirmPassword').value;
        // @ts-ignore
        this.user.password = this.form.get('password').value;

        if (this.user.confirmPassword !== this.user.password) {

          this.openSnackBar('Password Mismatch', 'Close');

          return;
        }

        const status = new UserStatus();

        // valid number
        const loginPhoneNumber = this.loginPhoneNumber.substr(1);
        this.storageService.saveString(StorageService.USER_PHONE_NUMBER, loginPhoneNumber);
        status.username = loginPhoneNumber;

        this.authenticationService.getUserStatus(status).subscribe(async value => {

          if (value.registered === true) {

            const res = await this.getUserProfile();

            if (!res || res.result !== 'OK') {
              this.requestOtp(loginPhoneNumber, true);
            } else {
              this.openSnackBar('You already have an account', 'Close');
            }
          } else if (value.lockedAccount === true) {
            this.openSnackBar('Your account was locked', 'Close');
          } else {
            this.requestOtp(loginPhoneNumber);
          }

        }, error => {

          this.openSnackBar('Failed to get user details, please try again later', 'Close');
        });


      } else {
        this.openSnackBar('Invalid Login Phone Number', 'Close');

      }
    } else {
      this.openSnackBar('Fill all details correctly', 'Close');
    }

    return;

  }

  requestOtp(loginPhoneNumber: string, userExist?: boolean): void {


    const otp = new Otp();
    otp.serviceName = 'test';
    otp.phoneNumber = loginPhoneNumber;
    this.spinner.show();
    this.notificationService.requestOtp(otp).subscribe(value => {
      this.spinner.hide();
      if (value.result === 'OK') {


        if (this.floatLabelControl.value === 'auto') {
          const business = this.businessPhoneNumber.substr(1);

          const user = this.createBusinessUser(business, loginPhoneNumber);
          this.storageService.saveObject(StorageService.USER_REGISTRATION, user);
        } else {
          const user = this.createPersonalUser(loginPhoneNumber);
          this.storageService.saveObject(StorageService.USER_REGISTRATION, user);
        }

        this.storageService.saveString(StorageService.OTP_VALIDATION_DATA, value.data);


        this.openOtpDialog(userExist);

      } else {
        this.openSnackBar('Failed to validate your mobile number', 'Close');
      }

    }, error => {
      this.spinner.hide();
      this.openSnackBar('Failed to generate OTP, please try again later', 'Close');
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  openOtpDialog(userExist?: boolean): void {
    const dialogRef = this.dialog.open(OtpComponent
      , {
        data: userExist,
        width: '550px',
        backdropClass: 'bg-color',
        disableClose: true//// This is the "wanted" line
      });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private createBusinessUser(businessPhoneNumber: string, userPhoneNumber: string): ClientUser {

    this.markUserFieldsAsBlank();
    this.user.userType = 'CLIENT';
    this.user.groupId = 200;
    this.user.username = userPhoneNumber;
    this.user.userPhoneNumber = userPhoneNumber;
    this.user.businessPhoneNumber = businessPhoneNumber;

    // @ts-ignore
    this.user.firstName = this.form.get('firstName').value;
    // @ts-ignore
    this.user.lastName = this.form.get('lastName').value;
    // @ts-ignore
    this.user.language = this.form.get('language').value;
    // @ts-ignore
    this.user.confirmPassword = this.form.get('confirmPassword').value;
    // @ts-ignore
    this.user.password = this.form.get('password').value;
    // @ts-ignore
    this.user.businessEmailAddress = this.form.get('businessEmail').value;
    // @ts-ignore
    this.user.streetAddress =  this.addressData;
    // @ts-ignore
    this.user.businessStreetAddress = this.form.get('invoicingAddress').value;
    // @ts-ignore
    this.user.vatNumber = this.form.get('vatNumber').value;
    // @ts-ignore
    this.user.businessName = this.form.get('businessName').value;

    this.user.businessAddressCity= this.invoiceCity;
    this.user.businessAddressZipCode= this.invoiceZipCode;
    this.user.businessStreetAddress= this.invoiceStreet;

    this.user.city= this.mainMissionCity;
    this.user.zipCode= this.mainMissionZipCode;
    this.user.country= this.mainMissionCountryCode;
    this.user.streetAddress=  this.addressData;
    return this.user;
  }

  private createPersonalUser(userPhoneNumber: string): ClientUser {

    this.markUserFieldsAsBlank();
    this.user.userType = 'CLIENT';
    this.user.username = userPhoneNumber;
    this.user.userPhoneNumber = userPhoneNumber;
    this.user.groupId = 200;
    let bus_phon=this._type=='sub_user'?this.clientUserService.getLoggedInProfile()?.businessPhoneNumber as string:userPhoneNumber ;
    if(bus_phon){
      this.user.businessPhoneNumber= bus_phon;
    }
    else{
      this.user.businessPhoneNumber ='';
    }

console.log('mybusphon',this.user.businessPhoneNumber);
    // @ts-ignore
    this.user.firstName = this.form.get('firstName').value;
    // @ts-ignore
    this.user.lastName = this.form.get('lastName').value;
    // @ts-ignore
    this.user.language = this.form.get('language').value;
    // @ts-ignore
    this.user.confirmPassword = this.form.get('confirmPassword').value;
    // @ts-ignore
    this.user.password = this.form.get('password').value;
    // @ts-ignore
    this.user.businessEmailAddress = '';
    // @ts-ignore
    this.user.streetAddress =  this.addressData;
    // @ts-ignore
    this.user.businessStreetAddress = this.mainMissionStreet;
    // @ts-ignore
    this.user.vatNumber = '';
    // @ts-ignore
    this.user.businessName = this.user.firstName + this.user.lastName;
    this.user.city= this.mainMissionCity;
    this.user.zipCode= this.mainMissionZipCode;
    this.user.zipCode= this.mainMissionCountryCode;
    this.user.streetAddress=  this.addressData;
    return this.user;
  }

  isBusiness(value: string): void {
    this.addressData.splice(0,this.addressData.length);
    this.missionAddress.splice(0,this.missionAddress.length);
    if (value === 'auto') {
      this.form = new FormGroup({
        floatLabel: this.floatLabelControl,
        businessName: new FormControl('', Validators.required),
        vatNumber: new FormControl('', Validators.required),
        invoicingAddress: new FormControl('', Validators.required),
        mainMissionAddress: new FormArray([
          new FormControl('')
        ]),
        language: new FormControl('', Validators.required),
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(100)]),
        lastName: new FormControl('', [
          Validators.maxLength(100),
          Validators.required]),
        businessEmail: new FormControl('', [
          Validators.required,
          Validators.maxLength(100),
          patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ]),
        password: new FormControl('', [
          Validators.minLength(5),
          Validators.required]),
        confirmPassword: new FormControl('', [
          Validators.minLength(5),
          Validators.required]),
      });

    } else {

      this.form = new FormGroup({
        floatLabel: this.floatLabelControl,
        mainMissionAddress: new FormArray([
          new FormControl('')
        ]),
        language: new FormControl('', Validators.required),
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(100)]),

        lastName: new FormControl('', [
          Validators.maxLength(100),
          Validators.required]),

        password: new FormControl('', [
          Validators.minLength(5),
          Validators.required]),
        confirmPassword: new FormControl('', [
          Validators.minLength(5),
          Validators.required]),
      });
    }

  }
  // this keeps track of all addresses
  get addresses(): FormArray {
    return this.form.get('mainMissionAddress') as FormArray;
  }
  // we use this to delete addresses
  deleteAddress(index: number) {
    this.addresses.removeAt(index);
  }
  // this is used to create a new address control
  addMissionAddress() {
    this.addresses.push(new FormControl('', [Validators.required]));
  }
  private getUserProfile(): Promise<CustomResponse> {

    return new Promise((resolve, reject) => {
      const loginPhoneNumber = this.loginPhoneNumber.substr(1);
      this.clientUserService.getProfile(loginPhoneNumber).subscribe((result: CustomResponse) => {

        if (result.result === "OK") {
          const profile = <UserProfile>result.data;
          this.storageService.saveObject(StorageService.CLIENT_AGENT_PROFILE, profile)
          this.storageService.saveString(StorageService.USER_CATEGORY, profile.userType)
          if (profile.userType === 'CLIENT') {
            resolve(result);
          } else {
            this.openSnackBar('AGENT is allowed to mobile application', 'Close');
            // @ts-ignore
            resolve(null);
          }

        } else {
          // @ts-ignore
          resolve(null);
        }

      }, error => {
        reject(null);
      })
    });


  }

  invoiceAddressDialog() {

    const dialogRef = this.dialog.open(AddressDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          address: this.invoiceStreet,
          city: this.invoiceCity,
          zipCode: this.invoiceZipCode,
          title:'Invoice Address'
        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result.confirmation === 'DONE') {
        this.invoiceStreet = result.address;
        this.invoiceCity = result.city;
        this.invoiceZipCode = result.zipCode;
        this.form.get('invoicingAddress')?.setValue(this.invoiceStreet+", "+this.invoiceZipCode+" "+this.invoiceCity)
      }

    });
  }
// update array item if exist else insert new item
   upsert(array: any[], element:any, i:number) {
     if (i > -1) array[i] = element;
    else array.push(element);
  }
  mainMissionAddressDialog(addressIndex:any) {
    const dialogRef = this.dialog.open(AddressDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          address: (this.addressData.length>addressIndex)?this.addressData[addressIndex].mainMissionStreet:'',
          city: (this.addressData.length>addressIndex)?this.addressData[addressIndex].mainMissionCity:'',
          zipCode: (this.addressData.length>addressIndex)?this.addressData[addressIndex].mainMissionZipCode:'',
          country: (this.addressData.length>addressIndex)?this.addressData[addressIndex].mainMissionCountryCode:'',
          title:'Mission Address',
          countries:this.countries
        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result.confirmation === 'DONE') {
        let data = {} as Address;
        data.mainMissionStreet = result.address;
        data.mainMissionCity = result.city;
        data.mainMissionZipCode = result.zipCode;
        data.mainMissionCountryCode = result.country
        // this is used to push to server and edit address
        this.upsert(this.addressData, data, addressIndex)
        // this is used to display address on UI
        this.upsert(this.missionAddress, result.address+", "+result.zipCode+" "+result.city+" "+result.country, addressIndex)
        this.addresses.patchValue(this.missionAddress);
      }

    });
  }

  markUserFieldsAsBlank(){
    this.user.dateOfBirth =''
    this.user.height =0
    this.user.weight =0
    this.user.qualification =''
    this.user.gender =''
    this.user.dressCode =''
    this.user.nationalRegister =''
    this.user.bankAccountNumber =''
    this.user.agentPhotoFileName =''
    this.user.agentProfessionalCardBackFileName =''
    this.user.agentProfessionalCardFrontFileName =''
    this.user.businessType =''
    this.user.job =''
  }
}
