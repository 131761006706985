<header class="header">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="content">

    <section class="header-logo">
      <h1><a routerLink="businessownermanager" style="color: white;font-weight: bold" translate>nbs.title</a></h1>
    </section>

    <div class="header-account">

     <!--   <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn">
                  <a routerLink="" style="margin-right: 20px; color: white" translate>nbs.menu.label.contactus</a>
              </span>-->

   <!--   <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn">
                  <a routerLink="" style="margin-right: 20px; color: white" translate>nbs.menu.label.help</a>
              </span>-->

    <!--  <span style="margin-right: 20px; color: white" *ngIf="this.isLoggedIn">
                  <a routerLink="" style="margin-right: 20px; color: white" translate>nbs.menu.label.ourservices</a>
              </span>-->

      <span style="margin-right: 20px; color: white" >
                <a mat-icon-button   style="margin-right: 20px; color: white" [matMenuTriggerFor]="menu"><span class="flag-icon flag-icon-round" [ngClass]="currentFlag"></span> </a>
            <mat-menu #menu="matMenu">
                                       <button mat-menu-item (click)="useLanguage('en')">
                                        <span class="flag-icon flag-icon-gb flag-icon-round"></span>
                                         <span>English</span>
                                       </button>
                                       <button mat-menu-item (click)="useLanguage('fr')">
                                        <span class="flag-icon flag-icon-fr flag-icon-round"></span>
                                         <span>French</span>
                                       </button>

</mat-menu>
 </span>

    </div>
  </div>
</header>
