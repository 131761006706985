import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MessageDialog} from "../../model/message-dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  message: any;
  title: any;
  isError: boolean;
  isConfirmation: boolean;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: MessageDialog) { }

  ngOnInit(): void {

    if (this.data) {
      this.message = this.data.description;
      this.title = this.data.title;
      this.isError = this.data.error;
      this.isConfirmation = this.data.confirmation;
    }


  }

  confirm() {
    this.dialogRef.close('CONFIRM');
  }

  ok() {
    this.dialogRef.close('OK');
  }

  cancel() {
    this.dialogRef.close('CANCEL');
  }
}
