<mat-card class="mt-lg-3" (click)="showDialog(mission)">
  <div class="row">

    <div class="col">
         <span>
         <mat-icon svgIcon="icons_job">
         </mat-icon>
           {{mission.missionName}}
       </span>
    </div>

    <div class="col">
          <span>
         <mat-icon [svgIcon]="mapStatus(mission.missionStatus,mission.id)">
         </mat-icon>
            {{mission.missionStatus}}
       </span>
    </div>
    <div class="col">
       <span>
         <mat-icon svgIcon="icons_job-1">
         </mat-icon>
         {{mission.missionStartDate | date: 'dd-MM-YYYY'}}
       </span>
    </div>


  </div>
  <div class="row">
    <div class="col">
      <p> {{mission.missionDescription}}</p>
    </div>
  </div>


  <div class="row">


    <div class="col">
      <p style="color: #00b871;font-size: 14px;font-weight: bold" class="btn">{{'nbs.businessownermanager.manageagents.bookedMissions.viewMission'|translate}}</p>
    </div>
  </div>


</mat-card>
