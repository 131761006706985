import {Component, Inject, OnInit} from '@angular/core';
import {Mission} from "../../../model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MissionService} from "../../service/mission.service";
import {AccountTransaction} from "../../model/account-transaction";
import {UtilService} from "../../service/util.service";
import {MissionAgent} from "../../model/mission-agent";
import {MissionUpdateRequest} from "../../model/mission-request";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileService} from "../../service/file.service";
import {UserProfile} from "../../model/user-profile";
import {ClientUserService} from "../../service/client-user.service";

@Component({
  selector: 'app-mission-dialog',
  templateUrl: './mission-dialog.component.html',
  styleUrls: ['./mission-dialog.component.css']
})
export class MissionDialogComponent implements OnInit {

  mission: Mission;
  accountTransactions: AccountTransaction[];
  agents: MissionAgent[] = [];
  missionExtensionWindowValid= false;
  pending= false;

  constructor(public dialogRef: MatDialogRef<MissionDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private missionService: MissionService,
              private utilService: UtilService,
              private router: Router,
              private store: Store,
              private snackBar: MatSnackBar,
              private fileService: FileService,
              private clientUserService: ClientUserService,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.mission = this.data.mission;
      this.pending = this.data.pending;

      this.getMissionAgents();
      this.missionExtensionWindow();

    }
  }

  cancelDialog() {
    this.dialogRef.close('CANCEL_MISSION');

  }

  doneDialog() {
    this.dialogRef.close('DONE');

  }

  getTransactions(missionId: string){

    this.missionService.getMissionTransactions(missionId).subscribe(response=>{

      if(response && response.result === 'OK'){
        this.accountTransactions = <AccountTransaction[]>response.data.data;
      }

    });

  }

  title(reference: string): string{

    if(reference.includes('NBS_M_E_')){
      return 'Mission Extension';
    }

    return 'Mission Creation';
  }

  amount(amount: number): string {

    return this.utilService.toDecimal(amount);
  }

  isSuccess(state: string): boolean{

    if(state === 'COMPLETED'){
      return true;
    }
    return false;
  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(`${this.mission.id}`).subscribe(async (response) => {

      if (response.result === "OK") {
        this.agents = <MissionAgent[]>response.data;

        if (this.agents && this.agents.length > 0) {
          for (const value of this.agents) {

            const path = await this.getProfile(value.agentPhoneNumber);

            if (path && path.length > 10) {
              value.path = path;
            }

          }
        }
      }

    });
  }

  getProfile(phoneNumber: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.clientUserService.getProfile(phoneNumber).subscribe(result => {
        if (result.result === "OK") {
          const profile = <UserProfile>result.data;

          this.fileService.getFile(profile.agentPhotoPath).subscribe(value => {

            if(value){

              resolve( `data:image/png;base64,${value.base64}`);

            }
          });

        }else{
          resolve("");
        }
      },error => {
        resolve("");
      });


    });

  }
  missionExtensionWindow(): void {


    this.missionService.isValidMissionExtension(this.mission.id+'')
      .subscribe(response => {
        console.log(response);
        if (response.result === 'OK') {
          this.missionExtensionWindowValid =  true;

        }

      });


  }

  extendMission() {


  }




  updateMissionWithSufficiency(missionId:any,deleted: boolean){


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    this.missionService.updateMission(missionUpdateRequest).subscribe(value => {
      if(value.result === 'OK'){
        this.router.navigate(['client']);
      }else{

        if(value.data){
          this.openSnackBar(value.data, 'Close');
        }else{
          this.openSnackBar('Failed to complete your request', 'Close');
        }

      }
    },error => {
      this.openSnackBar('An error occurred', 'Close');
    });
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
