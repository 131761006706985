import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageService} from "../../service/storage.service";

@Injectable({providedIn: 'root'})
export class PaymentService {

  constructor(private http: HttpClient,
              private storageService: StorageService) {
  }


  initiatePayment(formModel: any): Observable<any> {

    return this.http
      .post<any>(`/api/payment/initiate`,
        formModel);
  }



  callPaymentWebHook(id: any): Observable<any> {


    var headers = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };

    let body = 'id=' + id ;

    return this.http
      .post<any>(`/api/payment/mollie/webhook`,body,headers);
  }



  getPaymentByGatewayReference(gatewayId: string): Observable<any> {
    const url = `/api/payment/get?gatewayId=${gatewayId}`;
    return this.http.get<any>(url);
  }

  getPaymentByTransactionReference(transactionReference: string): Observable<any> {
    const url = `/api/payment/get?transactionReference=${transactionReference}`;
    return this.http.get<any>(url);
  }

  getPaymentsByPhoneNumber(phoneNumber: string): Observable<any> {
    const url = `/api/payment/get?phoneNumber=${phoneNumber}`;
    return this.http.get<any>(url);
  }
}
