import {AuthServerUser, Token} from "../../model";
import {UserProfile} from "./user-profile";

export class AuthProfileUser{

  authorizationUser?: AuthServerUser;
  userProfile?: UserProfile;
  profile: string = 'admin-portal';
  token?: Token;

}
