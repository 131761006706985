import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {catchError, finalize, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import {UsersService} from "../../service/users.service";
import {
  AgentRegistration,
  AgentStatistics,
  appConstants,
  CustomResponse,
  ResponseUserInformationList
} from "../../model";

import {ToastrService} from "ngx-toastr";
import { PartnersDatasource } from 'src/app/service/datasource/partners-datasource';
import { ViewAgencyDetailsComponent } from './view-agency-details/view-agency-details.component';
import { AddAgencyComponent } from './add-agency/add-agency.component';
import { EmploymentAgencyDatasource } from 'src/app/service/datasource/employmentagency-datasource';

@Component({
  selector: 'app-manageemployeeagency',
  templateUrl: './manageemployeeagency.component.html',
  styleUrls: ['./manageemployeeagency.component.css']
})
export class ManageemployeeagencyComponent implements OnInit {

  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :EmploymentAgencyDatasource;


  columnsToDisplay = ['agencyName','phoneNumber','emailAddress','vatNumber','status','buttons'];
  private paginator: MatPaginator ;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];

  constructor(private httpClient: HttpClient,private dialog: MatDialog,
              private userService: UsersService,
              private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.dataSource = new EmploymentAgencyDatasource(this.userService);
    this.dataSource?.loaddata();

    // this. loadAgencies();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  ngAfterViewInit() {
    this.dataSource?.counter$
    .pipe(
      tap((count) => {
        // @ts-ignore
        this.paginator.length = count;
      })
    )
    .subscribe();

  // @ts-ignore
  this.paginator.page
    .pipe(
      tap(() => this.loadAgencies())
    )
    .subscribe();
  }

  loadAgencies() {
   

    this.dataSource?.loaddata(this.paginator.pageIndex, this.paginator.pageSize);

    // this.userService.getAgencies()
    //   .pipe(
    //     catchError(() => of([]))
    //   )
    //   .subscribe((result:any) => {
         

    //     this.dataSource=result.data;
    //     }
    //   );
  }

  openAgencyViewDetails(element:any){
    this.openViewInfo(element);
  }

  openViewInfo(data:any): void {
    
    const dialogRef = this.dialog.open(ViewAgencyDetailsComponent
      , {
        width: '50%',
        data:data

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadAgencies()
      });
  }

  addAgency(){
    const dialogRef = this.dialog.open(AddAgencyComponent
      , {
        width: '50%',
        

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadAgencies()
      });
  }

}
