import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/service/storage.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
export class UserPermissionComponent implements OnInit {


  menus:any;
  userGroups:any

  selectedUserGroup:any=0

  constructor(
    private toastr: ToastrService,
    private userService: UsersService,
    private storageService: StorageService,
  ) { 
    //this.getAllMenuByGroupid();
    this.getUserGroup();
  }

  ngOnInit(): void {
  }

  getUserGroup(){

    var sub=this.userService.getUserGroup().subscribe((result:any)=>{

      if(result){
        this.userGroups=result ;
      }else{
       
      }

      sub.unsubscribe();
    },error=>{
      
      sub.unsubscribe();
      
    });
  }


  getAllMenuByGroupid(groupid:any){
    var sub=this.userService.getAllMenuByGroupid(groupid,this.storageService.getItem(StorageService.USER_LANGUAGE)).subscribe((result:any)=>{

      if(result){
       this.menus=result ;
      }else{
      }
      sub.unsubscribe();
    },error=>{
      sub.unsubscribe();
    });
  }

  changeUserGroup(event:any){

    this.menus=[]
    //console.log(event.target.value)
    this.getAllMenuByGroupid(event.target.value)
    this.selectedUserGroup=event.target.value
  }

  changePermision(menuId:any){
    // console.log(this.selectedUserGroup)
    // console.log(menuId)

    var sub=this.userService.setPermission(this.selectedUserGroup,menuId).subscribe((result:any)=>{

      if(result.result==='OK'){
        this.toastr.success("Edited successfully","MENU PERMISSION");
      }else{
        this.toastr.error(result.data,"MENU PERMISSION");
      }
      sub.unsubscribe();
    },error=>{
      this.toastr.error("Failed","MENU PERMISSION");
      sub.unsubscribe();

    });
  
  }

}
