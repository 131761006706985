import {Component, Inject, OnInit} from '@angular/core';
import {Mission} from "../../../model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MissionService} from "../../service/mission.service";
import {UtilService} from "../../service/util.service";
import {MissionAgent} from "../../model/mission-agent";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../../service/storage.service";
import {MissionExtensionRequest} from "../../model/mission-extension-request";
import {ClientUserService} from "../../service/client-user.service";

@Component({
  selector: 'app-mission-extension-dialog',
  templateUrl: './mission-extension-dialog.component.html',
  styleUrls: ['./mission-extension-dialog.component.css']
})
export class MissionExtensionDialogComponent implements OnInit {

  hours: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
  mission: Mission;
  agents: MissionAgent[] = [];
  form: FormGroup;
  private agent: MissionAgent;
  private duration: string;

  constructor(public dialogRef: MatDialogRef<MissionExtensionDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private missionService: MissionService,
              private utilService: UtilService,
              private fb: FormBuilder,
              private storageService: StorageService,
              private clientUserService: ClientUserService,
  ) {
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      agent: new FormControl('', null),
      duration: new FormControl('', Validators.required),

    });
    if (this.data) {
      this.mission = this.data.mission;

      console.log(" this.mission "+ JSON.stringify(this.mission));

      this.getMissionAgents();

    }
  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(`${this.mission.id}`).subscribe((response) => {

      //console.log("AGENTS ",response.data);
      if (response.result === "OK") {
        this.agents = <MissionAgent[]>response.data;
      }

    });
  }

  extendMission() {

    if (this.form.valid) {

      const missionExtension = new MissionExtensionRequest();
      missionExtension.clientInitiate = true;
      if (this.agents && this.agents.length > 1 && !this.agent) {

        // todo select an agent
        return;

      }

      missionExtension.extensionDuration = +this.duration;
      missionExtension.agentPhoneNumber = this.agent ? this.agent.agentPhoneNumber : "";
      // @ts-ignore
      missionExtension.clientPhoneNumber = this.clientUserService.getLoggedInProfile()?.businessPhoneNumber;
      missionExtension.missionId = `${this.mission.id}`;
      missionExtension.currentMissionEndDate = this.mission.missionEndDate;

      this.storageService.saveObject(StorageService.MISSION_EXTENSION, missionExtension);

      this.dialogRef.close({
        action: 'EXTEND',
        missionExtension: missionExtension,
        mission: this.mission,
        duration: +this.duration,
      });

    }

  }

  cancelDialog() {
    this.dialogRef.close({
      action: 'CANCEL'
    });
  }

  selectedAgent(agentValue: MissionAgent) {
    this.agent = agentValue;
  }

  selectedHour(val: string) {
    this.duration = val;
  }
}
