import {Component, OnInit} from '@angular/core';
import {UtilService} from "../service/util.service";

@Component({
  selector: 'app-tarrifs',
  templateUrl: './tarrifs.component.html',
  styleUrls: ['./tarrifs.component.css']
})
export class TarrifsComponent implements OnInit {


  paymentRate1: number;
  paymentRate5: number;
  paymentRate10: number;
  numberSelected: string;

  agents:string[];

  constructor(private utilService: UtilService) {
  }

  ngOnInit(): void {

    this.agents = new Array();
    for (let i =1;i<=24;i++){

      this.agents.push(`${i}`);

    }

  }


  calculateRate(duration: number): void {

    this.paymentRate1 = this.utilService.getMissionPaymentRate(1) * duration * 1;
    this.paymentRate5 = this.utilService.getMissionPaymentRate(5) * duration * 5;
    this.paymentRate10 = this.utilService.getMissionPaymentRate(10) * duration * 10;

  }

  selectedNumberOfAgents(agentValue: string) {

    this.calculateRate(+agentValue);
    this.numberSelected = agentValue+'h';

  }
}
