import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {LoginComponent} from "../login/login.component";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {getAuthProfileUser} from "../../store";
import {AuthProfileUser} from "../../client/model/auth-profile-user";
import {ClientSingletonService} from "../../client/service/client-singleton.service";
import {StorageService} from "../../service/storage.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  loading = false;
  currentFlag = "flag-icon-gb";
  otherProfile = true;
  clientProfile = false;
  currentLang:string="en";
  private currentUser: AuthProfileUser;

  constructor(private translate: TranslateService, public dialog: MatDialog,
              private router: Router,
              private store: Store,
              private clientSingletonService: ClientSingletonService,private storage: StorageService
  ) {

  }

  useLanguage(language: string): void {
    this.translate.use(language);
    if (language === "en") {
      this.currentFlag = "flag-icon-gb";
    } else if (language === "fr") {
      this.currentFlag = "flag-icon-fr";
    }
    this.storage.saveString(StorageService.USER_LANGUAGE,language);
  }

  ngOnInit(): void {
    let saveLang= this.storage.getItem(StorageService.USER_LANGUAGE);
    if(saveLang){
      this.currentLang=saveLang;
    }
    this.useLanguage(this.currentLang);

    this.clientSingletonService.loadingProgressBar.subscribe(
      (status: boolean) => {
        this.loading = status;
      }
    );

    this.store.select(getAuthProfileUser).subscribe((value: AuthProfileUser) => {
      if (value) {
        this.currentUser = value;
        if (value && value.profile === 'client') {
          this.clientProfile = true;
          this.otherProfile = false;

        } else {
          this.otherProfile = true;
          this.clientProfile = false;
        }

        if(value.token && value.token.access_token){
          this.isLoggedIn = true;
        }
      }
    })
  }


  openSignInDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent
      , {
        width: '50%',
        backdropClass: 'bg-color' // This is the "wanted" line
      });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openSignUpDialog(): void {

  }


  logOut(): void {
    this.router.navigate(['client/oauth2/login']);
  }

  onRoute(rT: string): void {
    this.router.navigate([rT]);
  }

  signOut() {

    this.router.navigate(['client/oauth2/login']);

  }
}
