import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {StorageService} from "../../service/storage.service";

@Component({
  selector: 'app-businessownerheader',
  templateUrl: './businessownerheader.component.html',
  styleUrls: ['./businessownerheader.component.css']
})
export class BusinessownerheaderComponent implements OnInit {

  isLoggedIn = true;
  loading = false;
  currentFlag="flag-icon-gb";
  currentLang="en";

  constructor(private translate: TranslateService,public dialog: MatDialog,private storage:StorageService) {

  }

  useLanguage(language: string): void {
    this.translate.use(language);
    if(language==="en"){
      this.currentFlag="flag-icon-gb";
    }else if(language==="fr"){
      this.currentFlag="flag-icon-fr";
    }
    this.storage.saveString(StorageService.USER_LANGUAGE,language);
  }

  ngOnInit(): void {
    this.useLanguage(this.currentLang);
    let saveLang= this.storage.getItem(StorageService.USER_LANGUAGE);
    if(saveLang){
      this.currentLang=saveLang;
    }
    this.useLanguage(this.currentLang);
  }



}
