
<ng-container *ngIf="tariffDetails" >

  <!-- {{clientDetails | json}} -->
  {{'label.changeRateFor'|translate}} : {{tariffDetails.tariffName}}

  <form class="myFormStyle" [formGroup]="form"  >


    <div>
      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.tariffName'|translate}}</label>
            <input type="text" class="myInput" [(ngModel)]="tariffDetails.tariffName"  formControlName="tariffName"      placeholder="{{tariffDetails.tariffName}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.costPerAgent'|translate}}</label>
            <input type="text" class="myInput"  [(ngModel)]="tariffDetails.costPerAgent" formControlName="costPerAgent"   placeholder="{{tariffDetails.costPerAgent}}">

          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.minimumNumberOfAgentsForTariff'|translate}}</label>
            <input type="text" class="myInput" [(ngModel)]="tariffDetails.minimumNumberOfAgentsForTariff"  formControlName="minimumNumberOfAgentsForTariff"      placeholder="{{tariffDetails.minimumNumberOfAgentsForTariff}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.maximumNumberOfAgentsForTariff'|translate}}</label>
            <input type="text" class="myInput"  [(ngModel)]="tariffDetails.maximumNumberOfAgentsForTariff" formControlName="maximumNumberOfAgentsForTariff"   placeholder="{{tariffDetails.maximumNumberOfAgentsForTariff}}">

          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.dayForTariff'|translate}}</label>
            <input type="text" class="myInput" [(ngModel)]="tariffDetails.dayForTariff"  formControlName="dayForTariff"      placeholder="{{tariffDetails.dayForTariff}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.startTime'|translate}}</label>
            <input type="text" class="myInput"  [(ngModel)]="tariffDetails.startTime" formControlName="startTime"   placeholder="{{tariffDetails.startTime}}">

          </div>


        </div>

      </div>

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.endTime'|translate}}</label>
            <input type="text" class="myInput" [(ngModel)]="tariffDetails.endTime"  formControlName="endTime"      placeholder="{{tariffDetails.endTime}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.specialDay'|translate}}</label>
            <input type="checkbox" [(ngModel)]="tariffDetails.specialDay" formControlName="specialDay">

          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.specialDayDate'|translate}}</label>
            <input type="text" class="myInput" [(ngModel)]="tariffDetails.specialDayDate"  formControlName="specialDayDate"      placeholder="{{tariffDetails.specialDayDate}}">

          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.status'|translate}}</label>
            <input type="text" class="myInput"  [(ngModel)]="tariffDetails.status" formControlName="status"   placeholder="{{tariffDetails.status}}" >

          </div>


        </div>

      </div>
      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label >{{'label.publicHoliday'|translate}}</label>
            <input type="checkbox" [(ngModel)]="tariffDetails.publicHoliday"  formControlName="publicHoliday">

          </div>


        </div>
        </div>
    </div>


    <footer>
      <button  (click)="saveTariff()" class="btn btn-danger">
        {{'btn.save_btn'|translate}}
      </button>

      <button type="button" class="btn btn-default" (click)="cancel()">
        {{'btn.dismiss'|translate}}
      </button>

    </footer>
  </form>


</ng-container>
