<app-client-header ></app-client-header>
<div>
  <div class="row">
    <div class="quarter column mh-100">
      <mat-card class="mt-lg-3">
        <div style="text-align: center; padding-top: 20px">

          <mat-icon class="icon-display mt-lg-5" svgIcon="placeholder_user" *ngIf="!pathBase64"></mat-icon>
          <img *ngIf="pathBase64" [src]="pathBase64" class="icon-display mt-lg-5" />


          <div style="text-align: left" class="ml-5 mt-5">

            <mat-selection-list [multiple]="false" style="text-align: left">


              <mat-list-option (click)="bookAgent()">
         <span>       <mat-icon svgIcon="icons_book_agent"></mat-icon>

            {{'client.book_an_agent'|translate}} </span>
              </mat-list-option>


              <div (click)="ongoingMissions()">
                <mat-list-option>

             <span>  <mat-icon svgIcon="icons_ongoing_missions"></mat-icon>
               {{'client.ongoing_missions'|translate}} </span>
                </mat-list-option>
              </div>

              <mat-list-option (click)="pastMissions()">
            <span>  <mat-icon svgIcon="past_missions"></mat-icon>
              {{'client.past_missions'|translate}} </span>
              </mat-list-option>

              <mat-list-option (click)="missionsWaiting()">
              <span>  <mat-icon svgIcon="icons_transaction_history"></mat-icon>
                {{'client.pending_missions'|translate}} </span>
              </mat-list-option>

              <mat-list-option (click)="tariffs()">
            <span>  <mat-icon svgIcon="icons_tariffs"></mat-icon>
              {{'client.tariffs'|translate}} </span>
              </mat-list-option>
              <mat-list-option (click)="changeType('sub_user')">
            <span>  <mat-icon svgIcon="add_subaccount"></mat-icon>
              {{'client.add_user'|translate}} </span>
              </mat-list-option>




            </mat-selection-list>

          </div>


        </div>

      </mat-card>

    </div>

    <div class="three-quarter column col-sm mx-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

