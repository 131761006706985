import {Action} from '@ngrx/store';
import {UserProfile} from "../../client/model/user-profile";

export const CREATE_AGENT = 'CREATE_AGENT';
export const GET_AGENT = 'CREATE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';


export class CreateAgent implements Action {
  type = CREATE_AGENT;

  constructor(public payload: UserProfile) {
  }
}


export class GetAgent implements Action {
  type = GET_AGENT;

  constructor(public payload: UserProfile) {
  }
}


export class DeleteAgent implements Action {
  type = DELETE_AGENT;

  constructor(public payload: UserProfile) {
  }
}

export type AgentActions
  = CreateAgent |
  GetAgent |
  DeleteAgent;
