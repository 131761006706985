import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {Token} from "../model";
import {environment} from "../../environments/environment";



@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {


  constructor(
    private router: Router,
    private storageService: StorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    const token = this.storageService.getItem(StorageService.USER_TOKEN);
    let newReq;
    let url = environment.baseUrl;
   /* if(!environment.production) {
      url = localStorage.getItem('baseURL')
    }*/
    if (token) {
      const tokenObj = JSON.parse(token) as Token;
      if (req.headers.has('Authorization')) {
        if (!req.url.includes('assets')) {
          newReq = req.clone({
            headers: req.headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS'),
            url: `${url}${req.url.replace('/api', '')}`
          });
        } else {
          newReq = req.clone({
            headers: req.headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS')
          });
        }

      } else {
        if (!req.url.includes('assets')) {
          newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + tokenObj.access_token)
              .set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS'),
            url: `${url}${req.url.replace('/api', '')}`
          });
        } else {
          newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + tokenObj.access_token)
              .set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS')
          });
        }


      }


    } else {
      if (req.headers.has('Authorization')) {
        // req.headers.delete('AUTHORIZATION');
      }
      if (!req.url.includes('assets')) {
        newReq = req.clone({url: `${url}${req.url.replace('/api', '')}`});
      } else {
        newReq = req.clone();
      }

    }


    window.scroll(0, 0);

    // console.log(`----------------------------REQUEST-----------------------`);
    // console.log(newReq);
    // console.log(newReq.body);
    // console.log(newReq.headers);

    // console.log(`----------------------------REQUEST-----------------------`);
    return next.handle(newReq).pipe(
      tap(
        response => {

          // console.log(`----------------------------RESPONSE-----------------------`);
          // console.log(response);
          // console.log(`----------------------------RESPONSE-----------------------`);
        },
        error => {
          // console.log(`----------------------------RESPONSE ERROR-----------------------`);
          // console.log(error);
          // console.log(`----------------------------RESPONSE ERROR-----------------------`);
          if (error.status === 401) {
            this.handleAuthError();
            // this.handle401Error(newReq,next);
            return of(error);
          }
          throw error;
        }
      )
    );
  }

  private handleAuthError(): void {
    // this.storageService.deleteItem(StorageService.USER_TOKEN);
    this.router.navigateByUrl('login');
  }

  /*private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const usertoken = this.storageService.getItem(StorageService.USER_TOKEN);
      const tokenObj = JSON.parse(usertoken) as Token;
      const token=tokenObj.refresh_token;

      if (token) {
      console.log(token);
      }
      return this.authServer.getRefreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.storageService.saveObject(StorageService.USER_TOKEN,token.accessToken);
            this.refreshTokenSubject.next(token.accessToken);

            console.log(token);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            console.log(err);
            // this.tokenService.signOut();
            return throwError(err);
          })
        );
    }
  }*/

  /*  private addTokenHeader(request: HttpRequest<any>, token: string) {
      /!* for Spring Boot back-end *!/
      return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });

      /!* for Node.js Express back-end *!/
      // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
    }*/
}
