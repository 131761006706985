import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthenticationService} from "../../service/authentication.service";
import {StorageService} from "../../service/storage.service";
import {AuthServerUser, Menu} from "../../model";
import {match} from 'minimatch';
import {ToastrService} from "ngx-toastr";
import { UsersService } from 'src/app/service/users.service';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  E164PhoneNumber: string|any ;
  phoneNumber :string|any ;
  password:string|any ;
  userExist:boolean=false ;
  ngForm: FormGroup | any;
  loggedInUser: AuthServerUser | any;

  processingLogin:boolean =false;
  AllowedCountries: string[] =['be', 'fr',  'nl', 'de', 'es', 'lu', 'gb' ]
  constructor(private router: Router,private dialog: MatDialog,
              private  authService: AuthenticationService,
              private userService: UsersService,
              private storageService: StorageService,
              public dialogRef: MatDialogRef<LoginComponent>,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ngForm = new FormGroup({
      password: new FormControl('', [
        Validators.minLength(5),
        Validators.required]),
    });
  }

  async login(): Promise<void> {
    console.log('login clicked');
    if (this.ngForm.valid && match) {

      if (this.E164PhoneNumber) {
        this.phoneNumber = this.E164PhoneNumber.substr(1);
       // this.password=this.ngForm.password.value;
        this.processingLogin=true;
        this.authService.loginWithUsernameAndPassword(this.phoneNumber, this.password).subscribe(async value => {
          this.storageService.saveObject(StorageService.USER_TOKEN, value);
          this.storageService.saveString(StorageService.USER_PHONE_NUMBER, this.phoneNumber);
          this.loggedInUser = await this.getLoggedInUser();
          this.processingLogin=false;
          this.dialogRef.close();
          this.router.navigate(['businessownermanager']);
        },error => {
          this.processingLogin=false;
          console.log(error);
          this.toastr.error('There was error in logging you in', 'Login!',{
            timeOut: 3000,
            closeButton: true,
            progressAnimation:'decreasing',
            progressBar: true,
            tapToDismiss: true,
            newestOnTop:true,
          });
        });
      }
    }

  }

  async getLoggedInUser(): Promise<AuthServerUser> {

    return new Promise((resolve, reject) => {
      this.authService.getLoggedInUser().subscribe(value => {
        this.storageService.saveObject(StorageService.GROUPID, value.groupId);
        console.log("user info \n"+value);
        resolve(value);
        this.getMenu(value.groupId, this.storageService.getItem(StorageService.USER_LANGUAGE));

      }, error => {
        resolve(error);
      });
    });
  }



  getMenu(groupid:number,language:string){

    var sub=this.userService.getMenu(groupid,language).subscribe((result:Menu)=>{

      if(result){
        this.storageService.saveObject(StorageService.MENU, result);

      }else{

      }

      sub.unsubscribe();
    },error=>{

      sub.unsubscribe();

    })
  }

  forgetPassword(){
    this.dialogRef.close();

    const dialogRef = this.dialog.open(ForgetPasswordComponent
      , {
        width: '550px',
        backdropClass: 'bg-color', // This is the "wanted" line
      });

    dialogRef.afterClosed().subscribe(result => {
      //openChangePasswordDialog(phoneNumber)
    });

  }

}
