<app-client-header></app-client-header>
<div>
  <ngx-spinner></ngx-spinner>
  <h1 style="color: black;text-align: center">NBS</h1>

  <img src="../assets/images/messaging.png" class="img-otp c-align" style="text-align: center"/>

  <p style="text-align: center;margin: 20px; color: black">{{'client.sms_sent'|translate}}</p>
  <h3 class="c-align" style="color: black; margin: 10px">{{'client.verification_code'|translate}}</h3>


  <div class="p-margin c-align">



    <ng-otp-input  (onInputChange)="onOtpChange($event)"
                   [config]="{length:4,inputClass: 'image_box',allowNumbersOnly:true}">

    </ng-otp-input>



  </div>

  <div class="p-margin c-align " style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">


    <span style="color: white">
                <a routerLink="" style="margin-right: 30px; color: black"
                   (click)="resendOtp()">{{'client.resend'|translate}}</a>
            </span>

    <span class="btn-login">
                <a routerLink="" (click)="submitCode()" style="margin-left: 30px">{{'client.submit'|translate}}</a>
            </span>

  </div>

</div>
