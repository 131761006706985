import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {

  e164Number: string|any ;

  rForm: FormGroup;

  phoneNumber='';
  separateDialCode = false;
	SearchCountryField = SearchCountryField;

	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  submitted:boolean=false;

  userGroups:any

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditEmployeeComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private userService: UsersService,
    private toastr: ToastrService
  ) {
    this.rForm=this.fb.group({ 
      username: [''],
      firstName: ['',Validators.required],
      lastName:["",Validators.required],
      email : [""],
      groupId:[101,Validators.required],
      language:["",Validators.required]
    });

    this.getUserGroup();
   }

  ngOnInit(): void {


    console.log(this.data)
    this.rForm.patchValue(this.data)
    //Object.assign(this.rForm.patchValue(this.data),this.data);

  }

  cancel(){
    this.dialogRef.close();
  }

  confirmSave(){

    this.submitted=true;

    //console.log(this.rForm)
    
    if (this.rForm.valid) {

     
      var sub=this.authService.updateBasicInformation(this.rForm.value).subscribe(async value => {

        if (value ) {
            this.toastr.success("Save successfully","EDIT");
            this.dialogRef.close();
        } else {
          this.toastr.error("Error in processing");
        }
        sub.unsubscribe();
      }, error => {
        sub.unsubscribe();
        this.toastr.error("Error in processing");
      });
  

    }


   
  }

  getUserGroup(){

    var sub=this.userService.getUserGroup().subscribe((result:any)=>{

      if(result){
        this.userGroups=result ;
      }else{
       
      }

      sub.unsubscribe();
    },error=>{
      
      sub.unsubscribe();
      
    });
  }


}
