import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MessageDialog} from "../../model/message-dialog";
import {MissionRequest} from "../../model/mission-request";
@Component({
  selector: 'app-book-agent-summary-dialog',
  templateUrl: './book-agent-summary-dialog.component.html',
  styleUrls: ['./book-agent-summary-dialog.component.css']
})
export class BookAgentSummaryDialogComponent implements OnInit {
  message: any;
  title: any;
  isError: boolean;
  isConfirmation: boolean;
  isExtension: boolean;
  request: any;
  constructor(public dialogRef: MatDialogRef<BookAgentSummaryDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    if (this.data) {
      this.title = this.data.title;
      this.isError = this.data.isError;
      this.isExtension = this.data.isExtension;
      this.isConfirmation = this.data.isConfirmation;
      this.request = this.data.request;
    }
  }

  confirm() {
    this.dialogRef.close('CONFIRM');
  }

  okDialog() {
    this.dialogRef.close('OK');
  }

  cancelDialog() {
    this.dialogRef.close('CANCEL');
  }

}
