<div *ngIf="mission" class="mt-lg-3">
  <mat-card style=" height: auto">


    <div class="row">

      <div class="col">
         <span>
         <mat-icon svgIcon="icons_job">
         </mat-icon>
           {{mission.missionName}}
       </span>
      </div>

      <div class="col">
        <p>{{mission.missionStartDate | date: 'dd-MM-yyyy'}}</p>
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionDescription}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.address'|translate}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{mission.missionStreetAddress}}, {{mission.missionZipCodeAddress}} {{mission.missionCityAddress}}</p>
      </div>

      <div class="col">
      </div>

    </div>

    <div class="row">

      <div class="col">
        <p>{{'client.date'|translate}}</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'dd-MM-yyyy'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'dd-MM-yyyy'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{"client.time"|translate}} :</p>
      </div>

      <div class="col">
        {{mission.missionStartDate | date:'HH:mm'}}
      </div>
      <div class="col">
        {{mission.missionEndDate | date:'HH:mm'}}
      </div>
    </div>

    <div class="row">

      <div class="col">
        <p>{{'nbs.businessownermanager.manageagents.missionInfo.type'|translate}} : {{mission.missionType}}</p>
      </div>
      <div class="col">
      </div>

    </div>
    <div class="row">

      <div class="col">
        <p>Status : {{mission.missionStatus}}</p>
      </div>
      <div class="col">
      </div>

    </div>

    <div class="mt-lg-5" style="" *ngIf="agents && agents.length >0">
      <h1>{{'client.agents'|translate}}</h1>
      <div class="col" style="color: black">

        <div class="row" *ngFor="let agent of agents; let i = index">
          <div class="col" style="margin-left: 30px">
            <mat-icon class="icon-display mt-lg-5" svgIcon="placeholder_user" *ngIf="!agent.path"></mat-icon>
            <img *ngIf="agent.path" [src]="agent.path" class="icon-display mt-lg-5" />
          </div>

          <div class="col-1 mt-4">
            <p>{{agent.agentName}}</p>
            <p>{{agent.agentPhoneNumber}}</p>
          </div>
          <div class="col">
          </div>
          <div class="col">

            <p>{{'label.evaluation'|translate}} </p>

            <img  [src]="rating1[i]" />
            <img  [src]="rating2[i]" />
            <img  [src]="rating3[i]" />
            <img  [src]="rating4[i]" />
            <img  [src]="rating5[i]" />
          </div>
          <div class="col-4">
            <div class="mt-4">
              <!-- <p class="btn primary-color" *ngIf="!agent.agentRated" (click)="rateAgent(agent)" >Rate Agent</p> -->
              
              <button mat-button color="primary" class="button-center"
                      style="text-align: center;text-align: center;font-size: 14px;border-radius: 10px; color: white;"
                      *ngIf="!agent.agentRated" (click)="rateAgent(agent)" >
                {{'btn.rate_agent'|translate}}
              </button>

            </div>
          </div>

        </div>

      </div>


    </div>


    <div *ngIf="accountTransactions && accountTransactions.length > 0">

      <h2 class="mt-lg-5">{{'client.transactions'|translate}}</h2>
    </div>
    <div *ngIf="accountTransactions && accountTransactions.length > 0">

      <mat-card>
        <div class="row" style=" color:#000;" *ngFor="let transaction of accountTransactions">
          <div class="row">
            <div class="col">
                <span>
         <mat-icon svgIcon="icons_job">
         </mat-icon>
                  {{title(transaction.reference)}}
                </span>
            </div>
            <div class="col">
              <p
                [class]="isSuccess(transaction.transactionState) ? 'green-color': 'red-color'">{{transaction.transactionState}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>{{transaction.transactionType}}-{{transaction.reference}}</p>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <p>{{transaction.dateCreated| date:'YYYY-MM-dd HH:mm:ss'}}</p>
            </div>

            <div class="col-2">
              <p>{{amount(transaction.amount) | currency: 'EUR'}}</p>
            </div>

          </div>

        </div>
      </mat-card>
    </div>




  </mat-card>
</div>
