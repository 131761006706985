<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

    <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
      <!-- loop over the cardList and show the cards -->
  
      <button mat-raised-button class="labelBlack" (click)="addAgency()" >{{'btn.add'|translate}}</button>
  
    </div>
    <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
      <div  fxFlex="100"
            fxFlex.md="100"
            fxFlex.sm="100"
            fxFlex.xs="100"
            fxLayout="column"
            style="padding: 5px;">
        <mat-form-field appearance="standard" class="header-search">
          <mat-label>{{'label.search'|translate}}</mat-label>
          <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
  
        <table mat-table [dataSource]="dataSource"  >
  
  
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
  
          <!-- Position Column -->
          <ng-container matColumnDef="agencyName">
            <th mat-header-cell *matHeaderCellDef> {{'label.agencyName'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div style="text-align: justify;">
                <p style="font-weight: bold">{{element.agencyName}}</p>
                 </div>
            </td>
          </ng-container>
  
          <!-- Name Column -->
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> {{'label.phoneNumber'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div >
                <p style="font-weight: bold">{{element.phoneNumber}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="emailAddress">
            <th mat-header-cell *matHeaderCellDef> {{'label.emailAddress'|translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div >
                <p style="font-weight: bold">{{element.emailAddress}}</p>
              </div>
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="vatNumber">
            <th mat-header-cell *matHeaderCellDef> {{'label.vatNumber'|translate}} </th>
            <td mat-cell *matCellDef="let element">  {{element.vatNumber}} </td>
          </ng-container>
          
          <!-- Weight Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'label.status'|translate}} </th>
            <td mat-cell *matCellDef="let element">  
               <a *ngIf="element.enabled===true"   class="statusLabel labelSuccess"  >
                {{'label.active'|translate}}
              </a>
              <a *ngIf="element.enabled===false"   class="statusLabel labelDanger"  >
                {{'label.inactive'|translate}}
              </a>
  
            </td>
          </ng-container>
  
          <!-- Symbol Column -->
          <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openAgencyViewDetails(element)">{{'btn.view'|translate}}</button> </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  
  
        </table>
        <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes"></mat-paginator>
  
      </div>
    </div>
  </div>
  