import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Countries} from "../../model/Countries";

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.css']
})
export class AddressDialogComponent implements OnInit {
countries:Countries[]=[];
  form: FormGroup;
  constructor(public dialogRef: MatDialogRef<AddressDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.countries=this.data.countries;
    this.form = this.fb.group({
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),

    });


    this.form.get('address')?.setValue(this.data.address);
    this.form.get('city')?.setValue(this.data.city);
    this.form.get('zipCode')?.setValue(this.data.zipCode);
    this.form.get('country')?.setValue(this.data.country);
  }

  okDialog() {


    // @ts-ignore
    let address = this.form.get('address').value;
    // @ts-ignore
    let city = this.form.get('city').value;
    // @ts-ignore
    let zipCode = this.form.get('zipCode').value;
    // @ts-ignore
    let country = this.form.get('country').value;

    this.dialogRef.close({
      confirmation: 'DONE',
      address: address,
      city: city,
      zipCode: zipCode,
      country:country
    });
  }

}
