<div class="mt-lg-3">
  <app-book-agent-header></app-book-agent-header>

  <div class="col mt-5 ">
    <mat-card style="text-align: center; height: auto;margin-top: 20px;padding: 20px">


      <div class="row justify-content-evenly mt-lg-5">
        <div class="col" (click)="instantBooking()">
          <mat-icon class="icon-display" svgIcon="instant_booking"></mat-icon>

          <p style="color: darkgrey;font-size: 16px;padding: 0px;font-weight: bold;"
             class="mt-lg-5">{{'client.instant_booking'|translate}}</p>

        </div>

        <div class="col" (click)="plannedBooking()">
          <mat-icon class="icon-display" svgIcon="planned_booking"></mat-icon>
          <p style="color: darkgrey;font-size: 16px;padding: 0px;font-weight: bold;"
             class="mt-5">{{'client.planned_booking'|translate}}</p>

        </div>
      </div>
      <mat-divider
        style="color: gray;width: 100%;text-align: center;margin-top: 20px;"></mat-divider>
      <div class="col mt-5" *ngIf="missions && missions.length > 0">
        <p style="color: black;font-size: 16px;padding: 0px;font-weight: bold;" class="mt-5">{{'client.upcoming_missions'|translate}}</p>

      </div>
      <div class="col mt-5" *ngIf="missions && missions.length > 0">
        <div *ngFor="let mission of missions" class="row">
<!--          <div class="col">-->
<!--            <p-->
<!--              style="color: black;font-size: 16px;padding: 0px;font-weight: bold;">{{mission.missionStartDate | date:'dd/MM/yyyy'}}</p>-->
<!--          </div>-->
          <div class="col">
            <p style="color: black;font-size: 16px">{{mission.missionDescription}}</p>
          </div>
          <div class="col">
            <p
              style="color: black;font-size: 16px;padding: 0px;font-weight: bold;">{{mission.missionStartDate | date:'dd-MM-yyyy HH:mm'}}
              to {{mission.missionEndDate | date:'dd-MM-yyyy HH:mm'}}</p>
          </div>
        </div>
      </div>

      <div class="col mt-5" *ngIf="missions && missions.length<=0">
        <div class="col">
          <mat-icon svgIcon="icons_vacant" inline="true"></mat-icon>
        </div>
        <div class="col">
          <p style="color: black;font-size: 16px">
            {{'client.no_upcoming_missions_at_the_moment' | translate}}
          </p>
        </div>
        <div class="col">
          <div style="text-align: center;margin-top: 30px">
            <button mat-button color="primary" class="button-center"
                    style="text-align: center;width: 30%;text-align:
                      center;font-size: 14px;border-radius: 10px" (click)="instantBooking()">
              {{'client.start_booking_agents'|translate}}
            </button>
          </div>
        </div>
      </div>
    </mat-card>


  </div>
</div>
