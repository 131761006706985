import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";

import {CanActivateGuard} from "../service/can-activate.guard";
import {ClientDashboardComponent} from "./client-dashboard/client-dashboard.component";
import {BookAgentDashboardComponent} from "./book-agent-dashboard/book-agent-dashboard.component";
import {BookAgentPlannedComponent} from "./book-agent-planned/book-agent-planned.component";
import {CheckOutComponent} from "./subscription/check-out/check-out.component";
import {GatewayPaymentComponent} from "./subscription/gateway-payment/gateway-payment.component";
import {PaymentStatusComponent} from "./payment-status/payment-status.component";
import {OngoingMissionsComponent} from "./ongoing-missions/ongoing-missions.component";
import {OngoingMissionComponent} from "./ongoing-mission/ongoing-mission.component";
import {UpcomingMissionsComponent} from "./upcoming-missions/upcoming-missions.component";
import {BookAgentInstantComponent} from "./book-agent-instant/book-agent-instant.component";
import {RegistrationComponent} from "./authentication/registration/registration.component";
import {LandingAComponent} from "../common/landing-a/landing-a.component";
import {SignInComponent} from "./authentication/sign-in/sign-in.component";
import {MissionInProgressComponent} from "./mission-in-progress/mission-in-progress.component";
import {AgentRatingComponent} from "./agent-rating/agent-rating.component";
import {CanActivateAuthGuard} from "./service/can-activate-auth-guard";
import {PastMissionsComponent} from "./past-missions/past-missions.component";
import {PastMissionViewComponent} from "./past-mission-view/past-mission-view.component";
import {TarrifsComponent} from "./tarrifs/tarrifs.component";
import {WaitingMissionsComponent} from "./waiting-missions/waiting-missions.component";
import {FaqClientComponent} from "../common/faq-client/faq-client.component";

const routes: Routes = [
  {
    path: 'client', component: ClientDashboardComponent, canActivate: [CanActivateAuthGuard],
    children: [
      {path: '', component: BookAgentDashboardComponent},
      {path: 'booking/planned', component: BookAgentPlannedComponent},
      {path: 'booking/instant', component: BookAgentInstantComponent},
      {path: 'booking/checkout', component: CheckOutComponent},
      {path: 'gateway-payment', component: GatewayPaymentComponent},
      {path: 'payment/status', component: PaymentStatusComponent},
      {path: 'ongoing-missions', component: OngoingMissionsComponent},
      {path: 'upcoming-missions', component: UpcomingMissionsComponent},
      {path: 'ongoing-mission/:missionId', component: OngoingMissionComponent},
      {path: 'mission-in-progress/:missionId', component: MissionInProgressComponent},
      {path: 'past-mission/:missionId', component: PastMissionViewComponent},
      {path: 'mission/:missionId/rate', component: AgentRatingComponent},
      {path: 'notification/:notificationData', component: BookAgentDashboardComponent},
      {path: 'past-missions', component: PastMissionsComponent},
      {path: 'booking/instant/:agent', component: BookAgentInstantComponent},
      {path: 'booking/planned/:agent', component: BookAgentPlannedComponent},
      {path: 'tariffs', component: TarrifsComponent},
      {path: 'waiting-missions', component: WaitingMissionsComponent},
      {path:'sub-registration',component:RegistrationComponent}

    ],

  },
  {path:'client-registration',component:RegistrationComponent},
  {path:'client/oauth2/login',component:SignInComponent},
  {path:'help',component:FaqClientComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule {

}
