import {Component, OnInit, ViewChild} from '@angular/core';
import {BookedMissionDatasource} from "../../../service/datasource/booked-mission-datasource";
import {MatPaginator} from "@angular/material/paginator";
import {MissionService} from "../../../service/mission.service";
import {MatDialog} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {ActiveAgentsDatasource} from "../../../service/datasource/active-agents-datasource";
import {UsersService} from "../../../service/users.service";
import {appConstants} from "../../../model";
import {AgentviewdetailsComponent} from "../agentviewdetails/agentviewdetails.component";

@Component({
  selector: 'app-activeagents',
  templateUrl: './activeagents.component.html',
  styleUrls: ['./activeagents.component.css']
})
export class ActiveagentsComponent implements OnInit {
  searchValue="";

  resultsLength = 0;
  // @ts-ignore
  activeAgentsDatasource: ActiveAgentsDatasource ;

  columnsToDisplay = ['checkbox','missionInfo','viewmore'];
  private paginator: MatPaginator | undefined;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }


  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];



  constructor(private userService:UsersService, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.activeAgentsDatasource = new ActiveAgentsDatasource(this.userService);
    this.activeAgentsDatasource?.loadAgents();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.activeAgentsDatasource.loadFiltered(filterValue);
  }


  ngAfterViewInit() {
    this.activeAgentsDatasource?.counter$
      .pipe(
        tap((count) => {
          // @ts-ignore
          this.paginator.length = count;
        })
      )
      .subscribe();

    // @ts-ignore
    this.paginator.page
      .pipe(
        tap(() => this.loadAgents())
      )
      .subscribe();
  }

  loadAgents() {
    // @ts-ignore
    this.activeAgentsDatasource?.loadAgents(this.paginator.pageIndex, this.paginator.pageSize,'true');
  }

  openViewAgentInfo(data:any): void {
    const dataToSend={agentInfo:data,type:appConstants.viewAgentPlainView}
    const dialogRef = this.dialog.open(AgentviewdetailsComponent
      , {
        width: '65%',
        height: '80%',
        data:dataToSend
        // backdropClass: 'bg-color' // This is the "wanted" line
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadAgents()
      });
  }
 


}
