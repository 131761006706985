<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <!-- loop over the cardList and show the cards -->

  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div  fxFlex="50"
          fxFlex.md="50"
          fxFlex.sm="50"
          fxFlex.xs="50"
          fxLayout="column"
          style="padding: 5px;">
      <mat-form-field appearance="standard" class="header-search">

        <mat-label>{{'nbs.businessownermanager.manageagents.search'|translate}}</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
      </mat-form-field>

    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div  fxFlex="100"
          fxFlex.md="100"
          fxFlex.sm="100"
          fxFlex.xs="100"
          fxLayout="column"
          style="padding: 5px;">

      <table mat-table [dataSource]="activeAgentsDatasource"  >

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox style="left: 16px" ></mat-checkbox></th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: justify;">
              <mat-checkbox  ></mat-checkbox>
            </div>
          </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="missionInfo">
          <th mat-header-cell *matHeaderCellDef>
            <div style="text-align: center">
              <ul>
                <li><div> <mat-icon svgIcon="download" style="margin-right: 5px"></mat-icon>Download</div> </li>
                <li><div [matMenuTriggerFor]="sharemenu"> <mat-icon   svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Share
                  <mat-menu #sharemenu="matMenu">
                    <button mat-menu-item> <mat-icon svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Gmail</button>
                    <button mat-menu-item><mat-icon svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Whatsapp</button>
                    <button mat-menu-item><mat-icon svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Linkedin</button>
                    <button mat-menu-item><mat-icon svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Twitter</button>
                    <button mat-menu-item><mat-icon svgIcon="ellipse" style="margin-right: 5px"></mat-icon>Facebook</button>
                  </mat-menu>
                </div></li>
                <li><div><mat-icon svgIcon="print" style="margin-right: 5px"></mat-icon>Print</div></li>
              </ul>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: left;">
              <ul>
                <li>{{element.gender}} </li>
                <li [ngStyle]="{'color':element.userProfileStatus === 'ACTIVE' ? 'green' : 'red'}">{{element.userProfileStatus}} </li>
                <li>{{element.createdDate | date: 'dd/MM/yyyy HH:mm'}} </li>
                <li *ngIf="element.agentStatus==1" style="color: green"> ONLINE</li>
                <li *ngIf="element.agentStatus!=1" style="color: red"> OFFLINE</li>
              </ul>
            </div>

            <div style="text-align: justify;">
              <p style="font-weight: bold"> {{element.firstName}} {{element.lastName}}-  {{element.streetAddress}} {{element.city}} {{element.addressZipCode}}</p>
            </div>

          </td>
        </ng-container>
        <ng-container matColumnDef="viewmore">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox style="left: 16px" ></mat-checkbox></th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p style="text-align: left">
                <button mat-raised-button style="background-color: var(--medium-color)" (click)="openViewAgentInfo(element)">{{'nbs.businessownermanager.manageagents.disabledAgents.viewMore'|translate}}</button>
              </p>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>


      </table>
      <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes"></mat-paginator>

    </div>
  </div>
</div>
