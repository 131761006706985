import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule} from '@angular/material/button';
import { MatMenuModule} from '@angular/material/menu';
import { LandingAComponent } from './common/landing-a/landing-a.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './common/login/login.component';
import { FormsModule ,ReactiveFormsModule }   from '@angular/forms';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BusinessownermanagerRoutingModule} from "./businessownermanager/businessownermanager-routing.module";
import  {MatTooltipModule} from "@angular/material/tooltip";
import { ToastrModule } from 'ngx-toastr';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ClientRoutingModule} from "./client/client-routing.module";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MessagingService} from "./service/messaging.service";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {AgmCoreModule} from "@agm/core";
import {ClientModule} from "./client/client.module";
import {metaReducers, reducers} from "./store";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import { NgxSpinnerModule } from 'ngx-spinner';
import {HttpClientInterceptor} from "./service/htppInterceptor";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCardModule} from "@angular/material/card";
import { HomeLandingComponent } from './common/home-landing/home-landing.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ForgetPasswordComponent } from './common/forget-password/forget-password.component';
import { ChangePasswordComponent } from './common/change-password/change-password.component';
import { FaqClientComponent } from './common/faq-client/faq-client.component';
import {MatAccordion, MatExpansionModule} from "@angular/material/expansion";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatPaginatorModule} from "@angular/material/paginator";

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};
const modules = [
  NgxMaterialTimepickerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSliderModule,
  MatDividerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  FlexLayoutModule,
  FormsModule,
  MatSidenavModule,
  MatSelectModule,
  MatTooltipModule,
  ReactiveFormsModule, IntlTelInputNgModule.forRoot(),
  NgxIntlTelInputModule,
  MatProgressBarModule,
  MatSlideToggleModule
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingAComponent,
    LoginComponent,
    HomeLandingComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    FaqClientComponent
  ],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.maps_api_key,
            libraries: ['places', 'drawing', 'geometry']
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        BusinessownermanagerRoutingModule,
        ClientRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot(),
        modules,
        MatCardModule,
        MatIconModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ClientModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        NgxSpinnerModule,
        MatExpansionModule,
        MatToolbarModule,
        MatPaginatorModule,
    ],
  exports: [modules, HeaderComponent, FooterComponent],
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: appearance
  },AsyncPipe,MessagingService,
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: { useUtc: true }},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AppModule {
  constructor(   private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer){
    iconRegistry
      .addSvgIcon('app-store',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/app-store.svg'))
      .addSvgIcon('play-store',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/play-store.svg'))
      .addSvgIcon('welcome',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/welcome_svg'))
      .addSvgIcon('bisinessownermanager',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/businessownermanager.svg'))
      .addSvgIcon('clientmanager',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/clientmanager.svg'))
      .addSvgIcon('employmentagencymanager',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/employmentagencymanager.svg'))
      .addSvgIcon('logo_transparent',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/logo_transparentbg.svg'))
      .addSvgIcon('partnermanager',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/partnermanager.svg'))
      .addSvgIcon('arrow-right',this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/right_arrow.svg'))
      .addSvgIcon("settings",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Group18727.svg'))
      .addSvgIcon("manageemployeeagency",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Group18730.svg'))
      .addSvgIcon("managepartners",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Group18733.svg'))
      .addSvgIcon("manageclients",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Group18732.svg'))
      .addSvgIcon("manageagents",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Group18760.svg'))
      .addSvgIcon("reporting",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/reports.svg'))
      .addSvgIcon("download",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/download.svg'))
      .addSvgIcon("print",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/print.svg'))
      .addSvgIcon("ellipse",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/Ellipse.svg'))
      .addSvgIcon("sort-top",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/sort_top.svg'))
      .addSvgIcon("sort-top_muted",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/sort_top_muted.svg'))
      .addSvgIcon("sort-down",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/sort_down.svg'))
      .addSvgIcon("sort-down_muted",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/sort_down_muted.svg'))
      .addSvgIcon("active_star",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_active_star.svg'))
      .addSvgIcon("instant_booking",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_instant_booking.svg'))
      .addSvgIcon("planned_booking",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_planned_booking.svg'))
      .addSvgIcon("menu_drop_down",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_menu_dropdown.svg'))
      .addSvgIcon("muted_star",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_muted_star.svg'))
      .addSvgIcon("placeholder_user",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_placeholder_user.svg'))
      .addSvgIcon("search_button",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/icons_search_button.svg'))
      .addSvgIcon("edit_pen",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/svg/edit_pen.svg'))
      .addSvgIcon("arrow_back",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/arrow_back.svg'))
      .addSvgIcon("icon_information",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icon_information.svg'))
      .addSvgIcon("icons_active",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_active.svg'))
      .addSvgIcon("icons_agent_booking",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_agent_booking.svg'))
      .addSvgIcon("icons_agree",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_agree.svg'))
      .addSvgIcon("icons_app_store",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_app_store.svg'))
      .addSvgIcon("icons_approved",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_approved.svg'))
      .addSvgIcon("icons_arrow_bottom",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_arrow_bottom.svg'))
      .addSvgIcon("icons_arrow_top",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_arrow_top.svg'))
      .addSvgIcon("icons_ascending_active",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_ascending_active.svg'))
      .addSvgIcon("icons_ascending_mute",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_ascending_mute.svg'))
      .addSvgIcon("icons_book_agent",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_book_agent.svg'))
      .addSvgIcon("icons_cancelled",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_cancelled.svg'))
      .addSvgIcon("icons_collaborate",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_collaborate.svg'))
      .addSvgIcon("icons_company",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_company.svg'))
      .addSvgIcon("icons_deleted",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_deleted.svg'))
      .addSvgIcon("icons_descending_active",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_descending_active.svg'))
      .addSvgIcon("icons_descending_mute",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_descending_mute.svg'))
      .addSvgIcon("icons_down",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_down.svg'))
      .addSvgIcon("icons_down-1",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_down-1.svg'))
      .addSvgIcon("icons_down_carret",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_down_carret.svg'))
      .addSvgIcon("icons_edit",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_edit.svg'))
      .addSvgIcon("icons_exit",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_exit.svg'))
      .addSvgIcon("icons_extension",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_extension.svg'))
      .addSvgIcon("icons_facebook",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_facebook.svg'))
      .addSvgIcon("icons_favourite_highlighted",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_favourite_highlighted.svg'))
      .addSvgIcon("icons_favourite_mute",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_favourite_mute.svg'))
      .addSvgIcon("icons_home",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_home.svg'))
      .addSvgIcon("icons_image",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_image.svg'))
      .addSvgIcon("icons_instant_booking",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_instant_booking.svg'))
      .addSvgIcon("icons_issue_with_agent",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_issue_with_agent.svg'))
      .addSvgIcon("icons_job",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_job.svg'))
      .addSvgIcon("icons_job-1",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_job-1.svg'))
      .addSvgIcon("icons_left_arrow",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_left_arrow.svg'))
      .addSvgIcon("icons_linkedin",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_linkedin.svg'))
      .addSvgIcon("icons_list_active",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_list_active.svg'))
      .addSvgIcon("icons_list_mute",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_list_mute.svg'))
      .addSvgIcon("icons_location",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_location.svg'))
      .addSvgIcon("icons_location-1",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_location-1.svg'))
      .addSvgIcon("icons_manager",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_manager.svg'))
      .addSvgIcon("icons_more_jobs",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_more_jobs.svg'))
      .addSvgIcon("icons_NBS",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_NBS.svg'))
      .addSvgIcon("icons_NBS_back",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_NBS_back.svg'))
      .addSvgIcon("icons_notifications",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_notifications.svg'))
      .addSvgIcon("icons_ongoing_missions",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_ongoing_missions.svg'))
      .addSvgIcon("icons_option",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_option.svg'))
      .addSvgIcon("icons_partner_with_us",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_partner_with_us.svg'))
      .addSvgIcon("icons_pattern",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_pattern.svg'))
      .addSvgIcon("icons_pattern_border",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_pattern_border.svg'))
      .addSvgIcon("icons_pending",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_pending.svg'))
      .addSvgIcon("icons_planned_booking",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_planned_booking.svg'))
      .addSvgIcon("icons_plus_sign",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_plus_sign.svg'))
      .addSvgIcon("icons_profile_place_holder",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_profile_place_holder.svg'))
      .addSvgIcon("icons_random",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_random.svg'))
      .addSvgIcon("icons_reschedule",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_reschedule.svg'))
      .addSvgIcon("icons_right_arrow",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_right_arrow.svg'))
      .addSvgIcon("icons_right_arrow_white",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_right_arrow_white.svg'))
      .addSvgIcon("icons_search",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_search.svg'))
      .addSvgIcon("icons_supporter",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_supporter.svg'))
      .addSvgIcon("icons_tariffs",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_tariffs.svg'))
      .addSvgIcon("icons_time",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_time.svg'))
      .addSvgIcon("icons_topup",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_topup.svg'))
      .addSvgIcon("icons_topup-1",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_topup-1.svg'))
      .addSvgIcon("icons_twitter",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_twitter.svg'))
      .addSvgIcon("icons_vacant",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_vacant.svg'))
      .addSvgIcon("icons_warning",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_warning.svg'))
      .addSvgIcon("icons_white_arrow",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_white_arrow.svg'))
      .addSvgIcon("icons_youtube",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_youtube.svg'))
      .addSvgIcon("icons_transaction_history",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_transaction_history.svg'))
      .addSvgIcon("past_missions",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/past_missions.svg'))
      .addSvgIcon("add_subaccount",this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/icons_plus_sign.svg'))

    ;
  }
 }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
