import {Component, OnInit} from '@angular/core';
import {Mission} from "../../model";
import {MissionAgent} from "../model/mission-agent";
import {ActivatedRoute} from "@angular/router";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {MessagingService} from "../../service/messaging.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import * as moment from 'moment';
import {CountdownEvent} from "ngx-countdown";
import {format} from "path";
import {MessageDialog} from "../model/message-dialog";
import {MessageDialogComponent} from "../dialogs/message-dialog/message-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {interval, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {UserProfile} from "../model/user-profile";
import {getAgent} from "../../store";
import {FileService} from "../service/file.service";

@Component({
  selector: 'app-mission-in-progress',
  templateUrl: './mission-in-progress.component.html',
  styleUrls: ['./mission-in-progress.component.css']
})
export class MissionInProgressComponent implements OnInit {

  agents: MissionAgent[] = [];
  missionId: string;
  timeLeft: number = 0;
  notify = '';
  mySubscription: Subscription
  missionInProgress = false;
  agent: UserProfile;
  agentInProgress: MissionAgent;
  pathBase64: string;
  constructor(private route: ActivatedRoute,
              private missionService: MissionService,
              private clientUserService: ClientUserService,
              private messagingService: MessagingService,
              private _bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private datePipe: DatePipe,
              private store: Store,
              private fileService: FileService,
  ) {
    this.route.params.subscribe(params => {
      this.missionId = params['missionId'];

    });
  }

  ngOnInit(): void {
    this.store.select(getAgent).subscribe(async (value) => {
      if (value) {
        this.agent = value;
        this.getAgentMissionByMissionId();
        const pathBase64 = await this.getProfile(this.agent.agentPhotoPath);
        if (pathBase64 && pathBase64.length > 10) {
          this.pathBase64 = pathBase64;
        }
      }
    })

  }


  getAgentMissionByMissionId(): void {


    this.missionService.agentGetMission(this.missionId, this.agent.userPhoneNumber)
      .subscribe(response => {
        if (response.result === 'OK') {
          this.agentInProgress = <MissionAgent>response.data;


          // 2021-08-22T15:00:39
          const currentDateTime = moment(new Date());
          const date1 = moment(this.agentInProgress.agentStartTime);
          const date2 = moment(this.agentInProgress.agentEndTime);
          const diff = date2.diff(currentDateTime, 'seconds');


          if (currentDateTime.isAfter(date2)) {

            if (this.agentInProgress.missionStatus === 'COMPLETED') {
              this.messageDialog('Completed', 'Mission completed.')

            } else {
              this.messageDialog('Time Passed', 'Time has passed.')
            }
          } else if (currentDateTime.isAfter(date1) && currentDateTime.isBefore(date2)) {
            this.timeLeft = diff;
            this.missionInProgress = true;
            if (this.mySubscription) {
              this.mySubscription.unsubscribe();
            }
          } else {

            this.mySubscription = interval(15000).subscribe((x => {
              this.getAgentMissionByMissionId();
            }));
            // this.messageDialog('Error', 'Mission not yet started.')
          }

          this.getMissionAgents();

        }

      });


  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(this.missionId).subscribe((response) => {

      if (response.result === "OK") {

        this.agents = <MissionAgent[]>response.data;

      }

    });
  }


  cancelMission() {

  }

  handleEvent(e: CountdownEvent) {
    this.notify = e.action.toUpperCase();
    if (e.action === 'notify') {
      this.notify += ` - ${e.left} ms`;
    }
  }

  messageDialog(title: string, message: string) {
    const messagesDialog = new MessageDialog()
    messagesDialog.title = title;
    messagesDialog.description = message;
    messagesDialog.error = true;
    messagesDialog.confirmation = false;
    const dialogRef = this.dialog.open(MessageDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: messagesDialog,
        disableClose: true
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      return;
    });
  }


  getProfile(path: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.fileService.getFile(path).subscribe(value => {

        if (value) {

          resolve(`data:image/png;base64,${value.base64}`);

        }else{
          resolve("");
        }
      },error =>   resolve(""));


    });

  }
}
