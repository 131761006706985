<div class="h-100 mt-lg-3">
  <mat-card style=" height: auto" *ngIf="agent">

    <div style="background-color: white; color: black;text-align: left" class="h-50 w-100">
      <p class="p-3 align-content-md-end" style="height: auto;margin: 0px;">{{'client.mission_in_progress'|translate}}</p>

    </div>

    <div class="c-align" style="text-align: center;color: green; font-weight: bold;font-size: 44px">
      <countdown #cd [config]="{ leftTime: timeLeft, format: 'HH:mm:ss' }" (event)="handleEvent($event)"></countdown>

<!--      <div class="mt-lg-3">-->
<!--        <button (click)="cd.pause()" class="btn btn-sm">Pause</button>-->
<!--        <button (click)="cd.resume()" class="btn btn-sm">Play</button>-->
<!--      </div>-->

    </div>
    <div class="c-align mt-lg-5" style="text-align: center">

      <div class="col c-align" style="color: black">

        <h1>{{'client.assigned_agents'|translate}} ({{agents ? agents.length : 0}})</h1>

        <div class="row" *ngIf="agent">

          <div class="col">
          </div>
          <div class="col-1">
            <mat-icon class="icon-display mt-lg-5" svgIcon="placeholder_user" *ngIf="!pathBase64"></mat-icon>
            <img *ngIf="pathBase64" [src]="pathBase64" class="icon-display mt-lg-5" />

          </div>
          <div class="col-1 mt-4">
            <p>{{agent.firstName}} {{agent.lastName}}</p>
            <p>{{agent.userPhoneNumber}}</p>
          </div>
          <div class="col">
          </div>

        </div>

      </div>

      <div class="col mt-lg-5" style="color: white">

<!--        <div style="text-align: center;margin-top: 30px">-->
<!--          <button mat-button color="primary" class=" button-center"-->
<!--                  style="text-align: center;width: auto;text-align:-->
<!--                      left;font-size: 14px;border-radius: 10px" (click)="postponeMission()">-->
<!--            Extend mission-->
<!--          </button>-->
<!--        </div>-->

        <button mat-button class="button-center" style="text-align: center;width: auto;text-align:
      center;font-size: 16px;background-color: white;color: black;margin-top: 20px" (click)="cancelMission()">
          {{'client.cancel_mission'|translate}}
        </button>
      </div>
    </div>

  </mat-card>
</div>
