<div class="my-3">
  <mat-card style=" height: auto">

    <form [formGroup]="form">
    <div class="row mt-lg-5">
      <agm-map
        [zoom]="5"
        [latitude]="lat"
        [longitude]="lng"
        (mapReady)="onMapReady($event)"
      >
      </agm-map>
    </div>
    <div class="row mt-lg-5">


      <div class="col">

                <mat-form-field appearance="outline">
                  <mat-label>{{'client.start_time'|translate}}</mat-label>
                  <input matInput placeholder="{{'client.start_time'|translate}}"  formControlName="startTime" [ngxTimepicker]="min"[attr.min]="minTime" [format]="24"  readonly>
                  <ngx-material-timepicker #min [enableKeyboardInput]="true"></ngx-material-timepicker>
                </mat-form-field>

<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>{{'client.start_time'|translate}}</mat-label>-->
<!--          <input matInput placeholder="{{'client.start_time'|translate}}"  formControlName="startTime" (click)="startTimePicker()">-->
<!--        </mat-form-field>-->

      </div>


      <div class="col">

<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>{{'client.end_time'|translate}}</mat-label>-->
<!--          <input matInput placeholder="{{'client.end_time'|translate}}"   formControlName="endTime" (click)="endTimePicker()">-->
<!--        </mat-form-field>-->
        <mat-form-field>
          <mat-label>{{'client.end_time'|translate}}</mat-label>
          <input matInput
                 required
                 formControlName = "endTime"
                 type="datetime-local"
                 placeholder="End Date"

          >
          <mat-datepicker></mat-datepicker>
        </mat-form-field>

      </div>
      <div class="col">

      </div>
    </div>
    <div class="row mt-lg-5">


      <div class="col">

        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.number_of_agents'|translate}}</mat-label>
          <mat-select id="numberOfAgents"
                      name="numberOfAgents"
                      formControlName="numberOfAgents"
                      [disabled]="isAgent"

          >
            <mat-option *ngFor="let agentValue of numberOfAgents" [value]="agentValue"
                        style="color:  black" (click)="selectedNumberOfAgents(agentValue)">{{agentValue}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">

        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.gender'|translate}}</mat-label>
          <mat-select id="sex"
                      name="sex"
                      formControlName="sex"
                      [disabled]="isAgent"

          >
            <mat-option *ngFor="let val of genders" [value]="val"
                        style="color:  black" (click)="selectedGender(val)">{{val}}
            </mat-option>


          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'client.dress_code'|translate}}</mat-label>
          <mat-select id="dressCode"
                      name="dressCode"
                      formControlName="dressCode"

          >
            <mat-option *ngFor="let val of dressCodes" [value]="val"
                        style="color:  black">{{val.name}}
            </mat-option>


          </mat-select>
        </mat-form-field>

      </div>
    </div>




      <div class="row mt-lg-5">



        <div class="col">
          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'client.language'|translate}}</mat-label>
            <mat-select id="language"
                        name="language"
                        formControlName="language"

                        multiple>
              <mat-option *ngFor="let val of languages" [value]="val"
                          style="color:  black">{{val}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">

          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.numberOfFemaleAgents'|translate}}</mat-label>
            <mat-select id="femaleRequired"
                        name="femaleRequired"
                        formControlName="femaleRequired"
                        [disabled]="disableFemale || isAgent"

            >
              <mat-option *ngFor="let fR of femaleRequired" [value]="fR"
                          style="color:  black">{{fR}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">

          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.numberOfMaleAgents'|translate}}</mat-label>
            <mat-select id="maleRequired"
                        name="maleRequired"
                        formControlName="maleRequired"
                        [disabled]="disableMale || isAgent"

            >
              <mat-option *ngFor="let mR of femaleRequired" [value]="mR"
                          style="color:  black">{{mR}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>


      </div>
      <div class="row mt-lg-5">

        <div class="col">
          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}</mat-label>
            <mat-select id="street"
                        name="street"
                        formControlName="street"
 >
              <mat-option (onSelectionChange)="selectAddress(i,$event)"*ngFor="let val of userStreets ; index as i" [value]="val"
                          style="color:  black">{{val}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-checkbox [checked]="newAddress"
                        (change)="checkChange($event)">
            New Address
          </mat-checkbox>
        </div>
      </div>
      <div class="row mt-lg-5">


        <div class="col">

          <mat-form-field appearance="outline" class="w-75 h-25">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}</mat-label>
<!--            <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}" formControlName="address">-->
            <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}" [(ngModel)]="mainMissionStreet" formControlName="address"
                   >

          </mat-form-field>
        </div>

        <div class="col">

          <mat-form-field appearance="outline" class="w-75 h-25">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.city'|translate}}</mat-label>
            <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.city'|translate}}" [(ngModel)]="mainMissionCity" formControlName="city"
                 >

          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline" class="w-75 h-25">
            <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.zipCode'|translate}}</mat-label>
            <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.zipCode'|translate}}" [(ngModel)]="mainMissionZipCode" formControlName="zipCode"
                  >

          </mat-form-field>
        </div>
      </div>

    <div class="row mt-lg-5">

      <div class="col">

        <mat-form-field appearance="outline" class="w-75 h-25">
          <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.description'|translate}}</mat-label>
          <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.description'|translate}}" formControlName="missionDescription">

        </mat-form-field>
      </div>


    </div>

      <div style="text-align: right;margin-top: 30px">
        <button mat-button color="primary" class="button-center"
                style="text-align: center;width: 30%;text-align:
                      center;font-size: 14px;border-radius: 10px" (click)="onSubmit()"
                [ngClass]="form.invalid?'button-grey-center':'button-center'"
                [disabled]="form.invalid || loading">
          {{'client.continue_btn'|translate}}
        </button>
      </div>
    </form>
  </mat-card>
</div>
