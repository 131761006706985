import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {getAuthProfileUser} from "../../store";
import {AuthProfileUser} from "../model/auth-profile-user";
import {Token} from "../../model";


@Injectable({providedIn: 'root'})
export class CanActivateAuthGuard implements CanActivate {

  constructor(private router: Router, private store: Store) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const token = await this.isClientLoggedIn();
    if (token) {
      // logged in so return true
      return true;
    }

    this.router.navigate(['client/oauth2/login']).catch(reason => reason.log(reason));
    return false;
  }

  isClientLoggedIn(): Promise<Token> {


    return new Promise((resolve, reject) => {
      this.store.select(getAuthProfileUser).subscribe((value: AuthProfileUser) => {
        if (value && value.token) {

          resolve(value.token)
        }
        else{
          // @ts-ignore
          resolve(null);
        }
      })

    });
  }

}
