import {
  AuthProfileUserStoreActions,
  CREATE_AuthProfileUser,
  DELETE_AuthProfileUser,
  GET_AuthProfileUser
} from "./auth-profile-user-store.actions";
import {AuthProfileUser} from "../../client/model/auth-profile-user";


export const initialState: AuthProfileUser = new AuthProfileUser();


export function AuthProfileUserStoreReducer(state = initialState, action: AuthProfileUserStoreActions): any {
  switch (action.type) {
    case CREATE_AuthProfileUser:

      const object: AuthProfileUser = action.payload;
      return {
        authProfileUser: object
      };

    case GET_AuthProfileUser:

      return {
        ...state
      };
    case DELETE_AuthProfileUser:

      return {
        authProfileUser: null
      };
    default:
      return state;
  }
}

export const getAuthProfileUser = (state: any) => {
  return state.authProfileUser;
};
