import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor( private datePipe: DatePipe,
               private translate: TranslateService) {

  }
  getTranslatedMsg(key:string): string{

    return this.translate.instant(key)
      ;
  }

  formatAmount(amount: any): string{

    return  new Intl.NumberFormat('en-US',  {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  }
  toDecimal(amount: any): string{

    if(!amount){
      return '0.00';
    }
    return  (Math.round(amount * 100) / 100).toFixed(2);
  }

  toUTCDate(date: Date): string {
    const format = this.datePipe.transform(date,'YYYY-MM-ddTHH:mm:ss');
    return `${format}.000Z`
  }

  getMissionPaymentRate(nbAgent: number): number {
    if (nbAgent < 5) {
      return 43.75;
    } else {
      return 42.00;
    }
}
// update array item if exist else insert new item
  arrayUpsert(array: any[], element:any, i:number) {
    if (i > -1) array[i] = element;
    else array.push(element);
  }


}
