import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {match} from 'minimatch';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountService} from '../../service/account.service';
import {Account} from '../../model/account';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import {UserStatus} from '../../model/user-status';
import {ClientUser} from "../../model/client-user";
import {AuthServerUser, CustomResponse, Token} from "../../../model";
import {StorageService} from "../../../service/storage.service";
import {NotificationClientService} from "../../../service/notification-client.service";
import {AuthenticationService} from "../../../service/authentication.service";
import {ErrorResponse} from "../../model/error-response";
import {UserProfile} from "../../model/user-profile";
import {ClientUserService} from "../../service/client-user.service";
import {AuthProfileUser} from "../../model/auth-profile-user";
import {CREATE_AuthProfileUser} from "../../../store/auth-profile-user-store/auth-profile-user-store.actions";
import { ForgetPasswordComponent } from 'src/app/common/forget-password/forget-password.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  E164PhoneNumber: any;
  phoneNumber: any;
  password: any;
  userExist: any;
  isTeamMember: any;
  ngForm: FormGroup;
  userRegistration: ClientUser;
  loggedInUser: AuthServerUser;
  currentAccount: Account;
  AllowedCountries: string[] =['be', 'fr',  'nl', 'de', 'es', 'lu', 'gb' ]
  constructor(private spinner: NgxSpinnerService,
              private snackBar: MatSnackBar,
              private storageService: StorageService,
              private notificationClientService: NotificationClientService,
              private accountService: AccountService,
              // private  appState: AppStateService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog,
              private clientUserService: ClientUserService,
              private authService: AuthenticationService) {
  }


  ngOnInit(): void {
    this.clearKeyKeys();
    this.ngForm = new FormGroup({
      password: new FormControl('', [
        Validators.minLength(5),
        Validators.required]),
    });


  }

  clearKeyKeys(): void {
    this.storageService.clear();
    // @ts-ignore
    this.injectProfile('client', null, null);

  }


  async login(): Promise<void> {
    console.log('login clicked');
    console.log('login clicked this.E164PhoneNumber is' + this.E164PhoneNumber);
    if (this.ngForm.valid && match) {

      if (this.E164PhoneNumber) {
        this.phoneNumber = this.E164PhoneNumber.substr(1);

        const status = await this.getCustomerStatus();

        this.password = this.ngForm.get('password')?.value;

        this.authService.loginWithUsernameAndPassword(this.phoneNumber, this.password).subscribe(async value => {


          this.storageService.saveObject(StorageService.USER_TOKEN, value);
          this.storageService.saveString(StorageService.USER_PHONE_NUMBER, this.phoneNumber);


          this.loggedInUser = await this.getLoggedInUser();

          if (this.loggedInUser && this.loggedInUser.groupId !== 200) {

            this.openSnackBar('You are not allowed to use this application. Please contact our support team', 'Close');

            return;
          }
          const customerResponse = await this.getUserProfile();


          if (customerResponse && customerResponse.result === 'OK') {
            const profile = <UserProfile>customerResponse.data;
            if (profile) {

              this.injectProfile('client', profile, this.loggedInUser, value);

              // todo emit login state
              // this.appState.logedInUser.emit(true);

              this.router.navigate(['client']);

            } else {

              this.router.navigate(['client-registration']);

            }
          } else {

            this.router.navigate(['client-registration']);

          }


        }, (error1: any) => {


          const error = error1.error as ErrorResponse;

          if (error.error === 'Service Unavailable') {

            this.openSnackBar('Service Unavailable, Please try again', 'Close');

          } else if (error.error_description) {

            this.openSnackBar(`Invalid username or paasword`, 'Close');

          } else {
            this.openSnackBar('An error occurred, please try again later', 'Close');
          }

        });

      }
    }
  }

  private injectProfile(client: string, profile: UserProfile, loggedInUser: AuthServerUser, token?: Token) {
    const authProfileUser = new AuthProfileUser();
    authProfileUser.authorizationUser = loggedInUser;
    authProfileUser.userProfile = profile;
    authProfileUser.profile = client;
    authProfileUser.token = token;

    this.store.dispatch({type: CREATE_AuthProfileUser, payload: authProfileUser});

  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  async getLoggedInUser(): Promise<AuthServerUser> {

    return new Promise((resolve, reject) => {

      this.authService.getLoggedInUser().subscribe(value => {

        resolve(value);
      }, error => {
        reject(null);
      });
    });
  }

// @ts-ignore
  private getUserProfile(): Promise<CustomResponse> {

    return new Promise((resolve, reject) => {

      this.clientUserService.getProfile(this.phoneNumber).subscribe((result: CustomResponse) => {

        if (result.result === "OK") {
          const profile = <UserProfile>result.data;
          this.storageService.saveObject(StorageService.CLIENT_AGENT_PROFILE, profile)
          this.storageService.saveString(StorageService.USER_CATEGORY, profile.userType)
          if (profile.userType === 'CLIENT') {
            resolve(result);
          } else {
            this.openSnackBar('AGENT is allowed to mobile application', 'Close');
            // @ts-ignore
            resolve(null);
          }

        } else {
          // @ts-ignore
          resolve(null);
        }

      }, error => {
        reject(null);
      })
    });


  }

  getCustomerStatus(): Promise<UserStatus> {


    const status = new UserStatus();
    const phoneNumber = this.phoneNumber;

    status.username = phoneNumber;
    return new Promise<UserStatus>((resolve, reject) => {

      this.authService.getUserStatus(status).subscribe(value => {

        if (value.registered === true) {

          resolve(value);
        } else if (value.lockedAccount === true) {
          this.openSnackBar('Your account was locked', 'Close');
          reject('Your account was locked');
        } else {
          this.openSnackBar('Sorry you are not registered, please create an account', 'Close');
          reject('Please compete registration');
        }

      }, error => {

        this.openSnackBar('Failed to get your details, please try again', 'Close');

        reject('Failed to get user details, please try again later');
      });


    });
  }


  createAccount() {
    this.router.navigate(['client-registration'])
  }

  forgetPassword(){

    const dialogRef = this.dialog.open(ForgetPasswordComponent
      , {
        width: '550px',
        backdropClass: 'bg-color', // This is the "wanted" line
      });

    dialogRef.afterClosed().subscribe(result => {
      //openChangePasswordDialog(phoneNumber)
    });

  }
}
