import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {AgentHistory, Mission} from "../../model";
import {BehaviorSubject, Observable, of} from "rxjs";
import {MissionService} from "../mission.service";
import {catchError, finalize} from "rxjs/operators";
import { UsersService } from "../users.service";

export class PartnersDatasource implements DataSource<any>{ 

  private partnersSubject = new BehaviorSubject<any[]>([]);
  private partnersData:any[]=[];
  private partnersDataUnfiltered:any[]=[];

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private usersService: UsersService) { }


  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.partnersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.partnersSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  loadPartners(pageNumber=0, pageSize =5) {
    this.loadingSubject.next(true);
    //this.usersService.getPartners()
    this.usersService.getPartners({ page: pageNumber, size: pageSize})
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result:any) => {
          this.partnersSubject.next(result.data.content);
          this.countSubject.next(result.data.totalElements);
          this.partnersDataUnfiltered=result.data.content;
        }
      );
  }

  loadFiltered(filterValue:string){
    const searchValue=filterValue.trim().toLowerCase();
    this.partnersData = this.partnersDataUnfiltered.filter(user=>{

      return (user.phoneNumber.toLowerCase().includes(searchValue) 
      || (user.companyName && user.companyName.toLowerCase().includes(searchValue) ) || 
      ( user.emailAddress && user.emailAddress.toLowerCase().includes(searchValue)) )
    });
    this.partnersSubject.next(this.partnersData);

  }
}
