import {Mission} from "../../model";

export class MissionAgent{

  id: number;
  mission: Mission;
  agentPhoneNumber: string;
  agentName: string;
  gender: string;
  agentAcceptMissionTime: Date;
  confirmationStatus: any;
  confirmationTime: Date;
  agentArrivalStatus: any;
  agentArrivalTime: Date;
  agentArrivalConfirmation: Date;
  missionStatus: any;
  agentStartTime: string;
  agentEndTime: string;
  agentMissionAcceptTime: Date;
  clientConfirmStartTime: Date;
  clientConfirmEndTime: Date;
  missionLastUpdateDate: Date;
  extensionDuration: number;
  lastExtensionDuration: number;
  extensionStatus: any;
  extensionRequestedDate: Date;
  extensionUpdatedDate: Date;
  rating: number;
  ratingReason: string;
  version: number;
  agentRated: boolean;
  path:string;

}
