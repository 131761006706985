export class  UpdateMissionRequest{

  missionId?: string;
  agentMobileNumber?: string;
  gender?: string;
  clientMobileNumber?: string;
  status?: string;
  agentName?: string;
  rating?: number;
  ratingReason?: string;


}
