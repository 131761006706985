import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {hydrationMetaReducer} from './hydration.reducer';
import {MissionRequest} from "../client/model/mission-request";
import * as missionRequestReducer from './mission-request/mission-request.reducer';
import * as missionProgressReducer from './mission-progress/mission-progress.reducer';
import * as authProfileUserReducer from './auth-profile-user-store/auth-profile-user-store.reducer';
import * as agentReducer from './agent-store/agent.reducer';

import {MissionProgress} from "../client/model/mission-progress";
import {AuthProfileUser} from "../client/model/auth-profile-user";
import {UserProfile} from "../client/model/user-profile";



const reducerKeys = ['periodicInvoice'];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: reducerKeys})(reducer);
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  // tslint:disable-next-line:only-arrow-functions typedef
  return function(state, action) {
    //console.log('state', state);
    //console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [debug, localStorageSyncReducer, hydrationMetaReducer];


export interface State {
  missionRequest: MissionRequest;
  missionProgress: MissionProgress[];
  authProfileUser: AuthProfileUser;
  agent: UserProfile;

}


export const reducers: ActionReducerMap<State> = {
  // @ts-ignore
  missionRequest: missionRequestReducer.MissionRequestReducer,
  // @ts-ignore
  missionProgress: missionProgressReducer.MissionProgressReducer,
  // @ts-ignore
  authProfileUser: authProfileUserReducer.AuthProfileUserStoreReducer,
  // @ts-ignore
  agent: agentReducer.AgentReducer,

};
export const getMissionRequestState = createFeatureSelector<MissionRequest>('missionRequest');

export const getMissionProgressState = createFeatureSelector<MissionProgress[]>('missionProgress');

export const getAuthProfileUserState = createFeatureSelector<AuthProfileUser>('authProfileUser');

export const getAgentStateIndex = createFeatureSelector<UserProfile>('agent');

export const getMissionRequest = createSelector(
  getMissionRequestState,
  missionRequestReducer.getMissionRequest
);


export const getMissionProgress = createSelector(
  getMissionProgressState,
  missionProgressReducer.getMissionProgressRequest
);

export const getAuthProfileUser = createSelector(
  getAuthProfileUserState,
  authProfileUserReducer.getAuthProfileUser
);


export const getAgent = createSelector(
  getAgentStateIndex,
  agentReducer.getAgentState
);
