import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs'
import {ClientUserService} from "../client/service/client-user.service";
import {NotificationService} from "../client/service/notification.service";
import {PushNotification} from "../client/model/push-notification";

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(new PushNotification());

  constructor(private angularFireMessaging: AngularFireMessaging,
              private clientUserService: ClientUserService,
              private notificationService: NotificationService,
  ) {
    // this.angularFireMessaging.messaging.subscribe((_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {

        const user = this.clientUserService.getLoggedInProfile();

        if (user) {

          const request = {
            emailAddress: "",
            webPushNotificationId: token,
            pushNotificationId: "",
            phoneNumber: user.userPhoneNumber!,
            userType: 'CLIENT'
          }

          console.log(JSON.stringify(request));
          this.notificationService.saveFirebaseToken(request).subscribe(response=>{
            console.log(response);
          },error => {
            console.log(error);
          })
        }
        console.log(token);


      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        const pushNotification = <PushNotification>payload.data
        console.log("new message received. ", pushNotification);
        // @ts-ignore
        this.currentMessage.next(pushNotification);
      })

  }
}
