import {Component, OnInit} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';

import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';

import {Store} from "@ngrx/store";
import {MissionRequest, MissionUpdateRequest} from "../../model/mission-request";
import {getMissionRequest} from "../../../store";
import {AccountService} from "../../service/account.service";
import {StorageService} from "../../../service/storage.service";
import {PaymentService} from "../../service/payment.service";
import {ClientUserService} from "../../service/client-user.service";
import {AccountLicenseRequest} from "../../model/account-license-request";
import {NgxSpinnerService} from 'ngx-spinner';
import {InitiatePayment} from "../../model/initiate-payment";
import {paymentMode} from '../../client.utils';
import {Account} from "../../model/account";
import {MissionService} from "../../service/mission.service";
import {MissionExtensionRequest} from "../../model/mission-extension-request";
import {AgentExtensionWaitingDialogComponent} from "../../dialogs/agent-extension-waiting-dialog/agent-extension-waiting-dialog.component";
import {DELETE_MISSION_REQUEST} from "../../../store/mission-request/mission-request.actions";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})
export class CheckOutComponent implements OnInit {


  accountBalance = 0;

  sufficientBalance = false;

  togglerPaymentMode = 2;

  paymentMode = paymentMode

  initiatePayment = new InitiatePayment()
  isExtension = false;

  paymentInitResponse = {
    result: "",
    data: {
      status: "",
      redirectUrl: "",
      transactionReference: "",
      gatewayReference: "",
      amount: 0,
      gatewayStatus: ""
    }
  }


  accountLicenseBody = new AccountLicenseRequest();
  missionRequest: MissionRequest;
  tariffRequestMessage: MissionExtensionRequest;

  constructor(
    private route: ActivatedRoute,
    private clientUserService: ClientUserService,
    private paymentService: PaymentService,
    private accountService: AccountService,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private storageService: StorageService,
    private store: Store,
    private missionService: MissionService,
    private dialog: MatDialog,
  ) {


  }

  ngOnInit(): void {

    const missionExtension = this.storageService.getItem(StorageService.MISSION_EXTENSION);




    if(missionExtension){
      this.tariffRequestMessage = JSON.parse(missionExtension) as MissionExtensionRequest;

    }


    this.store.select(getMissionRequest).subscribe((value) => {
      if (value) {
        this.missionRequest = value;
      }
    })
    const phoneNumber = this.clientUserService.getLoggedInProfile()?.businessPhoneNumber;
    this.accountService.getAccount(phoneNumber!).subscribe((response) => {
      if (response.result === 'OK') {
        const account = <Account>response.data;

        this.accountBalance = account.accountBalance;
        if(this.tariffRequestMessage){
          // @ts-ignore
          this.sufficientBalance = !((this.accountBalance - this.tariffRequestMessage.priceForExtension) < 0);
          // @ts-ignore
        }else{
          this.sufficientBalance = !((this.accountBalance - this.missionRequest.missionPrice) < 0);

        }



        this.togglerPaymentMode = (this.sufficientBalance ? 1 : 2)
      }
    });
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  togglePaymentMode(ind: any) {
    this.togglerPaymentMode = ind
  }

  sendPaymentToGateway(mode: any) {

    this.initiatePayment.gateway = "nbsweb";
    // @ts-ignore
    this.initiatePayment.phoneNumber = this.clientUserService.getLoggedInProfile()?.userPhoneNumber;
    this.initiatePayment.paymentMethod = mode;
    this.initiatePayment.transactionReference = "";

    if(this.tariffRequestMessage){
      // @ts-ignore
      this.initiatePayment.paymentAmount = this.tariffRequestMessage.priceForExtension;
      // @ts-ignore
    }else{
      this.initiatePayment.paymentAmount = this.missionRequest.missionPrice;

    }
    this.paymentService.initiatePayment(this.initiatePayment).subscribe(value => {



      Object.assign(this.paymentInitResponse, value)


      if(!this.tariffRequestMessage){
        this.updateMission(value.data.gatewayReference,+this.missionRequest.missionId,true)
      }
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "initiatePaymentResponse": JSON.stringify(value)
        }
      };

      this.router.navigate(['client/gateway-payment'], navigationExtras);


    });


  }

  updateMission(gatewayReference:string,missionId:any,deleted: boolean){


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    missionUpdateRequest.gatewayReference = gatewayReference;
    this.missionService.updateMission(missionUpdateRequest).subscribe(value => {
      if(value.result === 'OK'){
        console.log("Payment gateway Reference "+gatewayReference)
      }
    });
  }

  updateMissionWithSufficiency(missionId:any,deleted: boolean,gatewayReference?:string){


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    missionUpdateRequest.gatewayReference = gatewayReference;
    this.missionService.updateMission(missionUpdateRequest).subscribe(value => {
      this.spinner.hide();
      if(value.result === 'OK'){
        this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION_WAITING);
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
        this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
        this.openSnackBar('Payment was successful', 'Close');

        let base64String = value.data.base64;
        let index = value.data.fileName.lastIndexOf('/');
        let fileName = value.data.fileName.substring(index + 1);
        this.missionService.downloadPdf(base64String,fileName);
        this.router.navigate(['client/upcoming-missions']);
      }else{

        this.openSnackBar('Payment failed', 'Close');

      }
    },error => {
      this.spinner.hide();
      this.openSnackBar('An error occurred', 'Close');
    });
  }
  // downloadPdf(base64String:string, fileName:string) {
  //
  //   const source = `data:application/pdf;base64,${base64String}`;
  //   const link = document.createElement("a");
  //   link.href = source;
  //   link.download = `${fileName}.pdf`
  //   link.click();
  // }


  updateMissionFavBookWithSufficiency(missionId:any,deleted: boolean,gatewayReference?:string){


    const missionUpdateRequest = new MissionUpdateRequest();
    missionUpdateRequest.missionId = missionId;
    missionUpdateRequest.deleted = deleted;
    missionUpdateRequest.gatewayReference = gatewayReference;
    this.missionService.updateFavBookMission(missionUpdateRequest).subscribe(value => {
      this.spinner.hide();
      if(value.result === 'OK'){
        this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION_WAITING);
        this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
        this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
        this.openSnackBar('Payment was successful', 'Close');

        this.router.navigate(['client/upcoming-missions']);
      }else{

        this.openSnackBar('Payment failed', 'Close');

      }
    },error => {
      this.spinner.hide();
      this.openSnackBar('An error occurred', 'Close');
    });
  }

  sendPayment() {

    this.spinner.show();


    if(this.tariffRequestMessage ){

      this.missionService.clientExtendMission(this.tariffRequestMessage).subscribe(response => {
        if (response.result == 'OK') {
          if (this.tariffRequestMessage.agentPhoneNumber) {

            this.storageService.saveObject(StorageService.MISSION_EXTENSION_WAITING, this.tariffRequestMessage);

            const dialogRef = this.dialog.open(AgentExtensionWaitingDialogComponent
              , {
                width: '550px',
                backdropClass: 'bg-color',
                data: {
                  missionExtensionRequest: this.tariffRequestMessage,
                },
                disableClose: true
              });

            dialogRef.afterClosed().subscribe((result: any) => {
              if (result === 'OK') {
                this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION_WAITING);
                this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
                this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
                this.openSnackBar('Payment was successful', 'Close');
                this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
                this.router.navigate(['client']);
              }
            });


          } else {
            this.store.dispatch({type: DELETE_MISSION_REQUEST, payload: null});
            this.storageService.deleteItem(StorageService.MISSION_EXTENSION);
            this.storageService.saveString(StorageService.PAYMENT_STATUS, 'true');
            this.openSnackBar('Payment was successful', 'Close');
            this.storageService.deleteItem(StorageService.BOOKING_PAYMENT)
            this.router.navigate(['client']);
          }

        } else {
          this.storageService.saveString(StorageService.PAYMENT_STATUS, 'false');
          this.openSnackBar('Payment failed', 'Close');
        }
      })
      this.spinner.hide();

    }else {
      // @ts-ignore
      this.accountLicenseBody.phoneNumber = this.clientUserService.getLoggedInProfile()?.userPhoneNumber;

      this.accountLicenseBody.trxAmount = this.missionRequest.missionPrice

      console.log(JSON.stringify(this.missionRequest));


      if(this.missionRequest.listFavoriteAgents && this.missionRequest.listFavoriteAgents.length > 0){
        console.log('updateMissionFavBookWithSufficiency',this.missionRequest.missionId);
        this.updateMissionFavBookWithSufficiency(this.missionRequest.missionId,false);
      }else{
        console.log('updateMissionWithSufficiency',this.missionRequest.missionId);
        this.updateMissionWithSufficiency(this.missionRequest.missionId,false);
      }
      this.spinner.hide();
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


}
