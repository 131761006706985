import {Component, Inject, OnInit} from '@angular/core';
import {Mission} from "../../../model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-viewmission',
  templateUrl: './viewmission.component.html',
  styleUrls: ['./viewmission.component.css']
})
export class ViewmissionComponent implements OnInit {
  missionData:Mission | undefined;

  constructor(public dialogRef: MatDialogRef<ViewmissionComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private sanitizer: DomSanitizer,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.missionData=this.data.mission;
  }

  closeViewMission(){
    this.dialogRef.close();
  }
}
