import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomResponse, User } from 'src/app/model';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-view-client-details',
  templateUrl: './view-client-details.component.html',
  styleUrls: ['./view-client-details.component.css']
})
export class ViewClientDetailsComponent implements OnInit {

  clientDetails=new User();


  constructor(
    
    public dialogRef: MatDialogRef<ViewClientDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private toastr: ToastrService
  ) { 
  
  }

  ngOnInit(): void {

    Object.assign(this.clientDetails,this.data);

  }

  cancel(){
    this.dialogRef.close();
  }

  activateDeactivate(phonenumber:string){
    this.userService.activateClient(phonenumber).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){
       
        this.toastr.success("Update successfully","ACTIVATE");
        this.dialogRef.close();
      }else{
      this.toastr.error("Error in processing");
      }
    });

   
  }

}
