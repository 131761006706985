import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {AgentRegistration} from "../../model";
import {BehaviorSubject, Observable, of} from "rxjs";
import {UsersService} from "../users.service";
import {catchError, finalize} from "rxjs/operators";

export class DisabledAgentsDatasource implements DataSource<AgentRegistration>{

  private agentsSubject = new BehaviorSubject<AgentRegistration[]>([]);
  private agentData:AgentRegistration[]=[];
  private agentDataUnfiltered:AgentRegistration[]=[];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private userService: UsersService) { }


  connect(collectionViewer: CollectionViewer): Observable<AgentRegistration[]> {
    return this.agentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.agentsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  loadAgents(pageNumber = 0, pageSize = 5,active='false') {
    this.loadingSubject.next(true);
    this.userService.getActiveAgents({ page: pageNumber, size: pageSize,active:active })
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result:any) => {
          this.agentsSubject.next(result.data.content);
          this.countSubject.next(result.data.totalElements);
          this.agentDataUnfiltered=result.data.content;
        }
      );
  }

  loadFiltered(filterValue:string){
    const searchValue=filterValue.trim().toLowerCase();
    this.agentData = this.agentDataUnfiltered.filter(agent=>{
      return (agent.userPhoneNumber.toLowerCase().includes(searchValue)|| agent.firstName.toLowerCase().includes(searchValue)||
        agent.streetAddress.toLowerCase().includes(searchValue)||agent.city.toLowerCase().includes(searchValue)||
        agent.addressZipCode.toLowerCase().includes(searchValue)||agent.lastName.toLowerCase().includes(searchValue)||
        agent.dressCode.toLowerCase().includes(searchValue)||agent.language.toLowerCase().includes(searchValue)||
        agent.qualification.toLowerCase().includes(searchValue))
    });
    this.agentsSubject.next(this.agentData);

  }
}
