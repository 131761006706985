import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-businessownerdashboardhome',
  templateUrl: './businessownerdashboardhome.component.html',
  styleUrls: ['./businessownerdashboardhome.component.css']
})
export class BusinessownerdashboardhomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
