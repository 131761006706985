import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {environment} from "../../environments/environment";
import {CustomResponse} from "../model";


@Injectable({
  providedIn: 'root'
})
export class TariffService {

  constructor(private http: HttpClient) { }
  getAllTariff(searchRequest:any): Observable<any> {
    const url = environment.getTariffs;
    return this.http.get<any>(url, {params: searchRequest} );
  }
  saveTariff(data:any): Observable<CustomResponse> {
    const url = environment.saveTariff;
    console.log('tariff',data);
    return this.http.post<CustomResponse>(url,data);
  }
}

export class Tariff {
  id:number;
  tariffName:string;
  costPerAgent:number;
  minimumNumberOfAgentsForTariff:number;
  maximumNumberOfAgentsForTariff:number;
  dayForTariff:DaysOfWeek;
  startTime:Date;
  endTime:Date;
  specialDay:boolean;
  specialDayDate:Date;
  dateCreated:Timestamp;
  lastUpdateDate:Timestamp;
  status:TariffStatus;
  publicHoliday:boolean;
  version:number;
}
enum TariffStatus {
  ACTIVE,DISABLED
}

export enum DaysOfWeek {
  SUNDAY,MONDAY,TUESDAY,WEDNESDAY,THURSDAY,FRIDAY,SATURDAY,NULL
}
