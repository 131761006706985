<app-businessownerheader></app-businessownerheader>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport fixedTopGap="100"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
<div style="text-align: center">
  <img src="assets/images/svg/profile.svg">
</div>
    <div style="text-align: center">
      <p>{{'nbs.businessownermanager.menu.label.welcome'|translate}} <b></b></p>
    </div>

    <mat-nav-list>
      <ng-container *ngIf="menus">
        <a mat-list-item href="#" *ngFor="let menu of menus" routerLink="{{menu.url}}">    <mat-icon svgIcon="{{menu.icon}}" style="margin-right: 5px"></mat-icon>{{menu.name}}</a>
      </ng-container>

       <a mat-list-item href="#" routerLink="generalsettings">    <mat-icon svgIcon="settings" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.generalsettings' | translate }}</a>
      <a mat-list-item href="#" routerLink="manageemployeeagency"><mat-icon svgIcon="manageemployeeagency" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.manageemployeeagency' | translate }}</a>
      <a mat-list-item href="#" routerLink="managepartners"><mat-icon svgIcon="managepartners" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.managepartners' | translate }}</a>
      <a mat-list-item href="#" routerLink="manageclients"><mat-icon svgIcon="manageclients" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.manageclients' | translate }}</a>
      <a mat-list-item href="#" routerLink="manageagents"><mat-icon svgIcon="manageagents" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.manageagents' | translate }}</a>
      <a mat-list-item href="#" routerLink="reporting"><mat-icon svgIcon="reporting" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.reporting' | translate }}</a>
      <a mat-list-item href="#" routerLink="manageemployee"><mat-icon svgIcon="manageclients" style="margin-right: 5px"></mat-icon>{{ 'nbs.businessownermanager.menu.label.manageemployee' | translate }}</a>
    </mat-nav-list>
    <button mat-raised-button  style="text-align: center;padding-right: 20%;padding-left:20%;margin-left: 10%;margin-right: 20%;margin-bottom: 20px;margin-top: 20px;width: auto" (click)="logout()">{{'nbs.login.label.logout'|translate}}</button>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="light" style="z-index: 2">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{'nbs.title'|translate}}</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
