import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthServerUser, AuthUser, DeactivateUserRequestDto, DeactivateUserResponseDto, UserStatus } from 'src/app/model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { EditEmployeeComponent } from '../edit-employee/edit-employee.component';

@Component({
  selector: 'app-view-employee-details',
  templateUrl: './view-employee-details.component.html',
  styleUrls: ['./view-employee-details.component.css']
})
export class ViewEmployeeDetailsComponent implements OnInit {

  dataDetail=new AuthUser() ;

  userBody=new DeactivateUserRequestDto();

  isEdit=false;

  constructor(
    public dialogRef: MatDialogRef<ViewEmployeeDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    
   }

  ngOnInit(): void {

    Object.assign(this.dataDetail,this.data);

   
  }

  cancel(){
    this.dialogRef.close();
  }


  editUser(data:any): void {
    
    const dialogRef = this.dialog.open(EditEmployeeComponent
      , {
        width: '50%',
        data:data

      });

      this.cancel();

      dialogRef.afterClosed().subscribe(result => {
        //this.loadData()
      });
  }

  saveEditUser(){

  }



  activateUser(element:any){
    this.userBody.username=element.username

    this.authService.activateUser(this.userBody).subscribe((result:DeactivateUserResponseDto)=>{

      if(result.success===true){
       
        this.toastr.success("Update successfully","ACTIVATE");
        this.dialogRef.close();
      }else{
      this.toastr.error("Error in processing");
      }
    });

  }

  deactivateUser(element:any){
    this.userBody.username=element.username

    this.authService.deactivateUser(this.userBody).subscribe((result:DeactivateUserResponseDto)=>{

      if(result.success===true){
       
        this.toastr.success("Update successfully","DEACTIVATE");
        this.dialogRef.close();
      }else{
      this.toastr.error("Error in processing");
      }
    });

  }

  getStatus(element:any){
  

    var data = <UserStatus>{};
    data.username=element.username;

    this.authService.getUserDetails(element.username).subscribe((result:any)=>{

      console.log(result)
    });

  }

}
