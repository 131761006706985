import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { environment } from './../../environments/environment';
import {CustomResponse} from "../model";
import {Otp} from "../client/model/otp";

@Injectable({
  providedIn: 'root'
})
export class NotificationClientService {

  constructor(private http: HttpClient) {

  }

  requestOtp(otp: Otp): Observable<CustomResponse> {
    const url = `/notification/otp/request`;
    return this.http.post<CustomResponse>(url, otp);
  }

  validateOtp(otp: Otp): Observable<CustomResponse> {
    const url = `/notification/otp/validate`;
    return this.http.post<CustomResponse>(url, otp);
  }

}
