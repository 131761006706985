<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <!-- loop over the cardList and show the cards -->

    <button mat-raised-button class="labelBlack" (click)="addTariff()" >{{'btn.add'|translate}}</button>

  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div  fxFlex="100"
          fxFlex.md="100"
          fxFlex.sm="100"
          fxFlex.xs="100"
          fxLayout="column"
          style="padding: 5px;">
      <mat-form-field appearance="standard" class="header-search">
        <mat-label>{{'label.search'|translate}}</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource"  >


        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="tariffName">
          <th mat-header-cell *matHeaderCellDef> {{'label.tariffName'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: justify;">
              <p style="font-weight: bold">{{element.tariffName}}</p>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="costPerAgent">
          <th mat-header-cell *matHeaderCellDef> {{'label.costPerAgent'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div >
              <p style="font-weight: bold">{{element.phoneNumber}}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="minimumNumberOfAgentsForTariff">
          <th mat-header-cell *matHeaderCellDef> {{'label.minimumNumberOfAgentsForTariff'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div >
              <p style="font-weight: bold">{{element.minimumNumberOfAgentsForTariff}}</p>
            </div>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="maximumNumberOfAgentsForTariff">
          <th mat-header-cell *matHeaderCellDef> {{'label.maximumNumberOfAgentsForTariff'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.maximumNumberOfAgentsForTariff}} </td>
        </ng-container>
        <ng-container matColumnDef="dayForTariff">
          <th mat-header-cell *matHeaderCellDef> {{'label.dayForTariff'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.dayForTariff}} </td>
        </ng-container>
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef> {{'label.startTime'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.startTime}} </td>
        </ng-container>
        <ng-container matColumnDef="endTime">
          <th mat-header-cell *matHeaderCellDef> {{'label.endTime'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.endTime}} </td>
        </ng-container>
        <ng-container matColumnDef="specialDay">
          <th mat-header-cell *matHeaderCellDef> {{'label.specialDay'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.specialDay}} </td>
        </ng-container>
        <ng-container matColumnDef="specialDayDate">
          <th mat-header-cell *matHeaderCellDef> {{'label.specialDayDate'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.specialDayDate}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> {{'label.status'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="publicHoliday">
          <th mat-header-cell *matHeaderCellDef> {{'label.publicHoliday'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.publicHoliday}} </td>
        </ng-container>
        <!-- Weight Column -->
<!--        <ng-container matColumnDef="status">-->
<!--          <th mat-header-cell *matHeaderCellDef> {{'label.status'|translate}} </th>-->
<!--          <td mat-cell *matCellDef="let element">-->
<!--            <a *ngIf="element.enabled===true"   class="statusLabel labelSuccess"  >-->
<!--              {{'label.active'|translate}}-->
<!--            </a>-->
<!--            <a *ngIf="element.enabled===false"   class="statusLabel labelDanger"  >-->
<!--              {{'label.inactive'|translate}}-->
<!--            </a>-->

<!--          </td>-->
<!--        </ng-container>-->

        <!-- Symbol Column -->
        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openTariffViewDetails(element)">{{'btn.view'|translate}}</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>


      </table>
      <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes"></mat-paginator>

    </div>
  </div>
</div>

<!--<div class="container">-->

<!--  <mat-card class="mat-elevation-z8">-->
<!--    <mat-toolbar color="accent" style="box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);">-->
<!--      <div align="center" style="text-align: right;">-->
<!--       Manage Tariffs-->
<!--      </div>-->
<!--    </mat-toolbar>-->
<!--    <br><br>-->
<!--    <mat-card-content>-->
<!--      <form [formGroup]="tariffForm" (ngSubmit)="onFormSubmit()">-->
<!--        <table>-->
<!--          <tr>-->
<!--            <td class="tbl1">-->
<!--              <mat-form-field class="demo-full-width">-->
<!--                <input formControlName="TariffName" matTooltip="Enter Tariff TariffName" matInput placeholder="TariffName" autocomplete="off">-->
<!--              </mat-form-field>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('TariffName').value && tariffForm.get('TariffName').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--            <td class="tbl1">-->
<!--              <mat-form-field class="demo-full-width">-->
<!--                <input formControlName="CostPerAgent" matTooltip="Enter Tariff CostPerAgent" matInput placeholder="CostPerAgent" autocomplete="off">-->
<!--              </mat-form-field>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('CostPerAgent').value && tariffForm.get('CostPerAgent').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--            <td class="tbl1">-->
<!--              <mat-form-field class="demo-full-width">-->
<!--                <input matInput [matDatepicker]="picker" matTooltip="Enter Date Of Birth" formControlName="StartTime" placeholder="Date Of Birth" autocomplete="off">-->
<!--                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--                <mat-datepicker #picker></mat-datepicker>-->
<!--              </mat-form-field>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('StartTime').value && tariffForm.get('StartTime').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--            <td class="tbl1">-->
<!--              <mat-form-field class="demo-full-width">-->
<!--                <input formControlName="MinimumNumberOfAgentsForTariff" matTooltip="Enter MinimumNumberOfAgentsForTariff" matInput placeholder="Email ID" autocomplete="off">-->
<!--              </mat-form-field>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('MinimumNumberOfAgentsForTariff').value && tariffForm.get('MinimumNumberOfAgentsForTariff').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--            <td class="tbl1">-->
<!--              <span>Maximum Agents</span>&nbsp;-->

<!--              <mat-radio-group matTooltip="Enter MaximumNumberOfAgentsForTariff" formControlName="MaximumNumberOfAgentsForTariff">-->
<!--                <mat-radio-button value="0" [checked]="isSpecialDay">Special Day</mat-radio-button>&nbsp;&nbsp;-->
<!--                <mat-radio-button value="1" [checked]="isPublicHoliday">Public Holiday</mat-radio-button>&nbsp;&nbsp;-->
<!--              </mat-radio-group>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('MaximumNumberOfAgentsForTariff').value && tariffForm.get('MaximumNumberOfAgentsForTariff').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--&lt;!&ndash;            <td class="tbl1">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-label>DayForTariff</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-select (selectionChange)="FillDays($event)" formControlName="DayForTariff" matTooltip="Select DayForTariff" autocomplete="off">&ndash;&gt;-->
<!--&lt;!&ndash;                  <mat-option *ngFor="let DayForTariff of (daysOfWeek | async)" [value]="DayForTariff.CountryId">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{DayForTariff.CountryName}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;            </td>&ndash;&gt;-->
<!--&lt;!&ndash;            <td class="tbl1">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-label>Status</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-select (selectionChange)="FillCityDDL($event)" formControlName="Status" matTooltip="Select Status" autocomplete="off">&ndash;&gt;-->
<!--&lt;!&ndash;                  <mat-option *ngFor="let Status of (allState | async)" [value]="Status.StateId">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{Status.StateName}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;            </td>&ndash;&gt;-->
<!--&lt;!&ndash;            <td class="tbl1">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-label>City</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-select formControlName="City" (selectionChange)="GetSelectedCity($event)" matTooltip="Select City" autocomplete="off">&ndash;&gt;-->
<!--&lt;!&ndash;                  <mat-option *ngFor="let City of (allCity | async)" [value]="City.Cityid">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{City.CityName}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;            </td>&ndash;&gt;-->
<!--            <td class="tbl1">-->
<!--              <mat-form-field class="demo-full-width">-->
<!--                <input matTooltip="Enter SpecialDay" formControlName="SpecialDay" matInput placeholder="SpecialDay" autocomplete="off">-->
<!--              </mat-form-field>-->
<!--              <mat-error>-->
<!--                <span *ngIf="!tariffForm.get('SpecialDay').value && tariffForm.get('SpecialDay').touched"></span>-->
<!--              </mat-error>-->
<!--            </td>-->
<!--&lt;!&ndash;            <td class="tbl1">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-form-field class="demo-full-width">&ndash;&gt;-->
<!--&lt;!&ndash;                <input formControlName="Pincode" matTooltip="Enter Pine Code" matInput placeholder="Pincode" minLength="5" maxLength="6" autocomplete="off">&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-error>&ndash;&gt;-->
<!--&lt;!&ndash;                <span *ngIf="!tariffForm.get('Pincode').value && tariffForm.get('Pincode').touched"></span>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-error>&ndash;&gt;-->
<!--&lt;!&ndash;            </td>&ndash;&gt;-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="content-center">-->
<!--              <button type="submit" mat-raised-button color="accent" matTooltip="Submit" [disabled]="!tariffForm.valid">Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--              <button type="reset" mat-raised-button color="accent" matTooltip="Reset" (click)="resetForm()">Reset</button>-->
<!--            </td>-->
<!--            <td>-->
<!--              <p *ngIf="dataSaved" style="color:rgb(0, 128, 0);font-size:20px;font-weight:bold" Class="success" align="left">-->
<!--                {{massage}}-->
<!--              </p>-->
<!--            </td>-->
<!--            <td></td>-->
<!--            <td></td>-->
<!--          </tr>-->
<!--        </table>-->
<!--        <br><br>-->



<!--        &lt;!&ndash;<mat-form-field>-->

<!--        </mat-form-field>&ndash;&gt;-->

<!--        <mat-form-field>-->
<!--          <input matInput (keyup)="applyFilter($event.target)" placeholder="Filter">-->
<!--        </mat-form-field>-->
<!--        &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--        <button mat-icon-button aria-label="Example icon button with a delete icon" type="button" mat-raised-button color="accent" matTooltip="Delete" (click)="DeleteData()"><mat-icon>delete</mat-icon></button>-->

<!--        <div>-->

<!--          <mat-table  [dataSource]="dataSource" matSort class="mat-elevation-z8" style="box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);">-->

<!--            &lt;!&ndash; Checkbox Column &ndash;&gt;-->
<!--            <ng-container matColumnDef="select">-->
<!--              <mat-header-cell  *matHeaderCellDef>-->
<!--                <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                              [checked]="selection.hasValue() && isAllSelected()"-->
<!--                              [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>-->
<!--              </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let row">-->
<!--                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"-->
<!--                              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"></mat-checkbox>-->
<!--              </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="TariffName">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.TariffName}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="CostPerAgent">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.CostPerAgent}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="StartTime">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Date Of Birth </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.StartTime | date:'dd-MM-yyyy'}}</mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="MinimumNumberOfAgentsForTariff">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Email Id </mat-header-cell>-->
<!--              <mat-cell   *matCellDef="let tariff"> {{tariff.MinimumNumberOfAgentsForTariff}}        </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="MaximumNumberOfAgentsForTariff">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> MaximumNumberOfAgentsForTariff </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.MaximumNumberOfAgentsForTariff ==0? 'Male' : 'Female'}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="DayForTariff">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> DayForTariff </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.DayForTariff}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Status">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Status </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.Status}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="City">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> City </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.City}} </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="SpecialDay">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> SpecialDay </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> {{tariff.specialDay}} </mat-cell>-->
<!--            </ng-container>-->

<!--&lt;!&ndash;            <ng-container matColumnDef="Pincode">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-header-cell  *matHeaderCellDef mat-sort-header> Pincode </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-cell  *matCellDef="let tariff"> {{tariff.incode}} </mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;            </ng-container>&ndash;&gt;-->

<!--            <ng-container matColumnDef="Edit">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>-->

<!--              <mat-cell  *matCellDef="let tariff">-->

<!--                <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" type="button" mat-raised-button color="accent" matTooltip="Edit" (click)="loadTariffToEdit(tariff.EmpId)"><mat-icon>edit</mat-icon></button>-->
<!--                &lt;!&ndash;<button mat-icon-button aria-label="Example icon button with a vertical three dot icon" color="accent" matTooltip="Edit" (click)="loadTariffToEdit(tariff.EmpId)">-->
<!--                  <mat-icon>more_vert</mat-icon>-->
<!--                </button>&ndash;&gt;-->
<!--              </mat-cell>-->
<!--            </ng-container>-->

<!--            <ng-container matColumnDef="Delete">-->
<!--              <mat-header-cell  *matHeaderCellDef mat-sort-header> Delete </mat-header-cell>-->
<!--              <mat-cell  *matCellDef="let tariff"> <button mat-icon-button aria-label="Example icon button with a delete icon" type="button" mat-raised-button color="accent" matTooltip="Delete" (click)="deleteTariff(tariff.EmpId)"><mat-icon>delete_forever</mat-icon></button></mat-cell>-->
<!--            </ng-container>-->

<!--            <mat-header-row  *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--            <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>-->
<!--          </mat-table>-->

<!--          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
<!--        </div>-->

<!--        &lt;!&ndash;<table mat-table class="table">-->
<!--          <tr color="accent">-->
<!--            <th class="tbl2">First Name</th>-->
<!--            <th class="tbl2">Last Name</th>-->
<!--            <th class="tbl2">Date Of Birth</th>-->
<!--            <th class="tbl2">Email Id</th>-->
<!--            <th class="tbl2">MaximumNumberOfAgentsForTariff</th>-->
<!--            <th class="tbl2">DayForTariff</th>-->
<!--            <th class="tbl2">Status</th>-->
<!--            <th class="tbl2">City</th>-->
<!--            <th class="tbl2">SpecialDay</th>-->
<!--            <th class="tbl2">Pincode</th>-->
<!--            <th class="tbl2">Edit</th>-->
<!--            <th class="tbl2">Delete</th>-->
<!--          </tr>-->
<!--          <tr *ngFor="let tariff of allTariffs | async">-->
<!--            <td class="tbl2">{{tariff.TariffName}}</td>-->
<!--            <td class="tbl2">{{tariff.CostPerAgent}}</td>-->
<!--            <td class="tbl2">{{tariff.StartTime | date }}</td>-->
<!--            <td class="tbl2">{{tariff.MinimumNumberOfAgentsForTariff}}</td>-->
<!--            <td class="tbl2">{{tariff.MaximumNumberOfAgentsForTariff ==0? 'Male' : 'Female'}}</td>-->
<!--            <td class="tbl2">{{tariff.DayForTariff}}</td>-->
<!--            <td class="tbl2">{{tariff.Status}}</td>-->
<!--            <td class="tbl2">{{tariff.City}}</td>-->
<!--            <td class="tbl2">{{tariff.SpecialDay}}</td>-->
<!--            <td class="tbl2">{{tariff.Pincode}}</td>-->
<!--            <td class="tbl2">-->
<!--              <button type="button" mat-raised-button color="accent" matTooltip="Edit" (click)="loadTariffToEdit(tariff.EmpId)">Edit</button>-->
<!--            </td>-->
<!--            <td class="tbl2">-->
<!--              <button type="button" mat-raised-button color="accent" matTooltip="Delete" (click)="deleteTariff(tariff.EmpId)">Delete</button>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </table>&ndash;&gt;-->
<!--      </form>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->
<!--</div>-->
