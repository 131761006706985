<!--{{ message | async | json }}-->

<div appController>

    <div class="main">
      <router-outlet >
      </router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
