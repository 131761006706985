
<ng-container *ngIf="clientDetails" >

    <!-- {{clientDetails | json}} -->

    <form class="myFormStyle"  >


        <div  >
            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label >{{'label.businessName'|translate}}</label>
                        <input type="text" class="myInput" value="{{clientDetails.businessName}}"   readonly  placeholder="">

                    </div>


                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.firstName'|translate}} </label>
                        <input type="text" class="myInput" value="{{clientDetails.firstName}}" readonly  placeholder="">
                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label >{{'label.lastName'|translate}}</label>
                        <input type="text" class="myInput"  value="{{clientDetails.lastName}}"  readonly  placeholder="">

                    </div>


                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.businessPhoneNumber'|translate}} </label>
                        <input type="text" class="myInput" value="{{clientDetails.businessPhoneNumber}}" readonly  placeholder="">
                    </div>


                </div>
            </div>


            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label >{{'label.phoneNumber'|translate}}</label>
                        <input type="text" class="myInput"  value="{{clientDetails.userPhoneNumber}}"  readonly  placeholder="">

                    </div>


                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.vatNumber'|translate}} </label>
                        <input type="text" class="myInput" value="{{clientDetails.vatNumber}}" readonly  placeholder="">
                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label >{{'label.businessStreetAddress'|translate}}</label>
                        <input type="text" class="myInput"  value="{{clientDetails.streetAddress}}"  readonly  placeholder="">

                    </div>


                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{'label.city'|translate}} </label>
                        <input type="text" class="myInput" value="{{clientDetails.city}}" readonly  placeholder="">
                    </div>


                </div>
            </div>

        </div>


        <footer>
            <button *ngIf="clientDetails.userProfileStatus==='ACTIVE'" (click)="activateDeactivate(clientDetails.userPhoneNumber)" class="btn btn-danger">
                {{'btn.deactivate'|translate}}
            </button>

            <button *ngIf="clientDetails.userProfileStatus!=='ACTIVE'" (click)="activateDeactivate(clientDetails.userPhoneNumber)" class="btn btn-success">
                {{'btn.activate'|translate}}
            </button>



            <button type="button" class="btn btn-default" (click)="cancel()">
                {{'btn.dismiss'|translate}}
            </button>

          </footer>
      </form>


</ng-container>
