<div class="h-100 mt-lg-3">
  <mat-card style=" height: auto" *ngIf="mission">

    <div style="background-color: black; color: white;text-align: left" class="h-50 w-100">
      <p class="p-3 align-content-md-end" style="height: auto;margin: 0px;">{{mission.missionDescription}}</p>
    </div>
    <div class="row">
      <agm-map
        [zoom]="5"
        [latitude]="lat"
        [longitude]="lng"
        (mapReady)="onMapReady($event)"
        [zoom]="zoom"
      >
        <agm-direction
          *ngFor="let agentDirection of directions"
          [origin]="agentDirection.origin"
          [destination]="agentDirection.destination"
        >

        </agm-direction>

        <agm-marker [latitude]="lat"
                    [longitude]="lng"></agm-marker>

        <agm-marker *ngFor="let agentLocation of agentLocationMessages" [latitude]="agentLocation.latitude"
                    [longitude]="agentLocation.longitude"></agm-marker>

      </agm-map>
    </div>

    <div style="background-color: black; color: white;text-align: right" class="h-50 w-100 mt-lg-3">
      <button mat-raised-button (click)="extendMission()" class="p-3 align-content-md-end"
              style="height: auto;margin: 30px;text-align: right"
              *ngIf="missionExtensionWindowValid">{{'client.extend_mission'|translate}}
      </button>
      <button mat-raised-button (click)="openBottomSheet()" class="p-3 align-content-md-end"
              style="height: auto;margin: 30px;text-align: right">{{'client.details'|translate}}
      </button>
    </div>

  </mat-card>
</div>
