<div>
  <form [formGroup]="form">
    <mat-card style=" height: auto">


      <div class="row mt-lg-5" *ngIf="mission">

      <span><strong>{{'client.number_of_agents'|translate}} : {{mission.numberOfAgents}}</strong></span>
      </div>

      <div class="row mt-lg-5" *ngIf="agents && agents.length>0">


        <div class="col">

          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'client.agents'|translate}}</mat-label>
            <mat-select formControlName="agent">
              <mat-option *ngFor="let agentValue of agents" [value]="agentValue"
                          style="color:  black" (click)="selectedAgent(agentValue)">{{agentValue.agentName}}
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>


      </div>

      <div class="row mt-lg-5">

        <div class="col">

          <mat-form-field appearance="fill" class="input-rounded-decoration">
            <mat-label>{{'client.mission_duration'|translate}}</mat-label>
            <mat-select formControlName="duration"

            >
              <mat-option *ngFor="let val of hours" [value]="val"
                          style="color:  black" (click)="selectedHour(val)">{{val}}h
              </mat-option>


            </mat-select>
          </mat-form-field>
        </div>

      </div>


      <div class="p-margin c-align row"
           style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">


        <div class="col" (click)="extendMission()">


          <div>
            <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="extendMission()">
              {{'client.extend_mission'|translate}}
            </button>
          </div>


        </div>
        <div class="col" (click)="cancelDialog()">


          <div>
            <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="cancelDialog()">
              {{'client.cancel_mission'|translate}}
            </button>
          </div>


        </div>

      </div>
    </mat-card>

  </form>
</div>
