<div>
  <mat-card style=" height: auto">
    <h1 style="color: black;text-align: center">{{data.title}}</h1>


    <div class="p-margin c-align">


      <h1 class="c-align" style="color: #74F55F; margin: 10px"></h1>

      <p class="c-align" style="color: #000000; margin: 10px;font-size: 14px">{{data.description}}</p>


    </div>

    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">




      <div class="col">
        <button  mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="confirm()">
          {{'client.ok'|translate}}
        </button>

      </div>


    </div>
  </mat-card>
</div>
