import {AgentActions, CREATE_AGENT, DELETE_AGENT, GET_AGENT} from "./agent.actions";
import {UserProfile} from "../../client/model/user-profile";


export const initialState: UserProfile = new UserProfile();


export function AgentReducer(state = initialState, action: AgentActions): any {
  switch (action.type) {
    case CREATE_AGENT:

      const object: UserProfile = action.payload;
      return {
        agent: object
      };

    case GET_AGENT:

      return {
        ...state
      };
    case DELETE_AGENT:

      return {
        agent: null
      };
    default:
      return state;
  }
}

export const getAgentState = (state: any) => {
  return state.agent;
};
