<div>
  <mat-card style="text-align: center; height: auto;margin-top: 20px;">
    <h1 style="color: black;text-align: center"></h1>

    <h1 style="color: black;text-align: center">{{favoriteAgent.agentName}}</h1>


    <div class="row justify-content-evenly mt-lg-5" style="margin-left: 30px">
      <div class="col" (click)="booking('INSTANT')">
        <mat-icon class="icon-display" svgIcon="instant_booking"></mat-icon>

        <p style="color: darkgrey;font-size: 16px;padding: 0px;font-weight: bold;"
           class="mt-lg-5">{{'client.instant_booking'|translate}}</p>

      </div>

      <div class="col" (click)="booking('PLANNED')">
        <mat-icon class="icon-display" svgIcon="planned_booking"></mat-icon>
        <p style="color: darkgrey;font-size: 16px;padding: 0px;font-weight: bold;" class="mt-5">{{'client.planned_booking'|translate}}</p>

      </div>
    </div>


  </mat-card>
</div>
