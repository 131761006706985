<ng-container>
    <div class="row myRow" >
        <div class="col-md-4">
    
            <!-- <mat-slide-toggle>Slide me!</mat-slide-toggle> -->
    
            <div class="form-group">
                <label >{{'label.user_group'|translate}}</label>
                <select class="myInput" (change)="changeUserGroup($event)" >
                    <option  value="0" >Select </option>
                    <ng-container *ngIf="userGroups">
                        <option *ngFor="let userGroup of userGroups" value="{{userGroup.groupId}}" >{{userGroup.name}}</option>
                      </ng-container>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <ng-container *ngIf="menus">
                <mat-slide-toggle *ngFor="let menu of menus" [(ngModel)]="menu.enabled" (change)="changePermision(menu.menuId)" >{{menu.name}}</mat-slide-toggle>
            </ng-container>
            
        </div>
    </div>
</ng-container>
