import {Component, OnInit} from '@angular/core';
import {Mission} from "../../model";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {MissionUpdateRequest} from "../model/mission-request";
import {DELETE_MISSION_REQUEST} from "../../store/mission-request/mission-request.actions";
import {StorageService} from "../../service/storage.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-waiting-missions',
  templateUrl: './waiting-missions.component.html',
  styleUrls: ['./waiting-missions.component.css']
})
export class WaitingMissionsComponent implements OnInit {


  missions: Mission[] = [];

  constructor(private missionService: MissionService,
              private clientUserService: ClientUserService,

  ) {
  }

  ngOnInit(): void {

    this.getMissions();
  }

  getMissions(): void{

    this.missionService.missionsWaitingForPayment(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!)
      .subscribe((response)=>{

        this.missions = response;
      })

  }


}
