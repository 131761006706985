import {
  CREATE_MISSION_REQUEST,
  DELETE_MISSION_REQUEST,
  GET_MISSION_REQUEST,
  MissionRequestActions
} from "./mission-request.actions";
import {MissionRequest} from "../../client/model/mission-request";


export const initialState: MissionRequest = new MissionRequest();


export function MissionRequestReducer(state = initialState, action: MissionRequestActions): any {
  switch (action.type) {
    case CREATE_MISSION_REQUEST:

      const object: MissionRequest = action.payload;
      return {
        missionRequest: object
      };

    case GET_MISSION_REQUEST:

      return {
        ...state
      };
    case DELETE_MISSION_REQUEST:

      return {
        missionRequest: null
      };
    default:
      return state;
  }
}

export const getMissionRequest = (state: any) => {
  return state.missionRequest;
};
