import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {catchError, finalize, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/service/users.service';
import { MatPaginator } from '@angular/material/paginator';
import { ViewClientDetailsComponent } from './view-client-details/view-client-details.component';
import { ClientsDatasource } from 'src/app/service/datasource/clients-datasource';
import {ChangeClientRateComponent} from "./change-client-rate/change-client-rate.component";

@Component({
  selector: 'app-manageclients',
  templateUrl: './manageclients.component.html',
  styleUrls: ['./manageclients.component.css']
})
export class ManageclientsComponent implements OnInit {

  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :ClientsDatasource;


  columnsToDisplay = ['businessName','firstName','lastName','businessEmailAddress','address','status','buttons','rate'];
  private paginator: MatPaginator ;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];




  constructor(
    private httpClient: HttpClient,private dialog: MatDialog,
              private userService: UsersService,
              private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.dataSource = new ClientsDatasource(this.userService);
    this.dataSource?.loadData();

  }

  ngAfterViewInit() {
    this.dataSource?.counter$
    .pipe(
      tap((count) => {
        // @ts-ignore
        this.paginator.length = count;
      })
    )
    .subscribe();

  // @ts-ignore
  this.paginator.page
    .pipe(
      tap(() => this.loadClient())
    )
    .subscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  loadClient() {

    this.dataSource?.loadData(this.paginator.pageIndex, this.paginator.pageSize);

    // this.userService.getAllClient()
    //   .pipe(
    //     catchError(() => of([]))
    //   )
    //   .subscribe((result:any) => {


    //     this.dataSource=result.data;
    //     }
    //   );
  }

  openViewDetails(data:any): void {

    const dialogRef = this.dialog.open(ViewClientDetailsComponent
      , {
        width: '50%',
        data:data

      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadClient()
      });

  }
  openRateDetails(data:any): void {

    const dialogRef = this.dialog.open(ChangeClientRateComponent
      , {
        width: '50%',
        data:data

      });

    dialogRef.afterClosed().subscribe(result => {
      this.loadClient()
    });

  }



}
