<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <!-- loop over the cardList and show the cards -->

  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div  fxFlex="100"
          fxFlex.md="100"
          fxFlex.sm="100"
          fxFlex.xs="100"
          fxLayout="column"
          style="padding: 5px;">
      <mat-form-field appearance="standard" class="header-search">
        <mat-label>{{'nbs.businessownermanager.manageagents.search'|translate}}</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource"  >

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="agentInformation">
          <th mat-header-cell *matHeaderCellDef> {{'label.agentName'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: justify;">
              <p style="font-weight: bold">{{element.agentName}}</p>
               </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="totalMissions">
          <th mat-header-cell *matHeaderCellDef> {{'nbs.businessownermanager.manageagents.agentHistory.totalMissions'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: center;">
              <p style="font-weight: bold">{{element.totalMissions}}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="completedMissions">
          <th mat-header-cell *matHeaderCellDef> {{'nbs.businessownermanager.manageagents.agentHistory.completedMissions'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: center;">
              <p style="font-weight: bold">{{element.completedMissions}}</p>
            </div>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="pendingMissions">
          <th mat-header-cell *matHeaderCellDef> {{'nbs.businessownermanager.manageagents.agentHistory.pendingMissions'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.pendingMission}} </td>
        </ng-container>
        <ng-container matColumnDef="missionInProgress">
          <th mat-header-cell *matHeaderCellDef> {{'nbs.businessownermanager.manageagents.agentHistory.missionsInProgress'|translate}}</th>
          <td mat-cell *matCellDef="let element">  {{element.missionInProgress}} </td>
        </ng-container>
        <ng-container matColumnDef="cancelledMissions">
          <th mat-header-cell *matHeaderCellDef> {{'nbs.businessownermanager.manageagents.agentHistory.cancelledMissions'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.cancelledMissions}} </td>
        </ng-container>
        <ng-container matColumnDef="clientCancelledMissions">
          <th mat-header-cell *matHeaderCellDef>{{'nbs.businessownermanager.manageagents.agentHistory.clientCancelledMissions'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.clientCancelledMissions}} </td>
        </ng-container>
        <ng-container matColumnDef="missionExtensions">
          <th mat-header-cell *matHeaderCellDef>{{'nbs.businessownermanager.manageagents.agentHistory.missionExtensions'|translate}}</th>
          <td mat-cell *matCellDef="let element">  {{element.missionExtensions}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef>  </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openAgentViewDetails(element.phoneNumber)">{{'nbs.businessownermanager.manageagents.agentHistory.viewAgentInfo'|translate}}</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>


      </table>
      <mat-paginator  [pageSize]="pageSize" [pageSizeOptions]="pageSizes"></mat-paginator>
      <!-- <mat-paginator  [pageSize]="10" [pageSizeOptions]="[ 10, 25,50, 100]"></mat-paginator> -->

    </div>
  </div>
</div>
