<div>
  <mat-card style=" height: auto">
    <h1 style="color: black;text-align: center">{{title}}</h1>
    <h5 style="color: black;text-align: center"
        *ngIf="isExtension">{{'client.notification_title_mission_extension'|translate}}</h5>


    <div class="p-margin">



      <div class="row" *ngIf="!isExtension">
        <div class="col">{{'label.address'|translate}}</div>
        <div class="col">{{request.missionStreetAddress}}, {{request.missionCityAddress}}, {{request.missionZipcodeAddress}}</div>



      </div>

      <div class="row"  *ngIf="!isExtension">
        <div class="col">{{'client.date'|translate}}</div>
        <div class="col">{{request.missionStartDate | date :"dd MMM, y"}}</div>

      </div>



      <div class="row"  *ngIf="!isExtension">

        <div class="col">{{'client.start_time'|translate}}</div>
<!--        <div class="col">{{request.missionStartDate |  slice:11:16}}</div>-->
        <div class="col">{{request.missionStartDate}}</div>
      </div>



      <div class="row"  *ngIf="!isExtension">

        <div class="col">{{'client.end_time'|translate}}</div>
<!--        <div class="col">{{request.missionEndDate | slice:11:16}}</div>-->
        <div class="col">{{request.missionEndDate}}</div>
      </div>

      <div class="row"  *ngIf="!isExtension">


        <div class="col">{{'client.gender'|translate}}</div>
        <div class="col">{{request.gender}}</div>
      </div>


      <div class="row"  *ngIf="!isExtension">


        <div class="col">{{'client.dress_code'|translate}}</div>
        <div class="col">{{request.missionDressCode}}</div>
      </div>
      <div class="row"  *ngIf="!isExtension">
        <div class="col">{{'nbs.businessownermanager.manageagents.missionInfo.duration'|translate}}</div>
        <div class="col">{{request.missionDuration}}{{request.missionDuration > 1 ? 'hrs' : 'hr'}}</div>
      </div>
      <div class="row"  *ngIf="!isExtension">
        <div class="col">{{'client.total'|translate}}</div>
        <div class="col">{{request.missionPrice | currency:'EUR'}}</div>
      </div>

      <div class="row"  *ngIf="isExtension">
        <div class="col">{{'client.total'|translate}}</div>
        <div class="col">{{request.priceForExtension | currency:'EUR'}}</div>
      </div>
    </div>

    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">

      <div class="col" (click)="cancelDialog()">
        <button mat-button class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px;background-color: white;color: black" (click)="cancelDialog()">
          {{'client.cancel'|translate}}
        </button>


      </div>
      <div *ngIf="isError" class="col" (click)="okDialog()">
        <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="okDialog()">
          {{'client.ok'|translate}}
        </button>


      </div>

      <div class="col" (click)="confirm()">
        <button *ngIf="isConfirmation" mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="confirm()">
          {{'client.confirm'|translate}}
        </button>

      </div>


    </div>
  </mat-card>
</div>
