import {Component, OnInit} from '@angular/core';
import {ClientUserService} from "../service/client-user.service";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse, Mission} from "../../model";
import {StorageService} from "../../service/storage.service";
import {UserProfile} from "../model/user-profile";
import {ClientSingletonService} from "../service/client-singleton.service";
import {MessagingService} from "../../service/messaging.service";
import {PushNotification} from "../model/push-notification";
import {MissionService} from "../service/mission.service";
import {MissionDialogComponent} from "../dialogs/mission-dialog/mission-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ApproveDialogComponent} from "../dialogs/approve-dialog/approve-dialog.component";
import {UpdateMissionRequest} from "../model/update-mission-request";
import {Router} from "@angular/router";
import {interval, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {getMissionProgress, getMissionRequest} from "../../store";
import {MissionProgress} from "../model/mission-progress";
import {CREATE_MISSION_REQUEST} from "../../store/mission-request/mission-request.actions";
import {CREATE_MISSION_PROGRESS_REQUEST} from "../../store/mission-progress/mission-progress.actions";
import {CREATE_AuthProfileUser} from "../../store/auth-profile-user-store/auth-profile-user-store.actions";
import {CREATE_AGENT, DELETE_AGENT} from "../../store/agent-store/agent.actions";
import {MissionAgent} from "../model/mission-agent";
import {FileService} from "../service/file.service";
import {TriggerService} from "../../service/trigger.service";

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {

  isAgent: boolean = false;
  isDialogShown: boolean = false;
  userProfile: any;
  message: any;
  mySubscription: Subscription
  private missionProgress: MissionProgress[];
  pathBase64: string;

  constructor(private clientUserService: ClientUserService,
              private storageService: StorageService,
              private clientSingletonService: ClientSingletonService,
              private messagingService: MessagingService,
              private missionService: MissionService,
              private dialog: MatDialog,
              private router: Router,
              private store: Store,
              private fileService: FileService,
              private triggerService: TriggerService
  ) {
    this.mySubscription = interval(5000).subscribe((x => {
      this.checkNotification();
    }));
  }
  changeType(value:any) {
    this.triggerService.trigger(value);
    this.router.navigate(['client/sub-registration'])
  }
  checkNotification(): void {
    const notification = this.storageService.getItem("NOTIFICATION");

    if (notification) {
      const pushNotification = <PushNotification>JSON.parse(notification);
      this.getMissionByMissionId(pushNotification)

    }
  }

  async ngOnInit(): Promise<void> {
    navigator.serviceWorker.addEventListener('message', function (event) {
      console.log('Received a message from service worker: ', event.data);
      localStorage.setItem("NOTIFICATION", JSON.stringify(event.data));
    });
     this.clientUserService.getProfile(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!).subscribe((result: CustomResponse) => {

      if (result.result === "OK") {
        const profile = <UserProfile>result.data;
        this.storageService.saveObject(StorageService.CLIENT_AGENT_PROFILE, profile)
        this.storageService.saveString(StorageService.USER_CATEGORY, profile.userType)
        this.userProfile = profile;
        if (profile.userType === 'AGENT') {
          this.isAgent = true;
        }

      }

    })
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage

    this.messagingService.currentMessage.subscribe((pN) => {

      if (pN) {
        this.getMissionByMissionId(pN)
      }
    });
    this.store.select(getMissionProgress).subscribe((value) => {
      if (value) {
        this.missionProgress = value;
      }
    })
    const pathBase64 = await this.getProfile(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!);
    if (pathBase64 && pathBase64.length > 10) {
      this.pathBase64 = pathBase64;
    }
  }


  getMissionByMissionId(pushNotification: PushNotification): void {

    if (pushNotification.missionId) {
      this.missionService.clientGetMission(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!, pushNotification.missionId)
        .subscribe(response => {
          console.log(response);
          if (response.result === 'OK') {
            const mission = <Mission>response.data;

            this.showDialog(mission, pushNotification);

          } else {
            this.storageService.deleteItem("NOTIFICATION");
          }

        }, error => {
        });
    }

  }

  showDialog(mission: Mission, pushNotification: PushNotification): void {
    this.storeNotificationAgent(mission, pushNotification);
    if (this.isDialogShown) {
      return;
    }
    this.isDialogShown = true;
    const dialogRef = this.dialog.open(ApproveDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: {
          mission: mission,
          pushNotification: pushNotification
        },
        disableClose: false
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.isDialogShown = false;
      this.storageService.deleteItem("NOTIFICATION");
      if (result && result.action === 'APPROVE') {
        const pushNotification = <PushNotification>result.pushNotification;

        if (pushNotification.missionNotificationType === 'CLIENT_APPROVE_AGENT_ARRIVE') {
          const updateMissionRequest = new UpdateMissionRequest();
          updateMissionRequest.agentMobileNumber = (pushNotification.agentPhoneNumber);
          updateMissionRequest.clientMobileNumber = (pushNotification.clientPhoneNumber);
          updateMissionRequest.gender = ("");
          updateMissionRequest.missionId = (pushNotification.missionId);
          updateMissionRequest.status = ("");
          this.missionService.clientConfirmMissionAgentArrival(updateMissionRequest).subscribe((response) => {
            if (response.result === 'OK') {
              console.log('Mission arrival approved')
            }
          });

        } else if (pushNotification.missionNotificationType === 'CLIENT_APPROVE_MISSION_START') {
          const updateMissionRequest = new UpdateMissionRequest();
          updateMissionRequest.agentMobileNumber = (pushNotification.agentPhoneNumber);
          updateMissionRequest.clientMobileNumber = (pushNotification.clientPhoneNumber);
          updateMissionRequest.gender = ("");
          updateMissionRequest.missionId = (pushNotification.missionId);
          updateMissionRequest.status = ("");
          const agent = new MissionAgent();
          agent.agentPhoneNumber = pushNotification.agentPhoneNumber;
          this.missionService.clientConfirmMissionStart(updateMissionRequest).subscribe((response) => {

            const missionProgress = Array<MissionProgress>();

            if (response.result === 'OK') {
              const mP1 = new MissionProgress();
              mP1.missionId = `${pushNotification.missionId}`;
              mP1.agent = agent;
              missionProgress.push(mP1);


              this.store.dispatch({type: CREATE_MISSION_PROGRESS_REQUEST, payload: missionProgress});

              this.clientSingletonService.missionStarted.emit(true);
              console.log('Mission start approved')

              this.router.navigate([`client/mission-in-progress/${pushNotification.missionId}`])

            }

          });

        } else if (pushNotification.missionNotificationType === 'CLIENT_APPROVE_MISSION_END') {
          const updateMissionRequest = new UpdateMissionRequest();
          updateMissionRequest.agentMobileNumber = (pushNotification.agentPhoneNumber);
          updateMissionRequest.clientMobileNumber = (pushNotification.clientPhoneNumber);
          updateMissionRequest.gender = ("");
          updateMissionRequest.missionId = (pushNotification.missionId);
          updateMissionRequest.status = ("");
          this.missionService.clientConfirmEndMission(updateMissionRequest).subscribe((response) => {
            if (response.result === 'OK') {
              console.log('Mission end approved')
              this.router.navigate([`client/mission/${pushNotification.missionId}/rate`])

            }
          });

        } else if (pushNotification.missionNotificationType === 'CLIENT_APPROVE_MISSION_END') {

          this.router.navigate([`client/ongoing-mission/${pushNotification.missionId}`])


        }
      }
    });
  }

  ongoingMissions(): void {
    this.router.navigate(['client/ongoing-missions'])
  }

  upcomingMissions(): void {
    this.router.navigate(['client/upcoming-missions'])
  }

  bookAgent(): void {
    this.router.navigate(['client'])
  }

  pastMissions(): void {
    this.router.navigate(['client/past-missions'])
  }

  tariffs(): void {
    this.router.navigate(['client/tariffs'])
  }

  storeNotificationAgent(mission: Mission, pushNotification: PushNotification) {

    this.store.dispatch({type: DELETE_AGENT, payload: null});
    this.clientUserService.getProfile(pushNotification.agentPhoneNumber).subscribe(result => {
      if (result.result === "OK") {
        const profile = <UserProfile>result.data;
        profile.mission = mission;
        profile.pushNotification = pushNotification;
        this.store.dispatch({type: CREATE_AGENT, payload: profile});

      }
    })

  }

  missionsWaiting() {
    this.router.navigate(['client/waiting-missions'])
  }


  getProfile(phoneNumber: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.clientUserService.getProfile(phoneNumber).subscribe(result => {
        if (result.result === "OK") {
          const profile = <UserProfile>result.data;

          this.fileService.getFile(profile.agentPhotoPath).subscribe(value => {

            if (value) {

              resolve(`data:image/png;base64,${value.base64}`);

            }
          });

        } else {
          resolve("");
        }
      }, error => {
        resolve("");
      });


    });

  }
}
