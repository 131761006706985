import {Component, OnInit, ViewChild} from '@angular/core';
import {AgentStatistics, Mission} from "../../../model";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {MissionService} from "../../../service/mission.service";
import {ExecutedMissionDatasource} from "../../../service/datasource/executed-mission-datasource";
import {tap} from "rxjs/operators";
import {ViewmissionComponent} from "../viewmission/viewmission.component";

@Component({
  selector: 'app-agentexecutedmissions',
  templateUrl: './agentexecutedmissions.component.html',
  styleUrls: ['./agentexecutedmissions.component.css']
})
export class AgentexecutedmissionsComponent implements OnInit {
  searchValue="";

  resultsLength = 0;
  // @ts-ignore
  executedMissionsDatasource: ExecutedMissionDatasource ;

  columnsToDisplay = ['checkbox','missionInfo','viewmore'];
  private paginator: MatPaginator | undefined;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
   // this.setDataSourceAttributes();
  }


  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];
  missionType: string ='all';


  constructor(private missionService:MissionService, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.executedMissionsDatasource = new ExecutedMissionDatasource(this.missionService);
    this.executedMissionsDatasource?.loadMissions();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.executedMissionsDatasource.loadFiltered(filterValue);
  }


  ngAfterViewInit() {
    this.executedMissionsDatasource?.counter$
      .pipe(
        tap((count) => {
          // @ts-ignore
          this.paginator.length = count;
        })
      )
      .subscribe();

    // @ts-ignore
    this.paginator.page
      .pipe(
        tap(() => this.loadMissions())
      )
      .subscribe();
  }

  loadMissions() {
    // @ts-ignore
    this.executedMissionsDatasource?.loadMissions(this.paginator.pageIndex, this.paginator.pageSize,this.missionType);
  }

  openViewMission(data:any): void {
    const dataToSend={mission:data,type:''}
    const dialogRef = this.dialog.open(ViewmissionComponent
      , {
        width: '65%',
        height: '80%',
        data:dataToSend
        // backdropClass: 'bg-color' // This is the "wanted" line
      });
  }
}
