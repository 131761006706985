
<ng-container>


    <form [formGroup]="rForm" class="myFormStyle"  (ngSubmit)="confirmSave()">

     
        <div  >
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label for="agencyName">Agency name</label>
                        <input type="text" class="myInput" formControlName="agencyName" id="agencyName"   placeholder="">

                        <span [class.hidden]="rForm['controls'].agencyName.valid || !submitted" class="help-block">
                            Field required
                            </span>
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="emailAddress">Email</label>
                        <input type="email" class="myInput" id="emailAddress" formControlName="emailAddress"  placeholder="">
                    </div>
                    
        
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
    
                    <div class="form-group">
                        <label for="vatNumber">Phone Number</label> <br>
                        <!-- <input type="email" class="myInput" id="vatNumber" formControlName="vatNumber"  placeholder=""> -->
                            <ngx-intl-tel-input
                                [cssClass]="'myInput myInputPhoneNumberStyle'"
                                [preferredCountries]="[CountryISO.Belgium, CountryISO.UnitedKingdom]"
                                [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="false"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.Belgium"
                                [phoneValidation]="true"
                                formControlName="phoneNumber"
                                width="100%"
                            ></ngx-intl-tel-input>

                            <span [class.hidden]="rForm['controls'].phoneNumber.valid || !submitted" class="help-block">
                                Field required
                                </span>
                    </div>
                    
        
                </div>
    
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="vatNumber">VAT Number</label>
                        <input type="email" class="myInput" id="vatNumber" formControlName="vatNumber"  placeholder="">
                    </div>
                    
        
                </div>
            </div>
    
        </div>
       
        
        <footer>
            <button  type="submit" class="btn btn-success">
              Submit
            </button>
            <button type="button" class="btn btn-default" (click)="cancel()">
              Cancel
            </button>

          </footer>
      </form>

    
</ng-container>