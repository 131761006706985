import {Component, OnInit} from '@angular/core';
import {CustomResponse, Mission} from "../../model";
import {AccountTransaction} from "../model/account-transaction";
import {MissionAgent} from "../model/mission-agent";
import {MissionService} from "../service/mission.service";
import {UtilService} from "../service/util.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientUserService} from "../service/client-user.service";
import {CREATE_AGENT, DELETE_AGENT} from "../../store/agent-store/agent.actions";
import {UserProfile} from "../model/user-profile";
import {PushNotification} from "../model/push-notification";
import {Store} from "@ngrx/store";
import {FileService} from "../service/file.service";

@Component({
  selector: 'app-past-mission-view',
  templateUrl: './past-mission-view.component.html',
  styleUrls: ['./past-mission-view.component.css']
})
export class PastMissionViewComponent implements OnInit {
  mission: Mission;
  isPastMission: boolean;
  missionId: string;
  accountTransactions: AccountTransaction[];
  agents: MissionAgent[] = [];

  greyStart="../../../assets/images/jpg/grey_star.jpg";
  greenStart="../../../assets/images/jpg/green_star.jpg";

  rating1:any =[]
  rating2:any=[]
  rating3:any=[]
  rating4:any=[]
  rating5:any=[]

  constructor(private route: ActivatedRoute,
              private missionService: MissionService,
              private utilService: UtilService,
              private clientUserService: ClientUserService,
              private router: Router,
              private store: Store,
              private fileService: FileService,
  ) {

    this.route.params.subscribe(params => {
      this.missionId = params['missionId'];

    });
  }

  ngOnInit(): void {
    this.getMissionByMissionId();
  }


  getMissionByMissionId(): void {


    this.missionService.clientGetMission(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!, this.missionId)
      .subscribe(response => {
        console.log(response);
        if (response.result === 'OK') {
          this.mission = <Mission>response.data;
          this.getMissionAgents();
          this.getTransactions(this.missionId);

        }

      });


  }

  getTransactions(missionId: string) {

    this.missionService.getMissionTransactions(missionId).subscribe(response => {

      if (response && response.result === 'OK') {
        this.accountTransactions = <AccountTransaction[]>response.data.data;
      }

    });

  }

  title(reference: string): string {

    if (reference.includes('NBS_M_E_')) {
      return 'Mission Extension';
    }

    return 'Mission Creation';
  }

  amount(amount: number): string {

    return this.utilService.toDecimal(amount);
  }

  isSuccess(state: string): boolean {

    if (state === 'COMPLETED') {
      return true;
    }
    return false;
  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(`${this.mission.id}`).subscribe(async (response) => {

        if (response.result === "OK") {
            this.agents = <MissionAgent[]>response.data;

            if (this.agents && this.agents.length > 0) {
              var i=0;
                for (const value of this.agents) {

                  this.resetAndSetRating(i,value.rating)
                    const path = await this.getProfile(value.agentPhoneNumber);

                    if(path && path.length >10){
                        value.path = path;
                    }
                    i++;
                }
            }
        }

    });
  }

  rateAgent(agent: MissionAgent) {


    this.store.dispatch({type: DELETE_AGENT, payload: null});
    this.clientUserService.getProfile(agent.agentPhoneNumber).subscribe(result => {
      if (result.result === "OK") {
        const profile = <UserProfile>result.data;
        profile.mission = this.mission;
        profile.pushNotification = new PushNotification();
        this.store.dispatch({type: CREATE_AGENT, payload: profile});
        this.router.navigate([`client/mission/${this.missionId}/rate`]);
      }
    })

  }



  getProfile(phoneNumber: string): Promise<string> {

      return new Promise((resolve, reject) => {

      this.clientUserService.getProfile(phoneNumber).subscribe(result => {
          if (result.result === "OK") {
              const profile = <UserProfile>result.data;

              this.fileService.getFile(profile.agentPhotoPath).subscribe(value => {

                  if(value){

                      resolve( `data:image/png;base64,${value.base64}`);

                  }
              });

          }else{
              resolve("");
          }
      },error => {
          resolve("");
      });


      });

  }

  resetRating(){

    this.rating1[0]=this.greyStart
    this.rating2[0]=this.greyStart
    this.rating3[0]=this.greyStart
    this.rating4[0]=this.greyStart
    this.rating5[0]=this.greyStart
  }


  resetAndSetRating(index:number,rating:number){

    this.resetRating();
    
    if(rating==1){
     
      this.rating1[index]=this.greenStart
    }else if(rating==2){

      this.rating1[index]=this.greenStart
      this.rating2[index]=this.greenStart
       
    }else if(rating==3){
      this.rating1[index]=this.greenStart
      this.rating2[index]=this.greenStart
      this.rating3[index]=this.greenStart
       
    }else if(rating==4){
      this.rating1[index]=this.greenStart
      this.rating2[index]=this.greenStart
      this.rating3[index]=this.greenStart
      this.rating4[index]=this.greenStart
       
    }else if(rating==5){
      this.rating1[index]=this.greenStart
      this.rating2[index]=this.greenStart
      this.rating3[index]=this.greenStart
      this.rating4[index]=this.greenStart
      this.rating5[index]=this.greenStart
    }
  }
}
