import {Component, Input, OnInit} from '@angular/core';
import {Mission} from "../../../model";
import {MissionDialogComponent} from "../../dialogs/mission-dialog/mission-dialog.component";
import {MissionService} from "../../service/mission.service";
import {ClientUserService} from "../../service/client-user.service";
import {ClientSingletonService} from "../../service/client-singleton.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MissionProgress} from "../../model/mission-progress";
import {getMissionProgress} from "../../../store";
import {MissionUpdateRequest} from "../../model/mission-request";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-mission-card',
  templateUrl: './mission-card.component.html',
  styleUrls: ['./mission-card.component.css']
})
export class MissionCardComponent implements OnInit {

  @Input() mission: Mission;
  private missionProgress: MissionProgress[];
  @Input() pending: boolean;

  constructor(private missionService: MissionService,
              private clientUserService: ClientUserService,
              private clientSingletonService: ClientSingletonService,
              private router: Router,
              private dialog: MatDialog,
              private store: Store,
              private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {

    this.store.select(getMissionProgress).subscribe((value) => {
      if (value) {
        this.missionProgress = value;

      }
    })
  }

  showDialog(mission: Mission): void {
    if (mission.missionStatus === 'COMPLETED') {
      this.router.navigate([`client/past-mission/${mission.id}`])

    } else if (mission.numberOfAgents > 1) {
      this.router.navigate([`client/ongoing-mission/${mission.id}`])

    } else if (mission.missionStatus === 'INITIATED') {


      if (this.pending) {
        const dialogRef = this.dialog.open(MissionDialogComponent
          , {
            width: '550px',
            backdropClass: 'bg-color',
            data: {
              mission: mission,
              pending: true
            },
            disableClose: false
          });

        dialogRef.afterClosed().subscribe((result: any) => {

          if (result === 'CANCEL_MISSION') {

          }
        });
      } else {
        this.router.navigate([`client/ongoing-mission/${mission.id}`])
      }

    } else {
      this.router.navigate([`client/ongoing-mission/${mission.id}`])

    }
  }


  mapStatus(status: string, missionId: number): string {
    if (status === 'INITIATED') {

      return 'icons_pending';
    } else if (status === 'ACCEPTED') {

      return 'icons_ongoing_missions';
    } else {


      return ''
    }
  }

}
