import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpLoaderFactory} from "../app.module";
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";

import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSliderModule} from "@angular/material/slider";
import {MatDividerModule} from "@angular/material/divider";
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {IntlTelInputNgModule} from "intl-tel-input-ng";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {RatingModule} from 'ng-starrating';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ClientRoutingModule} from "./client-routing.module";
import {ClientDashboardComponent} from './client-dashboard/client-dashboard.component';
import {BookAgentDashboardComponent} from './book-agent-dashboard/book-agent-dashboard.component';
import {BookAgentHeaderComponent} from './book-agent-header/book-agent-header.component';
import {BookAgentPlannedComponent} from './book-agent-planned/book-agent-planned.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {BrowserModule} from "@angular/platform-browser";
import {AgmCoreModule} from "@agm/core";
import {environment} from "../../environments/environment";
import {ConfirmationDialogComponent} from './dialogs/confirmation-dialog/confirmation-dialog.component';
import {BookAgentSummaryDialogComponent} from './dialogs/book-agent-summary-dialog/book-agent-summary-dialog.component';
import {CheckOutComponent} from "./subscription/check-out/check-out.component";
import {GatewayPaymentComponent} from "./subscription/gateway-payment/gateway-payment.component";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { UpcomingMissionsComponent } from './upcoming-missions/upcoming-missions.component';
import { MissionDialogComponent } from './dialogs/mission-dialog/mission-dialog.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import { ApproveDialogComponent } from './dialogs/approve-dialog/approve-dialog.component';
import { OngoingMissionsComponent } from './ongoing-missions/ongoing-missions.component';
import { OngoingMissionComponent } from './ongoing-mission/ongoing-mission.component';
import {BookAgentInstantComponent} from "./book-agent-instant/book-agent-instant.component";
import { TimePickerComponent } from './dialogs/time-picker/time-picker.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import {
  CompareDirectiveFirsNameDirective,
  CompareDirectiveLastNameDirective, CompareDirectiveMiddleNameDirective,
  CompareDirectivePassDirective
} from "./validators/customValidators";
import {OtpComponent} from "./authentication/otp/otp.component";
import {NgOtpInputModule} from "ng-otp-input";
import {MatRadioModule} from "@angular/material/radio";
import { AddressDialogComponent } from './dialogs/address-dialog/address-dialog.component';
import {SignInComponent} from "./authentication/sign-in/sign-in.component";
import { MissionCardComponent } from './components/mission-card/mission-card.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import { MissionBottomSheetComponent } from './components/mission-bottom-sheet/mission-bottom-sheet.component';
import { MissionInProgressComponent } from './mission-in-progress/mission-in-progress.component';
import {CountdownModule} from "ngx-countdown";
import { AgentRatingComponent } from './agent-rating/agent-rating.component';
import {NgxStarRatingModule} from "ngx-star-rating";
import {ProgressInterceptorService} from "./service/progress-interceptor.service";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { ClientHeaderComponent } from './client-header/client-header.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { PastMissionsComponent } from './past-missions/past-missions.component';
import { PastMissionViewComponent } from './past-mission-view/past-mission-view.component';
import {AgmDirectionModule} from "agm-direction";
import { MissionExtensionDialogComponent } from './dialogs/mission-extension-dialog/mission-extension-dialog.component';
import { AgentExtensionWaitingDialogComponent } from './dialogs/agent-extension-waiting-dialog/agent-extension-waiting-dialog.component';
import { FavoriteAgentBookingComponent } from './dialogs/favorite-agent-booking/favorite-agent-booking.component';
import { TarrifsComponent } from './tarrifs/tarrifs.component';
import {WaitingMissionsComponent} from "./waiting-missions/waiting-missions.component";
import { RegisterUserComponent } from './register-user/register-user.component';


const modules = [
 NgxMaterialTimepickerModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSliderModule,
  MatDividerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatStepperModule,
  MatSnackBarModule,
  FlexLayoutModule,
  FormsModule,
  MatSidenavModule,
  MatSelectModule,
  LayoutModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  ReactiveFormsModule, IntlTelInputNgModule.forRoot(),
  MatTabsModule,
  HttpClientModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  BrowserAnimationsModule,
  ToastrModule.forRoot(),
  RatingModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatBottomSheetModule,
  MatProgressBarModule
];

@NgModule({
    declarations: [


        ClientDashboardComponent,
        BookAgentDashboardComponent,
        BookAgentHeaderComponent,
        BookAgentPlannedComponent,
        ConfirmationDialogComponent,
        BookAgentSummaryDialogComponent,
        CheckOutComponent,
        GatewayPaymentComponent,
        PaymentStatusComponent,
        UpcomingMissionsComponent,
        MissionDialogComponent,
        ApproveDialogComponent,
        OngoingMissionsComponent,
        OngoingMissionComponent,
        BookAgentInstantComponent,
        TimePickerComponent,
        RegistrationComponent,
        CompareDirectivePassDirective,
        CompareDirectiveFirsNameDirective,
        CompareDirectiveLastNameDirective,
        CompareDirectiveMiddleNameDirective,
        OtpComponent,
        AddressDialogComponent,
        SignInComponent,
        MissionCardComponent,
        MessageDialogComponent,
        MissionBottomSheetComponent,
        MissionInProgressComponent,
        AgentRatingComponent,
        ClientHeaderComponent,
        AgentRatingComponent,
        PastMissionsComponent,
        PastMissionViewComponent,
        MissionExtensionDialogComponent,
        AgentExtensionWaitingDialogComponent,
        FavoriteAgentBookingComponent,
        TarrifsComponent,
        WaitingMissionsComponent,
        RegisterUserComponent
    ],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.maps_api_key,
            libraries: ['places', 'drawing', 'geometry', 'directions', 'direction']
        }),
        NgOtpInputModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        ClientRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        modules,
        CountdownModule,
        NgxStarRatingModule,
        NgxSpinnerModule,
        AgmDirectionModule
    ], schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    exports: [
        ClientHeaderComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProgressInterceptorService,
            multi: true
        }
    ]
})
export class ClientModule {
}
