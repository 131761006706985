<app-header></app-header>
<div  fxLayout="row" >
  <div fxFlex fxLayout="column" fxLayoutGap="10px" >
  </div>

</div>

<div fxLayout="row" >
  <div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">


    <div class="welcome" style="text-align: center; margin-bottom: 10px;  margin-top: 30px; padding-left: 40%">
      <mat-card class="third " style="text-align: center" >
        <img src="assets/images/svg/welcome_.svg">
        <p style="color: var(--primary);font-size:  44.71px ;padding: 20px;  font-weight: bold;margin-top: 30px;" translate>
          nbs.landing.label.welcome</p>
      </mat-card>
    </div>

    <div style="text-align: center;margin-top: 30px;">
      <p style="color: var(--primary);font-size: 24px;letter-spacing: 0.368903px; font-weight: normal;" translate>nbs.landing.label.selectcategory</p>
    </div>
    <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 100px;">
      <!-- loop over the cardList and show the cards -->
      <div
        fxFlex="25"
        fxFlex.md="33"
        fxFlex.sm="50"
        fxFlex.xs="100"
        fxLayout="column"
        style="padding: 5px;"
      >
        <mat-card matRipple matRippleColor="var(--light-grey)" class="  landingcard" style="text-align: center" >
          <img src="assets/images/svg/partnermanager.svg"/>
          <p style="color: var(--primary);font-size: 16px;padding: 20px;  font-weight: bold;" translate>nbs.landing.label.partnermanager
            <mat-icon svgIcon="arrow-right" style="margin-right: 5px"></mat-icon>
          </p>
        </mat-card>
      </div>
      <div
        fxFlex="25"
        fxFlex.md="33"
        fxFlex.sm="50"
        fxFlex.xs="100"
        fxLayout="column"
        style="padding: 5px;"
      >
        <mat-card class=" landingcard" style="text-align: center">
          <img src="assets/images/svg/clientmanager.svg"/>
          <p style="color: var(--primary) ;font-size: 16px;padding: 20px;font-weight: bold;" translate>nbs.landing.label.clientmanager</p>
        </mat-card>
      </div>
      <div
        fxFlex="25"
        fxFlex.md="33"
        fxFlex.sm="50"
        fxFlex.xs="100"
        fxLayout="column"
        style="padding: 5px;"
      >
        <mat-card class=" landingcard" style="text-align: center" >
          <img src="assets/images/svg/employmentagencymanager.svg"/>
          <p style="color: var(--primary) ;font-size: 16px;padding: 20px;font-weight: bold;" translate>nbs.landing.label.employmentagencymanager</p>
        </mat-card>
      </div>
      <div
        fxFlex="25"
        fxFlex.md="33"
        fxFlex.sm="50"
        fxFlex.xs="100"
        fxLayout="column"
        style="padding: 5px;"
      >
        <mat-card class=" landingcard" style="text-align: center" (click)="openSignInDialog()" >
          <img src="assets/images/svg/businessownermanager.svg"/>
          <p style="color: var(--primary) ;font-size: 16px;padding: 20px;font-weight: bold;" translate>nbs.landing.label.businessownermanager</p>
        </mat-card>
      </div>

    </div>
  </div>

</div>
