<div>

  <mat-card style=" height: auto">
    <h1 style="color: black;text-align: center" class="mt-lg-4">{{message}}</h1>

    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">

      <div *ngIf="agentAcceptedMission" class="col" (click)="okDialog()">
        <button mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="okDialog()">
          {{'client.ok'|translate}}
        </button>


      </div>
    </div>
  </mat-card>
</div>
