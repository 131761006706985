import {Observable, throwError as observableThrowError} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {catchError, finalize, map} from 'rxjs/operators';
import {ClientSingletonService} from "./client-singleton.service";

@Injectable()
export class ProgressInterceptorService implements HttpInterceptor {

  constructor(private progressService: ClientSingletonService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.progressService.loadingProgressBar.emit(true);
    return next.handle(req)
      .pipe(
        map(event => {
          return event;
        }),
        catchError(error => {
          return observableThrowError(error);
        }),
        finalize(() => {
          this.progressService.loadingProgressBar.emit(false);
        })
      );

  }

}
