import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { CustomResponse } from 'src/app/model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {

  // "id": 0,
  // "accountId": 0,
  // "createdDate": "",
  // "mobileNumber": "32470101700",
  // "firstName": "user2",
  // "lastName": "user2",
  // "email": "user2@s-mobile.be",
  // "category": "",
  // "status": 0,
  // "username": "32470101700",
  // "password": "DATeNumP",
  // "confirmPassword": "DATeNumP",
  // "groupId": 100

  e164Number: string|any ;

  rForm: FormGroup;

  phoneNumber='';
  separateDialCode = false;
	SearchCountryField = SearchCountryField;

	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  submitted:boolean=false;

  userGroups:any

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private userService: UsersService,
    public authService: AuthenticationService,
  ) { 
    this.rForm=this.fb.group({ 
      mobileNumber: ['',Validators.required],
      username: [''],
      firstName: ['',Validators.required],
      lastName:["",Validators.required],
      email : [""],
      password:["",Validators.required],
      confirmPassword:["",Validators.required],
      groupId:[101,Validators.required],
      language:["",Validators.required]
    });

    this.getUserGroup();
  }

  ngOnInit(): void {
  }

  confirmSave(){

    this.submitted=true;

    
    if (this.rForm.valid) {

      this.phoneNumber=this.rForm.value.mobileNumber.e164Number
      
      this.rForm.get('mobileNumber')?.setValue(this.phoneNumber.startsWith("+")?this.phoneNumber.substr(1):this.phoneNumber)
      this.rForm.get('username')?.setValue(this.phoneNumber.startsWith("+")?this.phoneNumber.substr(1):this.phoneNumber)


      var sub=this.authService.saveUser(this.rForm.value).subscribe(async value => {

        if (value.success === true) {
            this.toastr.success("Save successfully","ADD");
            this.dialogRef.close();
        } else {
          this.toastr.error("Error in processing");
        }
        sub.unsubscribe();
      }, error => {
        sub.unsubscribe();
        this.toastr.error("Error in processing");
      });
  

    }


   
  }

  cancel(){
    this.dialogRef.close();
  }

  getUserGroup(){

    var sub=this.userService.getUserGroup().subscribe((result:any)=>{

      if(result){
        this.userGroups=result ;
      }else{
       
      }

      sub.unsubscribe();
    },error=>{
      
      sub.unsubscribe();
      
    });
  }

}
