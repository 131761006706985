import {Address} from "./address";

export class ClientUser {

  id: number;
  firstName: string;
  lastName: string;
  // auth server
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  groupId: number;
  userPhoneNumber: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  qualification: string;
  gender: string;
  dressCode: string;
  nationalRegister: string;
  bankAccountNumber: string;
  job: string;
  distanceRestriction: number;
  agentPhotoFileName: string;
  agentProfessionalCardFrontFileName: string;
  agentProfessionalCardBackFileName: string;
  userType: string;
  businessType: string;
  streetAddress:Address[]=[];
  city: string;
  zipCode: string;
  country:string;
  businessEmailAddress: string;
  businessPhoneNumber: string;
  businessName: string;
  vatNumber: string;
  businessStreetAddress: string;
  businessAddressCity: string;
  businessAddressZipCode: string;
  language: string;
}
