<mat-card class="h-100 mt-lg-3">


  <mat-tab-group>
    <mat-tab label="Ongoing missions">
      <div class="c-align" *ngIf="missions && missions.length > 0"><h3>{{'client.ongoing_missions'|translate}}</h3></div>

      <div *ngIf="missions && missions.length > 0">

        <div *ngFor="let mission of missions">
          <app-mission-card [mission]="mission"></app-mission-card>
        </div>
      </div>

      <div class="col mt-5 c-align" *ngIf="missions && missions.length <= 0">
        <div class="col">
          <mat-icon svgIcon="icons_vacant" inline="true"></mat-icon>
        </div>
        <div class="col">
          <p style="color: black;font-size: 16px">
            {{'client.no_ongoing_missions_at_the_moment'|translate}}
          </p>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="Upcoming missions">

      <app-upcoming-missions></app-upcoming-missions>
    </mat-tab>
  </mat-tab-group>


</mat-card>

