import {Action} from '@ngrx/store';
import {AuthProfileUser} from "../../client/model/auth-profile-user";

export const CREATE_AuthProfileUser = 'CREATE_AuthProfileUser';
export const GET_AuthProfileUser = 'CREATE_AuthProfileUser';
export const DELETE_AuthProfileUser = 'DELETE_AuthProfileUser';




export class CreateAuthProfileUser implements Action {
  type = CREATE_AuthProfileUser;
  constructor(public payload: AuthProfileUser) { }
}


export class GetAuthProfileUser implements Action {
  type = GET_AuthProfileUser;
  constructor(public payload: AuthProfileUser) { }
}


export class DeleteAuthProfileUser implements Action {
  type = DELETE_AuthProfileUser;
  constructor(public payload: AuthProfileUser) { }
}

export type AuthProfileUserStoreActions
  = CreateAuthProfileUser |
  GetAuthProfileUser |
  DeleteAuthProfileUser;
