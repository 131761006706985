import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Mission, MissionInvoice} from "../../../model";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MissionAgent} from "../../model/mission-agent";
import {ClientSingletonService} from "../../service/client-singleton.service";
import {Router} from "@angular/router";
import {getMissionProgress} from "../../../store";
import {Store} from "@ngrx/store";
import {MissionProgress} from "../../model/mission-progress";
import {CREATE_MISSION_PROGRESS_REQUEST} from "../../../store/mission-progress/mission-progress.actions";
import {PushNotification} from "../../model/push-notification";
import {CREATE_AGENT, DELETE_AGENT} from "../../../store/agent-store/agent.actions";
import {UserProfile} from "../../model/user-profile";
import {ClientUserService} from "../../service/client-user.service";
import {interval, Subscription} from "rxjs";
import {AgentLocationMessage} from "../../model/agent-location-message";
import {MissionService} from "../../service/mission.service";
import {FileService} from "../../service/file.service";
import {AccountTransaction} from "../../model/account-transaction";
import {environment} from "../../../../environments/environment.prod";
import {DocumentService} from "../../../service/document.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-mission-bottom-sheet',
  templateUrl: './mission-bottom-sheet.component.html',
  styleUrls: ['./mission-bottom-sheet.component.css']
})
export class MissionBottomSheetComponent implements OnInit,OnDestroy {
  typeSelected: string;
  mission: Mission;
  invoices:MissionInvoice[];
  proformaInvoice:string;
  agents: MissionAgent[];
  missionStarted = false;
  private missionProgress: MissionProgress[];
  mySubscription: Subscription;
  constructor(private _bottomSheetRef: MatBottomSheetRef<MissionBottomSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private clientSingletonService: ClientSingletonService,
              private router: Router,
              private store: Store,
              private clientUserService: ClientUserService,
              private missionService:MissionService,
              private documentService:DocumentService,
              private fileService: FileService,
              private spinnerService: NgxSpinnerService


  ) {
    this.typeSelected = 'ball-fussion';
  }

  ngOnInit(): void {
    this.mission = this.data.mission;
    this.agents = this.data.agents;
    this.clientSingletonService.missionStarted.subscribe(missionStarted=>{
      this.missionStarted = missionStarted;
    })

    this.store.select(getMissionProgress).subscribe((value) => {
      if (value) {
        this.missionProgress = value;

        if(this.missionProgress && this.missionProgress.length > 0){
          this.missionProgress.forEach(value1 => {
           if(value1.missionId === `${this.mission.id}`){
             this.missionStarted = true;
           }
          });

        }
      }
    });

    this.mySubscription= interval(15000).subscribe((x =>{

      this.getMissionAgents();
    }));
  }

  extendMission() {

  }

  cancelMission() {

  }

  downloadInvoice(){
    this.spinnerService.show();
    this.missionService.getMissionInvoices(this.mission.mainMissionId).subscribe(response=>{

      if(response && response.result === 'OK'){
        this.invoices=<MissionInvoice[]>response.data
        const result = this.invoices.filter((obj) => {
          return obj.invoiceType === 'proforma';
        });

        this.downloadFile(result[0].fileName);
      }

    });
    this.spinnerService.hide();

  }

  downloadFile(fileName:string){
  this.documentService.getImageAsBase64(fileName).subscribe(response=>{
  console.log("file_response", response);
  if(response){
    let base64String = response.base64;
    let index = response.fileName.lastIndexOf('/');
    let fileName = response.fileName.substring(index + 1);
    this.missionService.downloadPdf(base64String,fileName);
  }

});
}

  missionInProgressMission(agent:MissionAgent) {
    const missionProgress = Array<MissionProgress>();

    const mP1 = new MissionProgress();
    mP1.missionId = `${this.mission.id}`;
    missionProgress.push(mP1);

    this.store.dispatch({type: CREATE_MISSION_PROGRESS_REQUEST, payload: missionProgress});

    this._bottomSheetRef.dismiss();
    this.storeNotificationAgent(this.mission,agent.agentPhoneNumber);

  }

  storeNotificationAgent(mission: Mission, agentPhoneNumber: string) {

    this.store.dispatch({type: DELETE_AGENT, payload: null});
    this.clientUserService.getProfile(agentPhoneNumber).subscribe(result => {
      if (result.result === "OK") {
        const profile = <UserProfile>result.data;
        profile.mission = mission;
        profile.pushNotification = new PushNotification();
        this.store.dispatch({type: CREATE_AGENT, payload: profile});
        this.router.navigate([`client/mission-in-progress/${this.mission.id}`])

      }
    })

  }
  ngOnDestroy(){
    this.mySubscription.unsubscribe();

  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(`${this.mission.id}`).subscribe(async (response) => {

      if (response.result === "OK") {

        this.agents = <MissionAgent[]>response.data;

        if (this.agents && this.agents.length > 0) {
          for (const value of this.agents) {

            const path = await this.getProfile(value.agentPhoneNumber);

            if (path && path.length > 10) {
              value.path = path;
            }

          }
        }
      }


    });
  }

  getProfile(phoneNumber: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.clientUserService.getProfile(phoneNumber).subscribe(result => {
        if (result.result === "OK") {
          const profile = <UserProfile>result.data;

          this.fileService.getFile(profile.agentPhotoPath).subscribe(value => {

            if(value){

              resolve( `data:image/png;base64,${value.base64}`);

            }
          });

        }else{
          resolve("");
        }
      },error => {
        resolve("");
      });


    });

  }
  missionStartedForAgent(agent: MissionAgent) {

    if(agent.missionStatus === 'STARTED'){

      return true;
    }

    return false;
  }
}
