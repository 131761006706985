import {Component, OnInit} from '@angular/core';
import {Mission} from "../../model";
import {MissionAgent} from "../model/mission-agent";
import {ActivatedRoute, Router} from "@angular/router";
import {MissionService} from "../service/mission.service";
import {ClientUserService} from "../service/client-user.service";
import {MessagingService} from "../../service/messaging.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {getAgent, getMissionProgress} from "../../store";
import {MissionProgress} from "../model/mission-progress";
import {DELETE_MISSION_PROGRESS_REQUEST} from "../../store/mission-progress/mission-progress.actions";
import {UpdateMissionRequest} from "../model/update-mission-request";
import {MessageDialog} from "../model/message-dialog";
import {MessageDialogComponent} from "../dialogs/message-dialog/message-dialog.component";
import {UserProfile} from "../model/user-profile";
import {FavoriteAgent} from "../model/favorite-agent";
import {ConfirmationDialogComponent} from "../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-agent-rating',
  templateUrl: './agent-rating.component.html',
  styleUrls: ['./agent-rating.component.css']
})
export class AgentRatingComponent implements OnInit {
  mission: Mission;
  agents: MissionAgent[] = [];
  missionId: string;
  public form: FormGroup;
  private missionProgress: MissionProgress[];
  agent: UserProfile;
  agentsFavs: FavoriteAgent[];
  isFavorite: boolean = false;

  constructor(private route: ActivatedRoute,
              private missionService: MissionService,
              private clientUserService: ClientUserService,
              private messagingService: MessagingService,
              private _bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private router: Router,
              private store: Store,
  ) {
    this.route.params.subscribe(params => {
      this.missionId = params['missionId'];

    });
  }


  ngOnInit(): void {
    this.form = this.fb.group({
      rating: ['', Validators.required],
      reason: ['', null],
    })
    this.store.select(getMissionProgress).subscribe((value) => {
      if (value) {
        this.missionProgress = value;
      }
    })
    this.store.select(getAgent).subscribe((value) => {
      if (value) {
        this.agent = value;
      }
    })
    this.getMissionByMissionId();
    this.getFavoriteAgents();

  }


  getFavoriteAgents(): void {
    this.clientUserService.getFavoriteAgents(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!).subscribe(value => {
      if (value.result === 'OK' && value.data) {
        this.agentsFavs = <FavoriteAgent[]>value.data;

        if (this.agentsFavs && this.agentsFavs.length > 0) {

          this.agentsFavs.forEach(value1 => {
            if (value1.agentPhoneNumber === this.agent.userPhoneNumber) {
              this.isFavorite = true;
            }
          });
        }

      }
    });
  }

  getMissionByMissionId(): void {


    this.missionService.clientGetMission(this.clientUserService.getLoggedInProfile()?.userPhoneNumber!, this.missionId)
      .subscribe(response => {
        console.log(response);
        if (response.result === 'OK') {
          this.mission = <Mission>response.data;
          this.getMissionAgents();
          const mP = new Array<MissionProgress>();
          if (this.missionProgress && this.missionProgress.length > 0) {
            this.missionProgress.forEach(value1 => {

              if (value1.missionId !== `${this.mission.id}`) {
                mP.push(value1);
              }
            });

          }
          this.store.dispatch({type: DELETE_MISSION_PROGRESS_REQUEST, payload: mP});

        }
      });

  }

  getMissionAgents(): void {
    this.missionService.clientGetMissionAgents(this.missionId).subscribe((response) => {

      if (response.result === "OK") {
        this.agents = <MissionAgent[]>response.data;
      }

    });
  }

  leaveRating() {

    const updateMissionRequest = new UpdateMissionRequest();
    updateMissionRequest.agentMobileNumber = this.agent.userPhoneNumber;
    updateMissionRequest.clientMobileNumber = this.clientUserService.getLoggedInProfile()?.userPhoneNumber!;
    updateMissionRequest.missionId = (this.missionId);
    const rating = this.form.get('rating')?.value;
    const reason = this.form.get('reason')?.value;
    if (reason) {
      updateMissionRequest.ratingReason = reason;
    } else {
      updateMissionRequest.ratingReason = '';
    }
    updateMissionRequest.rating = +rating;

    this.missionService.rateAgent(updateMissionRequest).subscribe((response) => {
      if (response.result === 'OK') {
        this.messageDialog('Success', 'Your rating was submitted')
      } else {
        this.messageDialog('Failed', 'Your rating was not submitted')

      }
    });
  }

  toDashboard() {


    this.router.navigate(['client']);
  }


  messageDialog(title: string, message: string) {
    const messagesDialog = new MessageDialog()
    messagesDialog.title = title;
    messagesDialog.description = message;
    messagesDialog.error = true;
    messagesDialog.confirmation = false;
    const dialogRef = this.dialog.open(MessageDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: messagesDialog,
        disableClose: true
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      return;
    });
  }

  addAgentToFavorites(): void {


    const messagesDialog = new MessageDialog();
    const request = new FavoriteAgent();
    request.agentPhoneNumber = this.agent.userPhoneNumber;
    request.clientPhoneNumber = this.clientUserService.getLoggedInProfile()?.userPhoneNumber!;
    request.agentName = this.agent.firstName + ' ' + this.agent.lastName;
    const rating = this.form.get('rating')?.value;
    request.rating = +rating;

    messagesDialog.title = 'Add Agent ' + request.agentName;
    messagesDialog.description = `Are you sure you want to save the agent?`
    messagesDialog.error = false;
    messagesDialog.confirmation = true;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent
      , {
        width: '550px',
        backdropClass: 'bg-color',
        data: messagesDialog,
        disableClose: true
      });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'CONFIRM') {

        this.clientUserService.addAgentAsFavorite(request).subscribe(value => {
          if (value.result === 'OK') {

            this.messageDialog("Agent Added!", "Your agent was added to your favorites");
          } else {

            this.messageDialog("Failed", "Failed to add agent");
          }
        });
      }
    });

  }


}
