import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import {CustomResponse} from "../model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MissionService {

  constructor(private http: HttpClient) {

  }

  getBookedMissions(searchRequest:any): Observable<any> {
    const url = environment.getBookedMissionsUrl;
    return this.http.get<any>(url,{params:searchRequest});
  }

  getExecutedMissions(searchRequest:any): Observable<any> {
    const url = environment.getExecutedMissionsUrl;
    return this.http.get<any>(url,{params:searchRequest});
  }

  getAgentHistoryList(searchRequest: any):Observable<any> {
    const url = environment.getAgentHistoryList;
    return this.http.get<any>(url,{params:searchRequest});
  }

  getRevenueSummary(): Observable<any>{
  const url = environment.getRevenueSummaryUrl;
  return this.http.get<any>(url);
  }
  agentGetCurrentMission(agentNumber:string): Observable<any>{
  const url = `/api/mission/agent/current?phoneNumber=${agentNumber}`;
  return this.http.get<any>(url);
  }


}
