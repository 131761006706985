import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  E164PhoneNumber:any;
  phoneNumber:any;

  constructor(
    public dialogRef: MatDialogRef<ForgetPasswordComponent>,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  forgetPassword(){
    if (this.E164PhoneNumber) {
      this.phoneNumber = this.E164PhoneNumber.substr(1);

      this.dialogRef.close();

      this.openChangePasswordDialog(this.phoneNumber)
    }
  }

  openChangePasswordDialog(phoneNumber:any): void {

    const dialogRef = this.dialog.open(ChangePasswordComponent
      , {
        width: '550px',
        backdropClass: 'bg-color', // This is the "wanted" line
        data:{
          phoneNumber: phoneNumber
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['dashboard/home']);
    });
   
  }

}
