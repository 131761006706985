import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse} from "../../model";
import {StorageService} from "../../service/storage.service";
import {UserProfile} from "../model/user-profile";
import {MissionRequest} from "../model/mission-request";
import {FileResponse} from "../model/file-response";


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) {
  }

  getFile(fileName: string): Observable<FileResponse> {
    const url = `file-api/file/user/document/get?fileName=${fileName}`;
    return this.http.get<FileResponse>(url);
  }

}
