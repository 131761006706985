<div>
  <mat-card style=" height: auto" class="c-align">
    <form [formGroup]="form">
      <h1 style="color: black;text-align: center">{{data.title}}</h1>
    <div>



      <div class="col">

        <mat-form-field appearance="outline" class="w-75 h-25">
          <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}</mat-label>
          <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.address'|translate}}"
                 formControlName="address" class="w-100">
          <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color" color="green"></mat-icon>
        </mat-form-field>
      </div>

      <div class="col">

        <mat-form-field appearance="outline" class="w-75 h-25">
          <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.city'|translate}}</mat-label>
          <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.city'|translate}}"
                 formControlName="city" class="w-100">
          <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color" color="green"></mat-icon>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="outline" class="w-75 h-25">
          <mat-label> {{'nbs.businessownermanager.manageagents.missionInfo.zipCode'|translate}}</mat-label>
          <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.zipCode'|translate}}" formControlName="zipCode" class="w-100">
          <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color" color="green"></mat-icon>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="fill" class="input-rounded-decoration">
          <mat-label>{{'nbs.businessownermanager.manageagents.missionInfo.country'|translate}}</mat-label>
          <mat-select id="dressCode" name="dressCode" formControlName="country" class="w-100">
            <mat-option *ngFor="let val of countries" [value]="val.countryCode" style="color:  black">{{val.countryName}}
            </mat-option>
          </mat-select>
<!--          <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color" color="green"></mat-icon>-->
        </mat-form-field>
<!--        <mat-form-field appearance="outline" class="w-75 h-25">-->
<!--          <mat-label> {{'nbs.businessownermanager.manageagents.missionInfo.country'|translate}}</mat-label>-->
<!--          <input matInput placeholder="{{'nbs.businessownermanager.manageagents.missionInfo.country'|translate}}" formControlName="country" class="w-100">-->
<!--          <mat-icon matSuffix svgIcon="edit_pen" class="icon-display-fields fields-icon-color" color="green"></mat-icon>-->
<!--        </mat-form-field>-->
      </div>
    </div>

    <div class="p-margin c-align row" style="text-align: center;margin-top: 50px;margin-right:20px;margin-left: 50px ">

      <div class="col">

      </div>
      <div class="col" (click)="okDialog()">
        <button  mat-button color="primary" class="button-center" style="text-align: center;width: 70%;text-align:
      center;font-size: 14px" (click)="okDialog()"
                 [ngClass]="form.invalid?'button-grey-center':'button-center'"
                 [disabled]="form.invalid">
          {{'client.ok'|translate}}
        </button>

      </div>


    </div>
    </form>
  </mat-card>
</div>
