import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AuthenticationService} from "../../service/authentication.service";
import { UsersService } from 'src/app/service/users.service';
import { StorageService } from 'src/app/service/storage.service';
import { Menu } from 'src/app/model';

@Component({
  selector: 'app-businessownerdashboard',
  templateUrl: './businessownerdashboard.component.html',
  styleUrls: ['./businessownerdashboard.component.css']
})
export class BusinessownerdashboardComponent {

  username: string=" Thomas";

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    menus:any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UsersService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService) {
      this. getMenu();
    }

  logout(){
    this.authenticationService.logout();
  }

  getMenu(){

    if( this.storageService.getItem(StorageService.MENU)){
      this.menus=JSON.parse(this.storageService.getItem(StorageService.MENU));
    }else{
      var sub=this.userService.getMenu(this.storageService.getItem(StorageService.GROUPID),this.storageService.getItem(StorageService.USER_LANGUAGE)).subscribe((result:Menu)=>{

        if(result){
          this.storageService.saveObject(StorageService.MENU, result);
          this.menus=result ;
        
        }else{
         
        }
  
        sub.unsubscribe();
      },error=>{
        
        sub.unsubscribe();
        
      });
    }
  }

}
