import {BehaviorSubject, Observable, of} from "rxjs";
import {Mission} from "../../model";
import {MissionService} from "../mission.service";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";

export class BookedMissionDatasource implements DataSource<Mission>{
  private missionsSubject = new BehaviorSubject<Mission[]>([]);
  private missionData:Mission[]=[];
  private missionDataUnfiltered:Mission[]=[];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private missionService: MissionService) { }


  connect(collectionViewer: CollectionViewer): Observable<Mission[]> {
    return this.missionsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.missionsSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  loadMissions(pageNumber = 0, pageSize = 5,missionType: String ="all") {
    this.loadingSubject.next(true);
    this.missionService.getBookedMissions({ page: pageNumber, size: pageSize,missionType:missionType })
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result:any) => {
          this.missionsSubject.next(result.data.content);
          this.countSubject.next(result.data.totalElements);
          this.missionDataUnfiltered=result.data.content;
        }
      );
  }

  loadFiltered(filterValue:string){
    const searchValue=filterValue.trim().toLowerCase();
    this.missionData = this.missionDataUnfiltered.filter(mission=>{
      return (mission.missionName.toLowerCase().includes(searchValue)|| mission.clientPhoneNumber.toLowerCase().includes(searchValue)||
        mission.missionStreetAddress.toLowerCase().includes(searchValue)||mission.missionCityAddress.toLowerCase().includes(searchValue)||
        mission.missionZipCodeAddress.toLowerCase().includes(searchValue)||mission.missionDescription.toLowerCase().includes(searchValue)||
        mission.missionDressCode.toLowerCase().includes(searchValue)||mission.language.toLowerCase().includes(searchValue)||
        mission.missionStatus.toLowerCase().includes(searchValue))
    });
    this.missionsSubject.next(this.missionData);

  }
}
