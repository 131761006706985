import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Agency, CustomResponse, Partner } from 'src/app/model';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-view-agency-details',
  templateUrl: './view-agency-details.component.html',
  styleUrls: ['./view-agency-details.component.css']
})
export class ViewAgencyDetailsComponent implements OnInit {

  dataDetail=new Agency();

  constructor(
    public dialogRef: MatDialogRef<ViewAgencyDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private toastr: ToastrService
  ) {
    
   }

  ngOnInit(): void {

    Object.assign(this.dataDetail,this.data);
  }

  cancel(){
    this.dialogRef.close();
  }

  activateDeactivate(phonenumber:string){
    this.userService.activateAgency(phonenumber).subscribe((result:CustomResponse)=>{

      if(result.result === "OK"){
       
        this.toastr.success("Update successfully","ACTIVATE");
        this.dialogRef.close();
      }else{
      this.toastr.error("Error in processing");
      }
    });

   
  }


}
