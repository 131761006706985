import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FaqItem} from "../../client/model/faq-item";
import {MatAccordionDisplayMode} from "@angular/material/expansion";
import {Address} from "../../client/model/address";
import {UserAddress} from "../../client/model/UserAddress";
import {FaqService} from "../../client/service/faq.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-faq-client',
  templateUrl: './faq-client.component.html',
  styleUrls: ['./faq-client.component.css']
})
export class FaqClientComponent implements OnInit {
  private paginator: MatPaginator | undefined;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  length=1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];


  @Input()
  multi = false;

  @Input()
  displayMode = 'default' as MatAccordionDisplayMode; // or flat

  @Input()
  faqList: FaqItem[] = [];
  constructor(private faqService:FaqService) { }

  ngOnInit(): void {

    this.faqService.getAllFaq(0,10).subscribe((response) => {
      if (response.result === 'OK') {
        this.faqList=<FaqItem[]>response.data.content;
        this.length=response.data.numberOfElements
              }
    });
  }
  change(event:any)
  {
    //make something
    // this.paginator!.pageIndex, this.paginator!.pageSize
    console.log(event.length);
    console.log(event.pageIndex);
    console.log(event.pageSize);
    console.log(event.previousPageIndex)
    this.faqService.getAllFaq(event.pageIndex,event.pageSize).subscribe((response) => {
      if (response.result === 'OK') {
        this.faqList=<FaqItem[]>response.data.content;
        this.length=response.data.numberOfElements
      }
    });

  }
}
