import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse, Mission, MissionSetting} from "../../model";
import {UpdateMissionRequest} from "../model/update-mission-request";
import {MissionExtensionRequest} from "../model/mission-extension-request";
import {MissionRequest, MissionUpdateRequest} from "../model/mission-request";


@Injectable({
  providedIn: 'root'
})
export class MissionService {

  constructor(private http: HttpClient) {

  }
  getMissionAddresses(phoneNumber:any): Observable<any> {
    const url = environment.getMissionAddresses;
    return this.http.get<any>(url,{params:{phoneNumber:phoneNumber}});
  }
  createMission(request: MissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CREATE}`;
    return this.http.post<CustomResponse>(url,request);
  }

  updateMission(request: MissionUpdateRequest): Observable<CustomResponse> {
    const url = `/api/mission/update`;
    return this.http.put<CustomResponse>(url,request);
  }

  updateFavBookMission(request: MissionUpdateRequest): Observable<CustomResponse> {
    const url = `/api/mission/update/book/favorite/agent`;
    return this.http.put<CustomResponse>(url,request);
  }
  cancelMission(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CANCEL}`;
    return this.http.post<CustomResponse>(url,request);
  }

  bookFavoriteAgent(request: MissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_BOOK_FAVOURITE_AGENT}`;
    return this.http.post<CustomResponse>(url,request);
  }

  getNewMissions(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_GET_NEW}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }
  getPastMissions(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_GETPAST}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }



  getClientStatsData(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_DASHBOARD_STATS}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }

  clientGetUpcomingMissions(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_UPCOMING}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }

  clientGetUpcomingMissionsMissionStatus(phoneNumber: string, missionStatus: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_UPCOMING}?phoneNumber=${phoneNumber}&missionStatus=${missionStatus}`;
    return this.http.get<CustomResponse>(url);
  }

  clientGetOngoingMissions(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_ONGOING}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }

  clientConfirmMission(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_CONFIRM}`;
    return this.http.post<CustomResponse>(url,request);
  }


  clientConfirmMissionAgentArrival(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_ARRIVAL_CONFIRMAGENT}`;
    return this.http.post<CustomResponse>(url,request);
  }


  clientConfirmMissionStart(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_START}`;
    return this.http.post<CustomResponse>(url,request);
  }


  clientConfirmEndMission(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_END}`;
    return this.http.post<CustomResponse>(url,request);
  }


  clientExtendMission(request: MissionExtensionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_EXTEND}`;
    return this.http.post<CustomResponse>(url,request);
  }

  clientGetMission(phoneNumber: string, missionId: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_GET}?phoneNumber=${phoneNumber}&missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }


  clientGetMissionAgents(missionId: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_GET_AGENTS}?missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }

  rateAgent(request: UpdateMissionRequest): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_RATE_AGENT}`;
    return this.http.post<CustomResponse>(url,request);
  }

  getClientPastMissions(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_GETPAST}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }

  getMissionTransactions(missionId: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_CLIENT_GET_ACCOUNT_TRANSACTIONS}?missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }
  getMissionInvoices(missionId: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_GET_INVOICES}?missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }

  downloadPdf(base64String:string, fileName:string) {

    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  isValidMissionExtension(missionId: string): Observable<CustomResponse> {
    const url = `/api/mission/extension/valid?missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }

  agentGetMission(missionId:string,phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.MISSION_AGENT_GET}?phoneNumber=${phoneNumber}&missionId=${missionId}`;
    return this.http.get<CustomResponse>(url);
  }
  getMissionSetting(key: string,active:boolean): Observable<MissionSetting> {
    const url = `${environment.getMissionSetting}?key=${key}&active=${active}`;
    return this.http.get<MissionSetting>(url);
  }
  getMissionSettings(category: string,active:boolean): Observable<MissionSetting[]> {
    const url = `${environment.getMissionSettings}?category=${category}&active=${active}`;
    return this.http.get<MissionSetting[]>(url);
  }

  missionsWaitingForPayment(phoneNumber: string): Observable<Mission[]> {
    const url = `/api/mission/payments/waiting?phoneNumber=${phoneNumber}`;
    return this.http.get<Mission[]>(url);
  }
}
