// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.test.nbs.s-mobile.live',
  basicToken: 'Basic bW9hYy1hcHAtY2xpZW50Oll5a016SXoxS2N0TjVtQ1l2bW1q',
  approveAgentUrl:'/api/users/profile/changeProfileActivation',
  getAgentsPendingApprovalUrl: '/api/users/profile/agents/unapproved/get',
  firebase: {
    apiKey: "AIzaSyAJvrNu9w1YsGX6lWFhztrT_tUGYZQwNDY",
    authDomain: "nbs-brussels.firebaseapp.com",
    projectId: "nbs-brussels",
    storageBucket: "nbs-brussels.appspot.com",
    messagingSenderId: "192122549168",
    appId: "1:192122549168:web:74c3bfb197d5e7300e5c75",
    measurementId: "G-856GZZJ9V8"
  },
  maps_api_key: "AIzaSyAJvrNu9w1YsGX6lWFhztrT_tUGYZQwNDY",
  getExecutedMissionsUrl: '/api/mission/get/missions/executed',
  getBookedMissionsUrl: '/api/mission/get/missions/booked',
  getActiveAgents: '/api/users/profile/agents/get',
  getAgentHistoryList:'/api/mission/get/agent/history/all',
  getUserProfileByPhone:'/api/users/profile/get',
  getRevenueSummaryUrl: '/api/mission/revenue/summary/get',
  getMissionSetting:'/api/mission/get/setting',
  getMissionSettings:'/api/mission/settings',
  getMissionAddresses:'/users/profile/get/address',
  createFaq:'/api/users/faq/create',
  getFaq:'/api/users/faq/get',
  // PARTNER
  getBusinessPartner:'/api/users/partners/get',
  getPartners:'/api/users/partners/all',
  savePartner:'/api/users/partners/create',
  activatePartner:'/api/users/partners/activate',

  // AGENCIES
  getAgency:'/api/users/agencies/get',
  getAgencies:'/api/users/agencies/all',
  saveAgency:'/api/users/agencies/create',
  activateAgency:'/api/users/agencies/activate',

  // CLIENT
  getClient:'/api/users/client/get',
  getAllClient:'/api/users/client/all',
  getClientAccount:'/api/users/profile/account',
  activateClient:'/api/users/client/activate',
  getActiveCountries:'/api/users/country/active',
  changeRate:'/api/users/profile/changePaymentRate',
  // USER
  saveUser:'/api/auth-server/v1/user/sign-up',
  activateUser:'/api/auth-server/v1/user/activate',
  deactivateUser:'/api/auth-server/v1/user/deactivate',
  getUserStatus:'/api/auth-server/v1/user/status',
  resetPassword:'/api/auth-server/v1/user/password/reset',
  initiateResetPassord:'/api/auth-server/v1/user/password/initiate',


  // MENU
  getMenu:'/api/users/menu',
  getUserGroup:'/api/users/group',
  getAllUserGroup:'/api/users/group/all',
  getAllMenuByGroupid:'/api/users/menu/permission',
  setMenuPermission:'/api/users/menu/activate',


  // FILE SERVICE
  getImageAsBase64:'/file-api/file/user/document/get',

  // Tariffs
  getTariffs:'/tariff/get/all',
  saveTariff: '/tariff/add-update'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
