<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;text-align: center">
  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
  <div fxFlex="100"
       fxFlex.md="100"
       fxFlex.sm="100"
       fxFlex.xs="100"
       fxLayout="column"
       style="padding: 5px;">
    <div fxLayout="row" >
      <div style="text-align: center"> <img class="userPhoto" src="{{photoImage}}" ></div>
    </div>

  </div>
  </div>
<div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
  <div fxFlex="50"
       fxFlex.md="50"
       fxFlex.sm="50"
       fxFlex.xs="50"
       fxLayout="column"
       style="padding: 5px;">

      <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.registrationDate'|translate}} </span><span class="contentText">{{registrationDate | date: 'dd/MM/yyyy HH:mm'}} </span>

</div>
  <div fxFlex="50"
       fxFlex.md="50"
       fxFlex.sm="50"
       fxFlex.xs="50"
       fxLayout="column"
       style="padding: 5px;">

      <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.lastUpdateDate'|translate}} </span><span class="contentText">{{lastUpdateDate | date: 'dd/MM/yyyy HH:mm'}} </span>

  </div>
</div>
  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.nationalRegistration'|translate}}  </span><span class="contentText">{{nationalRegistration}} </span>

    </div>

    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.company'|translate}} </span><span class="contentText">{{businessName}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.firstName'|translate}} </span><span class="contentText"> {{firstName}}</span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.lastName'|translate}}  </span><span class="contentText">{{lastName}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.email'|translate}} </span><span class="contentText"> {{emailAddress}}</span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.phoneNumber'|translate}}  </span><span class="contentText">+{{phoneNumber}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.gender'|translate}}  </span><span class="contentText">{{gender}} </span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.dateOfBirth'|translate}}  </span><span class="contentText">{{dateOfBirth}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.height'|translate}}  </span><span class="contentText">{{height}} m </span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.weight'|translate}}  </span><span class="contentText">{{weight}} kg </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.language'|translate}}  </span><span class="contentText">{{language}} </span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.dressCode'|translate}}  </span><span class="contentText">{{dressCode}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.streetAddress'|translate}}  </span><span class="contentText">{{streetAddress}} </span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.city'|translate}} </span><span class="contentText">{{city}} - {{zipCode}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.distanceRestriction'|translate}}  </span><span class="contentText">{{distanceRestriction}} </span>

    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">

        <span class="label">{{'nbs.businessownermanager.manageagents.agentInfo.bankAccount'|translate}}  </span><span class="contentText">{{bankAccount}} </span>

    </div>

  </div>

  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <img class="agentCard" src="{{cardFrontImage}}">
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <img src="{{cardBackImage}}">
    </div>

  </div>
  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;" *ngIf="agentApproval">
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <div>
        <button mat-button (click)="rejectViewAgent()"  class="button-center" style="background-color: var(--danger); text-align: center;width: 70%;text-align:
        center;" >
          {{'nbs.businessownermanager.manageagents.agentInfo.rejectButton'|translate}}
        </button>
      </div>
    </div>
    <div fxFlex="50"
         fxFlex.md="50"
         fxFlex.sm="50"
         fxFlex.xs="50"
         fxLayout="column"
         style="padding: 5px;">
      <div>
        <button mat-button (click)="approveAgent()"  class="button-center" style="background-color: var(--success); text-align: center;width: 70%;text-align:
        center;" >
          {{'nbs.businessownermanager.manageagents.agentInfo.approveButton'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" style="padding-left: 10%; padding-right: 10%;margin-bottom: 30px;"  *ngIf="viewAgent">
    <div fxFlex="100"
         fxFlex.md="100"
         fxFlex.sm="100"
         fxFlex.xs="100"
         fxLayout="column"
         style="padding: 5px;">
      <div>

       

      <button mat-button *ngIf="isAgentActive" (click)="deactivateAgent()"  class="button-center" style="background-color: var(--danger); text-align: center; margin-left: 10px; margin-right: 10px;" >
           {{'btn.deactivate'|translate}}
        </button>

        <button mat-button *ngIf="!isAgentActive" (click)="activateAgent()"  class="button-center" style="background-color: var(--success); text-align: center; margin-left: 10px; margin-right: 10px;" >
            {{'btn.activate'|translate}}
        </button>

       

        <button mat-button (click)="closeViewAgent()"  class="button-center" style="background-color: var(--danger); text-align: center;text-align:
        center;" >
          {{'nbs.businessownermanager.manageagents.agentInfo.closeButton'|translate}}
        </button>
      </div>
  </div>
</div>
</div>
