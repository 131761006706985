import {Mission} from "../../model";
import {PushNotification} from "./push-notification";


export class UserProfile{
  id: number;
  createdDate: string;
  lastUpdateDate: string;
  firstName: string;
  lastName: string;
  userPhoneNumber: string;
  email: string;
  dateOfBirth: Date;
  height: number;
  weight: number;
  qualification: string;
  gender: string;
  dressCode: string;
  nationalRegister: string;
  bankAccountNumber: string;
  job: string;
  distanceRestriction: number;
  agentPhoto: string;
  agentPhotoPath: string;
  agentProfessionalCardFront: string;
  agentProfessionalCardFrontPath: number;
  agentProfessionalCardBack: string;
  agentProfessionalCardBackPath: number;
  agentProfessionalCardBackStatus: number;
  agentStatus: number;
  userType: string;
  version: number;
  businessType: string;
  streetAddress: string;
  city: string;
  addressZipCode: string;
  businessEmailAddress: string;
  businessPhoneNumber: string;
  businessName: string;
  vatNumber: string;
  businessStreetAddress: string;
  businessCity: string;
  businessZipCode: string;
  language: string;
  addressLocation: Point;
  userProfileStatus: string;

  //temp to store data
  mission: Mission;
  pushNotification: PushNotification;
  clientPaymentRate?:number;
}

export interface  Point{
  x: number;
  y: number;
}
