import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Observable} from "rxjs";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FormBuilder, Validators} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {DaysOfWeek, Tariff, TariffService} from "../../service/tariff.service";
import {MatDialog} from "@angular/material/dialog";
import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {HttpClient} from "@angular/common/http";

import {ToastrService} from "ngx-toastr";
import {tap} from "rxjs/operators";
import {TariffsDatasource} from "../../service/datasource/tariffs-datasource";
import {ChangeClientRateComponent} from "../manageclients/change-client-rate/change-client-rate.component";
import {AddTariffComponent} from "./add-tariff/add-tariff.component";




@Component({
  selector: 'app-tariff-management',
  templateUrl: './tariff-management.component.html',
  styleUrls: ['./tariff-management.component.css']
})
export class TariffManagementComponent implements OnInit {
  searchValue="";
  resultsLength = 0;
  // @ts-ignore
  dataSource :TariffsDatasource;




  columnsToDisplay = ['tariffName', 'costPerAgent', 'minimumNumberOfAgentsForTariff', 'maximumNumberOfAgentsForTariff',
    'dayForTariff', 'startTime', 'endTime', 'specialDay', 'specialDayDate', 'status', 'publicHoliday','buttons'];
  private paginator: MatPaginator ;


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  page = 1;
  pageSize = 5;
  pageSizes :number[]= [5, 10, 25,50,100];

  constructor(private httpClient: HttpClient,private dialog: MatDialog,
              private tariffService: TariffService,
              private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.dataSource = new TariffsDatasource(this.tariffService);
    this.dataSource?.loadTariffs();

    //this. loadTariffs();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.loadFiltered(filterValue);
  }

  ngAfterViewInit() {
    this.dataSource?.counter$
      .pipe(
        tap((count) => {
          // @ts-ignore
          this.paginator.length = count;
        })
      )
      .subscribe();

    // @ts-ignore
    this.paginator.page
      .pipe(
        tap(() => this.loadTariffs())
      )
      .subscribe();
  }

  loadTariffs() {

    // @ts-ignore
    this.dataSource?.loadTariffs(this.paginator.pageIndex, this.paginator.pageSize);

    // this.tariffService.loadTariffs()
    //   .pipe(
    //     catchError(() => of([]))
    //   )
    //   .subscribe((result:any) => {


    //     this.dataSource=result.data;
    //     }
    //   );
  }

  openTariffViewDetails(element:any){
    this.editTariff(element)
  }

  openViewInfo(data:any): void {

    // const dialogRef = this.dialog.open(ViewTariffDetailsComponent
    //   , {
    //     width: '50%',
    //     data:data
    //
    //   });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.loadTariffs()
    // });
  }
  editTariff(data:any): void {

    const dialogRef = this.dialog.open(AddTariffComponent
      , {
        width: '50%',
        data:data

      });

    dialogRef.afterClosed().subscribe(result => {
      this.loadTariffs();
    });

  }
  addTariff(){
    const dialogRef = this.dialog.open(AddTariffComponent
      , {
        width: '50%',


      });

    dialogRef.afterClosed().subscribe(result => {
      this.loadTariffs()
    });
  }
 //  dataSaved = false;
 //  tariffForm: any;
 //  allTariffs: Observable<Tariff[]>;
 //  dataSource :TariffsDatasource;
 //  selection = new SelectionModel<Tariff>(true, []);
 //  tariffIdUpdate = 'null';
 //  massage='null';
 //  SelectedDate = 'null';
 //  isPublicHoliday = false;
 //  daysOfWeek=DaysOfWeek;
 //  isSpecialDay = false;
 //  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 //  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
 //  displayedColumns: string[] = ['select', 'id', 'tariffName', 'costPerAgent', 'minimumNumberOfAgentsForTariff', 'maximumNumberOfAgentsForTariff', 'dayForTariff', 'startTime', 'endTime', 'specialDay', 'specialDayDate', 'status', 'publicHoliday', 'Edit', 'Delete'];
 //  @ViewChild(MatPaginator) paginator: MatPaginator;
 //  @ViewChild(MatSort) sort: MatSort;
 //
 //  constructor(private formbulider: FormBuilder, private tariffService: TariffService, private _snackBar: MatSnackBar,
 //              public dialog: MatDialog) {
 //    this.tariffService.getAllTariff().subscribe(data => {
 //      this.dataSource = new MatTableDataSource(data.data);
 //      this.dataSource.paginator = this.paginator;
 //      this.dataSource.sort = this.sort;
 //    });
 //  }
 //
 //  ngOnInit() {
 //    this.dataSource = new TariffsDatasource(this.tariffService);
 //    this.dataSource?.loadTariffs();
 //    this.tariffForm = this.formbulider.group({
 //      tariffName: ['', [Validators.required]],
 //      costPerAgent: ['', [Validators.required]],
 //      minimumNumberOfAgentsForTariff: ['', [Validators.required]],
 //      maximumNumberOfAgentsForTariff: ['', [Validators.required]],
 //      dayForTariff: ['', [Validators.required]],
 //      startTime: ['', [Validators.required]],
 //      endTime: ['', [Validators.required]],
 //      specialDay: ['', [Validators.required]],
 //      specialDayDate: ['', [Validators.required]],
 //      status: ['', [Validators.required]],
 //      publicHoliday: ['', [Validators.required]]
 //    });
 //    this.dataSource.paginator = this.paginator;
 //    this.dataSource.sort = this.sort;
 //  }
 //
 //  isAllSelected() {
 //    const numSelected = this.selection.selected.length;
 //    const numRows = !!this.dataSource && this.dataSource.data.length;
 //    return numSelected === numRows;
 //  }
 //
 //  /** Selects all rows if they are not all selected; otherwise clear selection. */
 //  masterToggle() {
 //    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(r => this.selection.select(r));
 //  }
 //  /** The label for the checkbox on the passed row */
 //  checkboxLabel(row: Tariff): string {
 //    if (!row) {
 //      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
 //    }
 //    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
 //  }
 //
 //  DeleteData() {
 //    debugger;
 //    const numSelected = this.selection.selected;
 //    if (numSelected.length > 0) {
 //      if (confirm("Are you sure to delete items ")) {
 //        this.tariffService.deleteData(numSelected).subscribe(result => {
 //          this.SavedSuccessful(2);
 //          this.loadAllTariffs();
 //        })
 //      }
 //    } else {
 //      alert("Select at least one row");
 //    }
 //  }
 //
 //  applyFilter(filterValue: any) {
 //    this.dataSource.filter = filterValue.trim().toLowerCase();
 //
 //    if (this.dataSource.paginator) {
 //      this.dataSource.paginator.firstPage();
 //    }
 //  }
 //
 //  loadAllTariffs() {
 //    this.tariffService.getAllTariff().subscribe(data => {
 //      this.dataSource = new MatTableDataSource(data.data);
 //      this.dataSource.paginator = this.paginator;
 //      this.dataSource.sort = this.sort;
 //    });
 //  }
 //  onFormSubmit() {
 //    this.dataSaved = false;
 //    const tariff = this.tariffForm.value;
 //    this.CreateTariff(tariff);
 //    this.tariffForm.reset();
 //  }
 //
 //  loadTariffToEdit(tariffId: string) {
 //    this.tariffService.getTariffById(tariffId).subscribe(tariff => {
 //      this.massage = 'null';
 //      this.dataSaved = false;
 //      // @ts-ignore
 //      this.tariffIdUpdate = tariff.id;
 //      this.tariffForm.controls['tariffName'].setValue(tariff.tariffName);
 //      this.tariffForm.controls['costPerAgent'].setValue(tariff.costPerAgent);
 //      this.tariffForm.controls['startTime'].setValue(tariff.startTime);
 //      this.tariffForm.controls['endTime'].setValue(tariff.endTime);
 //      this.tariffForm.controls['dateCreated'].setValue(tariff.dateCreated);
 //      this.tariffForm.controls['minimumNumberOfAgentsForTariff'].setValue(tariff.minimumNumberOfAgentsForTariff);
 //      this.tariffForm.controls['maximumNumberOfAgentsForTariff'].setValue(tariff.maximumNumberOfAgentsForTariff);
 //      this.tariffForm.controls['specialDay'].setValue(tariff.specialDay);
 //      this.tariffForm.controls['status'].setValue(tariff.status);
 //      this.tariffForm.controls['version'].setValue(tariff.version);
 //      this.tariffForm.controls['publicHoliday'].setValue(tariff.publicHoliday);
 //      // @ts-ignore
 //      this.CityId = tariff.Cityid;
 //      this.isSpecialDay = tariff.specialDay ? true : false;
 //      this.isPublicHoliday = tariff.publicHoliday ? true : false;
 //    });
 //
 //  }
 //  CreateTariff(tariff: Tariff) {
 //
 //    if (this.tariffIdUpdate == null) {
 //      // tariff.id = this.CountryId;
 //      // tariff.StateId = this.StateId;
 //      // tariff.Cityid = this.CityId;
 //
 //      this.tariffService.createTariff(tariff).subscribe(
 //        () => {
 //          this.dataSaved = true;
 //          this.SavedSuccessful(1);
 //          this.loadAllTariffs();
 //          this.tariffIdUpdate = 'null';
 //          this.tariffForm.reset();
 //        }
 //      );
 //    } else {
 //      // @ts-ignore
 //      tariff.EmpId = this.tariffIdUpdate;
 //      // @ts-ignore
 //      tariff.CountryId = this.CountryId;
 //      // @ts-ignore
 //      tariff.StateId = this.StateId;
 //      // @ts-ignore
 //      tariff.Cityid = this.CityId;
 //      this.tariffService.updateTariff(tariff).subscribe(() => {
 //        this.dataSaved = true;
 //        this.SavedSuccessful(0);
 //        this.loadAllTariffs();
 //        this.tariffIdUpdate = 'null';
 //        this.tariffForm.reset();
 //      });
 //    }
 //  }
 //  deleteTariff(tariffId: string) {
 //    if (confirm("Are you sure you want to delete this ?")) {
 //      this.tariffService.deleteTariffById(tariffId).subscribe(() => {
 //        this.dataSaved = true;
 //        this.SavedSuccessful(2);
 //        this.loadAllTariffs();
 //        this.tariffIdUpdate = 'null';
 //        this.tariffForm.reset();
 //
 //      });
 //    }
 //
 //  }
 //
 //
 //
 //
 // resetForm() {
 //    this.tariffForm.reset();
 //    this.massage = 'null';
 //    this.dataSaved = false;
 //    this.isPublicHoliday = false;
 //    this.isSpecialDay = false;
 //    this.loadAllTariffs();
 //  }
 //
 //  SavedSuccessful(isUpdate:any) {
 //    if (isUpdate == 0) {
 //      this._snackBar.open('Record Updated Successfully!', 'Close', {
 //        duration: 2000,
 //        horizontalPosition: this.horizontalPosition,
 //        verticalPosition: this.verticalPosition,
 //      });
 //    }
 //    else if (isUpdate == 1) {
 //      this._snackBar.open('Record Saved Successfully!', 'Close', {
 //        duration: 2000,
 //        horizontalPosition: this.horizontalPosition,
 //        verticalPosition: this.verticalPosition,
 //      });
 //    }
 //    else if (isUpdate == 2) {
 //      this._snackBar.open('Record Deleted Successfully!', 'Close', {
 //        duration: 2000,
 //        horizontalPosition: this.horizontalPosition,
 //        verticalPosition: this.verticalPosition,
 //      });
 //    }
 //  }
 //  FillDays(event:any) {
 //
 //  }
}
