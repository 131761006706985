<div fxFlex fxLayout="column" fxLayoutGap="10px" style="height: 100vh; margin-top: 30px;">

  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <!-- loop over the cardList and show the cards -->

  </div>
  <div fxLayout="row wrap" style="padding-left: 1%; padding-right: 1%;margin-bottom: 100px;">
    <div  fxFlex="100"
          fxFlex.md="100"
          fxFlex.sm="100"
          fxFlex.xs="100"
          fxLayout="column"
          style="padding: 5px;">
      <mat-form-field appearance="standard" class="header-search">
        <mat-label>{{'nbs.businessownermanager.manageagents.search'|translate}}</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource"  >

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

       

        <!-- Name Column -->
        <ng-container matColumnDef="agentName">
          <th mat-header-cell *matHeaderCellDef> {{'label.agentName'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: justify;">
              <p style="font-weight: bold">{{element.firstName}} {{element.lastName}}</p>
              <p>{{element.address}}</p>
            </div>
             </td>
        </ng-container>

         <!-- Position Column -->
         <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef> {{'label.phoneNumber'|translate}} </th>
          <td mat-cell *matCellDef="let element">
            <div style="text-align: justify;">
              <p style="font-weight: bold">+{{element.userPhoneNumber}}</p>
            </div>
             </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef> {{'label.dob'|translate}} </th>
          <td mat-cell *matCellDef="let element">  {{element.dateOfBirth}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef >  </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button style="background-color: var(--medium-color)" (click)="openSignInDialog(element)">{{'nbs.businessownermanager.manageagents.agentRegistration.approveButton'|translate}}</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>


      </table>
      <mat-paginator  [pageSize]="5" [pageSizeOptions]="[5, 10, 25,50, 100]"></mat-paginator>

    </div>
  </div>
</div>
