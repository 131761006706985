import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  // getImageAsBase64(fileName: string): Observable<any> {
  //   const url = `https://pilim.net/file-api/file/user/document/get?fileName=${fileName}`;
  //   return this.http.get<any>(url);
  // }

  getImageAsBase64(fileName: string): Observable<any> {
    //console.log('********************* '+fileName)
    const url = environment.getImageAsBase64;
    return this.http.get<any>(url,{params:{fileName:fileName}});
  }

}
