import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { CustomResponse } from 'src/app/model';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent implements OnInit {

  e164Number: string|any ;

  rForm: FormGroup;

  phoneNumber='';
  separateDialCode = false;
	SearchCountryField = SearchCountryField;

	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  submitted:boolean=false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddPartnerComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public usersService: UsersService,
  ) { 
    this.rForm=this.fb.group({ 
      companyName: ['',Validators.required],
      emailAddress: [''],
      phoneNumber:["",Validators.required],
      vatNumber : ['']
    });
  }

  ngOnInit(): void {
  }

  confirmSave(){

    this.submitted=true;

    if (this.rForm.valid) {

      this.phoneNumber=this.rForm.value.phoneNumber.e164Number
      
      this.rForm.get('phoneNumber')?.setValue(this.phoneNumber.startsWith("+")?this.phoneNumber.substr(1):this.phoneNumber)


      var sub=this.usersService.savePartner(this.rForm.value).subscribe((result:CustomResponse)=>{

        if(result.result === "OK"){
      
          this.toastr.success("Save successfully","ADD");
          this.dialogRef.close();
        
        }else{
          this.toastr.error("Error in processing");
        }


        sub.unsubscribe();
      },error=>{
        
        sub.unsubscribe();
        this.toastr.error("Error in processing");
      })

    }

    
      console.log(this.rForm.value)

   
  }

  cancel(){
    this.dialogRef.close();
  }

}
