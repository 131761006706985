import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment.prod";
import {CustomResponse} from "../../model";
import {StorageService} from "../../service/storage.service";
import {UserProfile} from "../model/user-profile";
import {UpdateMissionRequest} from "../model/update-mission-request";
import {FavoriteAgent} from "../model/favorite-agent";


@Injectable({
  providedIn: 'root'
})
export class ClientUserService {

  constructor(private http: HttpClient,
              private storageService: StorageService) {

  }

  getProfile(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.getUserUrl}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }
  getAccount(phoneNumber: string): Observable<CustomResponse> {
    const url = `${environment.getClientAccount}?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }
  getDressCode(): Observable<CustomResponse> {
    const url = `${environment.GET_DRESSCODE}`;
    return this.http.get<CustomResponse>(url);
  }

  getLoggedInProfile(): UserProfile | null {

    const result = this.storageService.getItem(StorageService.CLIENT_AGENT_PROFILE)

    if(result){
      return JSON.parse(result) as UserProfile;
    }
    return  null;

  }

  getAgentLocation(phoneNumber: string):Observable<CustomResponse>{

    const url = `/api/users/agent/location?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }


  createUser(request: any): Observable<CustomResponse> {
    const url = `/api/users/profile/create`;
    return this.http.post<CustomResponse>(url,request);
  }

  addAgentAsFavorite(request:FavoriteAgent): Observable<CustomResponse> {
    const url = `/api/users/profile/favorite/agent/add`;
    return this.http.post<CustomResponse>(url,request);
  }


  getFavoriteAgents(phoneNumber: string): Observable<CustomResponse> {
    const url = `/api/users/profile/favorite/agent?phoneNumber=${phoneNumber}`;
    return this.http.get<CustomResponse>(url);
  }
}
